export const AIR_TABLE_CONTACT_DATA = {
  urls: {
    en: 'https://airtable.com/embed/shrYZnliZtmKSNULl?backgroundColor=gray',
  },
  name: 'Username',
  email: 'Email',
  projectName: 'ProjectName',
  projectUuid: 'Project UUID',
  date: 'Date',
};

export const AIR_TABLE_FEEDBACK_DATA = {
  urls: {
    en: 'https://airtable.com/embed/shrK7NhHDvPKkGeR0?backgroundColor=purple',
    fr: 'https://airtable.com/embed/shroZZgf3br58hr2x?backgroundColor=purple',
  },
  username: 'Username',
  email: 'Email',
  date: 'Date',
};

export const AIR_TABLE_PUBLISH_DATA = {
  url: {
    fr: 'https://airtable.com/embed/shrrPWkLtSHHlkjcG',
    en: 'https://airtable.com/embed/shru2Ih1GErIBamG5',
  },
  email: 'Email',
  userName: 'Name',
  projectName: 'Project name',
  projectUuid: 'Project UUID',
};
