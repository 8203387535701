import dayjs from 'dayjs';
import CONSTANTS from '../Constants';
import * as Exports from '../helpers/exports';
import {
  EXPORT_STATUS,
  generateExportRealName,
  generateExportTechnicalName,
} from '../tools/ExportTools';
import { getExportIRI } from '../tools/IRITools';
import PROJECTSACTIONS from './projectsStateConstants';
import { downloadFile } from '../tools/DownloadTools';
import {
  isExportJobRunning,
} from './exportSelector';

const initialState = {
  error: null,
  status: null,
  hasLoaded: false,
  exports: [],
  lastRunningExportDate: null,
};

export const ACTIONS = {
  CLEAR_ERROR: 'EXPORTS_CLEAR_ERROR',

  GET_EXPORTS_REQUEST: 'GET_EXPORTS_REQUEST_API_GET_REQUEST',
  GET_EXPORTS_SUCCESS: 'GET_EXPORTS_SUCCESS_API_GET_SUCCESS',
  GET_EXPORTS_FAILURE: 'GET_EXPORTS_FAILURE_API_GET_FAILURE',

  CREATE_EXPORTS_REQUEST: 'CREATE_EXPORTS_REQUEST_API_POST_REQUEST',
  CREATE_EXPORTS_SUCCESS: 'CREATE_EXPORTS_SUCCESS_API_POST_SUCCESS',
  CREATE_EXPORTS_FAILURE: 'CREATE_EXPORTS_FAILURE_API_POST_FAILURE',

  DELETE_EXPORTS_REQUEST: 'DELETE_EXPORTS_REQUEST_API_POST_REQUEST',
  DELETE_EXPORTS_SUCCESS: 'DELETE_EXPORTS_SUCCESS_API_POST_SUCCESS',
  DELETE_EXPORTS_FAILURE: 'DELETE_EXPORTS_FAILURE_API_POST_FAILURE',

  START_DOWNLOAD_REQUEST: 'START_DOWNLOAD_REQUEST',
  START_DOWNLOAD_SUCCESS: 'START_DOWNLOAD_SUCCESS',
  START_DOWNLOAD_FAILURE: 'START_DOWNLOAD_FAILURE',
};

export const EXPORTS_REVERT_ACTIONS = {
  // Delete DataKeyValue UNDO REDO parameters
  [ACTIONS.DELETE_EXPORTS_SUCCESS]: {
    undo: {
      command: (action, { name, replayToken }) => createExport(name, replayToken),
      createArgs: undoArgsForDeleteExport,
      succeededWhen: [ACTIONS.CREATE_EXPORTS_SUCCESS],
      failedWhen: [ACTIONS.CREATE_EXPORTS_FAILURE],
      getParamsToPatch: getParamsToPatchForDeleteExport,
    },
    redo: {
      command: (action, { exportIri }) => deleteExport(exportIri, () => {}),
      createArgs: redoArgsForDeleteExport,
      succeededWhen: [ACTIONS.DELETE_EXPORTS_SUCCESS],
      failedWhen: [ACTIONS.DELETE_EXPORTS_FAILURE],
    },
  },
};

/**
 * Creates the arguments for the undo command of a datakey deletion
 * @param {*} state state when the action to undo was called
 * @param {*} action action to undo
 */
function undoArgsForDeleteExport(state, action) {
  const deletedExport = {};
  for (let i = 0; i < state.exports.exports.length; i += 1) {
    if (state.exports.exports[i][CONSTANTS.IRI_FIELD]
      === action.deletedExport[CONSTANTS.IRI_FIELD]) {
      deletedExport.name = state.exports.exports[i].name;
      deletedExport.replayToken = state.exports.exports[i].playlistGeneratorReplayToken;
    }
  }
  return {
    name: deletedExport.name,
    replayToken: deletedExport.replayToken,
  };
}

/**
 * Creates the arguments for the redo command of a datakey deletion
 * @param {*} state state when the action to redo was called
 * @param {*} action action to undo
 */
function redoArgsForDeleteExport(state, action) {
  return {
    exportIri: action.deletedExport,
  };
}

/**
 * Retrieves the parameters that changed during the undo command
 * and that needs to be patched in the redoQueue args
 * @param {*} state state when the action to redo was called
 * @param {*} oldaction action to re-do
 * @param {*} newaction action that was re-done
 */
function getParamsToPatchForDeleteExport(state, oldaction, newaction) {
  let paramstopatch = [];

  const oldIri = oldaction.deletedExport[CONSTANTS.IRI_FIELD];
  const newIri = newaction.response.data[CONSTANTS.IRI_FIELD];

  if (oldIri !== newIri) {
    const idParamtopatch = {
      field: CONSTANTS.IRI_FIELD,
      type: 'ExperienceExport',
      previousvalue: oldIri,
      newvalue: newIri,
    };
    paramstopatch = [...paramstopatch, idParamtopatch];
  }

  return paramstopatch;
}

/**
 * Update the eport of the currentProject
 * by requesting the new array of export of the project
 */
export const updateExports = () => (dispatch, getState) => {
  dispatch({
    type: ACTIONS.GET_EXPORTS_REQUEST,
    payload: 'waiting response from API ...',
  });

  const { currentProject } = getState().projects;
  const { lastRunningExportDate } = getState().exports;

  if ((currentProject === null) || (typeof currentProject === 'undefined')) {
    dispatch({
      type: ACTIONS.GET_EXPORTS_FAILURE,
      error: CONSTANTS.COMMONERRORS.NO_CURRENT_PROJECT,
    });
    return;
  }

  const filterParams = {};
  if ((typeof lastRunningExportDate !== 'undefined')
   && (lastRunningExportDate !== null)) {
    filterParams['createdAt[after]'] = lastRunningExportDate;
  }

  Exports.getExports(currentProject.uuid, filterParams).then((exportsData) => {
    dispatch({
      type: ACTIONS.GET_EXPORTS_SUCCESS,
      exports: exportsData.exports,
    });
  }).catch((error) => {
    dispatch({
      type: ACTIONS.GET_EXPORTS_FAILURE,
      error: error.message,
    });
  });
};


/**
 * Add new export to th project by requesting API with new export name
 */
export const createExport = (name, replayToken, qualif) => (dispatch, getState) => {
  dispatch({ type: ACTIONS.CLEAR_ERROR });

  dispatch({
    type: ACTIONS.CREATE_EXPORTS_REQUEST,
    payload: 'waiting response from API ...',
  });

  const { currentProject } = getState().projects;

  if ((currentProject === null) || (typeof currentProject === 'undefined')) {
    dispatch({
      type: ACTIONS.CREATE_EXPORTS_FAILURE,
      error: CONSTANTS.COMMONERRORS.NO_CURRENT_PROJECT,
    });
    return;
  }

  const realname = generateExportRealName(name, qualif, currentProject.name);
  const technicalName = generateExportTechnicalName(realname);

  Exports.createExports(realname, technicalName, replayToken, currentProject.uuid)
    .then((response) => {
      dispatch({
        type: ACTIONS.CREATE_EXPORTS_SUCCESS,
        status: ACTIONS.CREATE_EXPORTS_SUCCESS,
        response,
      });
    }).catch((error) => {
      dispatch({
        type: ACTIONS.CREATE_EXPORTS_FAILURE,
        error: error.message,
      });
    });
};

/**
 * Delete export to th project by requesting API with export iri
 */
export const deleteExport = (exportIri, resolvePromise) => (dispatch, getState) => {
  dispatch({ type: ACTIONS.CLEAR_ERROR });

  dispatch({
    type: ACTIONS.DELETE_EXPORTS_REQUEST,
    payload: 'waiting response from API ...',
  });

  const { currentProject } = getState().projects;

  if ((currentProject === null) || (typeof currentProject === 'undefined')) {
    dispatch({
      type: ACTIONS.DELETE_EXPORTS_FAILURE,
      error: CONSTANTS.COMMONERRORS.NO_CURRENT_PROJECT,
    });
    return;
  }


  Exports.deleteExport(exportIri[CONSTANTS.IRI_FIELD])
    .then((response) => {
      dispatch({
        type: ACTIONS.DELETE_EXPORTS_SUCCESS,
        status: ACTIONS.DELETE_EXPORTS_SUCCESS,
        deletedExport: exportIri,
      });
      resolvePromise();
    }).catch((error) => {
      dispatch({
        type: ACTIONS.DELETE_EXPORTS_FAILURE,
        error: error.message,
      });
      resolvePromise();
    });
};

/**
 * Start the download of a given uuid of export
 */
export const startDownload = (exportIri) => (dispatch, getState) => {
  dispatch({
    type: ACTIONS.START_DOWNLOAD_REQUEST,
    payload: 'waiting response from API ...',
  });

  const { currentProject } = getState().projects;

  if ((currentProject === null) || (typeof currentProject === 'undefined')) {
    dispatch({
      type: ACTIONS.START_DOWNLOAD_FAILURE,
      error: CONSTANTS.COMMONERRORS.NO_CURRENT_PROJECT,
    });
    return;
  }


  Exports.getDownloadUrl(exportIri)
    .then((response) => {
      downloadFile(response.url);

      dispatch({
        type: ACTIONS.START_DOWNLOAD_SUCCESS,
        status: ACTIONS.START_DOWNLOAD_SUCCESS,
        iri: exportIri,
        url: response.url,
        expireAt: response.expires_at,
      });
    }).catch((error) => {
      dispatch({
        type: ACTIONS.START_DOWNLOAD_FAILURE,
        error: error.message,
      });
    });
};

let pollRunningExportsTimeoutID = null;
export const loadRunningExports = (sinceDate) => (dispatch, getState) => {
  // Reset current timeout
  if (pollRunningExportsTimeoutID != null) {
    clearTimeout(pollRunningExportsTimeoutID);
  }
  pollRunningExportsTimeoutID = null;

  const {
    currentProject,
  } = getState().projects;
  if ((currentProject === null) || (typeof currentProject === 'undefined')) {
    return;
  }

  const {
    lastRunningExportDate,
  } = getState().exports;

  let internalSinceDate = sinceDate;

  if ((typeof internalSinceDate === 'undefined')
   || (internalSinceDate === null)) {
    if ((typeof lastRunningExportDate === 'undefined')
    || (lastRunningExportDate === null)) {
      internalSinceDate = currentProject.createdAt;
    } else {
      internalSinceDate = lastRunningExportDate;
    }
  }

  const filterParams = {};

  if ((typeof internalSinceDate !== 'undefined')
   && (internalSinceDate !== null)) {
    filterParams['createdAt[after]'] = internalSinceDate;
  }
  if ((typeof lastRunningExportDate === 'undefined')
   || (lastRunningExportDate === null)) {
    filterParams['awsExperienceExportJobs.jobsStatus.name'] = [EXPORT_STATUS.PENDING, EXPORT_STATUS.PROCESSING];
  }

  dispatch({
    type: ACTIONS.GET_EXPORTS_REQUEST,
    payload: 'waiting response from API ...',
  });
  Exports.getExports(currentProject.uuid, filterParams).then((exportsData) => {
    dispatch({
      type: ACTIONS.GET_EXPORTS_SUCCESS,
      exports: exportsData.exports,
    });

    // Start a pool loop on running exports
    let timeinterval = 120 * 1000;
    let sinceWhen = dayjs().format();
    if (exportsData.exports.length !== 0) {
      timeinterval = 60 * 1000;
      sinceWhen = null;
    }
    pollRunningExportsTimeoutID = setTimeout(() => {
      dispatch(loadRunningExports(sinceWhen));
    }, timeinterval);

  }).catch((error) => {
    dispatch({
      type: ACTIONS.GET_EXPORTS_FAILURE,
      error: error.message,
    });
  });
};

export default (state = initialState, action) => {
  switch (action.type) {
    case PROJECTSACTIONS.CLEAR_ERROR:
    case ACTIONS.CLEAR_ERROR:
      return {
        ...state,
        error: null,
        status: null,
      };

    case PROJECTSACTIONS.GET_PROJECT_REQUEST:
      return {
        ...initialState,
      };

    case ACTIONS.GET_EXPORTS_REQUEST:
      return {
        ...state,
        status: ACTIONS.GET_EXPORTS_REQUEST,
      };

    case ACTIONS.GET_EXPORTS_SUCCESS:
    {
      let newExports = [...state.exports];

      let newLastRunningExportDate = null;
      if ((typeof newExports === 'undefined')
       || (newExports === null)
       || (newExports.length === 0)) {
        newExports = action.exports;
        // Get the date of the 'first' running export in the list
        for (
          let i = (newExports.length - 1);
          (i > -1) && (newLastRunningExportDate === null);
          i -= 1) {
          if (isExportJobRunning(newExports[i])) {
            newLastRunningExportDate = newExports[i].createdAt;
          }
        }
      } else {
        action.exports.forEach((exp) => {
          // Get the date of the 'first' running export in the list
          if (isExportJobRunning(exp)) {
            const expDate = new Date(exp.createdAt);
            const lastRunningExpDate = new Date(newLastRunningExportDate);
            if ((typeof newLastRunningExportDate === 'undefined')
             || (newLastRunningExportDate === null)) {
              newLastRunningExportDate = exp.createdAt;
            } else if (expDate < lastRunningExpDate) {
              newLastRunningExportDate = exp.createdAt;
            }
          }
          const expIdx = newExports.findIndex((elem) => elem.uuid === exp.uuid);
          if (expIdx === -1) {
            newExports.push(exp);
          } else {
            newExports.splice(expIdx, 1, exp);
          }
        });
      }

      return {
        ...state,
        status: ACTIONS.GET_EXPORTS_SUCCESS,
        exports: newExports,
        hasLoaded: true,
        lastRunningExportDate: newLastRunningExportDate,
      };
    }

    case ACTIONS.GET_EXPORTS_FAILURE:
      return {
        ...state,
        status: ACTIONS.GET_EXPORTS_FAILURE,
        error: action.error,
      };

    case ACTIONS.CREATE_EXPORTS_REQUEST:
      return {
        ...state,
        status: ACTIONS.CREATE_EXPORTS_REQUEST,
      };

    case ACTIONS.CREATE_EXPORTS_SUCCESS:
    {
      const newExportsArray = [...state.exports];
      newExportsArray.push(action.response.data);

      return {
        ...state,
        status: ACTIONS.CREATE_EXPORTS_SUCCESS,
        exports: newExportsArray,
      };
    }

    case ACTIONS.CREATE_EXPORTS_FAILURE:
      return {
        ...state,
        status: ACTIONS.CREATE_EXPORTS_FAILURE,
        error: action.error,
      };
    case ACTIONS.DELETE_EXPORTS_REQUEST:
      return {
        ...state,
        status: ACTIONS.DELETE_EXPORTS_REQUEST,
      };
    case ACTIONS.DELETE_EXPORTS_SUCCESS:
    {
      const newExportsArray = [...state.exports];
      let found = false;
      for (let i = 0; (i < newExportsArray.length) && (!found); i += 1) {
        if (getExportIRI(newExportsArray[i]) === getExportIRI(action.deletedExport)) {
          newExportsArray.splice(i, 1);
          found = true;
        }
      }

      return {
        ...state,
        status: ACTIONS.DELETE_EXPORTS_SUCCESS,
        exports: newExportsArray,
      };
    }
    case ACTIONS.DELETE_EXPORTS_FAILURE:
      return {
        ...state,
        status: ACTIONS.DELETE_EXPORTS_FAILURE,
        error: action.error,
      };

    case ACTIONS.START_DOWNLOAD_REQUEST:
      return {
        ...state,
        status: ACTIONS.START_DOWNLOAD_REQUEST,
      };
    case ACTIONS.START_DOWNLOAD_SUCCESS:
    {
      const downloadData = {
        url: action.url,
        expireAt: action.expireAt,
      };
      const newExportsArray = [...state.exports];
      let idx = -1;
      for (let i = 0; (i < newExportsArray.length) && (idx === -1); i += 1) {
        if (getExportIRI(newExportsArray[i]) === action.iri) {
          idx = i;
        }
      }
      if (idx !== -1) {
        newExportsArray[idx] = {
          ...state.exports[idx],
        };
        if ((typeof newExportsArray[idx].downloadData !== 'undefined')
        && (newExportsArray[idx].downloadData !== null)) {
          delete newExportsArray[idx].downloadData;
        }
        newExportsArray[idx].downloadData = downloadData;
      }

      return {
        ...state,
        status: ACTIONS.START_DOWNLOAD_SUCCESS,
        exports: newExportsArray,
      };
    }
    case ACTIONS.START_DOWNLOAD_FAILURE:
      return {
        ...state,
        status: ACTIONS.START_DOWNLOAD_FAILURE,
        error: action.error,
      };

    default:
      return state;
  }
};


