import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Color, FontSizes } from './UIConstants';

const useStyles = makeStyles(theme => ({
  container: {
    border: '1px solid rgba(0, 0, 0, .125)',
    boxShadow: 'none',
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    backgroundColor: Color.white,
    height: '100%',
    minHeight: 0,
  },
  header: {
    backgroundColor: 'rgba(0, 0, 0, 0.03)',
    borderBottom: '1px solid rgba(0, 0, 0, .125)',
    marginBottom: -1,
    flex: 0,
    padding: '0 24px 0 24px',
  },
  title: {
    margin: '10px 0px 5px 0px',
    display: 'flex',
    flexGrow: '1',
  },
  headerFilter: {
    margin: '0px 0px 10px 0px',
    border: 'none',
    backgroundColor: 'transparent',
    fontSize: FontSizes.textSmall,
    '&:focus': {
      outline: 'none',
    },
  },
  sortIcon: {
    fontSize: FontSizes.textMedium,
    padding: '0px',
    margin: '0px 0px 0px 4px',
    verticalAlign: 'baseline',
    cursor: 'pointer',
  },
  children: {
    padding: '5px',
    flexGrow: '1',
    height: '100%',
    minHeight: 0,
  },
}));

function SkwrPanel({
  title,
  children,
  className,
  onClickFilter,
  sortHandler,
  sortMessage,
}) {
  const classes = useStyles();

  return (
    <div className={classes.container}>
      <div className={classes.header}>
        <div className={classes.title}>{ title }</div>
        <button
          className={classes.headerFilter}
          onClick={onClickFilter}
        >
          {sortMessage}
          <span
            className={classes.sortIcon}
          >
            { sortHandler.value ? '↑' : '↓' }
          </span>
        </button>
      </div>
      <div className={classes.children}>{ children }</div>
    </div>
  );
}

export default SkwrPanel;
