/**
 * File: previewStateConstants.js
 */
const ACTIONS = {
  CLEAR_ERROR: 'PREVIEW_CLEAR_ERROR',

  RESET_PREVIEW: 'RESET_PREVIEW',

  // PREVIEW REQUESTS
  // ----------
  NEW_PREVIEW_REQUEST: 'NEW_PREVIEW_API_GET_REQUEST',
  NEW_PREVIEW_SUCCESS: 'NEW_PREVIEW_API_GET_SUCCESS',
  NEW_PREVIEW_FAILURE: 'NEW_PREVIEW_API_GET_FAILURE',

  SDK_REQUEST: 'GET_DATA_PREVIEW_API_GET_REQUEST',
  SDK_SUCCESS: 'GET_DATA_PREVIEW_API_GET_SUCCESS',
  SDK_ERROR: 'SDK_ERROR',

  NEW_EXPORT_SINGLE_REQUEST: 'NEW_EXPORT_SINGLE_API_POST_REQUEST',
  NEW_EXPORT_SINGLE_SUCCESS: 'NEW_EXPORT_SINGLE_API_POST_SUCCESS',
  NEW_EXPORT_SINGLE_FAILURE: 'NEW_EXPORT_SINGLE_API_POST_FAILURE',

  SET_RELOAD_PLAYER_FALSE: 'SET_RELOAD_PLAYER_FALSE',

  SET_PLAYER_NEW_VOLUME: 'SET_PLAYER_NEW_VOLUME',
};
export default ACTIONS;
