/**
 * File: NodesActions.js
 */
import { resetProjectSummary } from './projectsActions';
import LABELSACTIONS from './labelsStateConstants';
import * as Project from '../helpers/project';
import * as Labels from '../helpers/labels';
import * as Links from '../helpers/links';
import * as Nodes from '../helpers/nodes';
import CONSTANTS from '../Constants';
import {
  getDkIRI,
  getDkvIRI,
  getLinkIRI,
  getLabelIRI,
  getProjectIRI,
  getConnectableIRI,
  getVideoMediaTypeIRI,
  getAudioMediaTypeIRI,
  setAssetIRI,
} from '../tools/IRITools';
import { isKeyInstance, isVideoConnectable } from '../tools/ConnectableTools';
import {
  getKeyInstanceTargets,
  getConnectableFromIRI,
  getAudioRootConnectableIRI,
  getTargetOfKeyInstanceTargets,
  getConnectablesToDeleteToRestoreSingleTrack,
} from './nodesSelectors';
import {
  getConnectableSize,
  SIZES,
} from '../scenarioEditor/constants';
import { getAssetFromIRI } from './mediaLibrarySelector';
import { changeAssetsLabels } from './mediaLibraryActions';
import { getDataKeyFromIRI } from './dataKeysSelectors';
import ACTIONS from './nodesStateConstants';
import {
  LABEL_TYPES,
} from '../tools/LabelTools';
import {
  isValidPosition,
} from '../tools/OtherTools';

export const NODES_REVERT_ACTIONS = {
  // Modify connectables coordinates UNDO REDO parameters
  [ACTIONS.CHANGE_CONNECTABLES_COORDINATES_SUCCESS]: {
    undo: {
      command: (action, { connectables }) => changeConnectablesCoordinates(connectables),
      createArgs: undoArgsForChangeConnectablesCoords,
      succeededWhen: [ACTIONS.CHANGE_CONNECTABLES_COORDINATES_SUCCESS],
      failedWhen: [ACTIONS.CHANGE_CONNECTABLES_COORDINATES_FAILURE],
    },
    redo: {
      command: (action, { connectables }) => changeConnectablesCoordinates(connectables),
      createArgs: redoArgsForChangeConnectablesCoords,
      failedWhen: [ACTIONS.CHANGE_CONNECTABLES_COORDINATES_FAILURE],
    },
  },
  [ACTIONS.CHANGE_NODE_LABEL_SUCCESS_UNDOABLE]: {
    undo: {
      command: (action, { node, label }) => changeNodeLabel(node, label),
      createArgs: undoArgsForChangeConLabel,
      succeededWhen: [ACTIONS.CHANGE_NODE_LABEL_SUCCESS],
      failedWhen: [ACTIONS.CHANGE_NODE_LABEL_FAILURE],
    },
    redo: {
      command: (action, { node, label }) => changeNodeLabel(node, label),
      createArgs: redoArgsForChangeConLabel,
      succeededWhen: [ACTIONS.CHANGE_NODE_LABEL_SUCCESS],
      failedWhen: [ACTIONS.CHANGE_NODE_LABEL_FAILURE],
    },
  },
  [ACTIONS.LINK_CONNECTABLES_SUCCESS_UNDOABLE]: {
    undo: {
      command: (action, { links }) => deleteLinks(links, false),
      createArgs: undoArgsForLinkConnectables,
      succeededWhen: [ACTIONS.DELETE_LINKS_SUCCESS],
      failedWhen: [ACTIONS.DELETE_LINKS_FAILURE],
    },
    redo: {
      command: (action, { fromCons, toCon }) => linkConnectables(fromCons, toCon),
      createArgs: redoArgsForLinkConnectables,
      succeededWhen: [ACTIONS.LINK_CONNECTABLES_SUCCESS_UNDOABLE],
      failedWhen: [ACTIONS.LINK_CONNECTABLES_FAILURE],
      getParamsToPatch: getParamsToPatchForLinkConnectables,
    },
  },
  [ACTIONS.CHANGE_LINKS_TARGET_SUCCESS_UNDOABLE]: {
    undo: {
      command: (action, { links, target }) => changeLinksTarget(links, target),
      createArgs: undoArgsForChangeLinksTarget,
      succeededWhen: [ACTIONS.CHANGE_LINKS_TARGET_SUCCESS_UNDOABLE],
      failedWhen: [ACTIONS.CHANGE_LINKS_TARGET_FAILURE],
    },
    redo: {
      command: (action, { links, target }) => changeLinksTarget(links, target),
      createArgs: redoArgsForChangeLinksTarget,
      succeededWhen: [ACTIONS.CHANGE_LINKS_TARGET_SUCCESS_UNDOABLE],
      failedWhen: [ACTIONS.CHANGE_LINKS_TARGET_FAILURE],
    },
  },
  [ACTIONS.DELETE_LINKS_SUCCESS_UNDOABLE]: {
    undo: {
      command: (action, { links }) => recreateLinks(links),
      createArgs: undoArgsForDeleteLinks,
      succeededWhen: [ACTIONS.LINK_CONNECTABLES_SUCCESS],
      failedWhen: [ACTIONS.LINK_CONNECTABLES_FAILURE],
      getParamsToPatch: getParamsToPatchForDeleteLinks,
    },
    redo: {
      command: (action, { links }) => deleteLinks(links, false),
      createArgs: redoArgsForDeleteLinks,
      succeededWhen: [ACTIONS.DELETE_LINKS_SUCCESS],
      failedWhen: [ACTIONS.DELETE_LINKS_FAILURE],
    },
  },
  [ACTIONS.CLONE_NODES_SUCCESS]: {
    undo: {
      command: (action, { connectables }) => deleteConnectables(connectables, true),
      createArgs: undoArgsForCloneConnectables,
      succeededWhen: [ACTIONS.DELETE_CONNECTABLES_SUCCESS],
      failedWhen: [ACTIONS.DELETE_CONNECTABLES_FAILURE],
    },
    redo: {
      command: (action, { connectables, position }) => cloneConnectables(connectables, position),
      createArgs: redoArgsForCloneConnectables,
      succeededWhen: [ACTIONS.CLONE_NODES_SUCCESS],
      failedWhen: [ACTIONS.CLONE_NODES_FAILURE],
      getParamsToPatch: getParamsToPatchForCloneConnectables,
    },
  },
};

export const NODES_UNREVERTABLE_ACTIONS = [
  ACTIONS.DELETE_CONNECTABLE_SUCCESS,
];

export const resetError = () => {
  return {
    type: ACTIONS.CLEAR_ERROR,
  };
};

/**
 * Action called to create a new Node
 * if a parentNodes array is given, creates links between them and the new node
 * @param {object} nodeToCreate node to create
 * @param {array}  parentNodes  array of parentNodes or null
 */
export const createNewNode = (
  nodeToCreate,
  parentNodes,
  isAudio,
) => (dispatch, getState) => {
  dispatch({ type: ACTIONS.CLEAR_ERROR });

  dispatch({
    type: ACTIONS.NEW_NODES_REQUEST,
    payload: 'waiting response from API ...',
  });

  const {
    projects,
    nodes, // TODO there is an improvment to make between connectables from nodes and projects
  } = getState();

  const { currentProject } = projects;
  const {
    rootConnectables,
  } = nodes;

  if ((currentProject === null) || (typeof currentProject === 'undefined')) {
    dispatch({
      type: ACTIONS.NEW_NODES_FAILURE,
      error: CONSTANTS.COMMONERRORS.NO_CURRENT_PROJECT,
    });
    return;
  }

  // compute following variables only once !!
  const projectIRI = getProjectIRI(currentProject);

  const inputLinks = [];
  const outputLinks = [];
  const linksToMove = [];
  if ((typeof parentNodes === 'undefined')
   || (parentNodes === null)
   || (parentNodes.length === 0)) {
    // CREATE A NEW NODE OUT OF NOWHERE !! NO LINKS TO CREATE OR CHANGE
  } else {
    // WARNING : We suppose that all the parentNodes are linked TO the SAME target
    // WARNING : And that they have only one outputLink !!
    let outputLnk = null;
    const firstParent = parentNodes[0];
    if ((typeof firstParent.outputLinks !== 'undefined')
      && (firstParent.outputLinks !== null)
      && (firstParent.outputLinks.length !== 0)) {
      [outputLnk] = firstParent.outputLinks;
    }

    if (outputLnk === null) {
      // NO CURRENT TARGET -> CREATE NEW INPUT LINKS - NO OUTPUT LINKS

      for (let i = 0; i < parentNodes.length; i += 1) {
        const linkToCreate = {
          project: projectIRI,
          from: getConnectableIRI(parentNodes[i]),
          // PAS DE TO to: toIRI,
        };

        inputLinks.push(linkToCreate);
      }// for
    } else {
      // PARENTS NODE HAVE A TARGET
      // CREATE A SINGLE OUTPUTLINK (And then change target of input ones)

      const linkToCreate = {
        // PAS DE FROM from: fromIRI,
        project: projectIRI,
        to: outputLnk.to,
      };

      outputLinks.push(linkToCreate);

      // Create the array of links we'll need to change their target
      parentNodes.forEach((parent) => {
        parent.outputLinks.forEach((lnk) => {
          if (lnk.to === outputLnk.to) {
            linksToMove.push(lnk);
          }
        });
      });
    }
  }

  const nodeData = {
    project: projectIRI,
    name: '',
    description: '',
    inputLinks,
    outputLinks,
    mediaType: isAudio
      ? getAudioMediaTypeIRI(currentProject)
      : getVideoMediaTypeIRI(currentProject),
    posZ: 0,
    ...nodeToCreate,
  };

  Nodes.createNode(nodeData).then(({ newNode: createdNode }) => {
    // The node is Created

    dispatch({
      type: ACTIONS.NEW_NODES_SUCCESS,
      nodes: [createdNode],
    });

    // Change the target of the links that goes from the parent nodes
    if ((typeof linksToMove !== 'undefined')
     && (linksToMove !== null)
     && (linksToMove.length !== 0)) {
      dispatch(changeLinksTarget(linksToMove, createdNode, false));
    }

    // Root Node ?
    if (inputLinks.length === 0) {
      let setAsRoot = false;
      const isVideo = isVideoConnectable(createdNode, currentProject);
      if ((!isVideo) && (rootConnectables.audioRootConnectable === null)) {
        setAsRoot = true;
      } else if ((isVideo) && (rootConnectables.videoRootConnectable === null)) {
        setAsRoot = true;
      }

      if (setAsRoot) {
        dispatch(modifyProjectConnectableRoot(
          getConnectableIRI(createdNode),
          isVideo,
        ));
      }
    }

    // update project summary
    dispatch(resetProjectSummary());
    return createdNode;
  }).catch((error) => {
    dispatch({
      type: ACTIONS.NEW_NODES_FAILURE,
      error: error.message,
    });
    return error;
  });
};// createNewNode

/**
 * Action called to create Nodes after a given KeyInstance with
 * links associated to given dataKeyValues
 * @param {object} keyInstance keyInstance that will be the source of the node to create
 * @param {array}  dataKeyValues values of keyInstances that will be associated to the
 * links created between the keyInstance and the nodes to create
 */
export const createOptionalNodes = (
  keyInstance,
  dataKeyValues,
) => (dispatch, getState) => {
  dispatch({ type: ACTIONS.CLEAR_ERROR });

  dispatch({
    type: ACTIONS.CREATE_OPTIONAL_NODES_REQUEST,
    payload: 'waiting response from API ...',
  });

  const {
    currentProject,
  } = getState().projects;

  if ((currentProject === null) || (typeof currentProject === 'undefined')) {
    dispatch({
      type: ACTIONS.CREATE_OPTIONAL_NODES_FAILURE,
      error: CONSTANTS.COMMONERRORS.NO_CURRENT_PROJECT,
    });
    return;
  }

  // Target of current links
  let conTarget = null;
  const targets = getKeyInstanceTargets(getState(), keyInstance);
  if (targets.length !== 0) {
    conTarget = getTargetOfKeyInstanceTargets(getState(), targets);
  }

  // Compute best position for new nodes
  let newPosX = null;
  let newPosY = null;
  let minPosY = null;
  let maxPosY = null;
  if ((targets.length !== 0)
   && (keyInstance.graphPos.x !== null)
   && (keyInstance.graphPos.y !== null)) {
    targets.forEach((tgtCon, index) => {
      newPosX += tgtCon.graphPos.x;
      if (index === 0) {
        minPosY = tgtCon.graphPos.y;
        maxPosY = tgtCon.graphPos.y;
      } else {
        maxPosY = (tgtCon.graphPos.y > maxPosY) ? tgtCon.graphPos.y : maxPosY;
        minPosY = (tgtCon.graphPos.y > minPosY) ? minPosY : tgtCon.graphPos.y;
      }
    });
    newPosX = Math.floor(newPosX / targets.length);
    newPosY = maxPosY + SIZES.NODES.height + SIZES.MARGIN.height;
  } else if ((keyInstance.graphPos.x !== null)
          && (keyInstance.graphPos.y !== null)) {
    // Case where there is NO links at all behind the keyInstance
    // we have to recreate all the links
    // We cannot rely on exiting targets positions to compute new storyblocks positions
    newPosX = keyInstance.graphPos.x + SIZES.NODES.width + SIZES.MARGIN.width;

    const nodesArrayHeight = ((SIZES.NODES.height + SIZES.MARGIN.height)
      * (dataKeyValues.length - 1)) + SIZES.NODES.height;
    newPosY = Math.floor(keyInstance.graphPos.y
      + ((SIZES.KEY_INSTANCE.height - nodesArrayHeight) / 2));
  }

  // Is the keyInstance an audio one ?
  const isAudio = (getAudioMediaTypeIRI(currentProject) === keyInstance.mediaType);

  // If the keyInstance is an audio one, we have to force the default label on nodes we will create
  let labelToSet = null;
  if (isAudio) {
    const audioRootConnectableIRI = getAudioRootConnectableIRI(getState());
    const rootNode = getConnectableFromIRI(getState(), audioRootConnectableIRI);
    if (rootNode) {
      labelToSet = rootNode.label;
    }
  }

  const {
    promises,
  } = getCreateOptionalNodesPromises(currentProject,
    keyInstance,
    labelToSet,
    dataKeyValues,
    conTarget,
    {
      x: newPosX,
      y: newPosY,
    });
  Promise.all(promises).then((createdDatas) => {
    const createdNodes = [];
    createdDatas.forEach((createdata) => {
      createdNodes.push(createdata.newNode);
    });
    dispatch({
      type: ACTIONS.CREATE_OPTIONAL_NODES_SUCCESS,
      nodes: createdNodes,
    });
  }).catch((error) => {
    dispatch({
      type: ACTIONS.CREATE_OPTIONAL_NODES_FAILURE,
      error: error.message,
    });
  });
};// createOptionalNodes

/**
 * Retrieve the Promises that will create the nodes after a key instance
 * @param {*} currentProject
 * @param {*} keyInstance
 * @param {*} dataKeyValues
 * @param {*} target
 * @param {*} firstPosition
 */
function getCreateOptionalNodesPromises(currentProject,
  keyInstance,
  label,
  dataKeyValues,
  target,
  firstPosition) {
  const promises = [];

  dataKeyValues.forEach((dataKeyValue, index) => {
    const inputLinks = [];
    const linkToCreate = {
      from: getConnectableIRI(keyInstance),
      // PAS DE TO to: toIRI,
      dataKeyValue: getDkvIRI(dataKeyValue),
    };
    inputLinks.push(linkToCreate);

    let outputLinks = null;
    if ((typeof target !== 'undefined')
     && (target !== null)) {
      outputLinks = [];
      const outLinkToCreate = {
        to: getConnectableIRI(target),
      };
      outputLinks.push(outLinkToCreate);
    }

    const nodePosX = firstPosition.x;
    let nodePosY = firstPosition.y;
    if (nodePosY !== null) {
      nodePosY += (index * (SIZES.NODES.height + SIZES.MARGIN.height));
    }
    const nodeData = {
      project: getProjectIRI(currentProject),
      name: '',
      description: '',
      inputLinks,
      posX: nodePosX,
      posY: nodePosY,
      mediaType: keyInstance.mediaType,
      label,
    };
    if (outputLinks !== null) {
      nodeData.outputLinks = outputLinks;
    }

    promises.push(Nodes.createNode(nodeData));
  });

  return {
    promises,
  };
}// getCreateOptionalNodesPromises


/**
 * Retrieve the Promises that will change the coordinates of an Array of Connectables
 * @param {*} links array of links which should change their target
 */
function getMoveConnectablesPromises(connectablesToMove) {
  const promises = [];
  const previousCoordinates = [];
  let connectable = null;
  let connectableWithNewCoordinates = null;
  let prevCoord = null;
  for (let i = 0; i < connectablesToMove.length; i += 1) {
    connectable = connectablesToMove[i];
    connectableWithNewCoordinates = {
      id: connectable.id,
      posX: Math.floor(connectable.x),
      posY: Math.floor(connectable.y),
      posZ: connectable.z,
    };

    promises.push(Nodes.modifyConnectable(connectable[CONSTANTS.TYPE_FIELD],
      connectableWithNewCoordinates));

    prevCoord = {
      id: connectable.id,
      posX: connectable.posX,
      posY: connectable.posY,
      posZ: connectable.posZ,
    };
    previousCoordinates.push(prevCoord);
  }// for

  return {
    promises,
    previousCoordinates,
  };
}// getMoveConnectablesPromises

/**
 * Store in the database the new coordinates of the given connectables
 * @param {*} connectablesToMove connectables with modified coordinates
 */
export const changeConnectablesCoordinates = (connectablesToMove) => (dispatch, getState) => {
  dispatch({ type: ACTIONS.CLEAR_ERROR });

  dispatch({
    type: ACTIONS.CHANGE_CONNECTABLES_COORDINATES_REQUEST,
    connectables: connectablesToMove,
  });

  // Get the promises to move the connectables
  let moveConnectablesPromises = [];
  let prevCoords = [];
  if (connectablesToMove.length !== 0) {
    const {
      promises,
      previousCoordinates,
    } = getMoveConnectablesPromises(connectablesToMove);
    moveConnectablesPromises = promises;
    prevCoords = previousCoordinates;
  }

  Promise.all(moveConnectablesPromises).then((movedConnectables) => {
    dispatch({
      type: ACTIONS.CHANGE_CONNECTABLES_COORDINATES_SUCCESS,
      connectables: movedConnectables,
      previousCoordinates: prevCoords,
    });
  }).catch((error) => {
    dispatch({
      type: ACTIONS.CHANGE_CONNECTABLES_COORDINATES_FAILURE,
      error: error.message,
    });
  });
};

/**
 * Creates the arguments for the undo command of connectables move
 * @param {*} state state when the action to undo was called
 * @param {*} action action to undo
 */
function undoArgsForChangeConnectablesCoords(state, action) {
  const undoConnectables = [];
  action.connectables.forEach((con) => {
    const prevCoord = action.previousCoordinates.find((elem) => elem.id === con.id);

    const undoCon = {
      x: prevCoord.posX,
      y: prevCoord.posY,
      z: prevCoord.posZ,
      ...con,
    };
    undoConnectables.push(undoCon);
  });
  return {
    connectables: [...undoConnectables],
  };
}
/**
 * Creates the arguments for the redo command of connectables move
 * @param {*} state state when the action to redo was called
 * @param {*} action action to undo
 */
function redoArgsForChangeConnectablesCoords(state, action) {
  const connectables = [];
  action.connectables.forEach((con) => {
    connectables.push({
      x: con.posX,
      y: con.posY,
      z: con.posZ,
      ...con,
    });
  });

  return {
    connectables,
  };
}


/**
 * Create a fork after given nodes
 *   - Creates as many nodes as there are values in the dataKey
 *   - Creates as many output links as there are values in the dataKey
 *   - Creates a KeyInstance
 *   - Creates as Links from this KeyInstance to the created Nodes
 *   - each 'input' link has a Value of the dataKey
 *   - change the target of the prior existing input links to the dataKey
 * @param {Array} followNodes array of nodes that the fork will follow
 * @param {DataKey} dataKey DataKey used to create the fork
 * @param {keyInstancePosition} keyInstancePosition the  position of the keyInstance to create
 * @param {nodesPosition} nodesPosition the array of positions of the nodes to create
 * @param {label} label labelIRI of the label to apply on ALL the nodes to create
 */
export const forkAfterNodes = (
  followNodes,
  dataKey,
  keyInstancePosition,
  nodesPosition,
  label = null,
) => (dispatch, getState) => {
  if ((dataKey === null) || (typeof dataKey === 'undefined')) {
    return;
  }
  dispatch({ type: ACTIONS.CLEAR_ERROR });

  dispatch({
    type: ACTIONS.CREATE_FORK_REQUEST,
    payload: 'waiting response from API ...',
  });

  const {
    currentProject,
  } = getState().projects;

  if ((currentProject === null) || (typeof currentProject === 'undefined')) {
    dispatch({
      type: ACTIONS.CREATE_FORK_FAILURE,
      error: CONSTANTS.COMMONERRORS.NO_CURRENT_PROJECT,
    });
    return;
  }

  const nbValues = dataKey.dataKeyValues.length;
  if (nbValues === 0) {
    dispatch({
      type: ACTIONS.CREATE_FORK_FAILURE,
      error: 'Unable to create fork : Variable without value', // XXX - set a valid error
    });
    return;
  }
  const nbNodesToCreate = nbValues;
  if (nodesPosition.length !== nbNodesToCreate) {
    dispatch({
      type: ACTIONS.CREATE_FORK_FAILURE,
      error: 'Unable to create fork : Invalid array size', // XXX - set a valid error
    });
  }

  // compute following variables only once !!
  const projectIRI = getProjectIRI(currentProject);

  // Media Type to set on new elements
  let mediaTypeToSet = null;

  // What is the common target of the given node array
  // Compte the different links arrays
  const nodeOutputLinks = [];
  const kiInputLinks = [];
  const linksToDelete = [];
  if ((typeof followNodes !== 'undefined')
   && (followNodes !== null)
   && (followNodes.length !== 0)) {
    const firstNodeToFollow = followNodes[0];
    if ((typeof firstNodeToFollow.outputLinks !== 'undefined')
     && (firstNodeToFollow.outputLinks !== null)
     && (firstNodeToFollow.outputLinks.length !== 0)) {
      const outputLnk = firstNodeToFollow.outputLinks[0];
      nodeOutputLinks.push({
        project: projectIRI,
        to: outputLnk.to,
      });
    }

    // We suppose that all the nodes to follow have the same mediaType !!
    mediaTypeToSet = firstNodeToFollow.mediaType;

    followNodes.forEach((node) => {
      if ((typeof node.outputLinks !== 'undefined')
       && (node.outputLinks !== null)
       && (node.outputLinks.length !== 0)) {
        linksToDelete.push(node.outputLinks[0]);
      }
      kiInputLinks.push({
        project: projectIRI,
        from: getConnectableIRI(node),
      });
    });
  }

  // If there is no node to follow we may be in a video case !!
  if (mediaTypeToSet === null) {
    mediaTypeToSet = getVideoMediaTypeIRI(currentProject);
  }

  // Tableau des promesses de creation
  const promises = [];
  for (let i = 0; (i < nbNodesToCreate) ; i += 1) {
    const nodeToCreateTemplate = {
      project: projectIRI,
      mediaType: mediaTypeToSet,
      name: '',
      description: '',
      label,
      outputLinks: nodeOutputLinks,
      posX: nodesPosition[i].x,
      posY: nodesPosition[i].y,
      posZ: 0,
    };
    promises.push(Nodes.createNode(nodeToCreateTemplate));
  }
  dispatch({
    type: ACTIONS.NEW_NODES_REQUEST,
  });
  Promise.all(promises).then((createdDatas) => {
    const createdNodes = [];
    createdDatas.forEach((createdata) => {
      createdNodes.push(createdata.newNode);
    });

    dispatch({
      type: ACTIONS.NEW_NODES_SUCCESS,
      nodes: createdNodes,
    });

    // Create links between the future KeyInstance and the personnalized nodes
    const kiOutputLinks = [];
    // Then the links to newly created nodes
    createdNodes.forEach((node, index) => {
      kiOutputLinks.push({
        project: projectIRI,
        to: getConnectableIRI(node),
        dataKeyValue: getDkvIRI(dataKey.dataKeyValues[index]),
      });
    });

    // Delete the links that went out the nodes to follow, if there are some
    const {
      promises: deleteLinksPromises,
    } = getDeleteLinksPromises(linksToDelete);

    dispatch({
      type: ACTIONS.DELETE_LINKS_REQUEST,
      payload: 'waiting response from API ...',
    });

    Promise.all(deleteLinksPromises).then((deleteLinksAnswerData) => {
      dispatch({
        type: ACTIONS.DELETE_LINKS_SUCCESS,
        deletedLinks: linksToDelete,
      });

      const newKeyInstanceName = 'KeyInstanceOf'.concat(dataKey.id);
      const keyInstance = {
        project: projectIRI,
        dataKey: getDkIRI(dataKey),
        name: newKeyInstanceName,
        posX: keyInstancePosition.x,
        posY: keyInstancePosition.y,
        inputLinks: kiInputLinks,
        outputLinks: kiOutputLinks,
        mediaType: mediaTypeToSet,
      };

      dispatch({
        type: ACTIONS.NEW_NODES_REQUEST,
      });

      Nodes.createKeyInstance(keyInstance).then(({ newNode: newInstance }) => {
        // They Key Instance is created -> change the target of the input links
        dispatch({
          type: ACTIONS.NEW_NODES_SUCCESS,
          nodes: [newInstance],
        });

        // If there were NO connectable in the project before the 'fork', then the new
        // KeyInstance should be set as the root connectable
        if (kiInputLinks.length === 0) {
          const {
            rootConnectables,
          } = getState().nodes;

          const isVideo = isVideoConnectable(newInstance, currentProject);
          let setAsRoot = false;
          if ((!isVideo) && (rootConnectables.audioRootConnectable === null)) {
            setAsRoot = true;
          } else if ((isVideo) && (rootConnectables.videoRootConnectable === null)) {
            setAsRoot = true;
          }

          if (setAsRoot) {
            dispatch(
              modifyProjectConnectableRoot(
                getConnectableIRI(newInstance),
                isVideo,
              ),
            );
          }
        }

        dispatch({
          type: ACTIONS.CREATE_FORK_SUCCESS,
        });

        // update project summary
        dispatch(resetProjectSummary());
      }).catch((error) => {
        dispatch({
          type: ACTIONS.NEW_NODES_FAILURE,
          error: error.message,
        });

        // Error following creation of Key Instance
        dispatch({
          type: ACTIONS.CREATE_FORK_FAILURE,
          error: error.message,
        });
      });
    }).catch((error) => {
      // Error following links deletion
      dispatch({
        type: ACTIONS.CREATE_FORK_FAILURE,
        error: error.message,
      });
    });

  }).catch((error) => {
    dispatch({
      type: ACTIONS.NEW_NODES_FAILURE,
      error: error.message,
    });

    // Error Error during nodes creation
    dispatch({
      type: ACTIONS.CREATE_FORK_FAILURE,
      error: error.message,
    });
  });
};// forkAfterNodes


/**
 * Personnalize the given Node with the given dataKey
 *   - Creates as many nodes as there are values in the dataKey
 *   - Creates as many output links as there are values in the dataKey
 *   - Creates a KeyInstance
 *   - Creates as Links from this KeyInstance to the created Nodes
 *   - each 'input' link has a Value of the dataKey
 *   - change the target of the prior existing input links to the dataKey
 * @param {Node} nodeToPersonnalize Node to personnalize
 * @param {DataKey} dataKey DataKey used to personnalize the Node
 */
export const personnalizeNode = (
  nodeToPersonnalize,
  dataKey,
  nodesize,
) => (dispatch, getState) => {
  if ((nodeToPersonnalize === null) || (typeof nodeToPersonnalize === 'undefined')) {
    return;
  }
  dispatch({ type: ACTIONS.CLEAR_ERROR });

  dispatch({
    type: ACTIONS.PERSONNALIZE_NODE_REQUEST,
    payload: 'waiting response from API ...',
  });

  const {
    currentProject,
  } = getState().projects;

  if ((currentProject === null) || (typeof currentProject === 'undefined')) {
    dispatch({
      type: ACTIONS.PERSONNALIZE_NODE_FAILURE,
      error: CONSTANTS.COMMONERRORS.NO_CURRENT_PROJECT,
    });
    return;
  }

  const nbValues = dataKey.dataKeyValues.length;
  if (nbValues === 0) {
    dispatch({
      type: ACTIONS.PERSONNALIZE_NODE_FAILURE,
      error: 'Unable to personnalize node with a Variable without value', // XXX - set a valid error
    });
    return;
  }
  const nbNodesToCreate = nbValues - 1;

  // compute following variables only once !!
  const projectIRI = getProjectIRI(currentProject);

  const videoMediaTypeIRI = getVideoMediaTypeIRI(currentProject);

  // Creation des nodes 'personnalisés'
  const outputLinks = [];
  if ((typeof nodeToPersonnalize.outputLinks !== 'undefined')
    && (nodeToPersonnalize.outputLinks !== null)
    && (nodeToPersonnalize.outputLinks.length !== 0)) {
    nodeToPersonnalize.outputLinks.forEach((lnk) => {
      outputLinks.push({
        project: projectIRI,
        to: lnk.to,
      });
    });
  }

  const promises = [];
  for (let i = 0; (i < nbNodesToCreate) ; i += 1) {
    const nodeToCreateTemplate = {
      project: projectIRI,
      mediaType: videoMediaTypeIRI,
      name: '',
      description: '',
      color: nodeToPersonnalize.color,
      // label: nodeToPersonnalize.label, MAY BE we should !!,
      outputLinks,
      posX: nodeToPersonnalize.posX,
      posY: nodeToPersonnalize.posY + ((i + 1) * (nodesize.height + SIZES.MARGIN.height)),
      posZ: nodeToPersonnalize.posZ,
    };
    promises.push(Nodes.createNode(nodeToCreateTemplate));
  }
  dispatch({
    type: ACTIONS.NEW_NODES_REQUEST,
  });
  Promise.all(promises).then((createdDatas) => {
    const createdNodes = [];
    createdDatas.forEach((createdata) => {
      createdNodes.push(createdata.newNode);
    });

    dispatch({
      type: ACTIONS.NEW_NODES_SUCCESS,
      nodes: createdNodes,
    });

    // Create links between the future KeyInstance and the personnalized nodes
    const kiOutputLinks = [];
    // First link is linked to the existing node
    kiOutputLinks.push({
      project: projectIRI,
      to: getConnectableIRI(nodeToPersonnalize),
      dataKeyValue: getDkvIRI(dataKey.dataKeyValues[0]),
    });
    // Then the links to newly created nodes
    createdNodes.forEach((node, index) => {
      kiOutputLinks.push({
        project: projectIRI,
        to: getConnectableIRI(node),
        dataKeyValue: getDkvIRI(dataKey.dataKeyValues[index + 1]),
      });
    });

    // Try to compute a correct place for the new key instance
    const kiPosX = nodeToPersonnalize.posX - nodesize.width;
    let kiPosY = nodeToPersonnalize.posY;
    if (createdNodes.length !== 0) {
      kiPosY += ((createdNodes.length + 1) * nodesize.height) / 2;
    }

    const newKeyInstanceName = 'KeyInstanceOf'.concat(dataKey.id);
    const keyInstance = {
      project: projectIRI,
      dataKey: getDkIRI(dataKey),
      name: newKeyInstanceName,
      posX: Math.floor(kiPosX),
      posY: Math.floor(kiPosY),
      outputLinks: kiOutputLinks,
      mediaType: videoMediaTypeIRI,
    };

    dispatch({
      type: ACTIONS.NEW_NODES_REQUEST,
    });

    Nodes.createKeyInstance(keyInstance).then(({ newNode: newInstance }) => {
      // They Key Instance is created -> change the target of the input links
      dispatch({
        type: ACTIONS.NEW_NODES_SUCCESS,
        nodes: [newInstance],
      });

      // Then redirect all the inputLinks to the new KeyInstance
      if ((typeof nodeToPersonnalize.inputLinks !== 'undefined')
        && (nodeToPersonnalize.inputLinks !== null)
        && (nodeToPersonnalize.inputLinks.length !== 0)) {
        dispatch(changeLinksTarget(nodeToPersonnalize.inputLinks, newInstance, false));
      }
      dispatch({
        type: ACTIONS.PERSONNALIZE_NODE_SUCCESS,
      });

      // update project summary
      dispatch(resetProjectSummary());
    }).catch((error) => {
      dispatch({
        type: ACTIONS.NEW_NODES_FAILURE,
        error: error.message,
      });

      // Error following creation of Key Instance
      dispatch({
        type: ACTIONS.PERSONNALIZE_NODE_FAILURE,
        error: error.message,
      });
    });
  }).catch((error) => {
    dispatch({
      type: ACTIONS.NEW_NODES_FAILURE,
      error: error.message,
    });

    // Error following duplication of node
    dispatch({
      type: ACTIONS.PERSONNALIZE_NODE_FAILURE,
      error: error.message,
    });
  });
};// personnalizeNode

/**
 * Retrieve the Promises that will change the targets of an Array of links
 * ALL the given Links SHOULD HAVE SAME TARGET BEFORE THIS CALL
 * @param {*} links array of links which should change their target
 * @param {*} targetIRI the IRI of the new target of the links
 */
function getChangeLinksTargetPromises(links, targetIRI) {
  let oldToConIRI = null;
  const promises = [];
  links.forEach((lnk) => {
    if (oldToConIRI === null) {
      oldToConIRI = lnk.to;
    }
    const linkWithNewTarget = {
      id: lnk.id,
      to: targetIRI,
    };
    linkWithNewTarget[CONSTANTS.IRI_FIELD] = lnk[CONSTANTS.IRI_FIELD];

    promises.push(Links.modifyLink(linkWithNewTarget));
  });

  return {
    promises,
    oldToConIRI,
    newToConIRI: targetIRI,
  };
}

/**
 * change the target of given links to the connectable given
 * ALL the given Links SHOULD HAVE SAME TARGET BEFORE THIS CALL
 * @param {*} links array of links which should change their target
 * @param {*} targetConnectable the new target of the links
 */
export const changeLinksTarget = (
  links,
  targetConnectable,
  undoable = true,
) => (dispatch, getState) => {
  if ((typeof links === 'undefined')
    || (links === null)
    || (links.length === 0)) {
    return;
  }
  if ((typeof targetConnectable === 'undefined')
    || (targetConnectable === null)) {
    return;
  }
  dispatch({ type: ACTIONS.CLEAR_ERROR });

  dispatch({
    type: ACTIONS.CHANGE_LINKS_TARGET_REQUEST,
    payload: 'waiting response from API ...',
  });

  const {
    promises,
    oldToConIRI,
    newToConIRI,
  } = getChangeLinksTargetPromises(links, getConnectableIRI(targetConnectable));

  if ((promises.length === 0)
   || (oldToConIRI === null)
   || (newToConIRI === null)) {
    dispatch({
      type: ACTIONS.CHANGE_LINKS_TARGET_FAILURE,
      error: 'Unable to change target of given links',
    });

    return;
  }

  Promise.all(promises).then((modifiedLinks) => {
    dispatch({
      type: ACTIONS.CHANGE_LINKS_TARGET_SUCCESS,
      links: modifiedLinks,
      oldTo: oldToConIRI,
      newTo: newToConIRI,
    });
    if (undoable) {
      dispatch({
        type: ACTIONS.CHANGE_LINKS_TARGET_SUCCESS_UNDOABLE,
        links: modifiedLinks,
        oldTo: oldToConIRI,
        newTo: newToConIRI,
      });
    }

    // update project summary
    dispatch(resetProjectSummary());
  }).catch((error) => {
    // Error following duplication of node
    dispatch({
      type: ACTIONS.CHANGE_LINKS_TARGET_FAILURE,
      error: error.message,
    });
  });
};

/**
 * Creates the arguments for the undo command of change link target
 * @param {*} state state when the action to undo was called
 * @param {*} action action to undo
 */
function undoArgsForChangeLinksTarget(state, action) {
  const oldTarget = getConnectableFromIRI(state, action.oldTo);

  return {
    links: [...action.links],
    target: { ...oldTarget },
  };
}

/**
 * Creates the arguments for the redo command of change link target
 * @param {*} state state when the action to redo was called
 * @param {*} action action to undo
 */
function redoArgsForChangeLinksTarget(state, action) {
  const newTarget = getConnectableFromIRI(state, action.newTo);

  return {
    links: [...action.links],
    target: { ...newTarget },
  };
}

/**
 * Link each connectable from a given array to the given target connectable
 * @param {*} fromConnectables array of source connectables
 * @param {*} targetConnectable the target connectable
 */
export const linkConnectables = (
  fromConnectables,
  targetConnectable,
) => (dispatch, getState) => {
  const {
    currentProject,
  } = getState().projects;

  // compute following variables only once !!
  const projectIRI = getProjectIRI(currentProject);

  const promises = [];

  fromConnectables.forEach((fromConnectable) => {
    const fromIRI = getConnectableIRI(fromConnectable);
    const toIRI = getConnectableIRI(targetConnectable);

    const linkToCreate = {
      project: projectIRI,
      from: fromIRI,
      to: toIRI,
    };
    promises.push(Links.createLink(linkToCreate));
  });

  if (promises.length === 0) {
    return;
  }
  dispatch({ type: ACTIONS.CLEAR_ERROR });

  dispatch({
    type: ACTIONS.LINK_CONNECTABLES_REQUEST,
    payload: 'waiting response from API ...',
  });

  Promise.all(promises).then((createdLinks) => {
    dispatch({
      type: ACTIONS.LINK_CONNECTABLES_SUCCESS,
      links: createdLinks,
    });
    dispatch({
      type: ACTIONS.LINK_CONNECTABLES_SUCCESS_UNDOABLE,
      links: createdLinks,
      fromCons: [...fromConnectables],
      toCon: { ...targetConnectable },
    });

    // update project summary
    dispatch(resetProjectSummary());
  }).catch((error) => {
    dispatch({
      type: ACTIONS.LINK_CONNECTABLES_FAILURE,
      error: error.message,
    });
  });
};

/**
 * Creates the arguments for the undo command of link connectables
 * @param {*} state state when the action to undo was called
 * @param {*} action action to undo
 */
function undoArgsForLinkConnectables(state, action) {
  return {
    links: [...action.links],
  };
}

/**
 * Creates the arguments for the redo command of link connectables
 * @param {*} state state when the action to redo was called
 * @param {*} action action to undo
 */
function redoArgsForLinkConnectables(state, action) {
  return {
    fromCons: [...action.fromCons],
    toCon: { ...action.toCon },
  };
}

/**
 * Retrieves the parameters that changed during the undo command
 * and that needs to be patched in the redoQueue args
 * @param {*} state state when the action to redo was called
 * @param {*} oldaction action to re-do
 * @param {*} newaction action that was re-done
 */
function getParamsToPatchForLinkConnectables(state, oldaction, newaction) {
  let paramstopatch = [];

  const { links: deletedLinks } = oldaction;
  const { links: createdLinks } = newaction;

  deletedLinks.forEach((deletedLnk) => {
    const newCreatedLnk = createdLinks.find((elem) => {
      return ((elem.to === deletedLnk.to) && (elem.from === deletedLnk.from));
    });
    if (newCreatedLnk !== undefined) {
      const idParamtopatch = {
        field: 'id', // XXX - to be removed - one day
        type: CONSTANTS.TYPES.LINK,
        previousvalue: deletedLnk.id,
        newvalue: newCreatedLnk.id,
      };
      paramstopatch = [...paramstopatch, idParamtopatch];
      const atidParamtopatch = {
        field: CONSTANTS.IRI_FIELD,
        type: CONSTANTS.TYPES.LINK,
        previousvalue: getLinkIRI(deletedLnk),
        newvalue: getLinkIRI(newCreatedLnk),
      };
      paramstopatch = [...paramstopatch, atidParamtopatch];
    }
  });

  return paramstopatch;
}// getParamsToPatchForLinkConnectables


export const recreateLinks = (links) => (dispatch, getState) => {
  const {
    currentProject,
  } = getState().projects;

  // compute following variables only once !!
  const projectIRI = getProjectIRI(currentProject);

  const promises = [];

  links.forEach((lnk) => {
    const linkToCreate = {
      project: projectIRI,
      ...lnk,
    };
    promises.push(Links.createLink(linkToCreate));
  });

  if (promises.length === 0) {
    return;
  }
  dispatch({ type: ACTIONS.CLEAR_ERROR });

  dispatch({
    type: ACTIONS.LINK_CONNECTABLES_REQUEST,
    payload: 'waiting response from API ...',
  });

  Promise.all(promises).then((createdLinks) => {
    dispatch({
      type: ACTIONS.LINK_CONNECTABLES_SUCCESS,
      links: createdLinks,
    });

    // update project summary
    dispatch(resetProjectSummary());
  }).catch((error) => {
    dispatch({
      type: ACTIONS.LINK_CONNECTABLES_FAILURE,
      error: error.message,
    });
  });
};

/**
 * Change the label of a given node
 * @param {*} node Node of which we will change the label
 * @param {*} label the new label to apply
 */
export const changeNodeLabel = (node, label) => (dispatch, getState) => {
  dispatch({ type: ACTIONS.CLEAR_ERROR });

  dispatch({
    type: ACTIONS.CHANGE_NODE_LABEL_REQUEST,
    payload: 'waiting response from API ...',
  });

  const {
    currentProject,
  } = getState().projects;

  if ((currentProject === null) || (typeof currentProject === 'undefined')) {
    dispatch({
      type: ACTIONS.CHANGE_NODE_LABEL_FAILURE,
      error: CONSTANTS.COMMONERRORS.NO_CURRENT_PROJECT,
    });
    return;
  }

  const previousLabel = node.label;

  const newLabelIRI = getLabelIRI(label);
  if ((typeof newLabelIRI !== 'undefined')
    && (newLabelIRI !== null)
    && (newLabelIRI !== undefined)) {
    // The label exists - Use IT !
    const nodeToModify = {
      id: node.id,
      label: newLabelIRI,
    };

    Nodes.modifyConnectable(CONSTANTS.TYPES.NODE, nodeToModify)
      .then((modifiedConnectable) => {
        if ((typeof previousLabel !== 'undefined')
       && (previousLabel !== null)) {
          dispatch({
            type: ACTIONS.CHANGE_NODE_LABEL_SUCCESS_UNDOABLE,
            connectable: modifiedConnectable,
            oldLabel: previousLabel,
          });
        }
        dispatch({
          type: ACTIONS.CHANGE_NODE_LABEL_SUCCESS,
          connectable: modifiedConnectable,
        });

        // update project summary
        dispatch(resetProjectSummary());

        return modifiedConnectable;
      }).catch((error) => {
        dispatch({
          type: ACTIONS.CHANGE_NODE_LABEL_FAILURE,
          error: error.message,
        });

        return error;
      });
  // eslint-disable-next-line no-else-return
  } else {
    // The Label does not exists - CREATE IT !
    const projectIRI = getProjectIRI(currentProject);
    const labelToCreate = {
      name: label.name,
      project: projectIRI,
      mediaType: node.mediaType,
      type: LABEL_TYPES.DEFAULT,
    };

    Labels.createLabel(labelToCreate)
      .then((newLabel) => {
        dispatch({
          type: LABELSACTIONS.NEW_LABEL_SUCCESS,
          newLabel,
        });

        // Labels can't be created directly associated to assets
        // Associate them after creation !
        if ((typeof label.assets !== 'undefined')
         && (label.assets !== null)
         && (label.assets.length !== 0)) {
          const changesArray = [];
          label.assets.forEach((assetIRI, index) => {
            const asset = getAssetFromIRI(getState(), assetIRI);
            const assetModified = {
              labels: [...asset.labels],
            };
            assetModified.labels.push(getLabelIRI(newLabel));
            setAssetIRI(assetModified, assetIRI);
            changesArray.push({
              asset: assetModified,
              previousLabels: [...asset.labels],
            });
          });
          dispatch(changeAssetsLabels(changesArray));
        }

        const nodeToModify = {
          id: node.id,
          label: getLabelIRI(newLabel),
        };

        return Nodes.modifyConnectable(CONSTANTS.TYPES.NODE, nodeToModify)
          .then((modifiedConnectable) => {
            if (
              typeof previousLabel !== 'undefined'
              && previousLabel !== null
            ) {
              dispatch({
                type: ACTIONS.CHANGE_NODE_LABEL_SUCCESS_UNDOABLE,
                connectable: modifiedConnectable,
                oldLabel: previousLabel,
              });
            }
            dispatch({
              type: ACTIONS.CHANGE_NODE_LABEL_SUCCESS,
              connectable: modifiedConnectable,
            });

            // update project summary
            dispatch(resetProjectSummary());

            return { nodeToModify, modifiedConnectable };
          })
          .catch((error) => {
            dispatch({
              type: ACTIONS.CHANGE_NODE_LABEL_FAILURE,
              error: error.message,
            });

            return error;
          });
      })
      .catch((error) => {
        dispatch({
          type: ACTIONS.CHANGE_NODE_LABEL_FAILURE,
          error: error.message,
        });

        return error;
      });
  }
};

/**
 * Reset the label of a given node
 * @param {*} node Node of which we will change the label
 */
export const resetNodeLabel = (node, label) => (dispatch, getState) => {
  dispatch({ type: ACTIONS.CLEAR_ERROR });

  dispatch({
    type: ACTIONS.CHANGE_NODE_LABEL_REQUEST,
    payload: 'waiting response from API ...',
  });

  const {
    currentProject,
  } = getState().projects;

  if ((currentProject === null) || (typeof currentProject === 'undefined')) {
    dispatch({
      type: ACTIONS.CHANGE_NODE_LABEL_FAILURE,
      error: CONSTANTS.COMMONERRORS.NO_CURRENT_PROJECT,
    });
    return;
  }

  const previousLabel = node.label;

  const nodeToModify = {
    id: node.id,
    label: null,
  };

  Nodes.modifyConnectable(CONSTANTS.TYPES.NODE, nodeToModify)
    .then((modifiedConnectable) => {
      if ((typeof previousLabel !== 'undefined')
      && (previousLabel !== null)) {
        dispatch({
          type: ACTIONS.CHANGE_NODE_LABEL_SUCCESS_UNDOABLE,
          connectable: modifiedConnectable,
          oldLabel: previousLabel,
        });
      }
      dispatch({
        type: ACTIONS.CHANGE_NODE_LABEL_SUCCESS,
        connectable: modifiedConnectable,
      });

      // update project summary
      dispatch(resetProjectSummary());

      return modifiedConnectable;
    }).catch((error) => {
      dispatch({
        type: ACTIONS.CHANGE_NODE_LABEL_FAILURE,
        error: error.message,
      });

      return error;
    });
};

/**
 * Creates the arguments for the undo command of change label
 * @param {*} state state when the action to undo was called
 * @param {*} action action to undo
 */
function undoArgsForChangeConLabel(state, action) {
  const labelIRI = action.oldLabel;
  const fakeLabelWithCorrectID = {};
  fakeLabelWithCorrectID[CONSTANTS.IRI_FIELD] = labelIRI;

  return {
    node: action.connectable,
    label: fakeLabelWithCorrectID,
  };
}
/**
 * Creates the arguments for the redo command of change label
 * @param {*} state state when the action to redo was called
 * @param {*} action action to undo
 */
function redoArgsForChangeConLabel(state, action) {
  const fakeLabelWithCorrectID = {};
  fakeLabelWithCorrectID[CONSTANTS.IRI_FIELD] = action.connectable.label;

  return {
    node: action.connectable,
    label: fakeLabelWithCorrectID,
  };
}

function getDeleteLinksPromises(links) {
  const promises = [];
  links.forEach((lnk) => {
    promises.push(Links.deleteLink(lnk));
  });

  return {
    promises,
  };
}

/**
 * Delete the given links array - Remove each link from the inputLinks and outputLinks
 * from connected connectables
 * @param {*} links The links to delete
 */
export const deleteLinks = (links, undoable = true) => (dispatch, getState) => {
  dispatch({ type: ACTIONS.CLEAR_ERROR });

  dispatch({
    type: ACTIONS.DELETE_LINKS_REQUEST,
    payload: 'waiting response from API ...',
  });

  const {
    promises,
  } = getDeleteLinksPromises(links);
  if (promises.length === 0) {
    dispatch({
      type: ACTIONS.DELETE_LINKS_FAILURE,
      error: 'Unable to delete given links',
    });
    return;
  }

  Promise.all(promises).then((data) => {
    dispatch({
      type: ACTIONS.DELETE_LINKS_SUCCESS,
      deletedLinks: links,
    });
    if (undoable) {
      dispatch({
        type: ACTIONS.DELETE_LINKS_SUCCESS_UNDOABLE,
        deletedLinks: links,
      });
    }

    // update project summary
    dispatch(resetProjectSummary());
  }).catch((error) => {
    dispatch({
      type: ACTIONS.DELETE_LINKS_FAILURE,
      error: error.message,
    });
  });
};


/**
 * Creates the arguments for the undo command of delete links
 * @param {*} state state when the action to undo was called
 * @param {*} action action to undo
 */
function undoArgsForDeleteLinks(state, action) {
  const links = [];
  action.deletedLinks.forEach((deletedLink) => {
    if (deletedLink !== null) {
      const lnk = {
        from: deletedLink.from,
        to: deletedLink.to,
      };
      if ((typeof deletedLink.dataKeyValue !== 'undefined')
       && (deletedLink.dataKeyValue !== null)) {
        lnk.dataKeyValue = deletedLink.dataKeyValue;
      }
      links.push({ ...lnk });
    }
  });

  return {
    links: [...links],
  };
}
/**
 * Creates the arguments for the redo command of delete links
 * @param {*} state state when the action to redo was called
 * @param {*} action action to undo
 */
function redoArgsForDeleteLinks(state, action) {
  return {
    links: [...action.deletedLinks],
  };
}

/**
 * Retrieves the parameters that changed during the undo command
 * and that needs to be patched in the redoQueue args
 * @param {*} state state when the action to redo was called
 * @param {*} oldaction action to re-do
 * @param {*} newaction action that was re-done
 */
function getParamsToPatchForDeleteLinks(state, oldaction, newaction) {
  let paramstopatch = [];

  const { deletedLinks } = oldaction;
  const { links: createdLinks } = newaction;

  deletedLinks.forEach((deletedLnk) => {
    const newCreatedLnk = createdLinks.find((elem) => {
      return ((elem.to === deletedLnk.to) && (elem.from === deletedLnk.from));
    });
    if (newCreatedLnk !== undefined) {
      const idParamtopatch = {
        field: 'id', // XXX - to be removed - one day
        type: CONSTANTS.TYPES.LINK,
        previousvalue: deletedLnk.id,
        newvalue: newCreatedLnk.id,
      };
      paramstopatch = [...paramstopatch, idParamtopatch];
      const atidParamtopatch = {
        field: CONSTANTS.IRI_FIELD,
        type: CONSTANTS.TYPES.LINK,
        previousvalue: getLinkIRI(deletedLnk),
        newvalue: getLinkIRI(newCreatedLnk),
      };
      paramstopatch = [...paramstopatch, atidParamtopatch];
    }
  });

  return paramstopatch;
}// getParamsToPatchForDeleteLinks

function getDeleteConnectablesPromises(connectables) {
  const promises = [];
  for (let i = 0; i < connectables.length; i += 1) {
    promises.push(Nodes.deleteConnectable(getConnectableIRI(connectables[i])));
  }

  return {
    promises,
  };
}

/**
 * Delete the given Connectables
 * @param {*} connectablesToDelete The array of connectables to delete
 * @param {*} shouldLinksBeDeleted should we delete the links around nodes
 */
export const deleteConnectables = (
  connectablesToDelete,
  shouldLinksBeDeleted,
) => (dispatch, getState) => {
  dispatch({ type: ACTIONS.CLEAR_ERROR });

  // garde-foo
  if ((typeof connectablesToDelete === 'undefined')
   || (connectablesToDelete === null)
   || (connectablesToDelete.length === 0)) {
    return;
  }

  dispatch({
    type: ACTIONS.DELETE_CONNECTABLES_REQUEST,
    payload: 'waiting response from API ...',
  });

  // Array of IRI of connectables to delete
  const connectablesIRIToDelete = [];

  // Loop to see what has to be deleted, what has to be moved, etc ... !
  const linksToDelete = [];
  const linksToMove = [];
  let targetIRI = null;
  for (let i = 0; i < connectablesToDelete.length; i += 1) {
    const con = connectablesToDelete[i];

    const {
      inputLinks,
      outputLinks,
    } = con;

    // Check if the connectable has input and output links
    let currentLinkToTarget = null;
    if ((typeof inputLinks !== 'undefined')
     && (inputLinks !== null)
     && (inputLinks.length !== 0)
     && (typeof outputLinks !== 'undefined')
     && (outputLinks !== null)
     && (outputLinks.length !== 0)) {
      [currentLinkToTarget] = outputLinks;
    }// if

    const conLinksToDelete = ((typeof outputLinks !== 'undefined')
                        && (outputLinks !== null)
                        && (outputLinks.length !== 0)) ? [...outputLinks] : [];
    const conLinksToMove = [];
    if ((currentLinkToTarget !== null) && (!shouldLinksBeDeleted)) {
      // Links to move
      conLinksToMove.push(...inputLinks);
      if (targetIRI === null) {
        targetIRI = currentLinkToTarget.to;
      } else if (targetIRI !== currentLinkToTarget.to) {
        console.error('The target should be the same as the previous link one');
      }
    } else if ((typeof inputLinks !== 'undefined')
            && (inputLinks !== null)
            && (inputLinks.length !== 0)) {
      // Links to delete
      conLinksToDelete.push(...inputLinks);
    }

    if (conLinksToDelete.length !== 0) {
      for (let k = 0; k < conLinksToDelete.length; k += 1) {
        let found = false;
        for (let j = 0; ((j < linksToDelete.length) && (!found)); j += 1) {
          if (getLinkIRI(conLinksToDelete[k]) === getLinkIRI(linksToDelete[j])) {
            found = true;
          }
        }// for
        if (!found) {
          linksToDelete.push(conLinksToDelete[k]);
        }
      }// for
    }
    if (conLinksToMove.length !== 0) {
      linksToMove.push(...conLinksToMove);
    }

    connectablesIRIToDelete.push(getConnectableIRI(con));
  }// for - loop on connectables to delete

  // Get the promises to change the targets of the links
  let moveLnkPromises = [];
  let oldToIRI = null;
  let newToIRI = null;
  if ((linksToMove.length !== 0) && (targetIRI !== null)) {
    const {
      promises,
      oldToConIRI,
      newToConIRI,
    } = getChangeLinksTargetPromises(linksToMove, targetIRI);
    moveLnkPromises = promises;
    oldToIRI = oldToConIRI;
    newToIRI = newToConIRI;
  }

  Promise.all(moveLnkPromises).then((modifiedLinks) => {
    if (moveLnkPromises.length !== 0) {
      dispatch({
        type: ACTIONS.CHANGE_LINKS_TARGET_SUCCESS,
        links: modifiedLinks,
        oldTo: oldToIRI,
        newTo: newToIRI,
      });
    }

    // Get the promises to delete the links
    let deleteLnkPromises = [];
    if (linksToDelete.length !== 0) {
      const {
        promises,
      } = getDeleteLinksPromises(linksToDelete);
      deleteLnkPromises = promises;
      dispatch({
        type: ACTIONS.DELETE_LINKS_REQUEST,
      });
    }

    Promise.all(deleteLnkPromises).then((deleteddata) => {
      if (linksToDelete.length !== 0) {
        dispatch({
          type: ACTIONS.DELETE_LINKS_SUCCESS,
          deletedLinks: linksToDelete,
        });
      }

      let deleteConPromises = [];
      const {
        promises,
      } = getDeleteConnectablesPromises(connectablesToDelete);
      deleteConPromises = promises;

      Promise.all(deleteConPromises).then((data) => {
        dispatch({
          type: ACTIONS.DELETE_CONNECTABLES_SUCCESS,
          deletedConnectables: connectablesIRIToDelete,
        });
        // update project summary
        dispatch(resetProjectSummary());
      }).catch((error) => {
        dispatch({
          type: ACTIONS.DELETE_CONNECTABLES_FAILURE,
          error: error.message,
        });
      });
    }).catch((error) => {
      dispatch({
        type: ACTIONS.DELETE_CONNECTABLES_FAILURE,
        error: error.message,
      });
    });
  }).catch((error) => {
    dispatch({
      type: ACTIONS.DELETE_CONNECTABLES_FAILURE,
      error: error.message,
    });
  });
};


/**
* Modify the video root of the current project
 * @param {Object} newVideoRootConnectable New video root connectable
 * @param {Boolean} isVideoConnect isVideoConnect
 */
export const modifyProjectConnectableRoot = (
  newRootConnectableIRI,
  isVideoConnect,
) => (dispatch, getState) => {
  dispatch({
    type: ACTIONS.MODIFY_PROJECT_VIDEO_ROOT_REQUEST,
  });

  const {
    currentProject,
  } = getState().projects;

  if ((currentProject === null) || (typeof currentProject === 'undefined')) {
    dispatch({
      type: ACTIONS.MODIFY_PROJECT_VIDEO_ROOT_FAILURE,
      error: CONSTANTS.COMMONERRORS.NO_CURRENT_PROJECT,
    });
    return;
  }

  const {
    rootConnectables,
  } = getState().nodes;

  // What is the current Audio Root IRI
  let audioRootIRI = null;
  if (rootConnectables && rootConnectables.audioRootConnectable) {
    audioRootIRI = rootConnectables.audioRootConnectable;
  }

  // What is the current Video Root IRI
  let videoRootIRI = null;
  if (rootConnectables && rootConnectables.videoRootConnectable) {
    videoRootIRI = rootConnectables.videoRootConnectable;
  }

  const modifiedProject = {
    projectRootConnectables: {
      ...rootConnectables,
      videoRootConnectable: isVideoConnect
        ? newRootConnectableIRI
        : videoRootIRI,
      audioRootConnectable: !isVideoConnect
        ? newRootConnectableIRI
        : audioRootIRI,
    },
  };

  Project.modifyProject(modifiedProject, currentProject.uuid)
    .then((response) => {
      dispatch({
        type: ACTIONS.MODIFY_PROJECT_VIDEO_ROOT_SUCCESS,
        videoRoot: isVideoConnect
          ? newRootConnectableIRI
          : videoRootIRI,
        audioRoot: !isVideoConnect
          ? newRootConnectableIRI
          : audioRootIRI,
      });

      // update project summary
      dispatch(resetProjectSummary());

      return response;
    }).catch((error) => {
      dispatch({
        type: ACTIONS.MODIFY_PROJECT_VIDEO_ROOT_FAILURE,
        error: error.message,
      });

      return error;
    });
};

/**
 * Select the given connectables
 * @param {*} toSelect array of connectables to select
 */
export const selectConnectables = (toSelect, toUnselect) => (dispatch, getState) => {
  dispatch({
    type: ACTIONS.SELECT_CONNECTABLES,
    toSelect,
    toUnselect,
  });
};

/**
 * Change the value used to fork the soundtrack
 * @param {*} dkIRI IRI of the DK to use to fork soundtracks (or null to reset the fork)
 */
export const changeDKForkingSoundtracks = (dkIRI) => (dispatch, getState) => {
  dispatch({
    type: ACTIONS.CHANGE_DK_FORKING_SOUNDTRACK_REQUEST,
  });

  const {
    currentProject,
  } = getState().projects;

  if ((currentProject === null) || (typeof currentProject === 'undefined')) {
    dispatch({
      type: ACTIONS.CHANGE_DK_FORKING_SOUNDTRACK_FAILURE,
      error: CONSTANTS.COMMONERRORS.NO_CURRENT_PROJECT,
    });
    return;
  }
  const {
    connectablesToDelete,
    connectablesIRIToDelete,
    linksToDelete,
  } = getConnectablesToDeleteToRestoreSingleTrack(getState());

  let deleteLnkPromises = [];
  if (linksToDelete.length !== 0) {
    const {
      promises,
    } = getDeleteLinksPromises(linksToDelete);
    deleteLnkPromises = promises;

    dispatch({
      type: ACTIONS.DELETE_LINKS_REQUEST,
    });
  }

  Promise.all(deleteLnkPromises).then((deleteddata) => {
    if (linksToDelete.length !== 0) {
      dispatch({
        type: ACTIONS.DELETE_LINKS_SUCCESS,
        deletedLinks: linksToDelete,
      });
    }

    let deleteConPromises = [];
    const {
      promises,
    } = getDeleteConnectablesPromises(connectablesToDelete);
    deleteConPromises = promises;

    dispatch({
      type: ACTIONS.DELETE_CONNECTABLES_REQUEST,
    });

    // delete existing audio nodes
    Promise.all(deleteConPromises).then((data) => {
      dispatch({
        type: ACTIONS.DELETE_CONNECTABLES_SUCCESS,
        deletedConnectables: connectablesIRIToDelete,
      });

      if (dkIRI) {
        // Create a DataKeyValue Instance and its following audio nodes
        const audioRootConnectableIRI = getAudioRootConnectableIRI(getState());
        const rootNode = getConnectableFromIRI(getState(), audioRootConnectableIRI);
        const dk = getDataKeyFromIRI(getState(), dkIRI);

        const nbNodesToCreate = dk.dataKeyValues.length;

        // compute following variables only once !!
        const projectIRI = getProjectIRI(currentProject);

        // Tableau des promesses de creation
        const creationPromises = [];
        for (let i = 0; (i < nbNodesToCreate) ; i += 1) {
          const nodeToCreateTemplate = {
            project: projectIRI,
            mediaType: rootNode.mediaType,
            name: '',
            description: '',
            label: rootNode.label,
            posZ: 0,
          };
          creationPromises.push(Nodes.createNode(nodeToCreateTemplate));
        }
        dispatch({
          type: ACTIONS.NEW_NODES_REQUEST,
        });

        Promise.all(creationPromises).then((createdDatas) => {
          const createdNodes = [];
          createdDatas.forEach((createdata) => {
            createdNodes.push(createdata.newNode);
          });

          dispatch({
            type: ACTIONS.NEW_NODES_SUCCESS,
            nodes: createdNodes,
          });

          // Create links between the future KeyInstance and the personnalized nodes
          const kiInputLinks = [{
            project: projectIRI,
            from: audioRootConnectableIRI,
          }];
          const kiOutputLinks = [];
          // Then the links to newly created nodes
          createdNodes.forEach((node, index) => {
            kiOutputLinks.push({
              project: projectIRI,
              to: getConnectableIRI(node),
              dataKeyValue: getDkvIRI(dk.dataKeyValues[index]),
            });
          });

          const newKeyInstanceName = 'KeyInstanceOf'.concat(dk.id);
          const keyInstance = {
            project: projectIRI,
            dataKey: getDkIRI(dk),
            name: newKeyInstanceName,
            inputLinks: kiInputLinks,
            outputLinks: kiOutputLinks,
            mediaType: rootNode.mediaType,
          };

          dispatch({
            type: ACTIONS.NEW_NODES_REQUEST,
          });
          Nodes.createKeyInstance(keyInstance).then(({ newNode: newInstance }) => {
            // They Key Instance is created -> change the target of the input links
            dispatch({
              type: ACTIONS.NEW_NODES_SUCCESS,
              nodes: [newInstance],
            });

            dispatch({
              type: ACTIONS.CHANGE_DK_FORKING_SOUNDTRACK_SUCCESS,
            });

            // update project summary
            dispatch(resetProjectSummary());
          }).catch((error) => {
            dispatch({
              type: ACTIONS.NEW_NODES_FAILURE,
              error: error.message,
            });
            // Error following creation of Key Instance
            dispatch({
              type: ACTIONS.CHANGE_DK_FORKING_SOUNDTRACK_FAILURE,
              error: error.message,
            });
          });
        }).catch((error) => {
          dispatch({
            type: ACTIONS.NEW_NODES_FAILURE,
            error: error.message,
          });

          // Error following duplication of node
          dispatch({
            type: ACTIONS.CHANGE_DK_FORKING_SOUNDTRACK_FAILURE,
            error: error.message,
          });
        });
      } else {
        // Just a reset of the multiple track variable

        dispatch({
          type: ACTIONS.CHANGE_DK_FORKING_SOUNDTRACK_SUCCESS,
        });

        // update project summary
        dispatch(resetProjectSummary());
      }
    }).catch((error) => {
      dispatch({
        type: ACTIONS.CHANGE_DK_FORKING_SOUNDTRACK_FAILURE,
        error: error.message,
      });
    });
  }).catch((error) => {
    dispatch({
      type: ACTIONS.CHANGE_DK_FORKING_SOUNDTRACK_FAILURE,
      error: error.message,
    });

    return error;
  });
};


/**
 * Convert the nodes to clone into data for the creation promises
 * @param {*} connectables
 */
const getClonableNodesFromExistingOnes = (connectables, newPosition, maxPos) => {
  const consToCreate = [];
  const linksToCreate = [];
  const topleftposition = {
    x: CONSTANTS.INVALID_COORDINATE,
    y: CONSTANTS.INVALID_COORDINATE,
  };
  const bottomrightposition = {
    x: CONSTANTS.INVALID_COORDINATE,
    y: CONSTANTS.INVALID_COORDINATE,
  };

  let inCon = null;
  let conSize = null;
  for (let i = 0; i < connectables.length; i += 1) {
    inCon = connectables[i];

    conSize = getConnectableSize(inCon);
    if ((inCon.posX !== null) && (inCon.posY !== null)) {
      if (!isValidPosition(topleftposition)
       || !isValidPosition(bottomrightposition)) {
        topleftposition.x = inCon.posX;
        topleftposition.y = inCon.posY;
        bottomrightposition.x = inCon.posX + conSize.width;
        bottomrightposition.y = inCon.posY + conSize.height;
      } else {
        if (inCon.posX < topleftposition.x) topleftposition.x = inCon.posX;
        if (inCon.posY < topleftposition.y) topleftposition.y = inCon.posY;
      }
      if ((inCon.posX + conSize.width) > bottomrightposition.x) {
        bottomrightposition.x = inCon.posY + conSize.width;
      }
      if ((inCon.posY + conSize.height) > bottomrightposition.y) {
        bottomrightposition.y = inCon.posY + conSize.height;
      }
    }

    // Remove the input links that come from a connectable that is not in the array
    if ((typeof inCon.inputLinks !== 'undefined')
     && (inCon.inputLinks !== null)
     && (inCon.inputLinks.length !== 0)) {
      // Is the source of each inputLinks in the 'in' array
      for (let j = 0; j < inCon.inputLinks.length; j += 1) {
        const linkSource = inCon.inputLinks[j];

        let found = false;
        for (let k = 0; ((k < connectables.length) && (!found)); k += 1) {
          if (getConnectableIRI(connectables[k]) === linkSource.from) {
            found = true;

            let isLnkAlreadyThere = false;
            for (let iLnk = 0; ((iLnk < linksToCreate.length) && (!isLnkAlreadyThere)); iLnk += 1) {
              if (getLinkIRI(linksToCreate[iLnk]) === getLinkIRI(linkSource)) {
                isLnkAlreadyThere = true;
              }
            }// for
            if (!isLnkAlreadyThere) {
              linksToCreate.push({ ...linkSource });
            }
          }// if
        }// for
      }
    }

    // Remove the output links that go to a connectable that is not in the array
    if ((typeof inCon.outputLinks !== 'undefined')
      && (inCon.outputLinks !== null)
      && (inCon.outputLinks.length !== 0)) {
      // Is the target of each outputLinks in the 'in' array
      for (let j = 0; j < inCon.outputLinks.length; j += 1) {
        const linkTarget = inCon.outputLinks[j];

        let found = false;
        for (let k = 0; ((k < connectables.length) && (!found)); k += 1) {
          if (getConnectableIRI(connectables[k]) === linkTarget.to) {
            found = true;

            let isLnkAlreadyThere = false;
            for (let iLnk = 0; ((iLnk < linksToCreate.length) && (!isLnkAlreadyThere)); iLnk += 1) {
              if (getLinkIRI(linksToCreate[iLnk]) === getLinkIRI(linkTarget)) {
                isLnkAlreadyThere = true;
              }
            }// for
            if (!isLnkAlreadyThere) {
              linksToCreate.push({ ...linkTarget });
            }
          }// if
        }// for
      }
    }

    consToCreate.push({
      ...inCon,
      inputLinks: [],
      outputLinks: [],
    });
  } // for

  let deltaX = 0;
  let deltaY = 0;
  if ((typeof newPosition === 'undefined')
   || (newPosition === null)) {
    // Below current graph
    // deltaX = 0;
    // deltaY = (maxPos.y + SIZES.NODES.height + SIZES.MARGIN.height) - topleftposition.y;
    // On the right of curent graph
    deltaX = (maxPos.x + SIZES.NODES.width + SIZES.MARGIN.width) - topleftposition.x;
    deltaY = 0;
  } else {
    deltaX = newPosition.x - topleftposition.x;
    deltaY = newPosition.y - topleftposition.y;
  }

  for (let i = 0; i < consToCreate.length; i += 1) {
    consToCreate[i].posX += deltaX;
    consToCreate[i].posY += deltaY;
  }

  return {
    connectablesToCreate: consToCreate,
    linksToCreate,
  };
};// getClonableNodesFromExistingOnes

/**
 * Retrieve the Promises that will change the coordinates of an Array of Connectables
 * @param {*} links array of links which should change their target
 */
function getCloneConnectablesPromises(currentProject, connectablesToClone) {
  const promises = [];
  let conIRI = null;

  let connectable = null;
  for (let i = 0; i < connectablesToClone.length; i += 1) {
    connectable = connectablesToClone[i];
    const nodeData = {
      ...connectable,
      id: null,
      project: getProjectIRI(currentProject),
    };
    nodeData[CONSTANTS.IRI_FIELD] = null;
    conIRI = getConnectableIRI(connectable);
    if (isKeyInstance(connectable)) {
      promises.push(Nodes.createKeyInstance(nodeData, conIRI));
    } else {
      promises.push(Nodes.createNode(nodeData, conIRI));
    }
  }// for

  return {
    promises,
  };
}// getCloneConnectablesPromises

/**
 * Create new graph portion by copying an exiting one
 * @param {*} connectablesToClone connectables to copy
 */
export const cloneConnectables = (connectablesToClone, newPosition) => (dispatch, getState) => {
  dispatch({ type: ACTIONS.CLEAR_ERROR });

  if ((typeof connectablesToClone === 'undefined')
   || (connectablesToClone === null)
   || (connectablesToClone.length === 0)) {
    return;
  }

  const {
    currentProject,
  } = getState().projects;

  if ((currentProject === null) || (typeof currentProject === 'undefined')) {
    return;
  }

  const {
    maxPos,
  } = getState().nodes;
  const {
    connectablesToCreate,
    linksToCreate,
  } = getClonableNodesFromExistingOnes(connectablesToClone, newPosition, maxPos);

  if ((typeof connectablesToCreate === 'undefined')
   || (connectablesToCreate === null)
   || (connectablesToCreate.length === 0)) {
    return;
  }

  dispatch({
    type: ACTIONS.CLONE_NODES_REQUEST,
  });

  // Get the promises to create the new connectables
  let createConnectablesPromises = [];
  if (connectablesToCreate.length !== 0) {
    const {
      promises,
    } = getCloneConnectablesPromises(currentProject, connectablesToCreate);
    createConnectablesPromises = promises;
  }

  Promise.all(createConnectablesPromises).then((createdDatas) => {
    const createdConnectables = [];
    let createdata = null;
    for (let icd = 0; icd < createdDatas.length; icd += 1) {
      createdata = createdDatas[icd];
      createdConnectables.push(createdata.newNode);

      const oldIRI = createdata.callData;
      const newIRI = getConnectableIRI(createdata.newNode);
      for (let i = 0; i < linksToCreate.length; i += 1) {
        if (linksToCreate[i].to === oldIRI) {
          linksToCreate[i].to = newIRI;
        }
        if (linksToCreate[i].from === oldIRI) {
          linksToCreate[i].from = newIRI;
        }
      }
    }
    dispatch({
      type: ACTIONS.NEW_NODES_SUCCESS,
      nodes: createdConnectables,
    });

    if (linksToCreate.length === 0) {
      dispatch({
        type: ACTIONS.CLONE_NODES_SUCCESS,
        createdConnectables,
        clonedConnectables: [...connectablesToClone],
      });
    } else {
      const createLinksPromises = [];
      let lnk = null;
      let linkToCreate = null;
      for (let i = 0; i < linksToCreate.length; i += 1) {
        lnk = linksToCreate[i];
        linkToCreate = {
          project: getProjectIRI(currentProject),
          from: lnk.from,
          to: lnk.to,
        };
        if ((typeof lnk.dataKeyValue !== 'undefined')
         && (lnk.dataKeyValue !== null)) {
          linkToCreate.dataKeyValue = lnk.dataKeyValue;
        }
        createLinksPromises.push(Links.createLink(linkToCreate));
      }// for

      Promise.all(createLinksPromises).then((createdLinks) => {
        dispatch({
          type: ACTIONS.LINK_CONNECTABLES_SUCCESS,
          links: createdLinks,
        });

        // We have to patch the createdConnectables before sending him to the action
        // To ensure the links are correctly set in the array (to be deleted correctly
        // during the UNDO)
        let clnk = null;
        let ccon = null;
        let cconIRI = null;
        for (let iLnk = 0; iLnk < createdLinks.length; iLnk += 1) {
          clnk = createdLinks[iLnk];
          for (let iCon = 0; iCon < createdConnectables.length; iCon += 1) {
            ccon = createdConnectables[iCon];
            cconIRI = getConnectableIRI(ccon);
            if (clnk.from === cconIRI) {
              ccon.outputLinks.push({ ...clnk });
            }
            if (clnk.to === cconIRI) {
              ccon.inputLinks.push({ ...clnk });
            }
          }
        }// for
        dispatch({
          type: ACTIONS.CLONE_NODES_SUCCESS,
          createdConnectables,
          clonedConnectables: [...connectablesToClone],
        });
      }).catch((error) => {
        dispatch({
          type: ACTIONS.CLONE_NODES_FAILURE,
          error: error.message,
        });
      });
    }

  }).catch((error) => {
    dispatch({
      type: ACTIONS.CLONE_NODES_FAILURE,
      error: error.message,
    });
  });
};// cloneConnectables

export const copyConnectables = (connectablesToCopy) => (dispatch, getState) => {
  dispatch({
    type: ACTIONS.COPY_NODES_REQUEST,
    connectables: connectablesToCopy,
  });
};// copyConnectables

/**
 * Creates the arguments for the undo command of clone connectables
 * @param {*} state state when the action to undo was called
 * @param {*} action action to undo
 */
function undoArgsForCloneConnectables(state, action) {
  const {
    createdConnectables
  } = action;
  return {
    connectables: [...createdConnectables],
  };
}

/**
 * Creates the arguments for the redo command of clone connectables
 * @param {*} state state when the action to redo was called
 * @param {*} action action to undo
 */
function redoArgsForCloneConnectables(state, action) {
  const {
    clonedConnectables
  } = action;

  return {
    connectables: [...clonedConnectables],
    position: action.position,
  };
}

/**
 * Retrieves the parameters that changed during the undo command
 * and that needs to be patched in the redoQueue args
 * @param {*} state state when the action to redo was called
 * @param {*} oldaction action to re-do
 * @param {*} newaction action that was re-done
 */
function getParamsToPatchForCloneConnectables(state, oldaction, newaction) {
  const paramstopatch = [];

  const { createdConnectables: oldCreatedConnectables } = oldaction;
  const { createdConnectables: newCreatedConnectables } = newaction;

  let oldCon = null;
  let newCon = null;
  for (let i = 0; i < oldCreatedConnectables.length; i += 1) {
    oldCon = oldCreatedConnectables[i];
    newCon = newCreatedConnectables[i];

    let conType = CONSTANTS.TYPES.NODE;
    if (isKeyInstance(oldCon)) {
      conType = CONSTANTS.TYPES.KEYINSTANCE;
    }
    paramstopatch.push({
      field: CONSTANTS.IRI_FIELD,
      type: conType,
      previousvalue: getConnectableIRI(oldCon),
      newvalue: getConnectableIRI(newCon),
    });
    paramstopatch.push({
      field: 'id', // XXX - to be removed - one day,
      type: conType,
      previousvalue: oldCon.id,
      newvalue: newCon.id,
    });

    // Output links to patch
    let oldLinkIRI = null;
    for (let iLnk = 0; iLnk < oldCon.outputLinks.length; iLnk += 1) {
      oldLinkIRI = getLinkIRI(oldCon.outputLinks[iLnk]);
      let paramfound = false;
      for (let ip = 0; ((ip < paramstopatch.length) && (!paramfound)); ip += 1) {
        if (paramstopatch[ip].previousvalue === oldLinkIRI) {
          paramfound = true;
        }
      }
      if (!paramfound) {
        paramstopatch.push({
          field: CONSTANTS.IRI_FIELD,
          type: CONSTANTS.TYPES.LINK,
          previousvalue: oldLinkIRI,
          newvalue: getLinkIRI(newCon.outputLinks[iLnk]),
        });
        paramstopatch.push({
          field: 'id', // XXX - to be removed - one day,
          type: CONSTANTS.TYPES.LINK,
          previousvalue: oldCon.outputLinks[iLnk].id,
          newvalue: newCon.outputLinks[iLnk].id,
        });
      }
    }

    // Input links to patch
    for (let iLnk = 0; iLnk < oldCon.inputLinks.length; iLnk += 1) {
      oldLinkIRI = getLinkIRI(oldCon.inputLinks[iLnk]);
      let paramfound = false;
      for (let ip = 0; ((ip < paramstopatch.length) && (!paramfound)); ip += 1) {
        if (paramstopatch[ip].previousvalue === oldLinkIRI) {
          paramfound = true;
        }
      }
      if (!paramfound) {
        paramstopatch.push({
          field: CONSTANTS.IRI_FIELD,
          type: CONSTANTS.TYPES.LINK,
          previousvalue: oldLinkIRI,
          newvalue: getLinkIRI(newCon.inputLinks[iLnk]),
        });
        paramstopatch.push({
          field: 'id', // XXX - to be removed - one day,
          type: CONSTANTS.TYPES.LINK,
          previousvalue: oldCon.inputLinks[iLnk].id,
          newvalue: newCon.inputLinks[iLnk].id,
        });
      }
    }
  }// for

  return paramstopatch;
}// getParamsToPatchForCloneConnectables
