import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import {
  isDemoMode,
  isDevelopmentMode,
  isProductionMode,
} from './Constants';

console.debug('CONST', {
  IS_DEMO_MODE: isDemoMode(),
  IS_DEVELOPMENT_MODE: isDevelopmentMode(),
  IS_PRODUCTION_MODE: isProductionMode(),
  API_PROJECT_URL: process.env.REACT_APP_API_PROJECT_URL,
  API_AUTH_URL: process.env.REACT_APP_API_AUTH_URL,
  API_EXPORT_SINGLE_URL: process.env.REACT_APP_API_EXPORT_SINGLE_URL,
  VERSION: process.env.REACT_APP_VERSION,
  SDK_URL: process.env.REACT_APP_SDK_URL,
  'React Version': React.version,
  'ReactDOM Version': ReactDOM.version,
});

ReactDOM.render(
  <App />,
  document.getElementById('root'),
);
