import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { IntlProvider } from 'react-intl';
import * as RouterActions from 'connected-react-router';
import * as AuthenticationActions from '../reducers/authenticationState';
import {
  getLocale,
  getMessages,
  setAutomaticallyLocale,
} from '../reducers/localeState';


class Viewport extends Component {
  componentDidMount() {
    const {
      authenticationActions,
      localeActions,
    } = this.props;

    localeActions.setAutomaticallyLocale();
    authenticationActions.loadUser();
  }

  render() {
    const { locale, messages, children } = this.props;

    return (
      <IntlProvider
        locale={locale}
        key={locale}
        messages={messages}
      >
        <div>
          {children}
        </div>
      </IntlProvider>
    );
  }
}

export default connect(
  (state) => ({
    locale: getLocale(state),
    messages: getMessages(state),
  }),
  (dispatch) => ({
    localeActions: bindActionCreators({ setAutomaticallyLocale }, dispatch),
    authenticationActions: bindActionCreators(AuthenticationActions, dispatch),
    routerActions: bindActionCreators(RouterActions, dispatch),
  }),
)(Viewport);
