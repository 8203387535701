/* eslint-disable no-bitwise */
//
// File containing tools for color management
//
export default function bestForegroundFromRGBBackground(color) {
  // Is White or Black the best foreground color ?
  let red = 0;
  let green = 0;
  let blue = 0;

  // 3 digits
  if (color.length === 4) {
    red = color[1] + color[1];
    green = color[2] + color[2];
    blue = color[3] + color[3];
  // 6 digits
  } else if (color.length === 7) {
    red = color[1] + color[2];
    green = color[3] + color[4];
    blue = color[5] + color[6];
  }

  red = parseInt(red, 16);
  green = parseInt(green, 16);
  blue = parseInt(blue, 16);

  let foregroundColor = '#ffffff';
  if ((red * 0.299 + green * 0.587 + blue * 0.114) > 186) {
    foregroundColor = '#000000';
  }

  return foregroundColor;
}

export function getRandomColor() {
  const letters = '0123456789ABCDEF';
  let color = '#';
  for (let i = 0; i < 6; i += 1) {
    color += letters[Math.floor(Math.random() * 16)];
  }
  return color;
}

export function increaseBrightness(hexColor, percent) {
  // strip the leading # if it's there
  let hex = hexColor.replace(/^\s*#|\s*$/g, '');

  // convert 3 char codes --> 6, e.g. `E0F` --> `EE00FF`
  if (hex.length === 3) {
    hex = hex.replace(/(.)/g, '$1$1');
  }

  const r = parseInt(hex.substr(0, 2), 16);
  const g = parseInt(hex.substr(2, 2), 16);
  const b = parseInt(hex.substr(4, 2), 16);

  const rStr = ((0 | (1 << 8) + r + (256 - r) * percent / 100).toString(16)).substr(1);
  const gStr = ((0 | (1 << 8) + g + (256 - g) * percent / 100).toString(16)).substr(1);
  const bStr = ((0 | (1 << 8) + b + (256 - b) * percent / 100).toString(16)).substr(1);

  return `#${rStr}${gStr}${bStr}`;
}


const hexToRgb = (hex) => {
  const shorthandRegex = /^#?([a-f\d])([a-f\d])([a-f\d])$/i;
  const newhex = hex.replace(shorthandRegex, (m, r, g, b) => {
    return r + r + g + g + b + b;
  });

  const res = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(newhex);
  return res ? [parseInt(res[1], 16), parseInt(res[2], 16), parseInt(res[3], 16)] : null;
};


const luminance = (r, g, b) => {
  const a = [r, g, b].map((v) => {
    const value = v / 255;
    return (value <= 0.03928) ? (value / 12.92) : Math.pow(((value + 0.055) / 1.055), 2.4);
  });
  return a[0] * 0.2126 + a[1] * 0.7152 + a[2] * 0.0722;
};

export const contrastCheck = (hex1, hex2) => {
  const rgb1 = hexToRgb(hex1);
  const rgb2 = hexToRgb(hex2);
  const val1 = (luminance(rgb1[0], rgb1[1], rgb1[2]) + 0.05);
  const val2 = (luminance(rgb2[0], rgb2[1], rgb2[2]) + 0.05);
  if (val1 > val2) {
    return (val1 / val2).toFixed(1);
  }
  return (val2 / val1).toFixed(1);
};
