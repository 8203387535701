import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { injectIntl, defineMessages } from 'react-intl';
import {
  SkwrDialog,
  Color,
  IS_MACOS,
} from 'skewerui';
import * as shortcutConstants from '../../../KeyboardShortcutConstant';

const useStyles = makeStyles(theme => ({
  flexcentered: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
  },
  separator: {
    width: '100%',
    borderBottom: `1px solid ${Color.greyLight3}`,
    marginLeft: '10px',
  },
  sepTitle: {
    flexShrink: 0,
    fontWeight: 'bold',
  },
  SCInfoLine: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    margin: '15px',
  },
  SCTitle: {
    color: Color.greyDark,
  },
  SCInput: {
    padding: '5px',
    backgroundColor: Color.purpleLight,
    borderRadius: '5px',
    margin: '0px 5px 0px 5px',
  },
}));

const messages = defineMessages({
  stepBack: {
    id: 'shortcut.stepBack',
    defaultMessage: 'Step back / Undo',
  },
  stepForward: {
    id: 'shortcut.stepForward',
    defaultMessage: 'Step forward / Redo an action',
  },
  contextual: {
    id: 'shortcut.contextual',
    defaultMessage: 'Show contextual menu',
  },
  delete: {
    id: 'shortcut.delete',
    defaultMessage: 'Delete selected storyblock or fork',
  },
  deleteKeepLink: {
    id: 'shortcut.deleteKeepLink',
    defaultMessage: 'Delete selected storyblock but keep link',
  },
  copy: {
    id: 'shortcut.copy',
    defaultMessage: 'Copy',
  },
  paste: {
    id: 'shortcut.paste',
    defaultMessage: 'Paste',
  },
  zoomIn: {
    id: 'shortcut.zoomIn',
    defaultMessage: 'Zoom in',
  },
  zoomOut: {
    id: 'shortcut.zoomOut',
    defaultMessage: 'Zoom out',
  },
  navigate: {
    id: 'shortcut.navigate',
    defaultMessage: 'Navigate the scenario',
  },
  defaultZoom: {
    id: 'shortcut.defaultZoom',
    defaultMessage: 'Default zoom',
  },
  fitToScreen: {
    id: 'shortcut.fitToScreen',
    defaultMessage: 'Fit view to screen',
  },
  fitToSelection: {
    id: 'shortcut.fitToSelection',
    defaultMessage: 'Fit view to selection',
  },
  deleteAssets: {
    id: 'shortcut.deleteAssets',
    defaultMessage: 'Delete selected assets',
  },
  selectAllAssets: {
    id: 'shortcut.selectAllAssets',
    defaultMessage: 'Select all assets',
  },
  dividerGeneral: {
    id: 'shortcut.dividerGeneral',
    defaultMessage: 'General',
  },
  dividerScenario: {
    id: 'shortcut.dividerScenario',
    defaultMessage: 'Scenario editor',
  },
  dividerMediaLib: {
    id: 'shortcut.dividerMediaLib',
    defaultMessage: 'Media library',
  },
  deleteInput: {
    id: 'shortcut.deleteInput',
    defaultMessage: 'del',
  },
  scroll: {
    id: 'shortcut.scroll',
    defaultMessage: 'mouse wheel',
  },
  middleClick: {
    id: 'shortcut.middleClick',
    defaultMessage: 'middle click',
  },
  leftClick: {
    id: 'shortcut.leftClick',
    defaultMessage: 'left click',
  },
  rightClick: {
    id: 'shortcut.rightClick',
    defaultMessage: 'right click',
  },
  spaceBar: {
    id: 'shortcut.spaceBar',
    defaultMessage: 'space',
  },
  shift: {
    id: 'shortcut.shift',
    defaultMessage: 'shift',
  },
});

function KeyboardShortcutDialog(props) {
  const {
    isOpen,
    onClose,
    dialogTitle,
    intl: { formatMessage },
  } = props;

  const classes = useStyles();

  const separator = (title) => {
    return (
      <div className={classes.flexcentered}>
        <div className={classes.sepTitle}>{ title }</div>
        <div className={classes.separator} />
      </div>
    );
  };

  const displayInput = (input) => {
    if (typeof input === 'string') {
      return input;
    }

    return formatMessage(messages[input.intlInputId]);
  };

  const handleShortcut = (shortcut) => {
    return (
      <>
        {
          shortcut.map((elem, index) => {
            return (
              <div key={index} className={classes.flexcentered}>
                <div className={classes.SCInput}>
                  { displayInput(elem) }
                </div>
                {index < shortcut.length - 1 ? ' + ' : ''}
              </div>
            );
          })
        }
      </>
    );
  };

  const handleValues = (values) => {
    return (
      <>
        {
          values.map((elem, index) => {
            return (
              <div key={index} className={classes.flexcentered}>
                <div className={classes.flexcentered}>
                  { handleShortcut(elem) }
                </div>
                {index < values.length - 1 ? ' / ' : ''}
              </div>
            );
          })
        }
      </>
    );
  };

  const shortcutInfoLine = (scData) => {
    return (
      <div className={classes.SCInfoLine}>
        <div className={classes.SCTitle}>
          { scData.intlId ? formatMessage(messages[scData.intlId]) : '- - -'}
        </div>
        <div className={classes.flexcentered}>
          {scData.appleKeyVariant && IS_MACOS
            ? handleValues(scData.appleKeyVariant)
            : handleValues(scData.values)}
        </div>
      </div>
    );
  };

  const displayShortcut = (completeData) => {
    return (
      <>
        {completeData.map((elem, index) => {
          return (
            <div key={index}>
              {shortcutInfoLine(elem)}
            </div>
          );
        })}
      </>
    );
  };

  return (
    <>
      <SkwrDialog
        open={isOpen}
        onClose={() => onClose()}
        title={dialogTitle}
      >
        {separator(formatMessage(messages.dividerGeneral))}
        {displayShortcut(shortcutConstants.GENERAL_SHORTCUT_DATA)}
        {separator(formatMessage(messages.dividerScenario))}
        {displayShortcut(shortcutConstants.SCENARIO_SHORTCUT_DATA)}
        {separator(formatMessage(messages.dividerMediaLib))}
        {displayShortcut(shortcutConstants.MEDIA_LIB_SHORTCUT_DATA)}
      </SkwrDialog>
    </>
  );
}

export default injectIntl(KeyboardShortcutDialog);
