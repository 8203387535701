/**
 * boardState.js : reducer for board manipulation
 */

const initialState = {
  boardPosition: { k: 1, x: 0, y: 0 },
};

export const ACTIONS = {
  SET_BOARD_POSITION: 'SET_BOARD_POSITION',
};


/**
 * Action called to store new board position
 * @param {object}  boardPosition  array of parentNodes or null
 */
export const setBoardPosition = boardPosition => (dispatch, getState) => {
  dispatch({
    type: ACTIONS.SET_BOARD_POSITION,
    boardPosition,
  });
};// setBoardPosition


/**
 * THE REDUCER SWITCH
 */
export default (state = initialState, action) => {
  switch (action.type) {
    case ACTIONS.SET_BOARD_POSITION: {
      const newPosition = { ...action.boardPosition };
      return {
        ...state,
        boardPosition: newPosition,
      };
    }
    default:
      return state;
  }
};
