import CONSTANTS from '../Constants';
import { getVideoMediaTypeIRI, getAudioMediaTypeIRI } from './IRITools';

export function getConnectablePos(connectable) {
  let conPos = { x: 0, y: 0 };
  if (typeof connectable !== 'undefined') {
    if (typeof connectable.graphPos !== 'undefined') {
      conPos = { x: connectable.graphPos.x, y: connectable.graphPos.y };
    } else {
      console.error('graphPos parameter not found on connectable');
      conPos = { x: connectable.posX, y: connectable.posY };
    }
  }
  return conPos;
}


export const isStoryBlock = (connectable) => {
  return (connectable[CONSTANTS.TYPE_FIELD] === CONSTANTS.TYPES.NODE);
};

export const isAudioConnectable = (connectable, project) => {
  let audioMediaTypeIRI = null;
  if ((typeof project !== 'undefined')
   && (project !== null)) {
    audioMediaTypeIRI = getAudioMediaTypeIRI(project);
  }
  return (connectable.mediaType === audioMediaTypeIRI);
};
export const isVideoConnectable = (connectable, project) => {
  let videoMediaTypeIRI = null;

  if (project) {
    videoMediaTypeIRI = getVideoMediaTypeIRI(project);
  }

  return (connectable.mediaType === videoMediaTypeIRI);
};

export const isVideoStoryBlock = (connectable, project) => {
  return (isStoryBlock(connectable) && isVideoConnectable(connectable, project));
};

export const isKeyInstance = (connectable) => {
  return (connectable[CONSTANTS.TYPE_FIELD] === CONSTANTS.TYPES.KEYINSTANCE);
};

export const isRootConnectable = (connectable) => {
  // Check if 'isRoot' key exist in connectable obj
  if (connectable && Object.prototype.hasOwnProperty.call(connectable, 'isRoot')) {
    return connectable.isRoot;
  }

  console.error('isRootConnectable() --- connectable.isRoot undefined', connectable);
  return null;
};
