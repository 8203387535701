import axios from 'axios';
import dayjs from 'dayjs';
import jws from 'jsonwebtoken';
import authHeader from './auth-header';
import CONSTANTS, { PRODUCT_SHORTNAME, isDevelopmentMode } from '../Constants';

import { fakeUserFeatureAccessRights } from '../tools/fakeFeatureAccessRights';
import rejectUnfilteredError from './helperTools';
import { getFakeUserData } from './noConnectionHelper';

let currentUser = {};
export const get = () => currentUser;

// Identifiant du timeout de check du token
// -> utile pour supprimer le timeout pour eviter trop de timers simultanes
let checkTokenTimeoutID = null;

const getAppUserKey = () => {
  return `${PRODUCT_SHORTNAME}_user`;
};

export const loadData = () => {
  const localData = localStorage.getItem(getAppUserKey());
  startCheckTokenRoutine();
  currentUser = {};

  if (localData !== null) {
    currentUser = JSON.parse(localData);
  }
  return currentUser;
};

export const isLoaded = () => {
  return Object.keys(currentUser).length > 0;
};

const saveData = (username, token, usertosave) => {
  const jwt = jws.decode(token);
  const dataToSave = {
    uuid: jwt.uuid,
    username,
    token,
    jwt,
    email: usertosave.email,
    firstName: usertosave.firstName,
    lastName: usertosave.lastName,
    featuresRights: usertosave.featuresRights,
  };
  localStorage.setItem(getAppUserKey(), JSON.stringify(dataToSave));
  currentUser = dataToSave;
};

export const removeData = () => {
  localStorage.removeItem(getAppUserKey());
  currentUser = {};
};

export const signUp = (userData) => {
  return new Promise((resolve, reject) => {
    // This route accept only 'content-type': 'application/ld+json'
    axios.post(
      `${CONSTANTS.API.USERS_URI}/users`,
      userData,
      {
        headers: { 'content-type': 'application/ld+json' },
      }
    )
      .then((response) => {
        resolve(response.data.username);
      })
      .catch((error) => {
        if (error.response) {
          if (error.response.status === 400) {
            if (error.response.data['@type'] === 'ConstraintViolationList') {
              if (error.response.data.violations[0].propertyPath === 'username') {
                reject(new Error('signup.error.AlreadyKnownUser'));
                return;
              }
              if (error.response.data.violations[0].propertyPath === 'email') {
                reject(new Error('signup.error.AlreadyKnownEmail'));
                return;
              }
            }
          }
          if (typeof error.response.data['@type'] == 'undefined') {
            reject(error);
          } else {
            reject(new Error(`signup.error.${error.response.data['@type']}`));
          }
          return;
        }
        reject(error);
      });
  });
};

export const login = (credentials) => {
  return new Promise((resolve, reject) => {
    const credentialsToSend = {
      ...credentials,
      client: CONSTANTS.client,
      environment: CONSTANTS.environment,
    };
    // No connection dev case !!
    // This is a dev fallback in case of server in down state
    // We should no rely on any data provided thru this case
    // Use with care, only for UI dev purpose
    if (isDevelopmentMode()) {
      if ((credentials.username === 'noconnection')
       && (credentials.password === 'noconnection')) {
        const userdata = getFakeUserData();
        const loggeduser = userdata.user;
        loggeduser.featuresRights = fakeUserFeatureAccessRights;
        saveData(loggeduser.username, userdata.token, loggeduser);
        resolve(currentUser);
        return;
      }
    }

    axios.post(`${CONSTANTS.API.USERS_URI}/security/login`, credentialsToSend)
      .then((response) => {
        const { data } = response;
        const { username } = data.user;

        const loggeduser = data.user;
        // XXX FAKE - should be returned by the login
        loggeduser.featuresRights = fakeUserFeatureAccessRights;

        saveData(username, data.token, loggeduser);
        startCheckTokenRoutine();
        resolve(currentUser);
      })
      .catch((error) => {
        if (error.response) {
          if (error.response.status === 400) {
            reject(new Error('login.error.EmptyCredentials'));
            return;
          }
          if (typeof error.response.data['@type'] == 'undefined') {
            reject(error);
          } else {
            reject(new Error(`login.error.${error.response.data['@type']}`));
          }
          return;
        }
        reject(error);
      });
  });
};

export const logout = () => {
  return new Promise((resolve, reject) => {
    removeData();
    resolve(currentUser);
  });
};

export const lostPassword = () => {
};

export const checkToken = () => {
  // Ensure that we don't re-enter the startCheckTokenRoutine during the check
  if (checkTokenTimeoutID != null) {
    clearTimeout(checkTokenTimeoutID);
  }
  checkTokenTimeoutID = null;

  return new Promise((resolve, reject) => {
    if (!isLoaded()) {
      reject();
    }

    if ((typeof currentUser === 'undefined')
     || (currentUser === null)
     || (typeof currentUser.jwt === 'undefined')
     || (currentUser.jwt === null)) {
      logout();
      reject();
      return;
    }

    const now = dayjs();
    const exp = dayjs.unix(currentUser.jwt.exp);
    const duration = exp.diff(now, 'second');

    // less than a minute - logout
    if (duration < 60) {
      logout();
      reject();
      return;
    }

    // less than 5 minutes - refreshToken
    if (duration < 300) {
      refreshToken()
        .then(() => {
          startCheckTokenRoutine();
          resolve();
        })
        .catch((error) => {
          logout();
          reject();
        });
      return;
    }
    resolve();

    startCheckTokenRoutine();
  });
};


const startCheckTokenRoutine = () => {
  // Check if timer is on - Clear it if it is the case
  if (checkTokenTimeoutID != null) {
    clearTimeout(checkTokenTimeoutID);
  }
  checkTokenTimeoutID = null;

  checkTokenTimeoutID = setTimeout(() => {
    checkToken()
      .then(startCheckTokenRoutine);
  }, 2 * 60 * 1000);
};

export const refreshToken = () => {
  // Ensure that we don't re-enter the startCheckTokenRoutine during the refresh
  if (checkTokenTimeoutID != null) {
    clearTimeout(checkTokenTimeoutID);
  }
  checkTokenTimeoutID = null;

  return new Promise((resolve, reject) => {
    axios.get(`${CONSTANTS.API.USERS_URI}/security/refreshJWTToken`, {
      headers: authHeader(),
    })
      .then((response) => {
        const { username } = get();
        const { data } = response;
        saveData(username, data.token, currentUser);
        resolve(currentUser);
      })
      .catch(() => {
        reject();
      });
  });
};

export const forgotPassword = (usernameOrEmail) => {
  return new Promise((resolve, reject) => {
    axios.get(`${CONSTANTS.API.USERS_URI}/users/password_reset_request/${usernameOrEmail}`)
      .then((response) => {
        resolve();
      })
      .catch((error) => {
        if (error.response) {
          if (error.response.status === 500) {
            reject(new Error('forgotPassword.error.InternalServerError'));
            return;
          }
          if (error.response.status === 403) {
            reject(new Error('forgotPassword.error.RequestAlreadySubmit'));
            return;
          }
          if (error.response.status === 404) {
            reject(new Error('forgotPassword.error.UserNotFound'));
            return;
          }

          if (typeof error.response.data['@type'] == 'undefined') {
            reject(error);
          } else {
            reject(new Error(`forgotPassword.error.${error.response.data['@type']}`));
          }
          return;
        }

        reject(error);
      });
  });
};

export const resetPassword = (newpass, forgottenPasswordKey) => {
  return new Promise((resolve, reject) => {
    axios.put(`${CONSTANTS.API.USERS_URI}/users/password_reset/${forgottenPasswordKey}`, newpass)
      .then((response) => {
        resolve();
      })
      .catch((error) => {
        if (error.response) {
          if (error.response.status === 400) {
            reject(new Error('resetPassword.error.BadRequest'));
            return;
          }
          if (error.response.status === 403) {
            reject(new Error('resetPassword.error.ResetKeyExpired'));
            return;
          }
          if (error.response.status === 404) {
            reject(new Error('resetPassword.error.forgottenPasswordKeyNotFound'));
            return;
          }
          if (typeof error.response.data['@type'] == 'undefined') {
            reject(error);
          } else {
            reject(new Error(`resetPassword.error.${error.response.data['@type']}`));
          }
          return;
        }

        reject(error);
      });
  });
};

export const validateUser = (userid) => {
  return new Promise((resolve, reject) => {
    axios.get(`${CONSTANTS.API.USERS_URI}/users/validate/${userid}`)
      .then((response) => {
        resolve();
      })
      .catch((error) => {
        if (error.response) {
          if (error.response.status === 500) {
            reject(new Error('validateUser.error.InternalServerError'));
            return;
          }
          if (error.response.status === 403) {
            reject(new Error('validateUser.error.RequestAlreadySubmit'));
            return;
          }
          if (error.response.status === 404) {
            reject(new Error('validateUser.error.UserNotFound'));
            return;
          }
        }

        rejectUnfilteredError('validateUser', error, reject);
      });
  });
};


export const updateUserSettings = (userSettings) => {
  return new Promise((resolve, reject) => {
    const userData = get();

    axios.put(`${CONSTANTS.API.USERS_URI}/users/${userData.uuid}`, userSettings, {
      headers: authHeader(),
    })
      .then((response) => {
        saveData(response.data.username, userData.token, response.data);
        resolve(currentUser);
      })
      .catch((error) => {
        if (error.response) {
          if (error.response.status === 400) {
            if (error.response.data['@type'] === 'ConstraintViolationList') {
              for (let i = 0; i < error.response.data.violations.length; i += 1) {
                console.error(
                  error.response.data.violations[0].propertyPath,
                  ': ',
                  error.response.data.violations[0].message,
                );
              }
              if (error.response.data.violations[0].propertyPath === 'email') {
                if (error.response.data.violations[0].message !== '') {
                  reject(new Error('updateUserSettings.error.AlreadyUsedEmail'));
                  return;
                }
              }
              if (error.response.data.violations[0].propertyPath === 'username') {
                if (error.response.data.violations[0].message !== '') {
                  reject(new Error('updateUserSettings.error.AlreadyUsedUser'));
                  return;
                }
              }
            }

            reject(new Error('updateUserSettings.error.EmptyUserSettings'));
            return;
          }
        }

        rejectUnfilteredError('updateUserSettings', error, reject);
      });
  });
};


/**
 * Return true if the "logged user" is a dev fake one who does not want to connect to back servers
 * @returns false most of the time of course !!
 */
export const isNoConnectionUser = () => {
  if ((isDevelopmentMode())
   && (typeof currentUser !== 'undefined')
   && (currentUser !== null)
   && (typeof currentUser.firstName !== 'undefined')
   && (currentUser.firstName !== null)
   && (currentUser.firstName === 'noconnection')
   && (typeof currentUser.lastName !== 'undefined')
   && (currentUser.lastName !== null)
   && (currentUser.lastName === 'noconnection')) {
    return true;
  }
  return false;
};
