import * as User from '../helpers/user';
import * as Project from '../helpers/project';
import PROJECTS_ACTIONS from './projectsStateConstants';
import NODES_ACTIONS from './nodesStateConstants';
import MEDIA_LIBRARY_ACTIONS from './mediaLibraryStateConstants';
import DATAKEY_ACTIONS from './dataKeysStateConstants';
import LABEL_ACTIONS from './labelsStateConstants';
import PREVIEW_ACTIONS from './previewStateConstants';
import ACTIONS from './authenticationStateConstants';

const initialState = {
  loggedIn: false,
  user: null,
  status: null,
  error: null,
  userData: null,
};

export const setSignup = (form) => (dispatch) => {
  dispatch({
    type: ACTIONS.SIGNUP_REQUEST,
    payload: 'waiting response from api ...',
  });

  User.signUp({
    email: form.email,
    password: form.password,
    username: form.username,
  })
    .then((username) => {
      dispatch({
        type: ACTIONS.SIGNUP_SUCCESS,
        payload: username,
      });
    })
    .catch((error) => {
      dispatch({
        type: ACTIONS.SIGNUP_FAILURE,
        error: error.message,
      });
    });
};

export const login = (formValues) => (dispatch) => {
  dispatch({
    type: ACTIONS.LOGIN_REQUEST,
    payload: 'waiting response from api ...',
  });
  User.login(formValues)
    .then((user) => {
      Project.getCompleteUserData().then((completeUserData) => {
        dispatch({
          type: ACTIONS.LOGIN_SUCCESS,
          user,
          userData: completeUserData,
        });
      }).catch((errorUserData) => {
        dispatch({
          type: ACTIONS.LOGIN_SUCCESS,
          user,
          userData: null,
        });
      });
    })
    .catch((error) => {
      dispatch({
        type: ACTIONS.LOGIN_FAILURE,
        error: error.message,
      });
    });
};

export const resetError = () => {
  return {
    type: ACTIONS.CLEAR_ERROR,
  };
};

export const logout = () => {
  return (dispatch) => {
    User.logout().then((user) => {
      dispatch({
        type: ACTIONS.LOGOUT,
      });
    });
  };
};

export const setForgotPassword = (formValues) => (dispatch) => {
  dispatch({
    type: ACTIONS.FORGOT_PASSWORD_REQUEST,
  });

  User.forgotPassword(formValues.email)
    .then((user) => {
      dispatch({
        type: ACTIONS.FORGOT_PASSWORD_SUCCESS,
      });
    })
    .catch((error) => {
      dispatch({
        type: ACTIONS.FORGOT_PASSWORD_FAILURE,
        error: error.message,
      });
    });
};

export const setResetPassword = (formValues, forgottenPasswordKey) => (dispatch) => {
  dispatch({
    type: ACTIONS.RESET_PASSWORD_REQUEST,
  });

  const newpass = {
    password: formValues.password,
    passwordConfirmation: formValues.passwordconfirm,
  };

  User.resetPassword(newpass, forgottenPasswordKey)
    .then((user) => {
      dispatch({
        type: ACTIONS.RESET_PASSWORD_SUCCESS,
      });
    })
    .catch((error) => {
      dispatch({
        type: ACTIONS.RESET_PASSWORD_FAILURE,
        error: error.message,
      });
    });
};

export const validateUser = (userid) => (dispatch) => {
  dispatch({
    type: ACTIONS.VALIDATE_USER_REQUEST,
  });

  User.validateUser(userid)
    .then((user) => {
      dispatch({
        type: ACTIONS.VALIDATE_USER_SUCCESS,
      });
    })
    .catch((error) => {
      dispatch({
        type: ACTIONS.VALIDATE_USER_FAILURE,
        error: error.message,
      });
    });
};

export const loadUser = () => {
  return (dispatch, getState) => {
    dispatch({
      type: ACTIONS.CHECKTOKEN_REQUEST,
      payload: 'waiting response from api ...',
    });

    User.loadData();
    User.checkToken()
      .then(() => {
        Project.getCompleteUserData().then((completeUserData) => {
          dispatch({
            type: ACTIONS.CHECKTOKEN_SUCCESS,
            userStorage: User.get(),
            userData: completeUserData,
          });
        }).catch((errorUserData) => {
          dispatch({
            type: ACTIONS.CHECKTOKEN_SUCCESS,
            userStorage: User.get(),
            userData: null,
          });
        });
      })
      .catch((error) => {
        dispatch({
          type: ACTIONS.CHECKTOKEN_FAILURE,
        });
      });
  };
};

export const updateUserSettings = (formValues) => (dispatch) => {
  dispatch(resetError());

  dispatch({
    type: ACTIONS.UPDATE_USERSETTINGS_REQUEST,
    payload: 'waiting response from API ...',
  });

  const settingsToSet = { ...formValues };

  // Remove email if it is given, as it cant be modified thru this request
  if ((typeof settingsToSet.email !== 'undefined')
   && (settingsToSet.email !== null)) {
    delete settingsToSet.email;
  }

  User.updateUserSettings(settingsToSet)
    .then(() => {
      dispatch({
        type: ACTIONS.UPDATE_USERSETTINGS_SUCCESS,
        user: User.get(),
      });
    })
    .catch((error) => {
      dispatch({
        type: ACTIONS.UPDATE_USERSETTINGS_FAILURE,
        error: error.message,
      });
    });
};

export default (state = initialState, action) => {
  switch (action.type) {
    // Huge case here to catch FAILURE type and update logged status if necessary
    // if request fail with status 401 (bad JWT token) -> we must loggout user

    case DATAKEY_ACTIONS.GET_DATAKEYS_FAILURE: // DATAKEY_ACTIONS
    case DATAKEY_ACTIONS.NEW_DATAKEY_FAILURE:
    case DATAKEY_ACTIONS.MODIFY_DATAKEY_FAILURE:
    case DATAKEY_ACTIONS.DELETE_DATAKEY_FAILURE:
    case DATAKEY_ACTIONS.NEW_DATAKEYVALUE_FAILURE:
    case DATAKEY_ACTIONS.MODIFY_DATAKEYVALUE_FAILURE:
    case DATAKEY_ACTIONS.DELETE_DATAKEYVALUE_FAILURE:
    case LABEL_ACTIONS.NEW_LABEL_FAILURE: // LABEL_ACTIONS
    case LABEL_ACTIONS.UPDATE_LABEL_FAILURE:
    case LABEL_ACTIONS.DELETE_LABEL_FAILURE:
    case MEDIA_LIBRARY_ACTIONS.NEW_ASSET_FAILURE: // MEDIA_LIBRARY_ACTIONS
    case MEDIA_LIBRARY_ACTIONS.GET_ASSETS_FAILURE:
    case MEDIA_LIBRARY_ACTIONS.UPDATE_ASSET_FAILURE:
    case NODES_ACTIONS.NEW_NODES_FAILURE: // NODES_ACTIONS
    case NODES_ACTIONS.CREATE_FORK_FAILURE:
    case NODES_ACTIONS.CREATE_OPTIONAL_NODES_FAILURE:
    case NODES_ACTIONS.CHANGE_CONNECTABLES_COORDINATES_FAILURE:
    case NODES_ACTIONS.CHANGE_NODE_LABEL_FAILURE:
    case NODES_ACTIONS.PERSONNALIZE_NODE_FAILURE:
    case NODES_ACTIONS.CHANGE_LINKS_TARGET_FAILURE:
    case NODES_ACTIONS.LINK_CONNECTABLES_FAILURE:
    case NODES_ACTIONS.DELETE_LINKS_FAILURE:
    case NODES_ACTIONS.DELETE_CONNECTABLES_FAILURE:
    case NODES_ACTIONS.MODIFY_PROJECT_VIDEO_ROOT_FAILURE:
    case PREVIEW_ACTIONS.NEW_PREVIEW_FAILURE: // PREVIEW_ACTIONS
    case PREVIEW_ACTIONS.SDK_ERROR:
    case PREVIEW_ACTIONS.NEW_EXPORT_SINGLE_FAILURE:
    case PROJECTS_ACTIONS.NEW_PROJECT_FAILURE: // PROJECTS_ACTIONS
    case PROJECTS_ACTIONS.GET_PROJECTS_FAILURE:
    case PROJECTS_ACTIONS.GET_PROJECT_FAILURE:
    case PROJECTS_ACTIONS.DELETE_PROJECT_FAILURE:
    case PROJECTS_ACTIONS.MODIFY_PROJECT_SETTINGS_FAILURE:
    case PROJECTS_ACTIONS.MODIFY_PROJECT_NAME_FAILURE:
    case PROJECTS_ACTIONS.MODIFY_PROJECT_DESCRIPTION_FAILURE:
      return {
        ...state,
        error: action.error,
        status: action.type,
      };

    case ACTIONS.CLEAR_ERROR:
      return {
        ...state,
        error: null,
        status: null,
      };

    case ACTIONS.LOGIN_REQUEST:
      return {
        ...initialState,
        status: ACTIONS.LOGIN_REQUEST,
      };

    case ACTIONS.LOGIN_SUCCESS: {
      let userData = null;
      if ((typeof action.userData !== 'undefined')
       && (action.userData !== null)) {
        userData = { ...action.userData };
      }

      return {
        ...initialState,
        loggedIn: true,
        user: action.user,
        error: null,
        userData,
      };
    }

    case ACTIONS.LOGIN_FAILURE:
      return {
        ...initialState,
        error: action.error,
        status: ACTIONS.LOGIN_FAILURE,
      };

    case ACTIONS.LOGOUT:
      return {
        loggedIn: false,
        user: null,
        userData: null,
      };

    case ACTIONS.CHECKTOKEN_REQUEST:
      return state;

    case ACTIONS.CHECKTOKEN_SUCCESS: {
      let userData = null;
      if ((typeof action.userData !== 'undefined')
       && (action.userData !== null)) {
        userData = { ...action.userData };
      }

      return {
        loggedIn: true,
        user: action.userStorage,
        userData,
      };
    }

    case ACTIONS.CHECKTOKEN_FAILURE:
      return {
        ...state,
        loggedIn: false,
        user: null,
        userData: null,
        status: ACTIONS.CHECKTOKEN_FAILURE,
      };

    case ACTIONS.FORGOT_PASSWORD_SUCCESS:
      return {
        ...state,
        status: ACTIONS.FORGOT_PASSWORD_SUCCESS,
      };

    case ACTIONS.FORGOT_PASSWORD_FAILURE:
      return {
        ...state,
        error: action.error,
        status: ACTIONS.FORGOT_PASSWORD_FAILURE,
      };

    case ACTIONS.FORGOT_PASSWORD_REQUEST:
      return {
        ...state,
        status: ACTIONS.FORGOT_PASSWORD_REQUEST,
      };

    case ACTIONS.RESET_PASSWORD_SUCCESS:
      return {
        ...state,
        status: ACTIONS.RESET_PASSWORD_SUCCESS,
      };

    case ACTIONS.RESET_PASSWORD_FAILURE:
      return {
        ...state,
        error: action.error,
        status: ACTIONS.RESET_PASSWORD_FAILURE,
      };

    case ACTIONS.RESET_PASSWORD_REQUEST:
      return {
        ...state,
        status: ACTIONS.RESET_PASSWORD_REQUEST,
      };

    case ACTIONS.VALIDATE_USER_SUCCESS:
      return {
        ...state,
        status: ACTIONS.VALIDATE_USER_SUCCESS,
      };

    case ACTIONS.VALIDATE_USER_FAILURE:
      return {
        ...state,
        error: action.error,
        status: ACTIONS.VALIDATE_USER_FAILURE,
      };

    case ACTIONS.VALIDATE_USER_REQUEST:
      return {
        ...state,
        status: ACTIONS.VALIDATE_USER_REQUEST,
      };

    case ACTIONS.SIGNUP_SUCCESS:
      return {
        ...state,
        status: ACTIONS.SIGNUP_SUCCESS,
      };

    case ACTIONS.SIGNUP_FAILURE:
      return {
        ...state,
        status: ACTIONS.SIGNUP_FAILURE,
        error: action.error,
      };

    case ACTIONS.SIGNUP_REQUEST:
      return {
        ...state,
        status: ACTIONS.SIGNUP_REQUEST,
      };

    case ACTIONS.UPDATE_USERSETTINGS_SUCCESS:
      return {
        ...state,
        status: ACTIONS.UPDATE_USERSETTINGS_SUCCESS,
        user: action.user,
      };

    case ACTIONS.UPDATE_USERSETTINGS_FAILURE:
      return {
        ...state,
        status: ACTIONS.UPDATE_USERSETTINGS_FAILURE,
        error: action.error,
      };

    case ACTIONS.UPDATE_USERSETTINGS_REQUEST:
      return {
        ...state,
        status: ACTIONS.UPDATE_USERSETTINGS_REQUEST,
      };

    default:
      return state;
  }
};
