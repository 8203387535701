import React from 'react';
import DialogTitle from '@material-ui/core/DialogTitle';
import { withStyles } from '@material-ui/core/styles';
import { ColorByUsage, FontSizes } from './UIConstants';
import { ReactComponent as CloseSVG } from './assets/svg/close.svg';

const styles = {
  title: {
    color: ColorByUsage.formTitle,
    fontSize: FontSizes.titleThree,
    display: 'flex',
    alignItems: 'center',
  },
  closeIcon: {
    border: 'none',
    outline: 'none',
    height: '15px',
    width: '15px',
    lineHeight: '15px',
    cursor: 'pointer',
    '& svg': {
      fill: ColorByUsage.editableLabelButton,
    },
    '&:hover svg': {
      fill: ColorByUsage.editableLabelButtonHover,
    },
  },
};

const SkwrDialogTitle = ({
  classes,
  children,
  onClose,
  ...props
}) => {
  const handleClickCloseIcon = () => {
    if ((typeof onClose !== 'undefined') && (onClose !== null)) {
      onClose();
    }
  };
  return (
    <DialogTitle disableTypography className={classes.title} {...props}>
      <div style={{ flex: 1 }}>
        {children}
      </div>
      <div className={classes.closeIcon}>
        <CloseSVG onClick={handleClickCloseIcon} />
      </div>
    </DialogTitle>
  );
};

export default withStyles(styles)(SkwrDialogTitle);
