import React from 'react';
import { Link } from 'react-router-dom';
import { injectIntl, defineMessages } from 'react-intl';
import { makeStyles } from '@material-ui/core/styles';
import { PRODUCT_NAME, isDevelopmentMode } from '../Constants';
import View from '../components/View';
import { ROUTING } from '../Routes';

const useStyles = makeStyles(theme => ({
  IndexContainer: {
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  IndexTitle: {
    width: '100%',
    textAlign: 'center',
    textShadow: '0 3px 6px #000000',
    fontSize: '100px',
    fontWeight: '300',
    lineHeight: '1.19',
    color: '#ffffff',
    [theme.breakpoints.down('sm')]: {
      fontSize: '50px',
    },
    marginBottom: '40px',
  },
  IndexSubtitle: {
    width: '100%',
    textAlign: 'center',
    fontSize: '25px',
    [theme.breakpoints.down('sm')]: {
      fontSize: '20px',
    },
    fontWeight: '300',
    lineHeight: '1.2',
    color: '#ffffff',
    marginBottom: '40px',
  },
}));

const messages = defineMessages({
  title: {
    id: 'index.title',
    defaultMessage: 'Welcome to {PRODUCT_NAME}',
  },
  subtitle: {
    id: 'index.subtitle',
    defaultMessage: 'Enter the magic !',
  },
});
// Other available pitches :
// Enter the magic
// Let’s get this video fest started
// Stay, you’ll be amazed !
// Make your video great again

const Index = ({
  intl: { formatMessage },
}) => {
  const classes = useStyles();

  let subTitle = formatMessage(messages.subtitle);
  if (isDevelopmentMode()) {
    subTitle = 'Development Version';
  }

  return (
    <View pageConnectedUser={false}>
      <div className={classes.IndexContainer}>
        <p className={classes.IndexTitle}>{formatMessage(messages.title, { PRODUCT_NAME })}</p>
        <Link className={classes.IndexSubtitle} to={ROUTING.LOG_IN}>{subTitle}</Link>
      </div>
    </View>
  );
};

export default injectIntl(Index);
