import axios from 'axios';
import CONSTANTS from '../Constants';
import * as User from './user';
import authHeader from './auth-header';
import rejectUnfilteredError from './helperTools';
import { getFakeProjects, getFakeProject } from './noConnectionHelper';

export const removeData = () => {
  // Nothing to do yet - Add this empty function to have same behaviour between project and user
};

export const createProject = (projectData) => {
  return new Promise((resolve, reject) => {
    axios.post(`${CONSTANTS.API.PROJECTS_URI}/projects`, projectData, {
      headers: authHeader(),
    }).then((response) => {
      resolve(response.data);
    }).catch((error) => {
      if (error.response) {
        if (error.response.status === 400) {
          if (error.response.data['@type'] === 'ConstraintViolationList') {
            if (error.response.data.violations[0].propertyPath === 'name') {
              reject(new Error('newProject.error.nameTooLong'));
              return;
            }
          }
        }
      }
      rejectUnfilteredError('newProject', error, reject);
    });
  });
};


export const getProjects = (data) => {
  return new Promise((resolve, reject) => {
    // No connection dev case !!
    // This is a dev fallback in case of server in down state
    // We should no rely on any data provided thru this case
    // Use with care, only for UI dev purpose
    if (User.isNoConnectionUser()) {
      resolve(getFakeProjects());
      return;
    }

    const userData = User.get();

    const params = {
      'user.uuid': userData.uuid,
      page: data.page,
      // Get the more recent project first
      'order[lastEditedAt]': 'DESC',
    };
    axios.get(`${CONSTANTS.API.PROJECTS_URI}/projects`, {
      headers: authHeader(),
      params,
    })
      .then((response) => {
        const projectData = {
          projects: response.data['hydra:member'],
          totalProjects: response.data['hydra:totalItems'],
        };

        resolve(projectData);
      }).catch((error) => {
        rejectUnfilteredError('getProjects', error, reject);
      });
  });
};

export const loadProject = (projectUuid) => {
  return new Promise((resolve, reject) => {
    // No connection dev case !!
    // This is a dev fallback in case of server in down state
    // We should no rely on any data provided thru this case
    // Use with care, only for UI dev purpose
    if (User.isNoConnectionUser()) {
      resolve(getFakeProject());
      return;
    }

    // Load the project
    axios.get(`${CONSTANTS.API.PROJECTS_URI}/projects/${projectUuid}`, {
      headers: authHeader(),
    })
      .then((response) => {
        resolve(response.data);
      }).catch((error) => {
        if (error.response) {
          if (error.response.status === 401) {
            reject(new Error(CONSTANTS.COMMONERRORS.NOT_LOGGED_IN));
            return;
          }
          if (error.response.status === 404) {
            reject(new Error(CONSTANTS.COMMONERRORS.PROJECT_NOT_FOUND));
            return;
          }
        }
        rejectUnfilteredError('loadProject', error, reject);
      });
  });
};

export const deleteProject = (data) => {
  return new Promise((resolve, reject) => {
    axios.delete(`${CONSTANTS.API.PROJECTS_URI}/projects/${data.uuid}`, {
      headers: authHeader(),
    })
      .then((response) => {
        resolve(data.uuid);
      }).catch((error) => {
        if (error.response) {
          if (error.response.status === 404) {
            reject(new Error(CONSTANTS.COMMONERRORS.PROJECT_NOT_FOUND));
            return;
          }
        }
        rejectUnfilteredError('deleteProject', error, reject);
      });
  });
};

export const modifyProject = (project, projectUuid) => {
  return new Promise((resolve, reject) => {
    // Modify the project
    axios.put(`${CONSTANTS.API.PROJECTS_URI}/projects/${projectUuid}`,
      project,
      {
        headers: authHeader(),
      })
      .then((response) => {
        resolve(response.data);
      }).catch((error) => {
        if (error.response) {
          if (error.response.status === 401) {
            reject(new Error(CONSTANTS.COMMONERRORS.NOT_LOGGED_IN));
            return;
          }
          if (error.response.status === 404) {
            reject(new Error(CONSTANTS.COMMONERRORS.PROJECT_NOT_FOUND));
            return;
          }
        }

        rejectUnfilteredError('modifyProject', error, reject);
      });
  });
};

export const isProjectValid = (projectIRI) => {
  return new Promise((resolve, reject) => {
    // Modify the project
    axios.get(`${CONSTANTS.API.PROJECTS_URI}${projectIRI}/isValid`,
      {
        headers: authHeader(),
      })
      .then((response) => {
        resolve({ valid: true });
      }).catch((error) => {
        if (error.response) {
          if (error.response.status === 403) {
            if (error.response.data['@type'] === 'ProjectValidationFailedError') {
              if (typeof error.response.data['hydra:description'] !== 'undefined') {
                console.error(error.response.data['hydra:description']);
              }
              resolve({ valid: false });
              return;
            }
          }
          if (error.response.status === 401) {
            reject(new Error(CONSTANTS.COMMONERRORS.NOT_LOGGED_IN));
            return;
          }
          if (error.response.status === 404) {
            reject(new Error(CONSTANTS.COMMONERRORS.PROJECT_NOT_FOUND));
            return;
          }
        }

        rejectUnfilteredError('isProjectValid', error, reject);
      });
  });
};

export const createProjectThumbnail = (thumbnailData) => {
  return new Promise((resolve, reject) => {
    axios.post(`${CONSTANTS.API.PROJECTS_URI}/project_thumbnails`, thumbnailData, {
      headers: authHeader(),
    }).then((response) => {
      resolve(response.data);
    }).catch((error) => {
      if (error.response) {
        if (error.response.status === 400) {
          if (error.response.data['@type'] === 'ConstraintViolationList') {
            if (error.response.data.violations[0].message !== '') {
              console.error(error.response.data.violations[0].message);
            }
          }
        }

        rejectUnfilteredError('createProjectThumbnail', error, reject);
      }
    });
  });
};// createProjectThumbnail

export const modifyProjectThumbnailStatus = (projectThumbnailsIRI, uploadStatus) => {
  return new Promise((resolve, reject) => {
    // Modify the project
    axios.put(`${CONSTANTS.API.PROJECTS_URI}${projectThumbnailsIRI}`,
      {
        cloudResourceUploadStatus: uploadStatus,
      },
      {
        headers: authHeader(),
      })
      .then((response) => {
        resolve(response.data);
      }).catch((error) => {
        if (error.response) {
          if (error.response.status === 401) {
            reject(new Error(CONSTANTS.COMMONERRORS.NOT_LOGGED_IN));
            return;
          }
          if (error.response.status === 404) {
            reject(new Error(CONSTANTS.COMMONERRORS.PROJECT_NOT_FOUND));
            return;
          }
        }

        rejectUnfilteredError('modifyProjectThumbnailStatus', error, reject);
      });
  });
};

export const deleteProjectThumbnail = (thumbnailIRI) => {
  return new Promise((resolve, reject) => {
    axios.delete(`${CONSTANTS.API.PROJECTS_URI}${thumbnailIRI}`, {
      headers: authHeader(),
    })
      .then((response) => {
        resolve(response.data);
      }).catch((error) => {
        if (error.response) {
          if (error.response.status === 404) {
            reject(new Error(CONSTANTS.COMMONERRORS.PROJECT_NOT_FOUND));
            return;
          }
        }
        rejectUnfilteredError('deleteProjectThumbnail', error, reject);
      });
  });
};


export const getUserLogos = () => {
  return new Promise((resolve, reject) => {
    const userData = User.get();

    const params = {
      'user.uuid': userData.uuid,
    };
    axios.get(`${CONSTANTS.API.PROJECTS_URI}/user_logos`, {
      headers: authHeader(),
      params,
    })
      .then((response) => {
        const logosData = {
          logos: response.data['hydra:member'],
          totalLogos: response.data['hydra:totalItems'],
        };

        resolve(logosData);
      }).catch((error) => {
        rejectUnfilteredError('getUserLogos', error, reject);
      });
  });
};// getUserLogos

export const getCompleteUserData = () => {
  return new Promise((resolve, reject) => {
    // No connection dev case !!
    // This is a dev fallback in case of server in down state
    // We should no rely on any data provided thru this case
    // Use with care, only for UI dev purpose
    if (User.isNoConnectionUser()) {
      resolve(User.get());
      return;
    }

    const userData = User.get();

    const params = {
      'user.uuid': userData.uuid,
    };
    axios.get(`${CONSTANTS.API.PROJECTS_URI}/users/${userData.uuid}`, {
      headers: authHeader(),
      params,
    })
      .then((response) => {
        resolve(response.data);
      }).catch((error) => {
        rejectUnfilteredError('getCompleteUserData', error, reject);
      });
  });
};// getCompleteUserData
