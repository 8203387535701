import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import MenuItem from '@material-ui/core/MenuItem';
import { ColorByUsage } from './UIConstants';

const menuItemStyles = makeStyles(theme => ({
  menuItem: {
    paddingTop: '10px',
    paddingBottom: '10px',
    background: ColorByUsage.contextMenuBackground,
    color: ColorByUsage.contextMenuForeground,
    '&:focus': {
      background: ColorByUsage.contextMenuBackgroundSelected,
      color: ColorByUsage.contextMenuForegroundSelected,
    },
    '&:hover': {
      background: ColorByUsage.contextMenuBackgroundSelected,
      color: ColorByUsage.contextMenuForegroundSelected,
    },
  },
}));

const SkwrMenuItem = React.forwardRef(({
  action,
  onClick,
  children,
}, ref) => {
  const classes = menuItemStyles();

  return (
    <MenuItem
      onClick={onClick}
      ref={ref}
      classes={{
        root: classes.menuItem,
      }}
    >
      {children}
    </MenuItem>
  );
});

export default SkwrMenuItem;
