// File: LocateTools.jsx
import { createIntl } from 'react-intl';
import translations from '../i18n/locales';
import CONSTANTS from '../Constants';
import { getQueryStringParams } from './OtherTools';

/**
 * Returns the locale wanted by the user
 * THIS FUNCTION CAN BE FACTORIZED IN AN EXTERNAL LIBRARY
 * @param {*} defaultLocale
 * @param {*} availableTranslations
 * @returns
 */
export const getUserLocale = (defaultLocale, availableTranslations) => {
  // default locale
  let lang = defaultLocale;

  // Set this variable to true to force english version
  // Set it to false to try to use user preferred locale
  const forceInEnglish = false;

  // ckeck if the locale is set in the URL
  let localeSetInUrl = '';
  const params = getQueryStringParams();
  if (typeof params.locale !== 'undefined') {
    localeSetInUrl = params.locale;
  }
  if (localeSetInUrl.length > 0) {
    lang = localeSetInUrl;
  } else if (!forceInEnglish) {
    // Ckeck browser preferred language
    if (navigator.languages && navigator.languages.length) {
      // latest versions of Chrome and Firefox set this correctly
      const [firstlanguage] = navigator.languages;
      lang = firstlanguage;
    } else if (navigator.userLanguage) {
      // IE only
      lang = navigator.userLanguage;
    } else {
      // latest versions of Chrome, Firefox, and Safari set this correctly
      lang = navigator.language;
    }
    const pos = lang.indexOf('-');
    if (pos > 0) {
      lang = lang.substr(0, pos);
    }

    // End of the block that should be removed to force english version
  }

  if (typeof availableTranslations[lang] === 'undefined') {
    console.log(`The wanted locale '${lang}' is not available, the default one will be used: '${defaultLocale}'`);
    lang = defaultLocale;
  }

  return lang;
};

/**
 * get localised version of a message
 * DON'T USE IT IN A COMPONENT !
 * TO BE USED ONLY OUTSIDE OF A COMPONENT, AND THE LESS AS POSSIBLE
 * THIS FUNCTION CAN BE FACTORIZED IN AN EXTERNAL LIBRARY
 * @param {*} messId
 * @param {*} defaultLocale
 * @param {*} availableTranslations
 */
export const getLocalizedMessage = (messId, defaultLocale, availableTranslations) => {
  const lang = getUserLocale(defaultLocale, availableTranslations);
  const intl = createIntl({
    locale: lang,
    messages: availableTranslations[lang],
  });

  return intl.formatMessage(messId);
};

/**
 * The same as getLocalizedMessage, BUT AS IT USES EDITOR VARIABLES
 * IT CANNOT BE FACTORIZED IN AN EXTERNAL LIBRARY
 *
 * get localised version of a message
 * DON'T USE IT IN A COMPONENT !
 * TO BE USED ONLY OUTSIDE OF A COMPONENT, AND THE LESS AS POSSIBLE
 * @param {*} messId
 */
export const getEditorLocalizedMessage = (messId) => {
  return getLocalizedMessage(messId, CONSTANTS.DEFAULT_LOCALE, translations);
};
