/**
 * AssetTools.jsx
 * Tools to manage assets
 */
import dayjs from 'dayjs';

import {
  getObjectIRI,
  getVideoMediaTypeIRI,
  getAudioMediaTypeIRI,
  getLabelIRI,
} from './IRITools';
import {
  ASSETS_FILETYPES,
  isSuccessfullyUploaded,
  isUploading,
  isUploadPending,
  uploadFailed,
} from '../Constants';
import { isAssetDedicatedLabel } from './LabelTools';

export const isVideo = (fileType) => {
  return isAcceptedFileType('video', fileType);
};

export const isSound = (fileType) => {
  return isAcceptedFileType('sound', fileType);
};

export const isImage = (fileType) => {
  return isAcceptedFileType('image', fileType);
};

export const isAcceptedFileType = (type, fileType) => {
  if (typeof ASSETS_FILETYPES[type] === 'undefined') {
    return false;
  }

  return ASSETS_FILETYPES[type].indexOf(fileType) !== -1;
};

export const getAllAcceptedFileTypes = () => {
  const acceptedList = [];
  Object.values(ASSETS_FILETYPES).forEach((assetType) => {
    assetType.forEach((mimetype) => {
      acceptedList.push(mimetype);
    });
  });

  return acceptedList;
};

export const getAudioAcceptedFileTypes = () => {
  if (ASSETS_FILETYPES && ASSETS_FILETYPES.sound && ASSETS_FILETYPES.sound.length) {
    const acceptedList = [];
    const acceptedAudioFiles = ASSETS_FILETYPES.sound;

    for (let i = 0; i < acceptedAudioFiles.length; i += 1) {
      const acceptedAudioFile = acceptedAudioFiles[i];
      acceptedList.push(acceptedAudioFile);
    }

    return acceptedList;
  }

  console.error('getAudioAcceptedFileTypes --- ASSETS_FILETYPES.sound', ASSETS_FILETYPES.sound);
  return [];
};

export const getVideoAcceptedFileTypes = () => {
  if (ASSETS_FILETYPES && ASSETS_FILETYPES.video && ASSETS_FILETYPES.video.length) {
    const acceptedList = [];
    const acceptedVideoFiles = ASSETS_FILETYPES.video;

    for (let i = 0; i < acceptedVideoFiles.length; i += 1) {
      const acceptedAudioFile = acceptedVideoFiles[i];
      acceptedList.push(acceptedAudioFile);
    }

    return acceptedList;
  }

  console.error('getVideoAcceptedFileTypes --- ASSETS_FILETYPES.video', ASSETS_FILETYPES.video);
  return [];
};

export const isAudioAsset = (asset, project) => {
  let audioMediaTypeIRI = null;
  if ((typeof project !== 'undefined')
   && (project !== null)) {
    audioMediaTypeIRI = getAudioMediaTypeIRI(project);
  }

  if ((typeof asset.assetFileType !== 'undefined')
   && (asset.assetFileType !== null)) {
    let assetMediaTypeIRI = asset.assetFileType.mediaType;
    if (typeof asset.assetFileType.mediaType === 'object') {
      assetMediaTypeIRI = getObjectIRI(asset.assetFileType.mediaType);
    }
    return (assetMediaTypeIRI === audioMediaTypeIRI);
  }

  // By default - act as if the asset was a video one
  return false;
};

/**
 * Check Array of assets
 * @param {Array} assets
 */
export const isVideoAssets = (assets, project) => {
  if (assets && assets.length) {
    for (let index = 0; index < assets.length; index += 1) {
      const asset = assets[index];
      if (!isVideoAsset(asset, project)) {
        return false;
      }
    }

    return true;
  }
  return false;
};

export const isVideoAsset = (asset, project) => {
  let videoMediaTypeIRI = null;
  if ((typeof project !== 'undefined')
   && (project !== null)) {
    videoMediaTypeIRI = getVideoMediaTypeIRI(project);
  }

  if ((typeof asset.assetFileType !== 'undefined')
   && (asset.assetFileType !== null)) {
    let assetMediaTypeIRI = asset.assetFileType.mediaType;
    if (typeof asset.assetFileType.mediaType === 'object') {
      assetMediaTypeIRI = getObjectIRI(asset.assetFileType.mediaType);
    }
    return (assetMediaTypeIRI === videoMediaTypeIRI);
  }

  // By default - act as if the asset was a video one
  return true;
};


/**
 * ASSET STATUSES
 */
// const ASSET_PREVIEW_STATUS_NAME_PENDING = 'pending';
export const ASSET_PREVIEW_STATUS_NAME_READY = 'ready';
const ASSET_PREVIEW_STATUS_NAME_ERROR = 'error';
//  ASSET_PREVIEW_STATUS_PENDING = `/asset_preview_statuses/${ASSET_PREVIEW_STATUS_NAME_PENDING}`;
const ASSET_PREVIEW_STATUS_READY = `/asset_preview_statuses/${ASSET_PREVIEW_STATUS_NAME_READY}`;
const ASSET_PREVIEW_STATUS_ERROR = `/asset_preview_statuses/${ASSET_PREVIEW_STATUS_NAME_ERROR}`;

const assetPreviewFailed = (assetpreview) => {
  if ((typeof assetpreview === 'undefined')
   || (assetpreview === null)
   || (typeof assetpreview.status === 'undefined')
   || (assetpreview.status === null)) {
    return false;
  }
  return (assetpreview.status === ASSET_PREVIEW_STATUS_ERROR);
};
const assetPreviewReady = (assetpreview) => {
  if ((typeof assetpreview !== 'undefined')
   && (assetpreview !== null)
   && (typeof assetpreview.status !== 'undefined')
   && (assetpreview.status !== null)
   && (assetpreview.status === ASSET_PREVIEW_STATUS_READY)) {
    return true;
  }
  return false;
};

export const isAssetPreviewReady = (asset) => {
  return assetPreviewReady(asset.preview);
};
export const isAssetUploaded = (asset) => {
  return isSuccessfullyUploaded(asset.cloudResourceUploadStatus);
};

export const isAssetStatusOK = (asset) => {
  return (isSuccessfullyUploaded(asset.cloudResourceUploadStatus)
   && assetPreviewReady(asset.preview));
};

export const isAssetStatusNOK = (asset) => {
  return (uploadFailed(asset.cloudResourceUploadStatus)
    || assetPreviewFailed(asset.preview));
};

export const isAssetStatusUploadPending = (asset) => {
  return isUploadPending(asset.cloudResourceUploadStatus);
};

export const isAssetStatusUploading = (asset) => {
  return isUploading(asset.cloudResourceUploadStatus);
};


export const getAssetPreviewFileName = (asset) => {
  if ((typeof asset !== 'undefined')
   && asset
   && (typeof asset.preview !== 'undefined')
   && asset.preview
   && (typeof asset.preview.m3u8Manifest !== 'undefined')
   && asset.preview.m3u8Manifest
   && (typeof asset.preview.m3u8Manifest.fileName !== 'undefined')
   && asset.preview.m3u8Manifest.fileName) {
    return asset.preview.m3u8Manifest.fileName;
  }

  return null;
};


export const getAssetFileName = (asset) => {
  if ((typeof asset !== 'undefined')
   && asset) {
    if ((typeof asset.fileName !== 'undefined')
    && asset.fileName) {
      return asset.fileName;
    }
  }
  return null;
};

export const getAssetFileNameField = (asset) => {
  if ((typeof asset !== 'undefined')
   && asset) {
    if ((typeof asset.fileName !== 'undefined')
    && asset.fileName) {
      return 'fileName';
    }
  }
  return null;
};

/**
 * Asset creation day
 * returns a date (dayjs object) which represents the asset creation day
 * => enables direct comparison to a given DAY  (filter)
 * => don't use this function to sort assets by creation date !!
 */
export const getAssetCreationDay = (asset) => {
  let date = dayjs(asset.createdAt);
  date = date.set('hour', 11);
  date = date.set('minute', 0);
  date = date.set('second', 0);
  return date;
};


export const getAssetPublicLabels = (asset, labels) => {
  const publicLabels = [];
  let labelIRI = null;
  let label = null;
  for (let i = 0; i < asset.labels.length; i += 1) {
    labelIRI = asset.labels[i];
    label = null;
    for (let j = 0; (j < labels.length) && (label === null); j += 1) {
      if (labelIRI === getLabelIRI(labels[j])) {
        label = labels[j];
      }
    }// for
    if ((label !== null) && (!isAssetDedicatedLabel(label))) {
      publicLabels.push(label);
    }
  }// for
  return publicLabels;
};


/**
 * Returns the default asset name given its file name
 */
export const getDefaultAssetName = (assetFileName) => {
  if ((typeof assetFileName === 'undefined')
   || (assetFileName === null)
   || (assetFileName === '')) {
    return '';
  }
  const newFileName = assetFileName.replace(/\.[^/.]+$/, '');
  return newFileName;
};
