import React from 'react';
import { injectIntl, defineMessages } from 'react-intl';
import { makeStyles } from '@material-ui/core/styles';
import { Color, FontSizes } from 'skewerui';
import Link from '../../components/Link';
import { ROUTING } from '../../Routes';
import {
  Features,
  FEATURES,
} from '../../Features';

// Text content
const messages = defineMessages({
  loginBtn: {
    id: 'header.loginButton',
    defaultMessage: 'LOG IN',
  },
  signUpBtn: {
    id: 'header.signupButton',
    defaultMessage: 'Sign Up',
  },
});

const useStyles = makeStyles(theme => ({
  Header_actionBar: {
    flexGrow: 0,
    flexBasis: 'auto',
    display: 'flex',
    justifyContent: 'flex-end',
  },
  Header_projectBar: {
    flexGrow: 1,
    display: 'flex',
    justifyContent: 'flex-start',
    paddingLeft: '40px',
  },
  Header_button: {
    flexGrow: 0,
    marginLeft: '10px',
    padding: '5px 16px',
  },
  Header_Btn: {
    color: Color.black,
    fontSize: FontSizes.textLarge,
    margin: '0px 18px',
  },
}));

const HeaderNotConnected = ({ intl: { formatMessage }, displayLogin = true }) => {
  const {
    isAvailable: isLoginAvailable,
  } = Features.getAvailability(FEATURES.LOG_IN);
  const {
    isAvailable: isSignUpAvailable,
  } = Features.getAvailability(FEATURES.SIGN_UP);

  const classes = useStyles();

  return (
    <div className={classes.Header_actionBar}>
      { isSignUpAvailable && (
        <Link
          className={classes.Header_Btn}
          to={ROUTING.SIGN_UP}
        >
          {formatMessage(messages.signUpBtn)}
        </Link>
      )}

      { (isLoginAvailable && displayLogin) && (
        <Link
          className={classes.Header_Btn}
          to={ROUTING.LOG_IN}
        >
          {formatMessage(messages.loginBtn)}
        </Link>
      )}
    </div>
  );
};

export default injectIntl(HeaderNotConnected);
