/**
 * File: pendingRequestsStateConstants.js
 */
const ACTIONS = {
  ADD_GET_REQUEST: 'ADD_GET_REQUEST',
  REMOVE_GET_REQUEST: 'REMOVE_GET_REQUEST',

  ADD_POST_REQUEST: 'ADD_POST_REQUEST',
  REMOVE_POST_REQUEST: 'REMOVE_POST_REQUEST',
};
export default ACTIONS;
