import axios from 'axios';
import CONSTANTS from '../Constants';
import * as User from './user';
import authHeader from './auth-header';
import rejectUnfilteredError from './helperTools';
import { getFakeExports } from './noConnectionHelper';

// ************************************************************ API REQUEST

/**
 * Retrieves the array of exports of a given project
 * @param {string} projectUuid uuid of the project
 */
export const getExports = (projectUuid, filterParams, pageNumber = 1) => {
  return new Promise((resolve, reject) => {
    // No connection dev case !!
    // This is a dev fallback in case of server in down state
    // We should no rely on any data provided thru this case
    // Use with care, only for UI dev purpose
    if (User.isNoConnectionUser()) {
      resolve(getFakeExports());
      return;
    }

    let params = {};
    if (typeof filterParams !== 'undefined') {
      params = { ...filterParams };
    }
    params['order[createdAt]'] = 'DESC';
    params['project.uuid'] = projectUuid;
    params.page = pageNumber;

    axios.get(`${CONSTANTS.API.PROJECTS_URI}/experience_exports`, {
      headers: authHeader(),
      params,
    }).then((response) => {
      const hydraView = response.data['hydra:view'];

      const exports = response.data['hydra:member'];

      // The request is paginated !
      // If we do not have everything, get next page !
      if ((typeof hydraView['hydra:next'] !== 'undefined')
        && (hydraView['hydra:next'] !== null)) {
        console.debug('Get next page of exports : page ', pageNumber + 1);
        getExports(projectUuid, filterParams, pageNumber + 1).then((data) => {
          exports.push(...data.exports);

          const exportsData = {
            exports,
          };

          resolve(exportsData);
        });
      } else {
        const exportsData = {
          exports,
        };

        resolve(exportsData);
      }

      // resolve(response);
    }).catch((error) => {
      rejectUnfilteredError('getExports', error, reject);
    });
  });
};

/**
 * Add an export to the project
 * @param {string} projectUuid uuid of the project
 */
export const createExports = (exportName, exportTechnicalName, replayToken, projectUuid) => {
  const parsedValues = {
    name: exportName,
    technicalName: exportTechnicalName,
    playlistGeneratorReplayToken: replayToken,
    description: null, // display descritpion in future
    project: `projects/${projectUuid}`,
  };
  return new Promise((resolve, reject) => {
    axios.post(`${CONSTANTS.API.PROJECTS_URI}/experience_exports`, JSON.stringify(parsedValues), {
      headers: authHeader(),
    }).then((response) => {
      resolve(response);
    }).catch((error) => {
      if (error.response.status === 400) {
        if (error.response.data['@type'] === 'ConstraintViolationList') {
          if (error.response.data.violations[0].message !== '') {
            console.error(error.response.data.violations[0].message);
          }
          if (error.response.data.violations[0].propertyPath === 'name') {
            reject(new Error('exports.error.unauthorizedExportName'));
            return;
          }
        }
      }

      rejectUnfilteredError('createExports', error, reject);
    });
  });
};

/**
 * Delete an export to the project
 * @param {string} exportIri iri of export to delete
 */
export const deleteExport = (exportIri) => {
  return new Promise((resolve, reject) => {
    axios.delete(`${CONSTANTS.API.PROJECTS_URI}${exportIri}`, {
      headers: authHeader(),
    }).then((response) => {
      resolve(exportIri);
    }).catch((error) => {
      rejectUnfilteredError('getDataKeys', error, reject);
    });
  });
};

/**
 * Get the download url for a given export
 * @param {string} exportIri iri of export to download
 */
export const getDownloadUrl = (exportIri) => {
  return new Promise((resolve, reject) => {
    axios.get(`${CONSTANTS.API.PROJECTS_URI}${exportIri}/download`, {
      headers: authHeader(),
    }).then((response) => {
      resolve(response.data);
    }).catch((error) => {
      rejectUnfilteredError('getDataKeys', error, reject);
    });
  });
};
