import { IS_MACOS } from './UIConstants';

/**
 * Indicates if the given Key event is the shorcut combination for 'select all'
 * @param {*} evt
 */
export function isKeyEventForSelectAll(evt) {
  let isSelectAllShortcut = false;
  let isCtrlPressed = false;
  if (IS_MACOS) {
    if (evt.metaKey) {
      isCtrlPressed = true;
    }
  } else {
    isCtrlPressed = (evt.code === 'ControlLeft' || evt.ctrlKey);
  }
  if (isCtrlPressed
    && (evt.keyCode === 65 || evt.key === 'a')) {
    isSelectAllShortcut = true;
  }
  return isSelectAllShortcut;
}
