import React, { Component } from 'react';
import { SkwrHeader, SkwrLogoDevSVG, SkwrLogoSVG } from 'skewerui';
import HeaderConnected from './HeaderConnected';
import HeaderNotConnected from './HeaderNotConnected';
import { isDevelopmentMode } from '../../Constants';

class Header extends Component {
  handleClick() {
    const {
      onHome,
    } = this.props;

    if (onHome) {
      onHome();
    }
  }

  render() {
    const {
      pageConnectedUser,
      appDedicatedHeader,
      appDedicatedUserMenuItems,
      onLogOut,
      displayLogin,
      logoUrl,
    } = this.props;

    const logo = isDevelopmentMode() ? (
      <SkwrLogoDevSVG />
    ) : (
      <SkwrLogoSVG />
    );

    return (
      <SkwrHeader
        logo={logo}
        logoUrl={logoUrl}
        onLogo={this.handleClick.bind(this)}
      >
        { pageConnectedUser
          ? (
            <HeaderConnected
              appDedicatedHeader={appDedicatedHeader}
              appDedicatedUserMenuItems={appDedicatedUserMenuItems}
              onLogOut={onLogOut}
            />
          ) : <HeaderNotConnected displayLogin={displayLogin} /> }
      </SkwrHeader>
    );
  }
}

export default Header;
