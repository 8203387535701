import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import { withStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import { ReactComponent as IconResetSVG } from './assets/svg/icon-reset.svg';
import { ReactComponent as DisplayPasswordSVG } from './assets/svg/display-password.svg';
import { Color, ColorByUsage } from './UIConstants';

const styles = theme => ({
  cssLabel: {
    color: `${ColorByUsage.inputBorder} !important`,
  },
  cssLabelFocused: {
    color: `${ColorByUsage.focusColor} !important`,
  },
  cssOutlinedInput: {
    padding: 0,
    backgroundColor: Color.white,
    '&$cssFocused $notchedOutline': {
      borderColor: `${ColorByUsage.focusColor} !important`,
    },
  },
  cssMultilineOutlinedInput: {
    backgroundColor: Color.white,
    '&$cssFocused $notchedOutline': {
      borderColor: `${ColorByUsage.focusColor} !important`,
    },
  },
  notchedOutline: {},
  cssFocused: {},
  errorMessage: {
    marginTop: 0,
    marginBottom: 8,
  },
  buttonIcon: {
    border: '0',
    background: 'transparent',
    display: 'flex',
    cursor: 'pointer',
    outline: 'none',
    alignSelf: 'center',
    width: '30px',
    height: '30px',
  },
  InputLabelDisplayValue: {
    paddingLeft: '0px',
    paddingRight: '10px',  
  },
  resetButton: {
    paddingLeft: '0px',
    paddingRight: '10px',
  },
  
  resetIcon: {
    '& svg': {
      fill: `${ColorByUsage.errorColor}`,
    }  
  },
  inputIcon: {
    display: 'flex !important',
  },
  displayPasswordIcon: {
    '& svg': {
      stroke: `${ColorByUsage.inputBorder}`,
      transition: 'stroke .25s ease-in-out',
    },  
  },
  displayPasswordIconFocus: {
    '& svg': {
      stroke: `${ColorByUsage.buttonSecondaryForeground}`,
    },  
  },

});

const SkwrInput = ({
  children,
  className,
  label,
  input,
  meta,
  onDrop,
  onPaste,
  classes,
  multiline,
  viewButton = false,
  type = 'text',
  name = '',
  errorMessage,
  ...props
}) => {
  const [isFocus, setIsFocus] = useState(false);
  const [displayValue, setDisplayValue] = useState(false);
  const [hasError, setHasError] = useState(false)
  const originalType = type;

  // -------------
  // - useEffect
  // -------------
  useEffect(() => {
    if (errorMessage) {
      setHasError(true)
    } else {
      setHasError(false)
    }
  }, [errorMessage])

  const handleDisplayValue = () => {
    setDisplayValue(true);
  }

  const handleHideValue = () => {
    setDisplayValue(false);
  };

  const handleGetFocus = () => {
    setIsFocus(true);
  };
  const handleLooseFocus = () => {
    setIsFocus(false);
  };

  const handleClickResetIcon = () => {
    props.onChange('');
  };

  const handleOnBlurInput = () => {
    props.onBlur();
    handleLooseFocus();
  };

  let rootClasses = classes.cssOutlinedInput;
  if (multiline) {
    rootClasses = classes.cssMultilineOutlinedInput;
  }

  const resetButtonClasses = classNames(classes.buttonIcon, classes.resetButton);
  const displayPasswordButtonClasses = classNames(classes.buttonIcon, classes.InputLabelDisplayValue);
  let displayPasswordIconClasses = classNames(classes.inputIcon, classes.displayPasswordIcon);
  if (isFocus) {
    displayPasswordIconClasses = classNames(classes.inputIcon, classes.displayPasswordIcon, classes.displayPasswordIconFocus);
  }

  return (
    <div>
      <TextField
        className={classes.textField && className && 'Input'}
        label={label}
        error={hasError}
        fullWidth
        margin="normal"
        variant="outlined"
        type={displayValue ? 'text' : originalType}
        onFocus={handleGetFocus}
        onBlur={handleOnBlurInput}
        onDrop={onDrop}
        onPaste={onPaste}
        multiline={multiline}
        {...input}
        {...props}
        InputLabelProps={{
          classes: {
            root: classes.cssLabel,
            focused: classes.cssLabelFocused,
          },
        }}
        InputProps={{
          classes: {
            root: rootClasses,
            focused: classes.cssFocused,
            notchedOutline: classes.notchedOutline,
          },
          onFocus: handleGetFocus,
          onBlur: handleOnBlurInput,
          endAdornment: (
            <InputAdornment position="end">
              {hasError
              && (
                <button className={resetButtonClasses} type="button" onClick={handleClickResetIcon}>
                  <div className={classNames(classes.inputIcon, classes.resetIcon)}>
                    <IconResetSVG />
                  </div>
                </button>
              )}
              {viewButton
                && (
                  <button
                    type="button"
                    className={displayPasswordButtonClasses}
                    onTouchStart={handleDisplayValue}
                    onTouchEnd={handleHideValue}
                    onMouseDown={handleDisplayValue}
                    onMouseUp={handleHideValue}
                    onMouseOut={handleHideValue}
                    onClick={handleGetFocus}
                    onFocus={handleGetFocus}
                    onBlur={handleLooseFocus}
                  >
                    <div className={displayPasswordIconClasses}>
                      <DisplayPasswordSVG />
                    </div>
                  </button>
                )}
            </InputAdornment>
          ),
        }}
        helperText={hasError && errorMessage}
      />
    </div>
  );
};

export default withStyles(styles)(SkwrInput);
