import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { injectIntl, defineMessages } from 'react-intl';
import { makeStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import { useForm, Controller } from 'react-hook-form';
import {
  SkwrButtonWithProgress,
  SkwrFormError,
  SkwrFormHeader,
  SkwrInput,
  SkwrPasswordField,
  ColorByUsage,
  FontSizes,
} from 'skewerui';
import * as AuthenticationActions from '../reducers/authenticationState';
import AUTHENTICATIONACTIONS from '../reducers/authenticationStateConstants';
import View from '../components/View';
import CONSTANTS, { commonMessages } from '../Constants';
import { getDefaultProductName } from '../tools/PageTools';
import { ROUTING } from '../Routes';
import { Features, FEATURES } from '../Features';

// Text content
const messages = defineMessages({
  title: {
    id: 'login.title',
    defaultMessage: 'Log In',
  },
  subTitle: {
    id: 'login.subTitle',
    defaultMessage: 'Welcome back to {PRODUCT_NAME}',
  },
  emailLabel: {
    id: 'login.emailLabel',
    defaultMessage: 'Email or Username',
  },
  passwordLabel: {
    id: 'login.passwordLabel',
    defaultMessage: 'Password',
  },
  forgotPasswordLink: {
    id: 'login.forgotPasswordLink',
    defaultMessage: 'Forgot Password?',
  },
  contentButton: {
    id: 'login.contentButton',
    defaultMessage: "Let's get creative!",
  },
  dontAccountP: {
    id: 'login.dontAccountP',
    defaultMessage: "Don't have an account?",
  },
  signupLink: {
    id: 'login.signupLink',
    defaultMessage: 'Sign up',
  },
  'login.error.EmptyCredentials': {
    id: 'login.error.EmptyCredentials',
    defaultMessage: 'Specify a valid username and password',
  },
  'login.error.BadCredentialsError': {
    id: 'login.error.BadCredentialsError',
    defaultMessage: 'Your username or your password is incorrect',
  },
  'login.error.AccessDeniedException': {
    id: 'login.error.AccessDeniedException',
    defaultMessage: 'Your username or your password is incorrect',
  },
  'login.error.NotActivatedAccountError': {
    id: 'login.error.NotActivatedAccountError',
    defaultMessage: 'Your account is not activated yet',
  },
  'login.error.NotValidatedAccountError': {
    id: 'login.error.NotValidatedAccountError',
    defaultMessage:
      'Your account is not validated yet. Check your mails to validate it.',
  },
  'login.error.ExpiredAccountException': {
    id: 'login.error.ExpiredAccountException',
    defaultMessage:
      "Your account has expired. Don't worry, your work is not lost. Please contact {SUPPORT_EMAIL} to reactivate your account.",
  },
  'login.error.Unknown': {
    id: 'login.error.Unknown',
    defaultMessage: 'An error has occured, please retry later.',
  },

  'login.error.Password': {
    id: 'login.error.Password',
    defaultMessage: 'Specify a password',
  },
  'login.error.Username': {
    id: 'login.error.Username',
    defaultMessage: 'Specify a username',
  },
});

const useStyles = makeStyles((theme) => ({
  Login: {
    display: 'flex',
    flexDirection: 'column',
    [theme.breakpoints.up('sm')]: {
      width: '397px',
    },
    backgroundColor: ColorByUsage.formBackground,
    padding: '28px 41px 22px',
    [theme.breakpoints.down('sm')]: {
      padding: '14px 20px 20px',
    },
    margin: 'auto',
  },
  LoginSend: {
    display: 'block',
    width: '100%',
  },
  forgotPassword: {
    display: 'block',
    color: ColorByUsage.linkSecondary,
    fontSize: FontSizes.textMedium,
    textAlign: 'right',
    marginBottom: '47px',
  },
}));

const Login = ({
  intl: { formatMessage },
  authenticationActions,
  apiError,
  status,
  history,
}) => {
  // -------------
  // - state
  // -------------
  const classes = useStyles();
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const [apiErrorMessage, setApiErrorMessage] = useState('');
  const [disableSignUpLink, setDisableSignUpLink] = useState(null);
  const [disableLostPwdLink, setDisableLostPwdLink] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const { isEnabled: isLoginEnabled } = Features.getAvailability(
    FEATURES.LOG_IN
  );
  const { isEnabled: isSignUpEnabled, isAvailable: isSignUpAvailable } =
    Features.getAvailability(FEATURES.SIGN_UP);
  const { isEnabled: isLostPwdEnabled, isAvailable: isLostPwdAvailable } =
    Features.getAvailability(FEATURES.LOST_PASSWORD);

  // -------------
  // - useEffect
  // -------------
  useEffect(() => {
    authenticationActions.resetError();
  }, []);

  useEffect(() => {
    setIsLoading(status === AUTHENTICATIONACTIONS.LOGIN_REQUEST);
  }, [status]);

  useEffect(() => {
    if (!isSignUpEnabled) {
      setDisableSignUpLink({
        style: { pointerEvents: 'none' },
      });
    }
  }, []);

  useEffect(() => {
    if (!isLostPwdEnabled) {
      setDisableLostPwdLink({
        style: { pointerEvents: 'none' },
      });
    }
  }, []);

  useEffect(() => {
    if (!apiError) {
      setApiErrorMessage('');
      return;
    }

    const param = {
      internalErrorCode: apiError,
      SUPPORT_EMAIL: CONSTANTS.SUPPORT_EMAIL,
    };

    if (typeof messages[apiError] !== 'undefined') {
      setApiErrorMessage(formatMessage(messages[apiError], param));
    } else {
      setApiErrorMessage(formatMessage(commonMessages.internalError, param));
    }
  }, [apiError]);

  // -------------
  // - function
  // -------------
  function onSubmit(data) {
    authenticationActions.resetError();

    // remove extra spaces on user name
    const loginData = {
      username: data.username.trim(),
      password: data.password,
    };

    authenticationActions.login(loginData);
  }

  function backToHome() {
    if (history.location.pathname !== ROUTING.ROOT) {
      history.push(ROUTING.ROOT);
    }
  }

  return (
    <View
      pageConnectedUser={false}
      displayLogin={false}
      contentCentered
      onHome={backToHome}
    >
      <div className={classes.Login}>
        <SkwrFormHeader
          title={formatMessage(messages.title)}
          subtitle={formatMessage(messages.subTitle, {
            PRODUCT_NAME: getDefaultProductName(),
          })}
        />

        <SkwrFormError errorMessage={apiErrorMessage} />

        <form onSubmit={handleSubmit(onSubmit)}>
          <Controller
            control={control}
            name="username"
            rules={{
              required: formatMessage(messages['login.error.Username']),
            }}
            render={({ field }) => (
              <SkwrInput
                type="text"
                name="username"
                autoComplete="username"
                label={formatMessage(messages.emailLabel)}
                placeholder={formatMessage(commonMessages.emailPlaceholder)}
                errorMessage={errors.username?.message}
                inputRef={field.ref}
                onChange={field.onChange}
                onBlur={field.onBlur}
                value={field.value || ''}
              />
            )}
          />

          <Controller
            control={control}
            name="password"
            rules={{
              required: formatMessage(messages['login.error.Password']),
            }}
            render={({ field }) => (
              <SkwrPasswordField
                id="password"
                name="password"
                autoComplete="current-password"
                label={formatMessage(messages.passwordLabel)}
                errorMessage={errors.password?.message}
                inputRef={field.ref}
                onChange={field.onChange}
                onBlur={field.onBlur}
                value={field.value || ''}
              />
            )}
          />

          {isLostPwdAvailable && (
            <Link
              className={classes.forgotPassword}
              to={ROUTING.FORGOT_PASSWORD}
              {...disableLostPwdLink}
            >
              {formatMessage(messages.forgotPasswordLink)}
            </Link>
          )}

          <SkwrButtonWithProgress
            className={classes.LoginSend}
            type="submit"
            inProgress={isLoading}
            disabled={!isLoginEnabled}
          >
            {formatMessage(messages.contentButton)}
          </SkwrButtonWithProgress>
        </form>

        {isSignUpAvailable && (
          <p className={classes.forgotPassword}>
            {formatMessage(messages.dontAccountP)}
            {'\u00A0'}
            <Link to={ROUTING.SIGN_UP} {...disableSignUpLink}>
              {formatMessage(messages.signupLink)}
            </Link>
          </p>
        )}
      </div>
    </View>
  );
};

export default connect(
  (state) => ({
    apiError: state.authentication.error,
    status: state.authentication.status,
  }),
  (dispatch) => ({
    authenticationActions: bindActionCreators(AuthenticationActions, dispatch),
  })
)(injectIntl(Login));
