/**
 * File: DataKeyTools
 */

import { ColorByUsage } from 'skewerui';
import { getDkIRI, getDkvIRI } from './IRITools';

import {
  increaseBrightness,
  getRandomColor,
  contrastCheck,
} from './ColorTools';

/**
 * Returns the default color for a new DataKey
 */
export const getNewDkColor = () => {
  return getRandomColor();
};

/**
 * Returns the default color for a new DataKeyvalue
 */
export const getNewDkvColor = (color) => {
  if ((typeof color === 'undefined')
   || (color === null)
   || (color === '')) {
    return '';
  }

  let newColor = increaseBrightness(color, 20);

  // Under the given ratio we stop to increase brightness
  const contrastRatio = contrastCheck(ColorByUsage.workspaceBackground, newColor);
  if (contrastRatio < 1.1) {
    newColor = color;
  }

  return newColor;
};

export const doWeNeedToSetDefaultDataKeyValue = (dataKey, deletedDataKeyValue) => {
  const result = {
    needDefaultDkvReset: false,
    newDefaultDKV: null,
  };
  if ((typeof dataKey === 'undefined')
   || (dataKey === null)) {
    return result;
  }
  if ((typeof deletedDataKeyValue !== 'undefined')
   && (deletedDataKeyValue !== null)) {
    // Case where we delete a dataKeyValue - try to find a new one
    if (dataKey.defaultDataKeyValue === deletedDataKeyValue) {
      let newDkvIRI = null;
      for (let i = 0; (i < dataKey.dataKeyValues.length) && (newDkvIRI === null); i += 1) {
        const dkvIRI = getDkvIRI(dataKey.dataKeyValues[i]);
        if (dkvIRI !== deletedDataKeyValue) {
          newDkvIRI = dkvIRI;
        }
      }
      result.needDefaultDkvReset = true;
      result.newDefaultDKV = newDkvIRI;
    }
  } else if (dataKey.defaultDataKeyValue === null) {
    // Case where we there is no defaultDataKeyValue
    if ((typeof dataKey.dataKeyValues !== 'undefined')
     && (dataKey.dataKeyValues !== null)
     && (dataKey.dataKeyValues.length !== 0)) {
      result.needDefaultDkvReset = true;
      result.newDefaultDKV = getDkvIRI(dataKey.dataKeyValues[0]);
    }
  }

  return result;
};

const forbiddenCharsReplaceMap = [
  { base: '-', letters: /["']/g },
  { base: '_', letters: /[ ]/g },
];
export const getValidTechnicalValueFromName = (dkvName) => {
  let newStr = dkvName;
  for (let i = 0; i < forbiddenCharsReplaceMap.length; i += 1) {
    newStr = newStr.replace(forbiddenCharsReplaceMap[i].letters, forbiddenCharsReplaceMap[i].base);
  }
  return newStr;
};
export const getValidTechnicalNameFromName = (dkName) => {
  return getValidTechnicalValueFromName(dkName);
};


export const isDKExpandedInDKPanel = (dk) => {
  if ((typeof dk === 'undefined')
   || (dk === null)) {
    return true;
  }
  if ((typeof dk.dkPanelIExpanded === 'undefined')
   || (dk.dkPanelIExpanded === null)) {
    return true;
  }
  return dk.dkPanelIExpanded;
};
export const getClosedDataKeys = (dks) => {
  const closedDKs = [];
  for (let i = 0; i < dks.length; i += 1) {
    if (!isDKExpandedInDKPanel(dks[i])) {
      closedDKs.push(getDkIRI(dks[i]));
    }
  }
  return closedDKs;
};
