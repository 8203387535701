import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { ColorByUsage, FontSizes } from '../UIConstants';

const useStyles = makeStyles(theme => ({
  ErrorLine: {
    color: ColorByUsage.errorColor,
    fontSize: FontSizes.textLarge,
    fontWeight: 'lighter',
  },
}));

const FormError = ({
  errorMessage,
}) => {
  const classes = useStyles();

  let messageString;
  if (errorMessage) {
    messageString = errorMessage;
  } else {
    messageString = '';
  }

  return (
    <div>
      <p className={classes.ErrorLine}>
        {messageString}
      </p>
    </div>
  );
};

export default FormError;
