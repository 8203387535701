import * as User from './user';

export default function authHeader() {
  // return authorization header with jwt token
  const userData = User.get();

  const header = {
    Accept: 'application/ld+json',
    'Content-Type': 'application/ld+json',
  };
  // const header = {};
  if (userData && userData.token) {
    header.Authorization = `Bearer ${userData.token}`;
  }
  return header;
}
