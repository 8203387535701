/**
 * File ExportTools.jsx - Tools to manage Exports
 */
import dayjs from 'dayjs';
import GraphemeSplitter from 'grapheme-splitter';
import { removeDiacritics } from 'skewerui';

/**
 * Get the dayjs Date containing the day of an export
 * NOT the exact hour ! Only the day -> for filter purpose
 * @param {*} exp The export we want the day of
 */
export const getExportCreationDay = (exp) => {
  let date = dayjs(exp.createdAt);
  date = date.set('hour', 11);
  date = date.set('minute', 0);
  date = date.set('second', 0);
  return date;
};

const VALUES_SEPARATOR_UNDERSCORE = '_';
const EXPORTNAMES_PLACEHOLDERS = {
  PROJECT: '%P',
  FULLVALUES: '%V',
  PARTIALVALUES: /%[0-9]*v/g,
};
const getValuesStrFromQualif = (qualif, numberOfLetters) => {
  let resultStr = '';
  if ((typeof qualif === 'undefined')
   || (qualif === null)) {
    return resultStr;
  }
  const keys = Object.keys(qualif);
  const gphs = new GraphemeSplitter();
  const valuesSep = VALUES_SEPARATOR_UNDERSCORE;
  for (let i = 0; i < keys.length; i += 1) {
    if (i !== 0) {
      resultStr = resultStr.concat(valuesSep);
    }
    if ((typeof numberOfLetters !== 'undefined')
     && (numberOfLetters !== null)) {
      const gphArray = gphs.splitGraphemes(qualif[keys[i]]);

      for (let j = 0; (j < numberOfLetters) && (j < gphArray.length); j += 1) {
        resultStr = resultStr.concat(gphArray[j]);
      }
    } else {
      resultStr = resultStr.concat(qualif[keys[i]]);
    }
  }// for
  return resultStr;
};

export const generateExportRealName = (exportName, qualif, projectName) => {
  // Project
  let newExportName = exportName;
  if ((typeof projectName !== 'undefined')
   && (projectName !== null)) {
    newExportName = newExportName.replace(EXPORTNAMES_PLACEHOLDERS.PROJECT, projectName);
  }

  // Partial Value names
  const matchingPlaceHolders = newExportName.match(EXPORTNAMES_PLACEHOLDERS.PARTIALVALUES);

  if ((typeof matchingPlaceHolders !== 'undefined')
   && (matchingPlaceHolders !== null)) {
    matchingPlaceHolders.forEach((matchingPlaceHolder) => {
      const regex = /\d+/g;
      const numberOfLettersMatch = matchingPlaceHolder.match(regex);
      let numberOfLetters = 1;
      if ((typeof numberOfLettersMatch !== 'undefined')
      && (numberOfLettersMatch !== null)) {
        numberOfLetters = parseInt(numberOfLettersMatch[0], 10);
      }
      newExportName = newExportName.replace(
        matchingPlaceHolder,
        getValuesStrFromQualif(qualif, numberOfLetters),
      );
    });
  }

  // Full value names
  if ((typeof qualif !== 'undefined')
   && (qualif !== null)) {
    newExportName = newExportName.replace(
      EXPORTNAMES_PLACEHOLDERS.FULLVALUES,
      getValuesStrFromQualif(qualif),
    );
  }
  return newExportName;
};

export const generateExportTechnicalName = (oldExportName) => {
  let exportName = oldExportName;

  exportName = removeDiacritics(exportName);

  exportName = exportName.replace(/^\s+|\s+$/g, '');

  // Make the string lowercase
  exportName = exportName.toLowerCase();

  const from = '·/_,:;';
  const to = '------';
  for (let i = 0, l = from.length; i < l; i += 1) {
    exportName = exportName.replace(new RegExp(from.charAt(i), 'g'), to.charAt(i));
  }

  // Remove invalid chars
  exportName = exportName.replace(/[^a-z0-9 -]/g, '')
  // Collapse whitespace and replace by -
    .replace(/\s+/g, '_')
  // Collapse dashes
    .replace(/-+/g, '_');

  return exportName;
};


// Exports Status
export const EXPORT_STATUS = {
  PENDING: 'pending',
  PROCESSING: 'processing',
  COMPLETE: 'complete',
  ERROR: 'error',
};
export const getExportStatusIRI = (status) => `/experience_export_jobs_statuses/${status}`;
