import createUndoMiddleware from '../undoredo/createUndoMiddleware';
import {
  PROJECTS_REVERT_ACTIONS,
  PROJECTS_UNREVERTABLE_ACTIONS,
} from './projectsActions';
import {
  DATAKEYS_REVERT_ACTIONS,
  DATAKEYS_UNREVERTABLE_ACTIONS,
} from './dataKeysActions';
import {
  NODES_REVERT_ACTIONS,
  NODES_UNREVERTABLE_ACTIONS,
} from './nodesActions';
import { LABELS_REVERT_ACTIONS } from './labelsState';
import {
  ASSETS_REVERT_ACTIONS,
  ASSETS_UNREVERTABLE_ACTIONS,
} from './mediaLibraryActions';
import { EXPORTS_REVERT_ACTIONS } from './exportState';

export default createUndoMiddleware({
  getViewState: null,
  setViewState: null,
  revertingActions: {
    ...PROJECTS_REVERT_ACTIONS,
    ...DATAKEYS_REVERT_ACTIONS,
    ...NODES_REVERT_ACTIONS,
    ...LABELS_REVERT_ACTIONS,
    ...ASSETS_REVERT_ACTIONS,
    ...EXPORTS_REVERT_ACTIONS,
  },
  unrevertableActions: [
    ...NODES_UNREVERTABLE_ACTIONS,
    ...ASSETS_UNREVERTABLE_ACTIONS,
    ...PROJECTS_UNREVERTABLE_ACTIONS,
    ...DATAKEYS_UNREVERTABLE_ACTIONS,
  ],
});
