/**
 * File : authenticationSelector.js
 */
import { getDefaultProductName } from '../tools/PageTools';
import { isSuccessfullyUploaded } from '../Constants';

/**
 * Get current user data
 * @param {*} state The State
 */
export const getCurrentUser = (state) => state.authentication.user;

/**
 * Get current user data
 * @param {*} state The State
 */
export const getCurrentUserEmail = (state) => {
  if ((typeof state.authentication.user !== 'undefined')
   && (state.authentication.user !== null)
   && (typeof state.authentication.user.email !== 'undefined')
   && (state.authentication.user.email !== null)) {
    return state.authentication.user.email;
  }
  return '';
};

/**
 * Is the user loggedIn
 * @param {*} state The State
 */
export const isLoggedIn = (state) => state.authentication.loggedIn;

/**
 * Get authentication status
 * @param {*} state The State
 */
export const getAuthenticationStatus = (state) => state.authentication.status;

/**
 * Get authentication error
 * @param {*} state The State
 */
export const getAuthenticationError = (state) => state.authentication.error;


/**
 * Users logos
 */
export const LOGO_SIZE_TYPES = {
  SMALL: 'editor_small',
  LARGE: 'editor_big',
};

export const getCurrentUserSmallLogoURL = (state) => {
  let logoUrl = null;

  // First check validity of logos data !
  if ((typeof state.authentication.userData === 'undefined')
   || (state.authentication.userData === null)) {
    return logoUrl;
  }

  if ((typeof state.authentication.userData.logos === 'undefined')
   || (state.authentication.userData.logos === null)
   || (state.authentication.userData.logos.length === 0)) {
    return logoUrl;
  }

  // Ouf - logo datas are valid (at least it seems they are)
  let logoData = null;
  for (
    let i = 0;
    ((i < state.authentication.userData.logos.length) && (logoData === null));
    i += 1) {
    if (state.authentication.userData.logos[i].type === LOGO_SIZE_TYPES.SMALL) {
      logoData = state.authentication.userData.logos[i];
    }
  }// for

  // If no small logo found, use first one
  if (logoData === null) {
    // eslint-disable-next-line prefer-destructuring
    logoData = state.authentication.userData.logos[0];
  }

  // check if the logoData refers to a valid one
  if (isSuccessfullyUploaded(logoData.cloudResourceUploadStatus)
    && (typeof logoData.url !== 'undefined')
    && (logoData.url !== null)) {
    logoUrl = logoData.url;
  }

  return logoUrl;
};// getCurrentUserSmallLogoURL


export const getProductName = (state) => {
  let productName = getDefaultProductName();

  // First check validity of user data !
  if ((typeof state.authentication.userData === 'undefined')
   || (state.authentication.userData === null)) {
    return productName;
  }
  if ((typeof state.authentication.userData.productName !== 'undefined')
   && (state.authentication.userData.productName !== null)
   && (state.authentication.userData.productName !== '')) {
    productName = state.authentication.userData.productName;
  } else if ((typeof state.authentication.userData.companyName !== 'undefined')
   && (state.authentication.userData.companyName !== null)
   && (state.authentication.userData.companyName !== '')) {
    productName = state.authentication.userData.companyName;
  }

  return productName;
};// getProductName
