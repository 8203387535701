import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Menu from '@material-ui/core/Menu';

const menuStyles = makeStyles(theme => ({
  list: {
    paddingTop: '0px',
    paddingBottom: '0px',
  },
}));

const SkwrMenu = ({
  onClose,
  children,
  ...props
}) => {
  const classes = menuStyles();

  // Handle own context menu event -> prevent opening a browser context menu
  const handleOwnContextMenu = (evt) => {
    evt.preventDefault();
  };
  // If right click then closes the menu
  const handleMouseDown = (evt) => {
    if (evt.button === 2) {
      onClose();
    }
  };

  return (
    <Menu
      classes={{
        list: classes.list,
      }}
      keepMounted
      onClose={onClose}
      onMouseDown={handleMouseDown}
      onContextMenu={handleOwnContextMenu}
      transitionDuration={0}
      {...props}
    >
      {children}
    </Menu>
  );
};

export default SkwrMenu;
