import {
  isVideoLabel,
  isAudioLabel,
  isAssetDedicatedLabel,
} from '../tools/LabelTools';
import { getCurrentProject } from './projectsSelectors';

/**
 * DATAKEYS STATE SELECTORS
 */

/**
 * Get the labels ARRAY of the current project
 * WARNING : returns all the labels EXCEPT THE AUDIO ONES !!
 *
 * @param {*} state The State
 */
export const getCurrentLabels = state => state.labels.labels;
export const getVideoLabels = (state, onlyuserones = true) => {
  const labels = [];
  let addIt = true;
  for (let i = 0; i < state.labels.labels.length; i += 1) {
    if (isVideoLabel(state.labels.labels[i], getCurrentProject(state))) {
      addIt = true;
      if (onlyuserones) {
        if (isAssetDedicatedLabel(state.labels.labels[i])) {
          addIt = false;
        }
      }
      if (addIt) {
        labels.push(state.labels.labels[i]);
      }
    }
  }
  return labels;
};
export const getAudioLabels = (state, onlyuserones = true) => {
  const labels = [];
  let addIt = true;
  for (let i = 0; i < state.labels.labels.length; i += 1) {
    if (isAudioLabel(state.labels.labels[i], getCurrentProject(state))) {
      addIt = true;
      if (onlyuserones) {
        if (isAssetDedicatedLabel(state.labels.labels[i])) {
          addIt = false;
        }
      }
      if (addIt) {
        labels.push(state.labels.labels[i]);
      }
    }
  }
  return labels;
};

/**
 * Get a SELECTOR that will allow to get a label given its IRI
 * @param {*} state The State
 */
export const getLabelFromIRISelector = (state) => {
  return (iri) => {
    const index = state.labels.labelsIndexesMap[iri];
    return state.labels.labels[index];
  };
};
export const getLabelFromIRI = (state, iri) => {
  const func = getLabelFromIRISelector(state);
  return func(iri);
};

/**
 * Get the status of labels state of the current project
 * @param {*} state The State
 */
export const getLabelsStatus = (state) => state.labels.status;

/**
 * Get the error of labels state of the current project
 * @param {*} state The State
 */
export const getLabelsError = (state) => state.labels.error;


export const getLabelByName = (state, labelName) => {
  const func = getLabelByNameSelector(state);
  return func(labelName);
};

/**
 * Retrieve a label from its name
 * The comparison is case insensitive
 * @param {*} state
 * @returns
 */
export const getLabelByNameSelector = (state) => {
  return (labelName) => {
    let label = null;

    for (let i = 0; (i < state.labels.labels.length) && (label === null); i += 1) {
      if (state.labels.labels[i].name.localeCompare(labelName, undefined, { sensitivity: 'base' }) === 0) {
        label = state.labels.labels[i];
      }
    }

    return label;
  };
};

