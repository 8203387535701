import axios from 'axios';
import CONSTANTS from '../Constants';
import authHeader from './auth-header';
import rejectUnfilteredError from './helperTools';
import { getLabelIRI } from '../tools/IRITools';


export const createLabel = (labelData) => {
  return new Promise((resolve, reject) => {
    axios.post(`${CONSTANTS.API.PROJECTS_URI}/labels`, labelData, {
      headers: authHeader(),
    }).then((response) => {
      resolve(response.data);
    }).catch((error) => {
      if (error.response) {
        if (error.response.status === 400) {
          if (error.response.data['@type'] === 'ConstraintViolationList') {
            if (error.response.data.violations[0].message !== '') {
              console.error(error.response.data.violations[0].message);
            }
          }
        }
      }
      rejectUnfilteredError('createLabel', error, reject);
    });
  });
};

export const updateLabel = (label) => {
  return new Promise((resolve, reject) => {
    axios.put(`${CONSTANTS.API.PROJECTS_URI}${getLabelIRI(label)}`,
      label,
      {
        headers: authHeader(),
      }).then((response) => {
      resolve(response.data);
    }).catch((error) => {
      if (error.response) {
        if (error.response.status === 400) {
          if (error.response.data['@type'] === 'ConstraintViolationList') {
            if (error.response.data.violations[0].message !== '') {
              console.error(error.response.data.violations[0].message);
            }
          }
        }
      }
      rejectUnfilteredError('updateLabel', error, reject);
    });
  });
};

export const deleteLabel = (label) => {
  return new Promise((resolve, reject) => {
    axios.delete(`${CONSTANTS.API.PROJECTS_URI}${getLabelIRI(label)}`, {
      headers: authHeader(),
    }).then((response) => {
      resolve(response.data);
    }).catch((error) => {
      if (error.response) {
        if (error.response.status === 400) {
          if (error.response.data['@type'] === 'ConstraintViolationList') {
            if (error.response.data.violation[0].propertyPath === 'name') {
              if (error.resopnse.data.violation[0].message !== '') {
                reject(new Error(error.response.data.violations[0].message));
                return;
              }
            }
          }
        } else if (error.response.status === 403) {
          if (error.response.data['@type'] === 'ForbiddenLabelSuppressionError') {
            if (typeof error.response.data['hydra:description'] !== 'undefined') {
              console.error(error.response.data['hydra:description']);
            }
            reject(Error('deleteLabel.error.ForbiddenLabelSuppressionError'));
            return;
          }
        }
      }
      rejectUnfilteredError('deleteLabel', error, reject);
    });
  });
};
