
export const getFakeProjects = () => {
  return {
    projects: [
      {
        name: 'Fake Project',
        description: 'Fake project - No Connection Mode',
        lastEditedAt: '2021-11-22T16:00:00.000Z',
        createdAt: '2021-11-22T16:00:00.000Z',
        '@id': '/fakeproject/1',
        uuid: 'fakeProjectUuid1',
      },
      {
        name: 'Fake Project 1',
        description: 'Fake project - No Connection Mode',
        lastEditedAt: '2021-11-22T16:00:00.000Z',
        createdAt: '2021-11-22T16:00:00.000Z',
        '@id': '/fakeproject/2',
        uuid: 'fakeProjectUuid2',
      },
    ],
    totalProjects: 2,
  };
};// getFakeProjects


export const getFakeProject = () => {
  return {
    name: 'Fake Project',
    videoMediaTypeIRI: '/fakeVideoMediaType/1',
    audioMediaTypeIRI: '/fakeAudioMediaType/1',
    uuid: 'fakeProjectUuid1',
    dataKeys: [
      {
        name: 'Variable 1',
        '@id': '/fakeDk/1',
        color: '#ff0000',
        dataKeyValues: [
          {
            name: 'Value 1 - a',
            '@id': '/fakeDkv/1',
            color: '#ff0000',
          },
          {
            name: 'Value 1 - b',
            '@id': '/fakeDkv/2',
            color: '#ff0000',
          },
        ],
      },
      {
        name: 'Variable 2',
        '@id': '/fakeDk/2',
        color: '#0f00ff',
        dataKeyValues: [
          {
            name: 'Value 2 - a',
            '@id': '/fakeDkv/3',
            color: '#0f00ff',
          },
          {
            name: 'Value 2 - b',
            '@id': '/fakeDkv/4',
            color: '#0f00ff',
          },
        ],
      },
    ],
    labels: [
      {
        name: 'label 1',
        '@id': '/fakeLabel/1',
      },
      {
        name: 'label 2',
        '@id': '/fakeLabel/2',
      },
      {
        name: 'label 3',
        '@id': '/fakeLabel/3',
      },
    ],

  };
};// getFakeProject

export const getFakeConnectables = () => {
  return {
    connectables: [],
  };
};// getFakeConnectables

export const getFakeAssets = () => {
  return {
    assets: [
      {
        name: 'fake Asset',
        fileName: 'fakeAsset.mp4',
        '@id': '/fakeAsset/1',
        labels: ['/fakeLabel/2'],
        assetFileType: {
          mediaType: '/fakeVideoMediaType/1',
        },
        preview: {
          status: '/asset_preview_statuses/ready',
        },
      },
      {
        name: 'fake Asset 2',
        fileName: 'fakeAsset.mp4',
        '@id': '/fakeAsset/2',
        labels: [],
        assetFileType: {
          mediaType: '/fakeVideoMediaType/1',
        },
        preview: {
          status: '/asset_preview_statuses/ready',
        },
      },
      {
        name: 'fake Asset Audio',
        fileName: 'fakeAsset.mp3',
        '@id': '/fakeAsset/3',
        labels: [],
        assetFileType: {
          mediaType: '/fakeAudioMediaType/1',
        },
        preview: {
          status: '/asset_preview_statuses/ready',
        },
      },
    ],
  };
};// getFakeAssets

export const getFakeExports = () => {
  return {
    exports: [
      {
        name: 'fake export 1',
        '@id': '/fakeExport/1',
        awsExperienceExportJobs: {
          jobsStatus: '/experience_export_jobs_statuses/complete',
        },
        createdAt: '2021-11-22T16:00:00.000Z',
      },
      {
        name: 'fake export 2',
        '@id': '/fakeExport/2',
        awsExperienceExportJobs: {
          jobsStatus: '/experience_export_jobs_statuses/processing',
        },
        createdAt: '2021-11-22T17:00:00.000Z',
      },
    ],
  };
};// getFakeExports

export const getFakeUserData = () => {
  return {
    user: {
      username: 'noconnection',
      firstName: 'noconnection',
      lastName: 'noconnection',
      email: 'noconnection',
      uuid: 'noconnection',
      jwt: 'noconnection',
    },
    token: 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiIxMjM0NTY3ODkwIiwibmFtZSI6IkpvaG4gRG9lIiwiaWF0IjoxNTE2MjM5MDIyfQ.SflKxwRJSMeKKF2QT4fwpMeJf36POk6yJV_adQssw5c',
  };
};// getFakeUserData
