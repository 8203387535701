import axios from 'axios';
import CONSTANTS from '../Constants';
import authHeader from './auth-header';
import rejectUnfilteredError from './helperTools';

/**
 * Generate a new scenario_preview.json
 * @param projectUuid UUID of the project that need a new preview scenario
 */
export const createPreviewScenario = (projectUuid) => {
  return new Promise((resolve, reject) => {
    axios.get(`${CONSTANTS.API.PROJECTS_URI}/projects/${projectUuid}/preview`, {
      headers: authHeader(),
    }).then((response) => {
      resolve(response.data);
    }).catch((error) => {
      if (error.response) {
        if (error.response.status === 403) {
          reject(new Error('internalError'));
          return;
        }
      }
      rejectUnfilteredError('createPreviewScenario', error, reject);
    });
  });
};

/**
 * Reach the API Gateway export route to export the current video being previewed
 * @param projectUuid UUID of the project that need a new preview scenario
 * @param replayToken replay token given by the PLGen
 * @param userEmail the mail of the user to warn when the job ends
 */
export const createSingleExport = (projectUuid, token, replayToken, userEmail) => {
  // May need a re-do to be more integrated : use a selector to get a state in the right format
  const parsedValues = {
    'project-uuid': projectUuid,
    replayToken,
    token,
    contact: userEmail,
  };
  // Changing the content-type to strict application JSON, else 415 error.
  const headers = authHeader();
  headers['Content-Type'] = 'application/json';
  return new Promise((resolve, reject) => {
    axios.post(`${CONSTANTS.API.EXPORT_SINGLE_URI}`, JSON.stringify(parsedValues), {
      headers,
    }).then((response) => {
      resolve({
        state: 'sent',
      });
    }).catch((error) => {
      if (error.response) {
        if (error.response.status === 403) {
          reject(new Error('internalError'));
          return;
        }
      }
      rejectUnfilteredError('createSingleExport', error, reject);
    });
  });
};
