import React from 'react';
import { connect } from 'react-redux';
import {
  SkwrDialog,
} from 'skewerui';
import { getLocale } from '../../../reducers/localeState';
import { getCurrentUser } from '../../../reducers/authenticationSelector';
import * as AIR_TABLE_DATA from '../../../AirTableConstants';

function FeedbackRequestDialog(props) {
  const {
    isOpen,
    onClose,
    dialogTitle,
    locale,
    user,
    prefillDate,
  } = props;

  const TABLE_DATA = AIR_TABLE_DATA.AIR_TABLE_FEEDBACK_DATA;

  const buildPrefill = (url, name, value) => {
    return `${url}&prefill_${name}=${value}`;
  };

  const getUrl = () => {
    const concatUsername = `${user.firstName ? user.firstName : ''}+${user.lastName ? user.lastName : ''}`;
    let localeSelectedUrl = TABLE_DATA.urls[locale];

    localeSelectedUrl = buildPrefill(localeSelectedUrl, TABLE_DATA.username, concatUsername);
    localeSelectedUrl = buildPrefill(localeSelectedUrl, TABLE_DATA.email, user.email ? user.email : '');

    if (prefillDate) {
      localeSelectedUrl = buildPrefill(localeSelectedUrl, TABLE_DATA.date, prefillDate);
    }

    return localeSelectedUrl;
  };

  return (
    <>
      <SkwrDialog
        open={isOpen}
        onClose={() => onClose()}
        title={dialogTitle}
      >
        <iframe
          src={getUrl()}
          frameBorder="0"
          width="100%"
          height="533"
          style={{
            background: 'transparent',
          }}
        />
      </SkwrDialog>
    </>
  );
}

export default connect((state) => ({
  locale: getLocale(state),
  user: getCurrentUser(state),
}), (dispatch) => ({
}))(FeedbackRequestDialog);

