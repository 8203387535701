/**
 * File: dataKeysStateConstants.js
 */
const ACTIONS = {
  // DATA KEYS REQUESTS
  // ----------
  CLEAR_ERROR: 'DATAKEYS_CLEAR_ERROR',
  RESET_CURRENT_PROJECT: 'RESET_CURRENT_PROJECT',

  GET_DATAKEYS_REQUEST: 'GET_DATAKEYS_API_GET_REQUEST',
  GET_DATAKEYS_SUCCESS: 'GET_DATAKEYS_API_GET_SUCCESS',
  GET_DATAKEYS_FAILURE: 'GET_DATAKEYS_API_GET_FAILURE',

  NEW_DATAKEY_REQUEST: 'NEW_DATAKEY_API_POST_REQUEST',
  NEW_DATAKEY_SUCCESS: 'NEW_DATAKEY_API_POST_SUCCESS',
  NEW_DATAKEY_FAILURE: 'NEW_DATAKEY_API_POST_FAILURE',
  NEW_DATAKEY_SUCCESS_UNDOABLE: 'NEW_DATAKEY_SUCCESS_UNDOABLE',

  MODIFY_DATAKEY_REQUEST: 'MODIFY_DATAKEY_API_POST_REQUEST',
  MODIFY_DATAKEY_SUCCESS: 'MODIFY_DATAKEY_API_POST_SUCCESS',
  MODIFY_DATAKEY_FAILURE: 'MODIFY_DATAKEY_API_POST_FAILURE',

  SET_DATAKEY_DEFAULTKEYVALUE_REQUEST: 'SET_DATAKEY_DEFAULTKEYVALUE_API_POST_REQUEST',
  SET_DATAKEY_DEFAULTKEYVALUE_SUCCESS: 'SET_DATAKEY_DEFAULTKEYVALUE_API_POST_SUCCESS',
  SET_DATAKEY_DEFAULTKEYVALUE_FAILURE: 'SET_DATAKEY_DEFAULTKEYVALUE_API_POST_FAILURE',

  DELETE_DATAKEY_REQUEST: 'DELETE_DATAKEY_API_POST_REQUEST',
  DELETE_DATAKEY_SUCCESS: 'DELETE_DATAKEY_API_POST_SUCCESS',
  DELETE_DATAKEY_FAILURE: 'DELETE_DATAKEY_API_POST_FAILURE',

  NEW_DATAKEYVALUE_REQUEST: 'NEW_DATAKEYVALUE_API_POST_REQUEST',
  NEW_DATAKEYVALUE_SUCCESS: 'NEW_DATAKEYVALUE_API_POST_SUCCESS',
  NEW_DATAKEYVALUE_FAILURE: 'NEW_DATAKEYVALUE_API_POST_FAILURE',
  NEW_DATAKEYVALUE_SUCCESS_UNDOABLE: 'NEW_DATAKEYVALUE_SUCCESS_UNDOABLE',

  MODIFY_DATAKEYVALUE_REQUEST: 'MODIFY_DATAKEYVALUE_API_POST_REQUEST',
  MODIFY_DATAKEYVALUE_SUCCESS: 'MODIFY_DATAKEYVALUE_API_POST_SUCCESS',
  MODIFY_DATAKEYVALUE_FAILURE: 'MODIFY_DATAKEYVALUE_API_POST_FAILURE',

  DELETE_DATAKEYVALUE_REQUEST: 'DELETE_DATAKEYVALUE_API_POST_REQUEST',
  DELETE_DATAKEYVALUE_SUCCESS: 'DELETE_DATAKEYVALUE_API_POST_SUCCESS',
  DELETE_DATAKEYVALUE_FAILURE: 'DELETE_DATAKEYVALUE_API_POST_FAILURE',


  // UI ACTIONS - modify graphical state
  SWITCH_EXPAND_COLLAPSE_DK_PANEL_STATE: 'SWITCH_EXPAND_COLLAPSE_DK_PANEL_STATE',
};
export default ACTIONS;
