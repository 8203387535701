export const GENERAL_SHORTCUT_DATA = [
  {
    intlId: 'stepBack',
    values: [
      ['ctrl', 'Z']
    ],
    appleKeyVariant: [
      ['cmd', 'Z']
    ],
  },
  {
    intlId: 'stepForward',
    values: [
      ['ctrl', 'Y']
    ],
    appleKeyVariant: [
      ['cmd', 'Y']
    ],
  },
];

export const SCENARIO_SHORTCUT_DATA = [
  {
    intlId: 'contextual',
    values: [
      [{ intlInputId: 'rightClick' }]
    ]
  },
  {
    intlId: 'delete',
    values: [
      [{ intlInputId: 'deleteInput' }]
    ]
  },
  {
    intlId: 'deleteKeepLink',
    values: [
      [{ intlInputId: 'shift' }, { intlInputId: 'deleteInput' }]
    ]
  },
  {
    intlId: 'copy',
    values: [
      ['ctrl', 'C']
    ],
    appleKeyVariant: [
      ['cmd', 'C']
    ],
  },
  {
    intlId: 'paste',
    values: [
      ['ctrl', 'V']
    ],
    appleKeyVariant: [
      ['cmd', 'V']
    ],
  },
  {
    intlId: 'zoomIn',
    values: [
      ['+'],
      ['ctrl', { intlInputId: 'scroll' }]
    ],
    appleKeyVariant: [
      ['+'],
      ['cmd', { intlInputId: 'scroll' }]
    ],
  },
  {
    intlId: 'zoomOut',
    values: [
      ['-'],
      ['ctrl', { intlInputId: 'scroll' }]
    ],
    appleKeyVariant: [
      ['-'],
      ['cmd', { intlInputId: 'scroll' }]
    ],
  },
  {
    intlId: 'navigate',
    values: [
      [{ intlInputId: 'middleClick' }],
      [{ intlInputId: 'spaceBar' }, { intlInputId: 'leftClick' }]
    ]
  },
  {
    intlId: 'defaultZoom',
    values: [
      ['ctrl', '1']
    ],
    appleKeyVariant: [
      ['cmd', '1']
    ],
  },
  {
    intlId: 'fitToScreen',
    values: [
      ['ctrl', '0']
    ],
    appleKeyVariant: [
      ['cmd', '0']
    ],
  },
  {
    intlId: 'fitToSelection',
    values: [
      ['alt', 'ctrl', '0']
    ],
    appleKeyVariant: [
      ['alt', 'cmd', '0']
    ],
  },
];

export const MEDIA_LIB_SHORTCUT_DATA = [
  {
    intlId: 'deleteAssets',
    values: [
      [{ intlInputId: 'deleteInput' }]
    ]
  },
  {
    intlId: 'selectAllAssets',
    values: [
      ['ctrl', 'A']
    ],
    appleKeyVariant: [
      ['cmd', 'A']
    ],
  },
];
