/**
 * File : ProjectsActions.js
 */
import * as MediaLibrary from '../helpers/mediaLibrary';
import * as Project from '../helpers/project';
import * as Nodes from '../helpers/nodes';
import * as User from '../helpers/user';
import CONSTANTS, { UPLOAD_STATUS, isDemoMode } from '../Constants';
import { getAsset, retrieveAssets } from './mediaLibraryActions';
import { loadRunningExports } from './exportState';

import { computeCurrentProjectSummary } from './projectSummary';
import { isKeyInstance } from '../tools/ConnectableTools';
import { resizeImage } from '../tools/ImageTools';

import ACTIONS from './projectsStateConstants';

import {
  getVideoMediaTypeIRI,
  getAudioMediaTypeIRI,
  getConnectableIRI,
  getUserIRI,
  getLabelIRI,
  getProjectIRI,
  getProjectThumbnailIRI,
} from '../tools/IRITools';

export const PROJECTS_REVERT_ACTIONS = {
  // Modify Project Name UNDO REDO parameters
  [ACTIONS.MODIFY_PROJECT_NAME_SUCCESS]: {
    undo: {
      command: (action, { project, projectname }) => modifyProjectName(project, projectname),
      createArgs: undoArgsForModifyProjectName,
      succeededWhen: [ACTIONS.MODIFY_PROJECT_NAME_SUCCESS],
      failedWhen: [ACTIONS.MODIFY_PROJECT_NAME_FAILURE],
    },
    redo: {
      command: (action, { project, projectname }) => modifyProjectName(project, projectname),
      createArgs: redoArgsForModifyProjectName,
      failedWhen: [ACTIONS.MODIFY_PROJECT_NAME_FAILURE],
    },
  },
  // Modify Project Description UNDO REDO parameters
  [ACTIONS.MODIFY_PROJECT_DESCRIPTION_SUCCESS]: {
    undo: {
      command: (action,
        { project, projectdescr }) => modifyProjectDescription(project, projectdescr),
      createArgs: undoArgsForModifyProjectDescription,
      succeededWhen: [ACTIONS.MODIFY_PROJECT_DESCRIPTION_SUCCESS],
      failedWhen: [ACTIONS.MODIFY_PROJECT_DESCRIPTION_FAILURE],
    },
    redo: {
      command: (action,
        { project, projectdescr }) => modifyProjectDescription(project, projectdescr),
      createArgs: redoArgsForModifyProjectDescription,
      failedWhen: [ACTIONS.MODIFY_PROJECT_DESCRIPTION_FAILURE],
    },
  },
};

export const PROJECTS_UNREVERTABLE_ACTIONS = [
  ACTIONS.CREATE_PROJECT_SUCCESS,
  ACTIONS.DELETE_PROJECT_SUCCESS,
  ACTIONS.MODIFY_PROJECT_THUMBNAIL_SUCCESS,
];

export const retrieveProjects = (next = false) => {
  return (dispatch, getState) => {
    const {
      items,
      nbProjectsPerPage,
    } = getState().projects;

    // Calcul de l'index de la base à récupéer
    let pageToGet = 1;
    if (next) {
      if (nbProjectsPerPage !== 0) {
        if (items.length < nbProjectsPerPage) {
          pageToGet = 1;
        } else if (items.length >= nbProjectsPerPage) {
          pageToGet = (items.length / nbProjectsPerPage) + 1;
        }
      }
    }
    dispatch({
      type: ACTIONS.GET_PROJECTS_REQUEST,
    });

    Project.getProjects({ page: pageToGet })
      .then((projectData) => {
        dispatch({
          type: ACTIONS.GET_PROJECTS_SUCCESS,
          items: projectData,
          page: pageToGet,
        });
      }).catch((error) => {
        dispatch({
          type: ACTIONS.GET_PROJECTS_FAILURE,
          error: error.message,
        });
      });
  };
};

/**
 * Create a new project
 * @param {Object} form
 */
export const createNewProject = (projectTitle, projectDescription) => (dispatch) => {
  dispatch({
    type: ACTIONS.NEW_PROJECT_REQUEST,
    payload: 'waiting response from API ...',
  });

  const user = User.get();
  Project.createProject({
    user: getUserIRI(user),
    name: projectTitle,
    description: projectDescription,
  }).then((project) => {
    dispatch({
      type: ACTIONS.NEW_PROJECT_SUCCESS,
      payload: project,
    });
  }).catch((error) => {
    dispatch({
      type: ACTIONS.NEW_PROJECT_FAILURE,
      error: error.message,
    });
  });
};

/**
 * Delete a project by uuid
 * @param {Object} project
 */
export const deleteProject = project => (dispatch) => {
  dispatch({
    type: ACTIONS.DELETE_PROJECT_REQUEST,
    payload: 'waiting response from API ...',
  });
  Project.deleteProject({
    uuid: project.uuid,
  }).then((projectUuid) => {
    dispatch({
      type: ACTIONS.DELETE_PROJECT_SUCCESS,
      projectUUid: projectUuid,
    });
  }).catch((error) => {
    dispatch({
      type: ACTIONS.DELETE_PROJECT_FAILURE,
      error: error.message,
    });
  });
};

export const resetError = () => {
  return {
    type: ACTIONS.CLEAR_ERROR,
  };
};

export const loadProject = (projectUuid) => (dispatch, getState) => {
  const {
    items,
    currentProject,
  } = getState().projects;

  // Do we need to reset the current project before dispatching the load request
  let resetCurrentProject = true;
  let newCurrentProject = null;
  if ((typeof currentProject !== 'undefined')
      && (currentProject !== null)) {
    if (projectUuid === currentProject.uuid) {
      resetCurrentProject = false;
    }
  }
  if (resetCurrentProject === true) {
    // Is the projectUuid in the list of projects
    if ((typeof items !== 'undefined')
        && (items !== null)) {
      items.forEach((project) => {
        if (project.uuid === projectUuid) {
          newCurrentProject = { ...project };
        }
      });
    }
  }

  if (resetCurrentProject) {
    dispatch({
      type: ACTIONS.RESET_CURRENT_PROJECT,
      newCurrentProject,
    });
  }

  dispatch({
    type: ACTIONS.GET_PROJECT_REQUEST,
  });
  Project.loadProject(projectUuid)
    .then((project) => {
      // Get the connectables of the project
      Nodes.getConnectables(projectUuid).then((connectablesData) => {
        const createdProject = { ...project };

        // Store the videoMediaTypeIRI directly in the project to fasten getVideoMediaTypeIRI
        createdProject.videoMediaTypeIRI = getVideoMediaTypeIRI(createdProject);
        createdProject.audioMediaTypeIRI = getAudioMediaTypeIRI(createdProject);

        dispatch(loadSingleAudioTrackAsset(
          project.projectRootConnectables,
          connectablesData.connectables,
          project.labels,
        ));

        // Start the loop that polls on running export jobs
        dispatch(loadRunningExports());

        // XXX - Get all the assets of the project -> needed to be able
        // XXX - to display the asset names on storyblocks
        dispatch(retrieveAssets());

        dispatch({
          type: ACTIONS.GET_PROJECT_SUCCESS,
          project: createdProject,
          dataKeys: project.dataKeys,
          labels: project.labels,
          connectables: connectablesData.connectables,
          roots: project.projectRootConnectables,
        });

        // reset project summary
        dispatch(resetProjectSummary(true));
      }).catch((error) => {
        dispatch({
          type: ACTIONS.GET_PROJECT_FAILURE,
          error: error.message,
        });
      });
    }).catch((error) => {
      dispatch({
        type: ACTIONS.GET_PROJECT_FAILURE,
        error: error.message,
      });
    });
};

/**
 * loads the single audio track of a project given the project data
 */
export const loadSingleAudioTrackAsset = (roots, connectables, labels) => (dispatch, getState) => {
  if ((typeof roots === 'undefined')
   || (roots === null)) {
    return;
  }

  if ((typeof roots.audioRootConnectable === 'undefined')
   || (roots.audioRootConnectable === null)) {
    return;
  }

  if ((typeof connectables === 'undefined')
   || (connectables === null)
   || (connectables.length === 0)) {
    return;
  }

  if ((typeof labels === 'undefined')
   || (labels === null)
   || (labels.length === 0)) {
    return;
  }

  const audioRootIRI = roots.audioRootConnectable;
  const conIdx = connectables.findIndex(
    con => getConnectableIRI(con) === audioRootIRI,
  );
  if (conIdx === -1) {
    return;
  }

  const audioRoot = connectables[conIdx];
  if ((typeof audioRoot.label === 'undefined')
   || (audioRoot.label === null)) {
    return;
  }

  const labelIdx = labels.findIndex(
    lbl => getLabelIRI(lbl) === audioRoot.label,
  );
  if (labelIdx === -1) {
    return;
  }

  if ((typeof labels[labelIdx].assets === 'undefined')
   || (labels[labelIdx].assets === null)
   || (labels[labelIdx].assets.length === 0)) {
    return;
  }
  const firstAssetIRI = labels[labelIdx].assets[0];

  dispatch(getAsset(firstAssetIRI));

  // Multitracks or not ?
  if ((typeof audioRoot.outputLinks === 'undefined')
   || (audioRoot.outputLinks === null)
   || (audioRoot.outputLinks.length === 0)) {
    // Audio root has no output links
    return;
  }

  const dkiIRIFollowingRoot = audioRoot.outputLinks[0].to;
  const dkiIdx = connectables.findIndex(
    con => getConnectableIRI(con) === dkiIRIFollowingRoot,
  );
  if (dkiIdx === -1) {
    return;
  }

  const dki = connectables[dkiIdx];

  if ((typeof dki !== 'undefined')
   && (dki !== null)
   && (isKeyInstance(dki))
   && (typeof dki.outputLinks !== 'undefined')
   && (dki.outputLinks !== null)
   && (dki.outputLinks.length !== 0)) {
    dki.outputLinks.forEach((lnk) => {
      const toConIdx = connectables.findIndex(
        con => getConnectableIRI(con) === lnk.to,
      );
      if (toConIdx !== -1) {
        const toCon = connectables[toConIdx];
        if ((typeof toCon !== 'undefined')
        && (toCon !== null)) {
          const conLabelIdx = labels.findIndex(
            lbl => getLabelIRI(lbl) === toCon.label,
          );
          if (conLabelIdx === -1) {
            return;
          }

          if ((typeof labels[conLabelIdx].assets === 'undefined')
           || (labels[conLabelIdx].assets === null)
           || (labels[conLabelIdx].assets.length === 0)) {
            return;
          }
          const conAssetIRI = labels[conLabelIdx].assets[0];

          dispatch(getAsset(conAssetIRI));
        }
      }
    });
  }// if
};


// Called by logout - to reset projects state (data)
export const resetProjectState = () => (dispatch) => {
  dispatch({
    type: ACTIONS.RESET_PROJECT_STATE,
  });
};

/**
* Modify the project settings of the current project
 * @param {Object} projectSettings projectSettings
 */
export const modifyProjectSettings = projectSettings => (dispatch, getState) => {
  dispatch({
    type: ACTIONS.MODIFY_PROJECT_SETTINGS_REQUEST,
  });

  const {
    currentProject,
  } = getState().projects;

  if ((currentProject === null) || (typeof currentProject === 'undefined')) {
    dispatch({
      type: ACTIONS.MODIFY_PROJECT_SETTINGS_FAILURE,
      error: CONSTANTS.COMMONERRORS.NO_CURRENT_PROJECT,
    });
    return;
  }

  const modifiedProject = {
    projectSettings,
  };

  Project.modifyProject(modifiedProject, currentProject.uuid)
    .then(() => {
      dispatch({
        type: ACTIONS.MODIFY_PROJECT_SETTINGS_SUCCESS,
        projectSettings,
      });
    }).catch((error) => {
      dispatch({
        type: ACTIONS.MODIFY_PROJECT_SETTINGS_FAILURE,
        error: error.message,
      });
    });
};

/**
* Modify the project description of a given project
 * @param {Object} oldProject project to modify
 * @param {Object} newProjectDescription new description to apply to the above project
 */
export const modifyProjectDescription = (oldProject, newProjectDescription) => (dispatch) => {
  const projectToModify = {
    uuid: oldProject.uuid,
    description: newProjectDescription,
  };

  dispatch({
    type: ACTIONS.MODIFY_PROJECT_DESCRIPTION_REQUEST,
  });

  Project.modifyProject(projectToModify, projectToModify.uuid)
    .then((project) => {
      dispatch({
        type: ACTIONS.MODIFY_PROJECT_DESCRIPTION_SUCCESS,
        project,
      });
    }).catch((error) => {
      dispatch({
        type: ACTIONS.MODIFY_PROJECT_DESCRIPTION_FAILURE,
        error: error.message,
      });
    });
};
/**
 * Creates the arguments for the undo command of a project description modification
 * @param {*} state state when the action to undo was called
 * @param {*} action action to undo
 */
function undoArgsForModifyProjectDescription(state, action) {
  const found = state.projects.items.findIndex(((element) => {
    return element.uuid === action.project.uuid;
  }));

  if (found !== -1) {
    return {
      project: action.project,
      projectdescr: state.projects.items[found].description,
    };
  }
  return null;
}
/**
 * Creates the arguments for the redo command of a project description modification
 * @param {*} state state when the action to redo was called
 * @param {*} action action to redo
 */
function redoArgsForModifyProjectDescription(state, action) {
  return {
    project: action.project,
    projectdescr: action.project.description,
  };
}

/**
* Modify the project name of a given project
 * @param {Object} oldProject project to modify
 * @param {Object} newProjectName new name to apply to the above project
 */
export const modifyProjectName = (oldProject, newProjectName) => (dispatch) => {
  const projectToModify = {
    uuid: oldProject.uuid,
    name: newProjectName,
  };

  dispatch({
    type: ACTIONS.MODIFY_PROJECT_NAME_REQUEST,
  });

  Project.modifyProject(projectToModify, projectToModify.uuid)
    .then((project) => {
      dispatch({
        type: ACTIONS.MODIFY_PROJECT_NAME_SUCCESS,
        project,
      });
    }).catch((error) => {
      dispatch({
        type: ACTIONS.MODIFY_PROJECT_NAME_FAILURE,
        error: error.message,
      });
    });
};

/**
 * Creates the arguments for the undo command of a project name modification
 * @param {*} state state when the action to undo was called
 * @param {*} action action to undo
 */
function undoArgsForModifyProjectName(state, action) {
  const found = state.projects.items.findIndex(((element) => {
    return element.uuid === action.project.uuid;
  }));

  if (found !== -1) {
    return {
      project: action.project,
      projectname: state.projects.items[found].name,
    };
  }
  return null;
}
/**
 * Creates the arguments for the redo command of a project name modification
 * @param {*} state state when the action to redo was called
 * @param {*} action action to undo
 */
function redoArgsForModifyProjectName(state, action) {
  return {
    project: action.project,
    projectname: action.project.name,
  };
}

/**
* Modify the project thumbnail of a given project
 * @param {Object} oldProject project to modify
 * @param {Object} newProjectDescription new description to apply to the above project
 */
export const modifyProjectThumbnail = (oldProject, thumbnailFile) => (dispatch) => {
  dispatch({
    type: ACTIONS.MODIFY_PROJECT_THUMBNAIL_REQUEST,
  });

  // Resize the image (if possible and needed) to reduce its size - we only need a thumbnail
  resizeImage(
    thumbnailFile,
    { width: 400, height: 200 },
    (resizedData, unresizedFile) => {
      /**
       * Thumbnail Creation function
       */
      const createProjectThumbnail = (project, thumbData, file) => {
        // thumbnail object to create
        const thumbnailToCreate = {
          project: getProjectIRI(project),
          originalFileName: thumbnailFile.name,
        };
        Project.createProjectThumbnail(thumbnailToCreate).then((projectThumbnailData) => {
          // Thumbnail creation is done - upload the file
          MediaLibrary.uploadAsset(
            projectThumbnailData,
            (thumbData !== null) ? thumbData : file,
          ).then((data) => {
            // Upload is done - change the upload status in database
            const thumbIRI = getProjectThumbnailIRI(projectThumbnailData);
            Project.modifyProjectThumbnailStatus(thumbIRI, UPLOAD_STATUS.SUCCESS).then((tsd) => {
              dispatch({
                type: ACTIONS.MODIFY_PROJECT_THUMBNAIL_SUCCESS,
                projectThumbnail: tsd,
              });
            }).catch((error) => {
              dispatch({
                type: ACTIONS.MODIFY_PROJECT_THUMBNAIL_FAILURE,
              });
            });
          }).catch((error) => {
            // An error occured - delete the thumbnail in database
            const thumbIRI = getProjectThumbnailIRI(projectThumbnailData);
            Project.deleteProjectThumbnail(thumbIRI);

            // Then dispatch the error
            dispatch({
              type: ACTIONS.MODIFY_PROJECT_THUMBNAIL_FAILURE,
            });
          });
        }).catch((error) => {
          dispatch({
            type: ACTIONS.MODIFY_PROJECT_THUMBNAIL_FAILURE,
          });
        });
      };

      // If a thumbnail already exists we have to delete it prior creating a new one
      let existingThumbnailIRI = null;
      if ((typeof oldProject.projectThumbnail !== 'undefined')
        && (oldProject.projectThumbnail !== null)) {
        existingThumbnailIRI = getProjectThumbnailIRI(oldProject.projectThumbnail);
      }
      if ((typeof existingThumbnailIRI !== 'undefined')
       && (existingThumbnailIRI !== null)) {
        Project.deleteProjectThumbnail(existingThumbnailIRI).then((deleteData) => {
          // previous one is deleted , now create the new one
          createProjectThumbnail(oldProject, resizedData, unresizedFile);
        }).catch((error) => {
          dispatch({
            type: ACTIONS.MODIFY_PROJECT_THUMBNAIL_FAILURE,
          });
        });
      } else {
        // There is no thumbnail to delete, create directly a new one
        createProjectThumbnail(oldProject, resizedData, unresizedFile);
      }
    },
  );
};// modifyProjectThumbnail


/**
 * Update the project summary
 */
export const updateProjectSummary = () => (dispatch, getState) => {
  const projectSummary = computeCurrentProjectSummary(getState());
  dispatch({
    type: ACTIONS.GET_PROJECT_SUMMARY,
    summary: projectSummary,
  });
};

/**
 * Reset the project summary
 */
export const resetProjectSummary = (loadTime = false) => (dispatch, getState) => {
  if (isDemoMode()) {
    dispatch(updateProjectSummary());
  } else {
    dispatch({
      type: ACTIONS.RESET_PROJECT_SUMMARY,
      needCompute: !loadTime,
    });
  }
};

/**
 * Calls a request to know if the current project is a valid one or not
 * @param {Object} projectSettings projectSettings
 */
export const isCurrentProjectValid = () => (dispatch, getState) => {
  dispatch({
    type: ACTIONS.IS_PROJECT_VALID_REQUEST,
  });

  const {
    currentProject,
  } = getState().projects;

  if ((currentProject === null) || (typeof currentProject === 'undefined')) {
    dispatch({
      type: ACTIONS.IS_PROJECT_VALID_FAILURE,
      error: CONSTANTS.COMMONERRORS.NO_CURRENT_PROJECT,
    });
    return;
  }

  Project.isProjectValid(getProjectIRI(currentProject))
    .then((response) => {
      dispatch({
        type: ACTIONS.IS_PROJECT_VALID_SUCCESS,
        valid: response.valid,
      });
    }).catch((error) => {
      dispatch({
        type: ACTIONS.IS_PROJECT_VALID_FAILURE,
        error: error.message,
      });
    });
};


