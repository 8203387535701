import {
  getAssetIRI,
  getLabelIRI,
  assetUuidFromIRI,
} from '../tools/IRITools';
import {
  getCurrentProjectAssetThumbnailPattern,
  getCurrentProjectAssetPreviewPattern,
  getCurrentProject,
} from './projectsSelectors';
import {
  isLabelUsedByNodes,
} from './nodesSelectors';
import { ASSET } from '../Constants';
import {
  isAudioAsset,
  isAssetPreviewReady,
  getAssetPreviewFileName,
  getAssetFileName,
  getAssetCreationDay,
} from '../tools/AssetTools';
import {
  isAssetDedicatedLabel,
} from '../tools/LabelTools';
import { getLabelFromIRI } from './labelsSelectors';
import { getFileToUploadFromIndex } from './mediaLibraryState';

/**
 * Get all assets
 * @param {*} state The State
 */
export const getAssets = (state) => state.mediaLibrary.assets;

export const isUploadInProgress = (state) => {
  const {
    creationProgress,
    uploadProgress,
  } = state.mediaLibrary;
  if ((creationProgress.toCreate.length !== 0)
   || (creationProgress.creationInProgress.length !== 0)
   || (creationProgress.creationDone.length !== 0)
   || (uploadProgress.toUpload.length !== 0)
   || (uploadProgress.uploadInProgress.length !== 0)
   || (uploadProgress.uploadDone.length !== 0)) {
    return true;
  }
  return false;
};

export const getTotalNumberOfAssetsToUpload = (state) => {
  const {
    creationProgress,
  } = state.mediaLibrary;
  const total = creationProgress.toCreate.length
   + creationProgress.creationInProgress.length
   + creationProgress.creationDone.length;
  return total;
};

export const getNumberOfUploadedAssets = (state) => {
  const {
    uploadProgress,
  } = state.mediaLibrary;
  const total = uploadProgress.uploadInProgress.length
   + uploadProgress.uploadDone.length;
  return total;
};

export const getUploadingFiles = (state) => {
  const {
    uploadInProgress,
  } = state.mediaLibrary.uploadProgress;
  const files = [];
  for (let i = 0; i < uploadInProgress.length; i += 1) {
    const file = getFileToUploadFromIndex(uploadInProgress[i].fileIdxInUploadArray);
    if (file !== null) {
      files.push(file);
    }
  }
  return files;
};

/**
 * Get a SELECTOR that will allow to get a label given its IRI
 * @param {*} state The State
 */
export const getAssetFromIRISelector = (state) => {
  return (iri, displayNotFoundError = true) => {
    const index = state.mediaLibrary.assetsIndexesMap[iri];
    if ((typeof index === 'undefined')
     || (index === null)) {
      if (displayNotFoundError) {
        console.error('Asset not found : ', iri);
      }
      return undefined;
    }
    return state.mediaLibrary.assets[index];
  };
};
export const getAssetFromIRI = (state, iri, displayNotFoundError = true) => {
  const func = getAssetFromIRISelector(state);
  return func(iri, displayNotFoundError);
};

/**
 * Get the URL of the thumbnail of an asset from its IRI
 */
export const getAssetThumbnailURLFromIRISelector = (state) => {
  return (iri) => {
    const urlpattern = getCurrentProjectAssetThumbnailPattern(state);

    let thumbfilename;
    if ((typeof state.mediaLibrary.assets !== 'undefined')
     && (state.mediaLibrary.assets !== null)
     && (state.mediaLibrary.assets.length > 0)) {
      const asset = getAssetFromIRI(state, iri, false);
      if ((typeof asset !== 'undefined')
       && (asset !== null)
       && (typeof asset.assetThumbnail !== 'undefined')
       && (asset.assetThumbnail !== null)
       && (typeof asset.assetThumbnail.fileName !== 'undefined')
       && (asset.assetThumbnail.fileName !== null)) {
        thumbfilename = asset.assetThumbnail.fileName;
      }
    }
    return getAssetThumbnailURLFromPattern(urlpattern, iri, thumbfilename);
  };
};

export const getAssetThumbnailURLSelector = (state) => {
  return (asset) => {
    let url = '';
    const urlpattern = getCurrentProjectAssetThumbnailPattern(state);
    const assetIRI = getAssetIRI(asset);

    if ((typeof asset.assetThumbnail !== 'undefined')
      && (asset.assetThumbnail !== null)) {
      if (isAssetPreviewReady(asset)) {
        url = getAssetThumbnailURLFromPattern(
          urlpattern,
          assetIRI,
          asset.assetThumbnail.fileName,
        );
      }
    } else {
      url = getAssetThumbnailURLFromPattern(urlpattern, assetIRI);
    }
    return url;
  };
};

const getAssetThumbnailURLFromPattern = (urlpattern, iri, filename) => {
  let url = '';

  if ((typeof urlpattern !== 'undefined') && (urlpattern !== null)) {
    url = urlpattern.replace(ASSET.UUID_PATTERN, assetUuidFromIRI(iri));

    let realfilename = ASSET.THUMBNAIL_FILENAME;
    if ((typeof filename !== 'undefined')
     && (filename !== null)) {
      realfilename = filename;
    }
    url = url.replace(ASSET.THUMBFILE_PATTERN, realfilename);
  }
  return url;
};

export const getAssetPreviewURLSelector = (state) => {
  return (asset) => {
    let url = null;
    const urlpattern = getCurrentProjectAssetPreviewPattern(state);
    const assetIRI = getAssetIRI(asset);
    const filename = getAssetPreviewFileName(asset);

    if ((typeof urlpattern !== 'undefined')
     && (urlpattern !== null)
     && (typeof assetIRI !== 'undefined')
     && (assetIRI !== null)
     && (typeof filename !== 'undefined')
     && (filename !== null)) {
      url = getAssetPreviewURLFromPattern(urlpattern, assetIRI, filename);
    }
    return url;
  };
};

const getAssetPreviewURLFromPattern = (urlpattern, iri, filename) => {
  let url = '';

  if ((typeof urlpattern !== 'undefined')
   && (urlpattern !== null)
   && (typeof filename !== 'undefined')
   && (filename !== null)
   && (typeof iri !== 'undefined')
   && (iri !== null)) {
    url = urlpattern.replace(ASSET.UUID_PATTERN, assetUuidFromIRI(iri));
    url = url.replace(ASSET.PREVIEWFILE_PATTERN, filename);
  }
  return url;
};

/**
 * getAudioAssets return array of all audio assets
 */
export const getAudioAssets = (state) => {
  const assets = getAssets(state);
  const audioAssets = [];
  for (let i = 0; i < assets.length; i += 1) {
    if (isAudioAsset(assets[i], getCurrentProject(state))) {
      audioAssets.push(assets[i]);
    }
  }

  return audioAssets;
};

/**
 * Get all assets from a Label filter
 * @param {*} state The State
 */
export const getAssetsFromLabelIRISelector = (state) => (labelIRI) => {
  const assets = [];
  const label = getLabelFromIRI(state, labelIRI);

  if (label && label.assets) {
    for (let index = 0; index < label.assets.length; index += 1) {
      const asset = getAssetFromIRI(state, label.assets[index]);
      if (asset) {
        assets.push(asset);
      } else {
        break;
      }
    }

    return assets;
  }

  return null;
};

/**
   * Returns an array of assets that already exist among the one that the user wants to upload
   */
export const findAlreadyExistingAssets = (state) => (wantedAssetsFiles) => {
  const assets = getAssets(state);

  const existingAssets = [];
  const newFiles = [];
  let assetFile = null;
  let existIdx = -1;
  for (let i = 0; i < wantedAssetsFiles.length; i += 1) {
    assetFile = wantedAssetsFiles[i];
    existIdx = -1;
    for (let j = 0; (j < assets.length) && (existIdx === -1); j += 1) {
      if (assetFile.name === getAssetFileName(assets[j])) {
        existIdx = j;
      }
    }// for
    if (existIdx !== -1) {
      existingAssets.push({ ...assets[existIdx] });
    } else {
      newFiles.push(assetFile);
    }
  }// for

  return { existingAssets, newFiles: [...newFiles] };
};

/**
 * Returns the label dedicated to the given asset
 * @param {*} state
 * @param {*} asset
 * @param {*} project
 */
export const getAssetDedicatedLabel = (state, asset) => {
  const func = getAssetDedicatedLabelSelector(state);
  return func(asset);
};
export const getAssetDedicatedLabelSelector = (state) => {
  return (asset) => {
    let dedicatedLabel = null;
    if ((typeof asset.labels !== 'undefined')
     && (asset.labels !== null)) {
      for (let lbli = 0; (lbli < asset.labels.length) && (dedicatedLabel === null); lbli += 1) {
        const lblIRI = asset.labels[lbli];
        const label = getLabelFromIRI(state, lblIRI);

        if (isAssetDedicatedLabel(label, asset)) {
          dedicatedLabel = label;
        }
      }// for
    }
    return dedicatedLabel;
  };
};

/**
 * Find the undeletable assets among the given array
 * @param {*} assets array of assets in which we look for undeletable ones
 */
export const findUndeletableAssetsSelector = (state) => {
  return (assets) => {
    if ((typeof assets === 'undefined')
     || (assets === null)
     || (assets.length === 0)) {
      return [];
    }

    const undeletableAssets = [];

    let ownLabel = null;
    for (let i = 0; i < assets.length; i += 1) {
      ownLabel = getAssetDedicatedLabel(state, assets[i]);
      if ((typeof ownLabel !== 'undefined')
       && (ownLabel !== null)) {
        // Is the dedicated label used in the scenario ?
        if (isLabelUsedByNodes(state, getLabelIRI(ownLabel))) {
          undeletableAssets.push(assets[i]);
        }
      }
    }// for
    return undeletableAssets;
  };
};


export const getAssetsImportDates = (state) => {
  const importDates = [];
  let date = null;
  let found = false;
  for (let i = 0; i < state.mediaLibrary.assets.length; i += 1) {
    date = getAssetCreationDay(state.mediaLibrary.assets[i]);

    found = false;
    for (let j = 0; (j < importDates.length) && (!found); j += 1) {
      if (importDates[j].isSame(date)) {
        found = true;
      }
    }// for
    if (!found) {
      importDates.push(date);
    }
  }// for
  return importDates;

};// getAssetsImportDates
