import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import MenuItem from '@material-ui/core/MenuItem';
import { ColorByUsage } from './UIConstants';

const selectItemStyles = makeStyles(theme => ({
  selectItem: {
    background: ColorByUsage.selectBackground,
    color: ColorByUsage.selectForeground,
    '&:focus': {
      background: ColorByUsage.selectItemBackgroundSelected,
      color: ColorByUsage.selectItemForegroundSelected,
    },
    '&:hover': {
      background: ColorByUsage.selectItemBackgroundSelected,
      color: ColorByUsage.selectItemForegroundSelected,
    },
  },
}));

const SkwrSelectItem = React.forwardRef(({
  action,
  onClick,
  children,
}, ref) => {
  const classes = selectItemStyles();

  return (
    <MenuItem
      onClick={onClick}
      ref={ref}
      classes={{
        root: classes.selectItem,
      }}
    >
      {children}
    </MenuItem>
  );
});

export default SkwrSelectItem;
