
// TECHNICAL AVAILABILITY :
//     OK                -> available
//     BROKEN            -> not available because a bug occured somewhere
//     NOT_IMPLEMENTED   -> not implemented (Default state if the features is not found)
//     BETA              -> available features but only for beta testers with enough rights
export const FEATURE_TECHNICAL_AVAILABILITY = {
  OK: 'OK',
  BROKEN: 'BROKEN',
  NOT_IMPLEMENTED: 'NOT_IMPLEMENTED',
  BETA: 'BETA',
};

// INTERNAL FEATURE STATES
//  - COMPUTED ACCORDING TO TECHNICAL AVAILABILITY AND ACCESS RIGHTS
const FEATURE_ACCESS_STATES = {
  AVAILABLE: 'AVAILABLE',
  NOT_AVAILABLE: 'NOT_AVAILABLE',
  BROKEN: 'BROKEN',
};

// Features IDs
export const FEATURES = {
  // NOT LINKED TO ANY USER OR PROJECT
  SIGN_UP: 'SIGN_UP',
  LOG_IN: 'LOG_IN',
  LOST_PASSWORD: 'LOST_PASSWORD',

  // LINKED TO USER BUT NOT TO PROJECT
  MODIFY_USER_MAIL: 'MODIFY_USER_MAIL',
  MODIFY_USER_ID: 'MODIFY_USER_ID',
  MODIFY_USER_LASTNAME: 'MODIFY_USER_LASTNAME',
  MODIFY_USER_FIRSTNAME: 'MODIFY_USER_FIRSTNAME',
  CREATE_PROJECT: 'CREATE_PROJECT',

  // LINKED TO USER AND PROJECT
  DELETE_PROJECT: 'DELETE_PROJECT',
  MODIFY_PROJECT_NAME: 'MODIFY_PROJECT_NAME',
  MODIFY_PROJECT_DESCRIPTION: 'MODIFY_PROJECT_DESCRIPTION',

  MODIFY_ALERTS: 'MODIFY_ALERTS',
  MODIFY_PROJECT_SETTINGS: 'MODIFY_PROJECT_SETTINGS',
  MANAGE_MEDIA_LIBRARY: 'MANAGE_MEDIA_LIBRARY',

  ACCES_DESCRIPTION: 'ACCES_DESCRIPTION',
  ACCES_VERSIONS: 'ACCES_VERSIONS',
  ACCES_DUPLICATE: 'ACCES_DUPLICATE',
  DISPLAY_PROJECT_METRICS: 'DISPLAY_PROJECT_METRICS',

  ADD_DATA_KEY: 'ADD_DATA_KEY',
  ADD_LABEL: 'ADD_LABEL',

  ADD_AUDIO_ROOT_NODE: 'ADD_AUDIO_ROOT_NODE',
  DELETE_AUDIO_ROOT_NODE: 'DELETE_AUDIO_ROOT_NODE',
};

export function shouldFeatureBeEnabled(featureState) {
  if (featureState === FEATURE_ACCESS_STATES.AVAILABLE) {
    return true;
  }
  return false;
}

export function shouldFeatureBeDisplayed(featureState) {
  if ((featureState === FEATURE_ACCESS_STATES.AVAILABLE)
   || (featureState === FEATURE_ACCESS_STATES.BROKEN)) {
    return true;
  }
  return false;
}

let instance = null;

export class Features {
  constructor(store) {
    if (instance) {
      return instance;
    }
    instance = this;
    instance.store = store;
    instance.techFeatures = null;
  }

  /**
   * Check Feature availability according to projectid and current user
   * @param {*} featureID feature to check
   * @param {*} checkCurrentUserRights true if we need to check the current user rights
   * @param {*} project if any : the project in which to test the current user rights
   */
  static getAvailability(featureID, checkCurrentUserRights, project) {
    if ((typeof instance === 'undefined') || (instance == null)) {
      return FEATURE_ACCESS_STATES.NOT_AVAILABLE;
    }
    const availability = instance.availability(featureID, checkCurrentUserRights, project);
    const isEnabled = shouldFeatureBeEnabled(availability);
    const isAvailable = shouldFeatureBeDisplayed(availability);
    return { isEnabled, isAvailable };
  }

  /**
   * Check Feature availability according to projectid and current user
   * @param {*} featureID feature to check
   * @param {*} checkCurrentUserRights true if we need to check the current user rights
   * @param {*} project if any : the project in which to test the current user rights
   */
  availability(featureID, checkCurrentUserRights, project) {
    // Technical availability
    // ----------------------

    if ((typeof this.techFeatures === 'undefined')
     || (this.techFeatures === null)) {
      console.error('Technical availability not initialised');
      return FEATURE_ACCESS_STATES.NOT_AVAILABLE;
    }

    if ((typeof this.techFeatures[featureID] === 'undefined')
     || (this.techFeatures[featureID] === null)) {
      // If the feature is not known -> NOT AVAILABLE
      return FEATURE_ACCESS_STATES.NOT_AVAILABLE;
    }

    // If the feature 'exists' in the technical table but is NOT IMPLEMENTED don't go further
    if (this.techFeatures[featureID] === FEATURE_TECHNICAL_AVAILABILITY.NOT_IMPLEMENTED) {
      return FEATURE_ACCESS_STATES.NOT_AVAILABLE;
    }

    // Do we need to check user rights ?
    // If no, then just check technical availability to compute feature availability
    if ((typeof checkCurrentUserRights === 'undefined')
     || (checkCurrentUserRights === null)
     || (!checkCurrentUserRights)) {
      let featureAvailability = FEATURE_ACCESS_STATES.NOT_AVAILABLE;

      if (this.techFeatures[featureID] === FEATURE_TECHNICAL_AVAILABILITY.BROKEN) {
        featureAvailability = FEATURE_ACCESS_STATES.BROKEN;
      } else if (this.techFeatures[featureID] === FEATURE_TECHNICAL_AVAILABILITY.OK) {
        featureAvailability = FEATURE_ACCESS_STATES.AVAILABLE;
      }
      // Here we do not check BETA as we do not have any user, so no beta tester at all !
      return featureAvailability;
    }

    // Here we have to check user access rights to the feature

    // If the user is null we may be in a logout call -> so whatever the feature NOT_AVAILABLE
    if ((typeof instance.store.getState().authentication.user === 'undefined')
     || (instance.store.getState().authentication.user === null)) {
      return FEATURE_ACCESS_STATES.NOT_AVAILABLE;
    }

    const userFeaturesAccessRights = instance.store.getState().authentication.user.featuresRights;

    // garde-foo - verify that the access rights object exists
    if ((typeof userFeaturesAccessRights === 'undefined')
    || (userFeaturesAccessRights === null)) {
      return FEATURE_ACCESS_STATES.NOT_AVAILABLE;
    }

    if ((typeof userFeaturesAccessRights[featureID] === 'undefined')
     || (userFeaturesAccessRights[featureID] === null)
     || (!userFeaturesAccessRights[featureID])) {
      // If the feature is not known -> NOT AVAILABLE
      // If the feature is known but the user does not have rights -> NOT AVAILABLE
      return FEATURE_ACCESS_STATES.NOT_AVAILABLE;
    }

    // Do we have to check user access rights for a dedicated project ?

    if ((typeof project === 'undefined') || (project === null)) {
      // NO PROJECT TO CKECK -> Check user 'global' access rights
      let featureAvailability = FEATURE_ACCESS_STATES.NOT_AVAILABLE;

      if (this.techFeatures[featureID] === FEATURE_TECHNICAL_AVAILABILITY.BROKEN) {
        featureAvailability = FEATURE_ACCESS_STATES.BROKEN;
      } else if (this.techFeatures[featureID] === FEATURE_TECHNICAL_AVAILABILITY.OK) {
        featureAvailability = FEATURE_ACCESS_STATES.AVAILABLE;
      } else if (this.techFeatures[featureID] === FEATURE_TECHNICAL_AVAILABILITY.BETA) {
        if (this.isCurrentUserABetaTester()) {
          featureAvailability = FEATURE_ACCESS_STATES.AVAILABLE;
        }
      }

      return featureAvailability;
    }

    // FROM THERE we have to check the user access rights inside a given project

    const userFeaturesAccessRightsForProject = project.featuresRights;
    if ((typeof userFeaturesAccessRightsForProject === 'undefined')
      || (userFeaturesAccessRightsForProject === null)) {
      // If the feature is not known -> NOT AVAILABLE
      // If the feature is known but the user does not have rights -> NOT AVAILABLE
      return FEATURE_ACCESS_STATES.NOT_AVAILABLE;
    }

    if ((typeof userFeaturesAccessRightsForProject[featureID] === 'undefined')
      || (userFeaturesAccessRightsForProject[featureID] === null)
      || (!userFeaturesAccessRightsForProject[featureID])) {
      // If the feature is not known -> NOT AVAILABLE
      // If the feature is known but the user does not have rights -> NOT AVAILABLE
      return FEATURE_ACCESS_STATES.NOT_AVAILABLE;
    }

    let featureAvailability = FEATURE_ACCESS_STATES.NOT_AVAILABLE;

    if (this.techFeatures[featureID] === FEATURE_TECHNICAL_AVAILABILITY.BROKEN) {
      featureAvailability = FEATURE_ACCESS_STATES.BROKEN;
    } else if (this.techFeatures[featureID] === FEATURE_TECHNICAL_AVAILABILITY.OK) {
      featureAvailability = FEATURE_ACCESS_STATES.AVAILABLE;
    } else if (this.techFeatures[featureID] === FEATURE_TECHNICAL_AVAILABILITY.BETA) {
      // Is the user a beta tester for the given project
      if (this.isCurrentUserABetaTester(project)) {
        featureAvailability = FEATURE_ACCESS_STATES.AVAILABLE;
      }
    }

    return featureAvailability;
  }

  /**
   * Is current user a beta tester for given project
   * @param {*} project  if given, we have to check user role inside this project
   */
  isCurrentUserABetaTester(project) {
    let isABetaTester = false;

    // TODO - determine if the current user is a beta tester
    // TODO - either as a global user, or for the given project
    if ((typeof project === 'undefined') || (project === null)) {
      // If there is no project ID given, check the main role of the user
      console.log('XXX TODO : check user main role to see if he is a beta tester');
      isABetaTester = false;
    } else {
      // Check the user role in the given project
      console.log('XXX TODO : check user role in the given project to see if he is a beta tester');
      isABetaTester = false;
    }

    return isABetaTester;
  }

  // Set the table of feature availabilities whatever the user and whatever the project
  setTechnicalAvailability(skaf) {
    this.techFeatures = skaf;
  }
}
