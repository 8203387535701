
// import { get as getCurrentUser } from '../helpers/user';
import {
  COMPANY_NAME,
  PRODUCT_NAME,
  isDevelopmentMode,
} from '../Constants';
import { areWIPFeaturesEnabled } from './OtherTools';

export const getDefaultProductName = () => {
  let productName = PRODUCT_NAME;
  if (isDevelopmentMode()) {
    productName = `${PRODUCT_NAME} (Dev Version)`;
  }

  return productName;
};


export const getDefaultCompanyName = () => {
  const companyName = COMPANY_NAME;

  return companyName;
};

/**
 * Set the document title
 * @param {string} projectName Name of the current project - May be null or empty
 */
export const setPageTitle = (projectName, productName) => {
  let internalProductName = productName;
  if ((internalProductName === null)
   || (typeof internalProductName === 'undefined')
   || (internalProductName === '')) {
    internalProductName = getDefaultProductName();
  }
  let title = '';
  if ((projectName === null)
   || (typeof projectName === 'undefined')
   || (projectName === '')) {
    title = internalProductName;
  } else {
    title = projectName.concat(' - ').concat(internalProductName);
  }
  if (areWIPFeaturesEnabled()) {
    title = `WIP !! ${title}`;
  }
  document.title = title;
};

/**
 * Set the page icon
 */
export const setPageFavIcon = (logoUrl) => {
  let faviconurl = 'skwr-logo.svg';
  if (isDevelopmentMode()) {
    faviconurl = 'skwr-logo-dev.svg';
  }
  if ((typeof logoUrl !== 'undefined')
   && (logoUrl !== null)) {
    faviconurl = logoUrl;
  }

  const link = document.createElement('link');
  const oldLink = document.getElementById('dynamic-favicon');
  link.id = 'dynamic-favicon';
  link.type = 'image/svg+xml';
  link.rel = 'shortcut icon';
  link.href = faviconurl;
  if (oldLink) {
    document.getElementsByTagName('head')[0].removeChild(oldLink);
  }
  document.getElementsByTagName('head')[0].appendChild(link);
};
