import React from 'react';
import SkwrButton from './SkwrButton';
import SkwrDialog from './SkwrDialog';

function SkwrQuestionDialog(props) {
  const {
    isOpen,
    titleDialog,
    textDialog,
    textBtnYes,
    textBtnNo,
    textBtnCancel,
    actionBtnNo,
    actionBtnYes,
    actionBtnCancel,
    isCritical,
  } = props;

  const handleAnswerQuestion = (answer) => {
    if (answer === 'yes') {
      actionBtnCancel();
      actionBtnYes();
    }
    if (answer === 'no') {
      actionBtnCancel();
      actionBtnNo();
    }
  };

  const getBtnYesStyle = () => {
    if (isCritical) {
      return 'ButtonMajor';
    }
    return undefined;
  };

  return (
    <SkwrDialog
      open={isOpen}
      onClose={actionBtnCancel}
      title={titleDialog}
      actions={[
        <SkwrButton
          onClick={actionBtnCancel}
          componentStyle="ButtonOutlined"
        >
          {textBtnCancel}
        </SkwrButton>,
        typeof actionBtnNo === 'function' && (
          <SkwrButton
            onClick={() => handleAnswerQuestion('no')}
          >
            {textBtnNo}
          </SkwrButton>
        ),
        typeof actionBtnYes === 'function' && (
          <SkwrButton
            componentStyle={getBtnYesStyle()}
            onClick={() => handleAnswerQuestion('yes')}
          >
            {textBtnYes}
          </SkwrButton>
        ),
      ]}
    >
      {textDialog}
    </SkwrDialog>
  );
}

export default SkwrQuestionDialog;
