/**
 * File : projectsStateConstants.js
 */
const ACTIONS = {
  CLEAR_ERROR: 'PROJECT_CLEAR_ERROR',
  RESET_PROJECT_STATE: 'RESET_PROJECT_STATE',
  RESET_CURRENT_PROJECT: 'RESET_CURRENT_PROJECT',

  // PROJECT REQUESTS
  // ----------
  NEW_PROJECT_REQUEST: 'NEW_PROJECT_API_POST_REQUEST',
  NEW_PROJECT_SUCCESS: 'NEW_PROJECT_API_POST_SUCCESS',
  NEW_PROJECT_FAILURE: 'NEW_PROJECT_API_POST_FAILURE',

  GET_PROJECTS_REQUEST: 'GET_PROJECTS_API_GET_REQUEST',
  GET_PROJECTS_SUCCESS: 'GET_PROJECTS_API_GET_SUCCESS',
  GET_PROJECTS_FAILURE: 'GET_PROJECTS_API_GET_FAILURE',

  GET_PROJECT_REQUEST: 'GET_PROJECT_API_GET_REQUEST',
  GET_PROJECT_SUCCESS: 'GET_PROJECT_API_GET_SUCCESS',
  GET_PROJECT_FAILURE: 'GET_PROJECT_API_GET_FAILURE',

  DELETE_PROJECT_REQUEST: 'DELETE_PROJECT_API_POST_REQUEST',
  DELETE_PROJECT_SUCCESS: 'DELETE_PROJECT_API_POST_SUCCESS',
  DELETE_PROJECT_FAILURE: 'DELETE_PROJECT_API_POST_FAILURE',

  MODIFY_PROJECT_SETTINGS_REQUEST: 'MODIFY_PROJECT_SETTINGS_API_POST_REQUEST',
  MODIFY_PROJECT_SETTINGS_SUCCESS: 'MODIFY_PROJECT_SETTINGS_API_POST_SUCCESS',
  MODIFY_PROJECT_SETTINGS_FAILURE: 'MODIFY_PROJECT_SETTINGS_API_POST_FAILURE',

  MODIFY_PROJECT_NAME_REQUEST: 'MODIFY_PROJECT_NAME_API_POST_REQUEST',
  MODIFY_PROJECT_NAME_SUCCESS: 'MODIFY_PROJECT_NAME_API_POST_SUCCESS',
  MODIFY_PROJECT_NAME_FAILURE: 'MODIFY_PROJECT_NAME_API_POST_FAILURE',

  MODIFY_PROJECT_DESCRIPTION_REQUEST: 'MODIFY_PROJECT_DESCRIPTION_API_POST_REQUEST',
  MODIFY_PROJECT_DESCRIPTION_SUCCESS: 'MODIFY_PROJECT_DESCRIPTION_API_POST_SUCCESS',
  MODIFY_PROJECT_DESCRIPTION_FAILURE: 'MODIFY_PROJECT_DESCRIPTION_API_POST_FAILURE',

  MODIFY_PROJECT_THUMBNAIL_REQUEST: 'MODIFY_PROJECT_THUMBNAIL_API_POST_REQUEST',
  MODIFY_PROJECT_THUMBNAIL_SUCCESS: 'MODIFY_PROJECT_THUMBNAIL_API_POST_SUCCESS',
  MODIFY_PROJECT_THUMBNAIL_FAILURE: 'MODIFY_PROJECT_THUMBNAIL_API_POST_FAILURE',

  GET_PROJECT_SUMMARY: 'GET_PROJECT_SUMMARY',
  RESET_PROJECT_SUMMARY: 'RESET_PROJECT_SUMMARY',

  IS_PROJECT_VALID_REQUEST: 'IS_PROJECT_VALID_API_GET_REQUEST',
  IS_PROJECT_VALID_SUCCESS: 'IS_PROJECT_VALID_API_GET_SUCCESS',
  IS_PROJECT_VALID_FAILURE: 'IS_PROJECT_VALID_API_GET_FAILURE',
};
export default ACTIONS;
