import React from 'react';
import {
  SkwrDialog,
} from 'skewerui';

export default function CreditsDialog(props) {
  const {
    isOpen,
    onClose,
    dialogTitle,
  } = props;

  return (
    <>
      <SkwrDialog
        open={isOpen}
        onClose={() => onClose()}
        title={dialogTitle}
      >
        credit dialog
      </SkwrDialog>
    </>
  );
}
