import React from 'react';
import classNames from 'classnames';
import { withStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import SkwrButton from './SkwrButton';
import { ColorsType } from './UIConstants';

const styles = theme => ({
  buttonProgress: {
    color: ColorsType.primary,
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
});

const SkwrButtonWithProgress = ({
  className,
  componentStyle,
  children,
  classes,
  inProgress,
  disabled,
  ...props
}) => {
  return (
    <SkwrButton
      type="submit"
      disabled={inProgress || disabled}
      className={classNames(className)}
      componentStyle={componentStyle}
      {...props}
    >
      {children}
      {inProgress && <CircularProgress size={24} className={classes.buttonProgress} />}
    </SkwrButton>
  );
};

export default withStyles(styles)(SkwrButtonWithProgress);
