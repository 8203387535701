import {
  addUndoRedoItem,
  undoDone,
  redoDone,
  clear,
} from './actions';
import {
  getUndoItem,
  getRedoItem,
  getUndoyingItem,
  getRedoyingItem,
} from './selectors';

export default function createUndoMiddleware({
  getViewState,
  setViewState,
  revertingActions,
  unrevertableActions,
}) {
  const SUPPORTED_ACTIONS = Object.keys(revertingActions);
  return ({ dispatch, getState }) => next => (action) => {
    const state = getState();
    const ret = next(action);

    switch (action.type) {
      case 'UNDO_HISTORY@STARTUNDO': {
        const undoItem = getUndoItem(state);
        if (undoItem) {
          setViewState && dispatch(setViewState(undoItem.afterState));
          dispatch(getUndoCommand(undoItem));
          setViewState && dispatch(setViewState(undoItem.beforeState));
        }
        break;
      }
      case 'UNDO_HISTORY@UNDODONE': {
        break;
      }
      case 'UNDO_HISTORY@STARTREDO': {
        const redoItem = getRedoItem(state);
        if (redoItem) {
          setViewState && dispatch(setViewState(redoItem.beforeState));
          dispatch(getRedoCommand(redoItem));
        }
        break;
      }
      case 'UNDO_HISTORY@REDODONE': {
        break;
      }
      default: {
        const undoying = getUndoyingItem(state);
        const redoying = getRedoyingItem(state);

        if (undoying) {
          // We are currently undoing something. Check if the undo is done.
          const {
            succeededWhen,
            failedWhen,
          } = revertingActions[undoying.action.type].undo;

          if (succeededWhen.includes(action.type)) {
            dispatch(undoDone(true,
              getParamsToPatchIfNecessary(state, action, false)));
          } else if (failedWhen.includes(action.type)) {
            dispatch(undoDone(false));
          }
        } else if (redoying) {
          const {
            recomputeUndoArgs,
            succeededWhen,
            failedWhen,
          } = revertingActions[redoying.action.type].redo;

          // We are currently redoing something. Check if the redo is done.
          // The redo is done if the current action type is the same type as the action to redo
          let succeeded = false;
          if ((typeof succeededWhen !== 'undefined') && (succeededWhen !== null)) {
            if (succeededWhen.includes(action.type)) {
              succeeded = true;
            }
          } else if (action.type === redoying.action.type) {
            succeeded = true;
          }
          if (succeeded) {
            dispatch(redoDone(true,
              recomputeUndoArgs ? getUndoArgs(state, action) : null,
              getParamsToPatchIfNecessary(state, action, true)));
          } else if (failedWhen.includes(action.type)) {
            dispatch(redoDone(false, null, null));
          }
        } else if (SUPPORTED_ACTIONS.includes(action.type)) {
          dispatch(addUndoRedoItem(
            action,
            getViewState && getViewState(state),
            getViewState && getViewState(getState()),
            getUndoArgs(state, action),
            getRedoArgs(state, action),
          ));
        } else if (unrevertableActions.includes(action.type)) {
          dispatch(clear());
        }
        break;
      }
    }

    return ret;
  };

  function getUndoCommand(undoItem) {
    const { action, undoargs } = undoItem;
    const { type } = action;
    const actionCreator = revertingActions[type].undo.command;
    if (!actionCreator) {
      throw new Error(`Illegal reverting action definition for '${type}'`);
    }
    return actionCreator(action, undoargs);
  }

  function getRedoCommand(redoItem) {
    const { action, redoargs } = redoItem;
    const { type } = action;
    const actionCreator = revertingActions[type].redo.command;
    if (!actionCreator) {
      throw new Error(`Illegal reverting action definition for '${type}'`);
    }
    return actionCreator(action, redoargs);
  }

  function getUndoArgs(state, action) {
    const argsFactory = revertingActions[action.type].undo.createArgs;

    return argsFactory && argsFactory(state, action);
  }

  function getRedoArgs(state, action) {
    const argsFactory = revertingActions[action.type].redo.createArgs;

    return argsFactory && argsFactory(state, action);
  }

  function getParamsToPatchIfNecessary(state, action, isredoying) {
    // const redoQueue = getRedoQueue(state);
    // if ((typeof redoQueue === 'undefined') || (redoQueue === null)) {
    //   return null;
    // }
    let queueitem = null;
    if (isredoying) {
      queueitem = getRedoyingItem(state);
    } else {
      queueitem = getUndoyingItem(state);
    }
    if ((typeof queueitem === 'undefined') || (queueitem === null)) {
      return null;
    }

    let paramsToPatch = null;
    if (isredoying) {
      const { getParamsToPatch } = revertingActions[queueitem.action.type].redo;
      if ((typeof getParamsToPatch === 'undefined') || (getParamsToPatch === null)) {
        return null;
      }
      paramsToPatch = getParamsToPatch(state, queueitem.action, action);
    } else {
      const { getParamsToPatch } = revertingActions[queueitem.action.type].undo;
      if ((typeof getParamsToPatch === 'undefined') || (getParamsToPatch === null)) {
        return null;
      }
      paramsToPatch = getParamsToPatch(state, queueitem.action, action);
    }

    if ((typeof paramsToPatch === 'undefined')
     || (paramsToPatch === null)
     || (paramsToPatch.length === 0)) {
      return null;
    }

    return paramsToPatch;
  }
}
