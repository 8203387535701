import React, { useState } from 'react';
import { injectIntl, defineMessages } from 'react-intl';
import { makeStyles } from '@material-ui/core/styles';
import {
  SkwrMenu,
  SkwrMenuItem,
  SkwrTool,
  ColorByUsage,
} from 'skewerui';
import HelpIcon from '@material-ui/icons/Help';
import KeyboardShortcutDialog from '../dialogs/helpCenterDialog/KeyboardShortcutDialog';
import ContactSupportDialog from '../dialogs/helpCenterDialog/ContactSupportDialog';
import FeedbackRequestDialog from '../dialogs/helpCenterDialog/FeedbackRequestDialog';
import CreditsDialog from '../dialogs/helpCenterDialog/CreditsDialog';

const messages = defineMessages({
  helpCenter: {
    id: 'helpCenterMenu.helpCenter',
    defaultMessage: 'Help center',
  },
  userGuide: {
    id: 'helpCenterMenu.userGuide',
    defaultMessage: 'User guide',
  },
  keyboardShortcut: {
    id: 'helpCenterMenu.keyboardShortcut',
    defaultMessage: 'Keyboard shortcut',
  },
  contactSupport: {
    id: 'helpCenterMenu.contactSupport',
    defaultMessage: 'Contact support',
  },
  feedbackRequest: {
    id: 'helpCenterMenu.feedbackRequest',
    defaultMessage: 'Feedback and request',
  },
  releaseNote: {
    id: 'helpCenterMenu.releaseNote',
    defaultMessage: 'Release note ({version})',
  },
  credits: {
    id: 'helpCenterMenu.credits',
    defaultMessage: 'Credits',
  },
});

const useStyles = makeStyles(theme => ({
  helpBtn: {
    padding: '6px',
    height: '36px !important',
    width: '36px !important',
  },
  helpBtnSvg: {
    flex: 0,
    '& svg': {
      stroke: ColorByUsage.cardIcon,
      width: '25px',
      height: '25px',
      '& path': {
        fill: ColorByUsage.cardIcon,
        opacity: 1,
      },
    },
  },
  helpBtnContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    flexDirection: 'row',
  },
}));

const DATE = new Date();
const PREFILL_DATE = `${DATE.getDate()}/${DATE.getMonth()}/${DATE.getFullYear()}`;
const USER_GUIDE_URL = 'http://userguide.skewerlab.com';
const RELEASE_NOTE_URL = 'https://userguide.skewerlab.com/release-notes';
const CURRENT_VERSION = process.env.REACT_APP_VERSION;
const DISPLAY_CREDITS = false;

function HeaderHelpCenter(props) {
  const {
    intl: { formatMessage },
  } = props;

  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState(null);

  const [KSIsOpen, setKSIsOpen] = useState(false);
  const [contactIsOpen, setContactIsOpen] = useState(false);
  const [feedbackIsOpen, setFeedbackIsOpen] = useState(false);
  const [creditsIsOpen, setCreditsIsOpen] = useState(false);

  const handleClick = (e) => {
    setAnchorEl(e.currentTarget);
  };

  const closeDropDown = () => {
    setAnchorEl(null);
  };

  const onCloseKS = () => {
    setKSIsOpen(false);
  };

  const oncloseContact = () => {
    setContactIsOpen(false);
  };

  const OnCloseFeedback = () => {
    setFeedbackIsOpen(false);
  };

  const onCloseCredits = () => {
    setCreditsIsOpen(false);
  };

  return (
    <>
      <div className={classes.helpBtnContainer}>
        <SkwrTool
          SVGIcon={HelpIcon}
          tooltip={formatMessage(messages.helpCenter)}
          className={classes.helpBtn}
          onClick={handleClick}
          iconClassName={classes.helpBtnSvg}
        />
      </div>
      <SkwrMenu
        id="HelpCenter-Dropdown"
        anchorEl={anchorEl}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        getContentAnchorEl={null}
        open={Boolean(anchorEl)}
        onClose={closeDropDown}
        onClick={closeDropDown}
      >
        <SkwrMenuItem
          onClick={() => window.open(USER_GUIDE_URL)}
        >
          {`${formatMessage(messages.userGuide)} ${String.fromCharCode(8599)}`}
        </SkwrMenuItem>
        <SkwrMenuItem
          onClick={() => setKSIsOpen(true)}
        >
          {formatMessage(messages.keyboardShortcut)}
        </SkwrMenuItem>
        <SkwrMenuItem
          onClick={() => setContactIsOpen(true)}
        >
          {formatMessage(messages.contactSupport)}
        </SkwrMenuItem>
        <SkwrMenuItem
          onClick={() => setFeedbackIsOpen(true)}
        >
          {formatMessage(messages.feedbackRequest)}
        </SkwrMenuItem>
        <SkwrMenuItem
          onClick={() => window.open(RELEASE_NOTE_URL)}
        >
          {`${formatMessage(messages.releaseNote, { version: CURRENT_VERSION })} ${String.fromCharCode(8599)}`}
        </SkwrMenuItem>
        {DISPLAY_CREDITS && (
          <SkwrMenuItem
            onClick={() => setCreditsIsOpen(true)}
          >
            {formatMessage(messages.credits)}
          </SkwrMenuItem>
        )}
      </SkwrMenu>

      {KSIsOpen && (
        <KeyboardShortcutDialog
          isOpen={KSIsOpen}
          onClose={onCloseKS}
          dialogTitle={formatMessage(messages.keyboardShortcut)}
        />
      )}

      {contactIsOpen && (
        <ContactSupportDialog
          isOpen={contactIsOpen}
          onClose={oncloseContact}
          dialogTitle={formatMessage(messages.contactSupport)}
          prefillDate={PREFILL_DATE}
        />
      )}

      {feedbackIsOpen && (
        <FeedbackRequestDialog
          isOpen={feedbackIsOpen}
          onClose={OnCloseFeedback}
          dialogTitle={formatMessage(messages.feedbackRequest)}
          prefillDate={PREFILL_DATE}
        />
      )}

      {creditsIsOpen && (
        <CreditsDialog
          isOpen={creditsIsOpen}
          onClose={onCloseCredits}
          dialogTitle={formatMessage(messages.credits)}
        />
      )}
    </>
  );
}

export default injectIntl(HeaderHelpCenter);
