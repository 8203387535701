import React from 'react';
import classNames from 'classnames';
import SkwrInput from '../SkwrInput';

const PasswordField = ({
  label,
  className,
  ...props
}) => {
  // SEEMS TO ANNOY EVERYONE BUT ME : handleDropAndPaste = (event) => {
  // Forbid Paste and Drop - It's a password field!
  // SEEMS TO ANNOY EVERYONE BUT ME :   event.preventDefault();
  // SEEMS TO ANNOY EVERYONE BUT ME : }
  return (
    <div className={classNames('PasswordField', className)}>
      <div className="PasswordField-row">
        <SkwrInput
          className="PasswordField-input"
          placeholder="******"
          type="password"
          // SEEMS TO ANNOY EVERYONE BUT ME : onPaste={handleDropAndPaste}
          // SEEMS TO ANNOY EVERYONE BUT ME : onDrop={handleDropAndPaste}
          label={label}
          viewButton
          {...props}
        />
      </div>
    </div>
  );
};
export default PasswordField;
