/**
 * ImageTools.jsx
 * Tools to manipulate images
 */
import {
  THUMBNAIL_FILETYPES,
} from '../Constants';

export const getAcceptedThumbnailFileTypes = () => {
  let acceptedList = '';
  if (THUMBNAIL_FILETYPES && THUMBNAIL_FILETYPES.length) {
    for (let i = 0; i < THUMBNAIL_FILETYPES.length; i += 1) {
      const acceptedAudioFile = THUMBNAIL_FILETYPES[i].type;
      if (i !== 0) {
        acceptedList = acceptedList.concat(', ');
      }
      acceptedList = acceptedList.concat(acceptedAudioFile);
    }
  }
  return acceptedList;
};

export const isImageFileResizeable = (file) => {
  let isImageResizeable = false;
  if (THUMBNAIL_FILETYPES && THUMBNAIL_FILETYPES.length) {
    let found = false;
    for (let i = 0; (i < THUMBNAIL_FILETYPES.length) && (!found); i += 1) {
      if (THUMBNAIL_FILETYPES[i].type === file.type) {
        isImageResizeable = THUMBNAIL_FILETYPES[i].isResizeable;
        found = true;
      }
    }
  }
  return isImageResizeable;
};


/**
 * resize an image and calls a afterResize callback with dataURL as parameter
 * @param {*} file image file to resize
 * @param {{width, height}} maxSize maximum size of the image once resized
 * @param afterResizeCB callback after resize is done
 */
export const resizeImage = (file, maxSize, afterResizeCB) => {
  if (isImageFileResizeable(file)) {
    const reader = new FileReader();
    reader.onload = (event) => {
      const image = new Image();

      image.onload = () => {
        let imageWidth = image.width;
        let imageHeight = image.height;

        if (imageWidth > imageHeight) {
          if (imageWidth > maxSize.width) {
            imageHeight *= maxSize.width / imageWidth;
            imageWidth = maxSize.width;
          }
        } else if (imageHeight > maxSize.height) {
          imageWidth *= maxSize.height / imageHeight;
          imageHeight = maxSize.height;
        }

        const canvas = document.createElement('canvas');
        canvas.width = imageWidth;
        canvas.height = imageHeight;
        image.width = imageWidth;
        image.height = imageHeight;
        const ctx = canvas.getContext('2d');
        ctx.drawImage(image, 0, 0, imageWidth, imageHeight);

        // Convert the dataURL into binary
        const dataURItoBlob = (dataURI) => {
          const binary = atob(dataURI.split(',')[1]);
          const array = [];
          for (let i = 0; i < binary.length; i += 1) {
            array.push(binary.charCodeAt(i));
          }
          return new Blob([new Uint8Array(array)], { type: file.type });
        };

        afterResizeCB(dataURItoBlob(canvas.toDataURL(file.type)), file);
      };

      image.src = event.target.result;
    };
    reader.readAsDataURL(file);
  } else {
    afterResizeCB(null, file);
  }
};// resizeImage
