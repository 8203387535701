import CONSTANTS from '../Constants';
import * as ExternalPreview from '../helpers/ExternalPreview';
import PROJECTSACTIONS from './projectsStateConstants';

const initialState = {
  error: null,
  status: null,
  externalPreviewUrl: '',
  externalPreviewUuid: '',
};

export const ACTIONS = {
  GET_URL_REQUEST: 'GET_URL_REQUEST_API_GET_REQUEST',
  GET_URL_SUCCESS: 'GET_URL_SUCCESS_API_GET_SUCCESS',
  GET_URL_FAILURE: 'GET_URL_FAILURE_API_GET_FAILURE',

  CREATE_URL_REQUEST: 'CREATE_URL_REQUEST_API_POST_REQUEST',
  CREATE_URL_SUCCESS: 'CREATE_URL_SUCCESS_API_POST_SUCCESS',
  CREATE_URL_FAILURE: 'CREATE_URL_FAILURE_API_POST_FAILURE',

  UPDATE_URL_REQUEST: 'UPDATE_URL_API_UPDATE_REQUEST',
  UPDATE_URL_SUCCESS: 'UPDATE_URL_API_UPDATE_SUCCESS',
  UPDATE_URL_FAILURE: 'UPDATE_URL_API_UPDATE_FAILURE',

  DELETE_URL_REQUEST: 'DELETE_URL_REQUEST_API_DELETE_REQUEST',
  DELETE_URL_SUCCESS: 'DELETE_URL_SUCCESS_API_DELETE_SUCCESS',
  DELETE_URL_FAILURE: 'DELETE_URL_FAILURE_API_DELETE_FAILURE',
};

/**
 * Get the external preview url of the currentProject
 */
export const getExternalPreviewUrl = () => (dispatch, getState) => {
  dispatch({
    type: ACTIONS.GET_URL_REQUEST,
    payload: 'waiting response from API ...',
  });

  const { currentProject } = getState().projects;

  if ((currentProject === null) || (typeof currentProject === 'undefined')) {
    dispatch({
      type: ACTIONS.GET_URL_FAILURE,
      error: CONSTANTS.COMMONERRORS.NO_CURRENT_PROJECT,
    });
    return;
  }

  if (getState().externalPreview.externalPreviewUrl !== '') {
    dispatch({
      type: ACTIONS.GET_URL_SUCCESS,
      externalPreviewUrl: getState().externalPreview.externalPreviewUrl,
      externalPreviewUuid: getState().externalPreview.externalPreviewUuid,
    });
    return;
  }

  ExternalPreview.getExternalPreviewUrl(currentProject.uuid).then((data) => {
    dispatch({
      type: ACTIONS.GET_URL_SUCCESS,
      externalPreviewUrl: data.url,
      externalPreviewUuid: data.uuid,
    });
  }).catch((error) => {
    dispatch({
      type: ACTIONS.GET_URL_FAILURE,
      error: error.message,
    });
  });
};

/**
 * Create the external preview url of the currentProject
 */
export const createExternalPreviewUrl = () => (dispatch, getState) => {
  dispatch({
    type: ACTIONS.CREATE_URL_REQUEST,
    payload: 'waiting response from API ...',
  });

  const { currentProject } = getState().projects;

  if ((currentProject === null) || (typeof currentProject === 'undefined')) {
    dispatch({
      type: ACTIONS.CREATE_URL_FAILURE,
      error: CONSTANTS.COMMONERRORS.NO_CURRENT_PROJECT,
    });
    return;
  }

  ExternalPreview.generateExternalPreviewUrl(currentProject.uuid).then((data) => {
    dispatch({
      type: ACTIONS.CREATE_URL_SUCCESS,
      externalPreviewUrl: data.url,
      externalPreviewUuid: data.uuid,
    });
  }).catch((error) => {
    dispatch({
      type: ACTIONS.CREATE_URL_FAILURE,
      error: error.message,
    });
  });
};

/**
 * Update the external preview url of the currentProject
 */
export const updateExternalPreviewUrl = () => (dispatch, getState) => {
  dispatch({
    type: ACTIONS.UPDATE_URL_REQUEST,
    payload: 'waiting response from API ...',
  });

  const { currentProject } = getState().projects;
  const { externalPreviewUuid } = getState().externalPreview;

  if ((currentProject === null) || (typeof currentProject === 'undefined')) {
    dispatch({
      type: ACTIONS.UPDATE_URL_FAILURE,
      error: CONSTANTS.COMMONERRORS.NO_CURRENT_PROJECT,
    });
    return;
  }

  ExternalPreview.updateExternalPreviewUrl(externalPreviewUuid).then((data) => {
    dispatch({
      type: ACTIONS.UPDATE_URL_SUCCESS,
      externalPreviewUrl: data.url,
      externalPreviewUuid: data.uuid,
    });
  }).catch((error) => {
    dispatch({
      type: ACTIONS.UPDATE_URL_FAILURE,
      error: error.message,
    });
  });
};

/**
 * Update the external preview url of the currentProject
 */
export const deleteExternalPreviewUrl = () => (dispatch, getState) => {
  dispatch({
    type: ACTIONS.DELETE_URL_REQUEST,
    payload: 'waiting response from API ...',
  });

  const { currentProject } = getState().projects;
  const { externalPreviewUuid } = getState().externalPreview;

  if ((currentProject === null) || (typeof currentProject === 'undefined')) {
    dispatch({
      type: ACTIONS.DELETE_URL_FAILURE,
      error: CONSTANTS.COMMONERRORS.NO_CURRENT_PROJECT,
    });
    return;
  }

  ExternalPreview.deleteExternalPreviewUrl(externalPreviewUuid).then((url) => {
    dispatch({
      type: ACTIONS.DELETE_URL_SUCCESS,
      externalPreviewUrl: url,
    });
  }).catch((error) => {
    dispatch({
      type: ACTIONS.DELETE_URL_FAILURE,
      error: error.message,
    });
  });
};

export default (state = initialState, action) => {
  switch (action.type) {
    case PROJECTSACTIONS.GET_PROJECT_REQUEST:
      return {
        ...initialState,
      };

    // GET CASES ---------------------------
    case ACTIONS.GET_URL_REQUEST:
      return {
        ...state,
        status: ACTIONS.GET_URL_REQUEST,
      };

    case ACTIONS.GET_URL_SUCCESS:
      return {
        ...state,
        status: ACTIONS.GET_URL_SUCCESS,
        externalPreviewUrl: action.externalPreviewUrl,
        externalPreviewUuid: action.externalPreviewUuid,
      };

    case ACTIONS.GET_URL_FAILURE:
      return {
        ...state,
        status: ACTIONS.GET_URL_FAILURE,
        error: action.error,
      };

    // CREATE CASES ---------------------------
    case ACTIONS.CREATE_URL_REQUEST:
      return {
        ...state,
        status: ACTIONS.CREATE_URL_REQUEST,
      };

    case ACTIONS.CREATE_URL_SUCCESS:
      return {
        ...state,
        status: ACTIONS.CREATE_URL_SUCCESS,
        externalPreviewUrl: action.externalPreviewUrl,
        externalPreviewUuid: action.externalPreviewUuid,
      };

    case ACTIONS.CREATE_URL_FAILURE:
      return {
        ...state,
        status: ACTIONS.CREATE_URL_FAILURE,
        error: action.error,
      };

    // UPDATE CASES ---------------------------
    case ACTIONS.UPDATE_URL_REQUEST:
      return {
        ...state,
        status: ACTIONS.UPDATE_URL_REQUEST,
      };

    case ACTIONS.UPDATE_URL_SUCCESS:
      return {
        ...state,
        status: ACTIONS.UPDATE_URL_SUCCESS,
        externalPreviewUrl: action.externalPreviewUrl,
        externalPreviewUuid: action.externalPreviewUuid,
      };

    case ACTIONS.UPDATE_URL_FAILURE:
      return {
        ...state,
        status: ACTIONS.UPDATE_URL_FAILURE,
        error: action.error,
      };

    // DELETE CASES ---------------------------
    case ACTIONS.DELETE_URL_REQUEST:
      return {
        ...state,
        status: ACTIONS.DELETE_URL_REQUEST,
      };

    case ACTIONS.DELETE_URL_SUCCESS:
      return {
        ...state,
        status: ACTIONS.DELETE_URL_SUCCESS,
        externalPreviewUrl: action.externalPreviewUrl,
      };

    case ACTIONS.DELETE_URL_FAILURE:
      return {
        ...state,
        status: ACTIONS.UDELETE_URL_FAILURE,
        error: action.error,
      };

    default:
      return state;
  }
};
