/**
 * File exportSelector.js
 * Selectors of export redux states + export getters
 */
import { getExportIRI } from '../tools/IRITools';
import { EXPORT_STATUS, getExportStatusIRI } from '../tools/ExportTools';

/**
 * Get the exports ARRAY of the current project
 * @param {*} state The State
 */
export const getCurrentExports = (state) => state.exports.exports;

/**
 * Get the exports status of the current project
 * @param {*} state The State
 */
export const getExportStatus = (state) => state.exports.status;

/**
 * Get one export by his iri
 * @param {*} state The State
 * @param {*} iri The iri of export
 */
export const getExportByIriSelector = (state) => {
  return (iri) => {
    let exportFinded = null;
    for (let i = 0; (i < state.exports.exports.length) && (exportFinded === null); i += 1) {
      if (getExportIRI(state.exports.exports[i]) === iri) {
        exportFinded = { ...state.exports.exports[i] };
      }
    }
    return exportFinded;
  };
};


export const isExportJobPending = (exp) => {
  if ((typeof exp.awsExperienceExportJobs !== 'undefined')
   && (exp.awsExperienceExportJobs !== null)
   && (typeof exp.awsExperienceExportJobs.jobsStatus !== 'undefined')
   && (exp.awsExperienceExportJobs.jobsStatus !== null)
   && (exp.awsExperienceExportJobs.jobsStatus === getExportStatusIRI(EXPORT_STATUS.PENDING))) {
    return true;
  }
  return false;
}; // isExportJobPending

export const isExportJobProcessing = (exp) => {
  if ((typeof exp.awsExperienceExportJobs !== 'undefined')
   && (exp.awsExperienceExportJobs !== null)
   && (typeof exp.awsExperienceExportJobs.jobsStatus !== 'undefined')
   && (exp.awsExperienceExportJobs.jobsStatus !== null)
   && (exp.awsExperienceExportJobs.jobsStatus === getExportStatusIRI(EXPORT_STATUS.PROCESSING))) {
    return true;
  }
  return false;
}; // isExportJobProcessing

export const isExportJobRunning = (exp) => {
  return (isExportJobPending(exp) || isExportJobProcessing(exp));
}; // isExportJobRunning

export const isExportJobComplete = (exp) => {
  if ((typeof exp.awsExperienceExportJobs !== 'undefined')
   && (exp.awsExperienceExportJobs !== null)
   && (typeof exp.awsExperienceExportJobs.jobsStatus !== 'undefined')
   && (exp.awsExperienceExportJobs.jobsStatus !== null)
   && (exp.awsExperienceExportJobs.jobsStatus === getExportStatusIRI(EXPORT_STATUS.COMPLETE))) {
    return true;
  }
  return false;
}; // isExportJobComplete

export const isExportJobFailed = (exp) => {
  if ((typeof exp.awsExperienceExportJobs !== 'undefined')
   && (exp.awsExperienceExportJobs !== null)
   && (typeof exp.awsExperienceExportJobs.jobsStatus !== 'undefined')
   && (exp.awsExperienceExportJobs.jobsStatus !== null)
   && (exp.awsExperienceExportJobs.jobsStatus === getExportStatusIRI(EXPORT_STATUS.ERROR))) {
    return true;
  }
  return false;
}; // isExportJobFailed

export const isExportJobStatusUnknown = (exp) => {
  if ((typeof exp.awsExperienceExportJobs === 'undefined')
   || (exp.awsExperienceExportJobs === null)
   || (typeof exp.awsExperienceExportJobs.jobsStatus === 'undefined')
   || (exp.awsExperienceExportJobs.jobsStatus === null)) {
    return true;
  }
  return false;
}; // isExportJobStatusUnknown

/**
 * Is the given export deletable ?
 */
export const isExportJobDeletable = (exp) => {
  return (!isExportJobRunning(exp) && !isExportJobStatusUnknown(exp));
};// isExportJobDeletable


/**
 * Is there an export in progess
 * @param {*} state
 * @returns
 */
export const isThereAnExportInProgress = (state) => {
  let inProgress = false;
  for (let i = 0; (i < state.exports.exports.length) && (inProgress === false); i += 1) {
    if (isExportJobRunning(state.exports.exports[i])) {
      inProgress = true;
    }
  }
  return inProgress;

};
