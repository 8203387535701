/**
 * File : SkwrEditableLabel.jsx
 * SkwrEditableLabel : Label component with interactions behaviours allowing edition
 */
import React, { useState, useRef, useEffect } from 'react';
import classNames from 'classnames';
import { makeStyles } from '@material-ui/core/styles';
import { ReactComponent as CloseTokenSVG } from './assets/svg/closeToken.svg';
import { ReactComponent as PenSVG } from './assets/svg/pen.svg';
import SkwrTooltip from './SkwrTooltip';
import { ColorByUsage, FontSizes } from './UIConstants';
import SkwrAutocomplete from './SkwrAutocomplete';

const useStyles = makeStyles({
  editableLabelComponent: {
    display: 'flex',
    flexDirection: 'row',
    boxSizing: 'border-box',
    width: '100%',
    '&:hover': {
      '& .editableLabelButton': {
        opacity: 0.5,
        display: 'flex',

        '&:hover': {
          opacity: 1,
          display: 'flex',
        },
      },
    },
  },
  editableLabelComponentHeightSingleLine: {
    height: 'min-content',
  },

  editableLabelButton: ({editableLabelButton}) => ({
    display: 'none',
    marginLeft: '5px',
    padding: 0,
    border: 'none',
    outline: 'none',
    height: '15px',
    width: '15px',
    cursor: 'pointer',
    opacity: 0,
    '& svg': {
      fill: ColorByUsage.editableLabelButton,
    },
    '&:hover svg': {
      fill: ColorByUsage.editableLabelButtonHover,
    },
    ...editableLabelButton,
  }),
  editableLabelInputDiv: {
    width: '100%',
  },
  editableLabelLabelDiv: {
    display: 'flex',
    alignItems: 'center',
    paddingLeft: '5px',
    width: '100%',
    height: '100%',
  },
  editableLabelLabel: ({ editableLabelLabel }) => ({
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    flex: 1,
    textAlign: 'initial',
    whiteSpace: 'nowrap',
    ...editableLabelLabel
  }),
  font: ({ font }) => ({
    fontSize: FontSizes.textMedium,
    ...font
  }),
  labelFontStyle: ({ labelFontStyle }) => {
    if (labelFontStyle) {
      return {...labelFontStyle}
    } else {
      return {}
    }},
});

function usePrevious(value, defaultValue) {
  const ref = useRef();
  useEffect(() => {
    ref.current = value;
  });
  if (ref.current) {
    return ref.current;
  }
  return defaultValue;
};

const SkwrAutocompletableLabel = ({
  value,
  customJSS,
  onDelete,
  getFocus,
  className,
  displayButtons = false,
  labelWhenEmpty,
  options,
  getOptionLabel,
  onChange,
  allowValueOutsideOptions = false,
  getNoOptionsOption,
  renderOption,
}) => {
  const labelRef = useRef(null);
  const classes = useStyles(customJSS);

  const [editableModeState, setEditableModeState] = useState(false);
  const prevEditableModeState = usePrevious(editableModeState, false);

  const [getFocusState, setGetFocusState] = useState(getFocus);
  const prevGetFocusState = usePrevious(getFocusState, getFocus);

  useEffect(() => {
    if (getFocusState !== prevGetFocusState) {
      setEditableMode(true);
    }
  }, [getFocus]);

  const setEditableMode = (editOrNot) => {
    if (editOrNot) {
      if ((typeof onChange !== 'undefined') && (onChange !== null)) {
        setEditableModeState(true);
      }
    } else {
      setEditableModeState(false);
    }
  }

  const handleClickEditable = () => {
    setEditableMode(true);
  };

  const handleClickDelete = () => {
    if ((typeof onDelete !== 'undefined') && (onDelete !== null)) {
      onDelete(value);
    }
  };

  const handleOnChange = (evt, newValue, reason) => {
    setEditableMode(false);

    if ((reason !== 'clear')
     && ((typeof onChange !== 'undefined') && (onChange !== null))) {
      onChange(evt, newValue, reason);
    }
  };
  const handleOnBlur = () => {
    setEditableMode(false);
  }


  const handleDoubleClick = (evt) => {
    setEditableMode(true);
    evt.preventDefault();
    evt.stopPropagation();
  };

  let displayDeleteButton = false;
  if (displayButtons && ((typeof onDelete !== 'undefined') && (onDelete !== null))) {
    displayDeleteButton = true;
  }
  let displayEditButton = false;
  if (displayButtons && ((typeof onChange !== 'undefined') && (onChange !== null))) {
    displayEditButton = true;
  }

  let labelWhenNotEditable = getOptionLabel(value);
  if (((value === undefined) || (value === null) || (value === ''))
    && ((labelWhenEmpty !== undefined) && (labelWhenEmpty !== null) && (labelWhenEmpty !== ''))) {
    labelWhenNotEditable = labelWhenEmpty;
  }

  let mainHeightStyle = classes.editableLabelComponentHeightSingleLine;

  const autoCompleteCustomJSS = {
    ...customJSS,
    input : {
      padding: '0px !important',
    }
  }

    return (
      <div
        className={`${className} ${classes.editableLabelComponent} ${mainHeightStyle}`}
      >
        {editableModeState ? (
          <div
            role="presentation"
            className={`${classes.editableLabelInputDiv} ${classes.font}`}
          >
            <SkwrAutocomplete
              customJSS={autoCompleteCustomJSS}
              value={value}
              onChange={handleOnChange}
              options={options}
              getOptionLabel={getOptionLabel}
              allowValueOutsideOptions={allowValueOutsideOptions}
              getNoOptionsOption={getNoOptionsOption}
              renderOption={renderOption}
              onBlur={handleOnBlur}
              disableUnderline
            />
          </div>
        )
          : (
            <div
              className={classes.editableLabelLabelDiv}
              onDoubleClick={handleDoubleClick}
            >
              <SkwrTooltip ellipsisref={labelRef}>
                <label
                  className={`${classes.editableLabelLabel} ${classes.font} ${classes.labelFontStyle}`}
                  ref={labelRef}
                >
                  {labelWhenNotEditable}
                </label>
              </SkwrTooltip>
              { displayEditButton && (
                <div className={classNames('editableLabelButton', classes.editableLabelButton)}>
                  <PenSVG onClick={handleClickEditable} />
                </div>
              )}
              { displayDeleteButton && (
                <div className={classNames('editableLabelButton', classes.editableLabelButton)}>
                  <CloseTokenSVG onClick={handleClickDelete} />
                </div>
              )}
            </div>
          )
        }
      </div>
    );
};

export default SkwrAutocompletableLabel;
