import CONSTANTS from '../Constants';

/**
 * Returns the IRI of a given connectable object
 * @param {*} connectable connectable we want the IRI
 */
export const getObjectIRI = (obj) => (obj ? obj[CONSTANTS.IRI_FIELD] : null);
export const getConnectableIRI = getObjectIRI;
export const getLabelIRI = getObjectIRI;
export const getAssetIRI = getObjectIRI;
export const getDkIRI = getObjectIRI;
export const getDkvIRI = getObjectIRI;
export const getProjectIRI = getObjectIRI;
export const getExportIRI = getObjectIRI;
export const getLinkIRI = getObjectIRI;
export const getProjectThumbnailIRI = getObjectIRI;

export const getObjectType = (obj) => (obj ? obj[CONSTANTS.TYPE_FIELD] : null);

export const getUserIRI = (user) => `/users/${user.uuid}`;

/**
 * IRI setters
 * @param {} iri IRI to set
 */
// eslint-disable-next-line no-param-reassign
export const setObjectIRI = (obj, iri) => { obj[CONSTANTS.IRI_FIELD] = iri; };
export const setDkIRI = (obj, iri) => {
  // eslint-disable-next-line no-param-reassign
  obj[CONSTANTS.TYPE_FIELD] = CONSTANTS.TYPES.DATAKEY;
  setObjectIRI(obj, iri);
};
export const setDkvIRI = (obj, iri) => {
  // eslint-disable-next-line no-param-reassign
  obj[CONSTANTS.TYPE_FIELD] = CONSTANTS.TYPES.DATAKEYVALUE;
  setObjectIRI(obj, iri);
};
export const setAssetIRI = setObjectIRI;
export const setLabelIRI = setObjectIRI;

/**
 * Retrieve the IRI of the video MediaType for a give project (or null)
 * @param {*} project
 */
export const getVideoMediaTypeIRI = (project) => {
  let mediaTypeIRI = null;
  if ((typeof project.videoMediaTypeIRI === 'undefined')
   || (project.videoMediaTypeIRI === null)) {
    if ((typeof project.mediaTypes !== 'undefined')
     && (project.mediaTypes !== null)
     && (project.mediaTypes.length !== 0)) {
      const mediaType = project.mediaTypes.find(elem => elem.name === 'video');
      mediaTypeIRI = mediaType[CONSTANTS.IRI_FIELD];
    }
  } else {
    mediaTypeIRI = project.videoMediaTypeIRI;
  }
  return mediaTypeIRI;
};

/**
 * Retrieve the IRI of the video MediaType for a give project (or null)
 * @param {*} project
 */
export const getAudioMediaTypeIRI = (project) => {
  let mediaTypeIRI = null;
  if ((typeof project.audioMediaTypeIRI === 'undefined')
   || (project.audioMediaTypeIRI === null)) {
    if ((typeof project.mediaTypes !== 'undefined')
     && (project.mediaTypes !== null)
     && (project.mediaTypes.length !== 0)) {
      const mediaType = project.mediaTypes.find(elem => elem.name === 'audio');
      mediaTypeIRI = mediaType[CONSTANTS.IRI_FIELD];
    }
  } else {
    mediaTypeIRI = project.audioMediaTypeIRI;
  }
  return mediaTypeIRI;
};

/**
 * Returns the UUID of an asset given its IRI
 * @param {*} assetIRI IRI of the asset
 */
export const assetUuidFromIRI = (assetIRI) => {
  const assetsIRIPrefix = getAssetIRIPrefix();
  return assetIRI.slice(assetsIRIPrefix.length);
};

export const getAssetIRIPrefix = () => {
  return '/'.concat(CONSTANTS.IRI.ASSETS);
};
