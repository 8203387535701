/**
 * File: pendingRequestsState.js
 */
import PROJECTSACTIONS from './projectsStateConstants';
import ACTIONS from './pendingRequestsStateConstants';

const initialState = {
  getRequestsCounter: 0,
  postRequestsCounter: 0,
};

export const addUnstopppableAction = () => ({
  type: ACTIONS.ADD_POST_REQUEST,
});

export const removeUnstopppableAction = () => ({
  type: ACTIONS.REMOVE_POST_REQUEST,
});

const handleBeforeUnload = (e) => {
  // Cancel the event as stated by the standard.
  e.preventDefault();
  // Chrome requires returnValue to be set.
  e.returnValue = '';
};

const addPreventQuitHandler = () => {
  window.addEventListener('beforeunload', handleBeforeUnload);
};

const removePreventQuitHandler = () => {
  window.removeEventListener('beforeunload', handleBeforeUnload);
};

export default (state = initialState, action) => {
  switch (action.type) {
    case PROJECTSACTIONS.RESET_CURRENT_PROJECT:
      return {
        ...initialState,
      };

    case ACTIONS.ADD_POST_REQUEST:
    {
      const newPostRequestsCounter = state.postRequestsCounter + 1;
      if (newPostRequestsCounter === 1) {
        addPreventQuitHandler();
      }
      return {
        ...state,
        postRequestsCounter: newPostRequestsCounter,
      };
    }

    case ACTIONS.REMOVE_POST_REQUEST:
    {
      let newPostRequestsCounter = state.postRequestsCounter - 1;
      if (newPostRequestsCounter < 0) newPostRequestsCounter = 0;
      if (newPostRequestsCounter === 0) {
        removePreventQuitHandler();
      }
      return {
        ...state,
        postRequestsCounter: newPostRequestsCounter,
      };
    }

    case ACTIONS.ADD_GET_REQUEST:
    {
      const newGetRequestsCounter = state.getRequestsCounter + 1;
      return {
        ...state,
        getRequestsCounter: newGetRequestsCounter,
      };
    }

    case ACTIONS.REMOVE_GET_REQUEST:
    {
      let newGetRequestsCounter = state.getRequestsCounter - 1;
      if (newGetRequestsCounter < 0) newGetRequestsCounter = 0;
      return {
        ...state,
        getRequestsCounter: newGetRequestsCounter,
      };
    }

    default:
      return state;
  }
};
