import React from 'react';
import classNames from 'classnames';
import IconButton from '@material-ui/core/IconButton';
import { makeStyles } from '@material-ui/core/styles';
import SkwrTooltip from './SkwrTooltip';
import { ToolBarTypes } from './UIConstants';

const useStyles = makeStyles(theme => ({
  tool: {
    '&:disabled': {
      opacity: 0.5,
    },
  },
  icon: {
    height: '25px',
    width: '25px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  squaredToolRoot: {
    borderRadius: '8px',
  },
}));

const SkwrTool = ({
  className,
  onClick,
  tooltip,
  tooltipPlacement,
  disabled = false,
  iconClassName = null,
  size,
  SVGIcon,
  toolbartype = ToolBarTypes.ROUNDED,
}) => {
  const classes = useStyles();

  const getCustomedClasses = () => {
    const cc = {};
    if (toolbartype === ToolBarTypes.SQUARED) {
      cc.root = classes.squaredToolRoot;
    }
    return cc;
  };

  return (
    <SkwrTooltip title={tooltip} disabled={disabled} placement={tooltipPlacement}>
      <IconButton
        className={classNames(classes.tool, className)}
        onClick={onClick}
        disabled={disabled}
        size={size}
        classes={getCustomedClasses()}
      >
        <div className={classNames(classes.icon, iconClassName)}>
          <SVGIcon />
        </div>
      </IconButton>
    </SkwrTooltip>

  );
};

export default SkwrTool;
