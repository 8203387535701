import { PRODUCT_SHORTNAME } from '../Constants';
import * as User from './user';

/**
 * Global array of preferences constants
 * => Here to avoid duplicate preferences
 * constants can be explicite and large, but try to have smallest value as possible
 * to avoid to exceed localStorage maximum size
 */
export const PREFERENCES = {
  MEDIA_LIBRARY_ASSET_SORT_ORDER: 'ml_sortBy',
  MEDIA_LIBRARY_ASSET_SORT_DIRECTION: 'ml_sortRevert',
  MEDIA_LIBRARY_PREVIEW_CLOSED: 'ml_prvwClosed',
  MEDIA_LIBRARY_LABEL_FILTERS_SORT_DIRECTION: 'ml_lblSortRevert',
  PREVIEW_SOUND_VOLUME: 'pv_soundVolume',
  PREVIEW_QUALIFICATION: 'pv_previousQualif',
  DATAKEY_PANEL_CLOSED_DATAKEYS: 'dkp_closed',
  EXPORTS_SORT_ORDER: 'exp_sortBy',
  EXPORTS_SORT_DIRECTION: 'exp_sortRevert',
};

const getAppPreferenceKey = () => {
  const user = User.get();
  let uuid = '';
  if ((typeof user !== 'undefined')
   && (user !== null)
   && (typeof user.uuid !== 'undefined')
   && (user.uuid !== null)) {
    uuid = user.uuid;
  }
  return `${PRODUCT_SHORTNAME}_pref_${uuid}`;
};

/**
 * Save a user preference
 * @param {*} key     key of the preference
 * @param {*} value   value of the preference
 * @param {*} project if not NULL, the project on which the preference should be applied
 */
export const setPreference = (key, value, project) => {
  const localData = localStorage.getItem(getAppPreferenceKey());
  let allPreferences = {};
  if (localData !== null) {
    allPreferences = JSON.parse(localData);
  }

  if ((typeof project !== 'undefined')
   && (project !== null)) {
    let projectPreference = allPreferences[project.uuid];
    if ((typeof projectPreference === 'undefined')
     || (projectPreference === null)) {
      projectPreference = {};
    }
    projectPreference[key] = value;
    allPreferences[project.uuid] = projectPreference;
  } else {
    allPreferences[key] = value;
  }

  localStorage.setItem(getAppPreferenceKey(), JSON.stringify(allPreferences));
};

/**
 * Get a user preference
 * @param {*} key     key of the preference
 * @param {*} defaultValue The defaultValue to apply if the preference does not exist in the storage
 * @param {*} project if not NULL, the project on which the preference should be applied
 */
export const getPreference = (key, defaultValue, project) => {
  const localData = localStorage.getItem(getAppPreferenceKey());
  let allPreferences = {};

  if (localData !== null) {
    allPreferences = JSON.parse(localData);
  } else {
    return defaultValue;
  }

  let foundvalue;
  if ((typeof project !== 'undefined')
   && (project !== null)) {
    const projectPreference = allPreferences[project.uuid];
    if ((typeof projectPreference !== 'undefined')
     && (projectPreference !== null)) {
      foundvalue = projectPreference[key];
    }
  } else {
    foundvalue = allPreferences[key];
  }

  if ((typeof foundvalue === 'undefined')
   || (foundvalue === null)) {
    foundvalue = defaultValue;
  }

  return foundvalue;
};
