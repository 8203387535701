/**
 * dataKeysSelectors.js
 * DATAKEYS STATE SELECTORS
 * Contains all the selectors of the dataKeysState reducer
 */
import { getDkIRI } from '../tools/IRITools';
import { isKeyInstance } from '../tools/ConnectableTools';
import { getCurrentConnectables } from './nodesSelectors';

/**
 * Get the datakeys ARRAY of the current project
 * @param {*} state The State
 */
export const getCurrentDataKeys = state => state.dataKeys.dataKeys;
export const getDataKeyName = dataKey => dataKey?.name;
export const getDataKeyTechnicalName = dataKey => dataKey?.technicalName;
export const getDataKeyValueName = dataKeyValue => dataKeyValue?.name;
export const getDataKeyValueTechnicalValue = dataKeyValue => dataKeyValue?.technicalValue;

/**
 * Get the usefull datakeys ARRAY of the current project
 * @param {*} state The State
 */
export const getUsedDataKeys = (state) => {
  const keysToDisplay = [];

  const dataKeys = getCurrentDataKeys(state);
  const dataKeysLength = dataKeys.length;
  const connectables = getCurrentConnectables(state);
  const connectablesLength = connectables.length;

  let usedDkIndex = -1;
  let isDKVUsed = false;
  let dataKeyElement = null;
  let dataKeyValuesLength = 0;
  let dataKeyValueElement = null;

  // parse the datakeys
  for (let dki = 0; dki < dataKeysLength; dki += 1) {
    dataKeyElement = dataKeys[dki];

    usedDkIndex = -1;

    dataKeyValuesLength = dataKeyElement.dataKeyValues.length;

    // parse the data keys values
    for (let dkvi = 0; dkvi < dataKeyValuesLength; dkvi += 1) {
      dataKeyValueElement = dataKeyElement.dataKeyValues[dkvi];
      isDKVUsed = false;

      // parse all the nodes
      for (
        let nodeIndex = 0;
        ((nodeIndex < connectablesLength) && !isDKVUsed);
        nodeIndex += 1
      ) {
        // get only nodes who are data key instance
        if (isKeyInstance(connectables[nodeIndex])) {
          // parse on the output links of the data key instance
          const outputlinks = connectables[nodeIndex].outputLinks;
          for (
            let outputIndex = 0;
            ((outputIndex < outputlinks.length) && !isDKVUsed);
            outputIndex += 1
          ) {
            // check if the output links is the current data key value
            if (getDkIRI(dataKeyValueElement) === outputlinks[outputIndex].dataKeyValue) {
              // initialize a datakey into the returned data
              if (usedDkIndex === -1) {
                usedDkIndex = keysToDisplay.length;
                keysToDisplay.push({
                  ...dataKeyElement,
                  dataKeyValues: [],
                });
              }

              // initialize a the current data key value into the returned data
              if (isDKVUsed !== true) {
                isDKVUsed = true;
                keysToDisplay[usedDkIndex].dataKeyValues.push({ ...dataKeyValueElement });
              }
            }
          }
        }
      }
    } // for dataKeyValues
  } // for dataKeys

  return keysToDisplay;
};// getUsedDataKeys

/**
 * Get a SELECTOR that will allow to get a DataKey given its IRI
 * @param {*} state The State
 */
export const getDataKeyFromIRISelector = (state) => {
  return (iri) => {
    const index = state.dataKeys.dataKeysIndexesMap[iri];
    return state.dataKeys.dataKeys[index];
  };
};
export const getDataKeyFromIRI = (state, iri) => {
  const func = getDataKeyFromIRISelector(state);
  return func(iri);
};

/**
 * Get a SELECTOR that will allow to get a DataKeyValue given its IRI
 * @param {*} state The State
 */
export const getDataKeyValueFromIRISelector = (state) => {
  return (iri) => {
    const indexes = state.dataKeys.dataKeyValuesIndexesMap[iri];
    const dk = state.dataKeys.dataKeys[indexes.dkIndex];
    return dk.dataKeyValues[indexes.dkvIndex];
  };
};
export const getDataKeyValueFromIRI = (state, iri) => {
  const func = getDataKeyValueFromIRISelector(state);
  return func(iri);
};

/**
 * Get a SELECTOR that will allow to get a DataKey given a DataKeyValue IRI
 * @param {*} state The State
 */
export const getDataKeyFromDataKeyValueIRISelector = (state) => {
  return (iri) => {
    const indexes = state.dataKeys.dataKeyValuesIndexesMap[iri];
    return state.dataKeys.dataKeys[indexes.dkIndex];
  };
};
export const getDataKeyFromDataKeyValueIRI = (state, iri) => {
  const func = getDataKeyFromDataKeyValueIRISelector(state);
  return func(iri);
};

/**
 * Get the status of dataKeys state of the current project
 * @param {*} state The State
 */
export const getDataKeysStatus = state => state.dataKeys.status;

/**
 * Get the error of dataKeys state of the current project
 * @param {*} state The State
 */
export const getDataKeysError = state => state.dataKeys.error;
