/**
 * ComponentTools.jsx
 * Tools to manage components
 */
import React, { Suspense } from 'react';

export const LoadingMessage = () => (
  'Loading...'
);

export const getSuspensedComponent = (Component) => {
  return props => (
    <SkwrSuspense>
      <Component {...props} />
    </SkwrSuspense>
  );
};

export const SkwrSuspense = (props) => {
  const {
    children,
  } = props;
  return (
    <Suspense fallback={<div>{LoadingMessage()}</div>}>
      {children}
    </Suspense>
  );
};
