/**
 * File: nodesState.js
 */
import {
  clone,
} from 'lodash';
import PROJECTSACTIONS from './projectsStateConstants';
import LABELSACTIONS from './labelsStateConstants';
import DATAKEYSACTIONS from './dataKeysStateConstants';
import CONSTANTS from '../Constants';
import {
  getConnectableIRI,
  getDkIRI,
} from '../tools/IRITools';
import { isValidPosition } from '../tools/OtherTools';
import { isKeyInstance } from '../tools/ConnectableTools';
import ACTIONS from './nodesStateConstants';

const initialState = {
  error: null,
  status: null,
  connectables: [],
  connectablesIndexesMap: {},
  rootConnectables: null,

  copiedConnectables: [],

  // Running requests
  forkingSoundTrackRequestRunning: false,

  // Graphical state
  minPos: { x: CONSTANTS.INVALID_COORDINATE, y: CONSTANTS.INVALID_COORDINATE },
  maxPos: { x: CONSTANTS.INVALID_COORDINATE, y: CONSTANTS.INVALID_COORDINATE },
};

/**
 * THE REDUCER SWITCH
 */
export default (state = initialState, action) => {
  switch (action.type) {
    case PROJECTSACTIONS.CLEAR_ERROR:
    case ACTIONS.CLEAR_ERROR:
      return {
        ...state,
        error: null,
        status: null,
      };
    case PROJECTSACTIONS.RESET_CURRENT_PROJECT:
    {
      return { ...initialState };
    }

    case PROJECTSACTIONS.GET_PROJECT_SUCCESS:
    {
      // Get the IRI of the root of video graph
      let videoRootIRI = null;
      let newRootConnectables = null;
      if ((typeof action.roots !== 'undefined')
       && (action.roots !== null)) {
        newRootConnectables = { ...action.roots };

        if ((typeof action.roots.videoRootConnectable !== 'undefined')
         && (action.roots.videoRootConnectable !== null)) {
          videoRootIRI = action.roots.videoRootConnectable;

          newRootConnectables.videoRootConnectable = videoRootIRI;
        }// if

        if ((typeof action.roots.audioRootConnectable !== 'undefined')
         && (action.roots.audioRootConnectable !== null)) {
          newRootConnectables.audioRootConnectable = action.roots.audioRootConnectable;
        }// if
      }// if

      // Used to compute the mininum/maximum positions of connectables
      const newMinPos = { x: CONSTANTS.INVALID_COORDINATE, y: CONSTANTS.INVALID_COORDINATE };
      const newMaxPos = { x: CONSTANTS.INVALID_COORDINATE, y: CONSTANTS.INVALID_COORDINATE };

      // As we add some elements in each connectables, we can't store
      // directly the action connectables
      const newConnectables = [];

      // Create the indexes Array to access quickly to each object
      const newConnectablesIndexesMap = {};
      for (let conni = 0; conni < action.connectables.length; conni += 1) {
        const elem = action.connectables[conni];
        const newConnectable = { ...elem };

        // Create the "Displayed" position of the Connectable
        newConnectable.graphPos = { x: elem.posX, y: elem.posY };

        // Compute min and max positions of the different elements
        if ((elem.posX !== null) && (elem.posY !== null)) {
          if (!isValidPosition(newMinPos)
           || !isValidPosition(newMaxPos)) {
            newMinPos.x = elem.posX;
            newMinPos.y = elem.posY;
            newMaxPos.x = elem.posX;
            newMaxPos.y = elem.posY;
          } else {
            if (elem.posX < newMinPos.x) newMinPos.x = elem.posX;
            if (elem.posY < newMinPos.y) newMinPos.y = elem.posY;
          }
          if (elem.posX > newMaxPos.x) newMaxPos.x = elem.posX;
          if (elem.posY > newMaxPos.y) newMaxPos.y = elem.posY;
        }

        // Is the new connectable, the root one ?
        if ((videoRootIRI !== null)
         && (videoRootIRI === getConnectableIRI(elem))) {
          newConnectable.isRoot = true;
        } else {
          newConnectable.isRoot = false;
        }
        newConnectables.push(newConnectable);

        newConnectablesIndexesMap[getConnectableIRI(elem)] = conni;
      }

      return {
        ...state,
        status: PROJECTSACTIONS.GET_PROJECT_SUCCESS,
        connectables: newConnectables,
        connectablesIndexesMap: newConnectablesIndexesMap,
        rootConnectables: newRootConnectables,
        minPos: newMinPos,
        maxPos: newMaxPos,
        copiedConnectables: [],
      };
    }

    case ACTIONS.NEW_NODES_REQUEST:
    {
      return {
        ...state,
        status: action.type,
      };
    }
    case ACTIONS.NEW_NODES_SUCCESS:
    {
      const connectables = [...state.connectables];

      const createdNodes = action.nodes;

      const newMinPos = { ...state.minPos };
      const newMaxPos = { ...state.maxPos };
      const clonedIndexesMap = { ...state.connectablesIndexesMap };
      const currentIndexLength = state.connectables.length;

      let createdNode = null;
      for (let i = 0; i < createdNodes.length; i += 1) {
        createdNode = createdNodes[i];

        const newInputLinks = createdNode.inputLinks;
        if ((typeof newInputLinks !== 'undefined')
          && (newInputLinks !== null)
          && (newInputLinks.length)) {
          // change the outputLinks of the parent connectables
          newInputLinks.forEach((lnk) => {
            const fromCon = clone(connectables[state.connectablesIndexesMap[lnk.from]]);
            if ((typeof fromCon.outputLinks === 'undefined')
              || (fromCon.outputLinks === null)) {
              fromCon.outputLinks = [];
            } else {
              fromCon.outputLinks = clone(fromCon.outputLinks);
            }
            fromCon.outputLinks.push(lnk);
            connectables[state.connectablesIndexesMap[lnk.from]] = fromCon;
          });
        }

        const newOutputLinks = createdNode.outputLinks;

        if ((typeof newOutputLinks !== 'undefined')
          && (newOutputLinks !== null)
          && (newOutputLinks.length)) {
          // change the inputLinks of the target connectables
          newOutputLinks.forEach((lnk) => {
            const toCon = clone(connectables[state.connectablesIndexesMap[lnk.to]]);
            if ((typeof toCon.inputLinks === 'undefined')
              || (toCon.inputLinks === null)) {
              toCon.inputLinks = [];
            } else {
              toCon.inputLinks = clone(toCon.inputLinks);
            }
            toCon.inputLinks.push(lnk);
            connectables[state.connectablesIndexesMap[lnk.to]] = toCon;
          });
        }

        // New Node
        const newNode = { ...createdNode };
        newNode.graphPos = {
          x: newNode.posX,
          y: newNode.posY,
        };
        newNode.isRoot = false;
        connectables.push(newNode);

        // Add new index in the quick access map
        clonedIndexesMap[getConnectableIRI(createdNode)] = currentIndexLength + i;

        // New Min/Max
        if ((newNode.posX !== null) && (newNode.posY !== null)) {
          if (!isValidPosition(newMinPos)) {
            newMinPos.x = newNode.posX;
            newMinPos.y = newNode.posY;
          } else {
            if (newNode.posX < newMinPos.x) newMinPos.x = newNode.posX;
            if (newNode.posY < newMinPos.y) newMinPos.y = newNode.posY;
          }
          if (!isValidPosition(newMaxPos)) {
            newMaxPos.x = newNode.posX;
            newMaxPos.y = newNode.posY;
          } else {
            if (newNode.posX > newMaxPos.x) newMaxPos.x = newNode.posX;
            if (newNode.posY > newMaxPos.y) newMaxPos.y = newNode.posY;
          }
        }
      }// for - loop on created nodes

      return {
        ...state,
        error: null,
        status: action.type,
        connectables,
        connectablesIndexesMap: clonedIndexesMap,
        minPos: newMinPos,
        maxPos: newMaxPos,
      };
    }

    case ACTIONS.NEW_NODES_FAILURE:
      return {
        ...state,
        error: action.error,
        status: action.type,
      };

    case ACTIONS.CREATE_OPTIONAL_NODES_REQUEST:
    {
      return {
        ...state,
        status: action.type,
      };
    }
    case ACTIONS.CREATE_OPTIONAL_NODES_SUCCESS:
    {
      const connectables = [...state.connectables];
      const clonedIndexesMap = { ...state.connectablesIndexesMap };

      let newMinPos = { ...state.minPos };
      let newMaxPos = { ...state.maxPos };

      action.nodes.forEach((createdNode, index) => {
        const newInputLinks = createdNode.inputLinks;
        if ((typeof newInputLinks !== 'undefined')
          && (newInputLinks !== null)
          && (newInputLinks.length)) {
          // change the outputLinks of the parent connectables
          newInputLinks.forEach((lnk) => {
            const fromCon = clone(connectables[state.connectablesIndexesMap[lnk.from]]);
            if ((typeof fromCon.outputLinks === 'undefined')
            || (fromCon.outputLinks === null)) {
              fromCon.outputLinks = [];
            } else {
              fromCon.outputLinks = clone(fromCon.outputLinks);
            }
            fromCon.outputLinks.push(lnk);
            connectables[state.connectablesIndexesMap[lnk.from]] = fromCon;
          });
        }

        const newOutputLinks = createdNode.outputLinks;
        if ((typeof newOutputLinks !== 'undefined')
          && (newOutputLinks !== null)
          && (newOutputLinks.length)) {
          // change the inputLinks of the target connectables
          newOutputLinks.forEach((lnk) => {
            const toCon = clone(connectables[state.connectablesIndexesMap[lnk.to]]);
            if ((typeof toCon.inputLinks === 'undefined')
              || (toCon.inputLinks === null)) {
              toCon.inputLinks = [];
            } else {
              toCon.inputLinks = clone(toCon.inputLinks);
            }
            toCon.inputLinks.push(lnk);
            connectables[state.connectablesIndexesMap[lnk.to]] = toCon;
          });
        }

        // Add new index in the quick access map
        clonedIndexesMap[getConnectableIRI(createdNode)] = state.connectables.length + index;

        // New Node
        const newNode = { ...createdNode };
        newNode.graphPos = {
          x: newNode.posX,
          y: newNode.posY,
        };
        newNode.isRoot = false;

        connectables.push(newNode);

        // New Min/Max
        if ((newNode.posX !== null)
         && (newNode.posY !== null)) {
          if (index === 0) {
            newMinPos = connectables.length === 0 ? { ...newNode.graphPos } : { ...state.minPos };
            newMaxPos = connectables.length === 0 ? { ...newNode.graphPos } : { ...state.maxPos };
          }
          if (newNode.posX < newMinPos.x) newMinPos.x = newNode.posX;
          if (newNode.posY < newMinPos.y) newMinPos.y = newNode.posY;
          if (newNode.posX > newMaxPos.x) newMaxPos.x = newNode.posX;
          if (newNode.posY > newMaxPos.y) newMaxPos.y = newNode.posY;
        }
      });

      return {
        ...state,
        error: null,
        status: ACTIONS.CREATE_OPTIONAL_NODES_SUCCESS,
        connectables: [...connectables],
        connectablesIndexesMap: clonedIndexesMap,
        minPos: newMinPos,
        maxPos: newMaxPos,
      };
    }
    case ACTIONS.CREATE_OPTIONAL_NODES_FAILURE:
      return {
        ...state,
        error: action.error,
        status: ACTIONS.CREATE_OPTIONAL_NODES_FAILURE,
      };

    case ACTIONS.CHANGE_CONNECTABLES_COORDINATES_REQUEST:
    {
      const connectables = [...state.connectables];
      let connectable = null;
      for (let i = 0; i < action.connectables.length; i += 1) {
        connectable = action.connectables[i];
        const conIdx = state.connectablesIndexesMap[getConnectableIRI(connectable)];
        connectables[conIdx] = {
          ...connectables[conIdx],
          graphPos: {
            x: connectable.x,
            y: connectable.y,
            z: connectable.z,
          },
        };
      }// for

      return {
        ...state,
        status: ACTIONS.CHANGE_CONNECTABLES_COORDINATES_REQUEST,
        connectables,
      };
    }
    case ACTIONS.CHANGE_CONNECTABLES_COORDINATES_SUCCESS:
    {
      const connectables = [...state.connectables];

      // New Min/Max
      const newMinPos = { ...state.minPos };
      const newMaxPos = { ...state.maxPos };

      action.connectables.forEach((connectable) => {
        const conIdx = state.connectablesIndexesMap[getConnectableIRI(connectable)];

        connectables[conIdx] = {
          ...connectables[conIdx],
          posX: connectable.posX,
          posY: connectable.posY,
          posZ: connectable.posZ,
          graphPos: {
            x: connectable.posX,
            y: connectable.posY,
            z: connectable.posZ,
          },
        };


        if (connectable.posX < newMinPos.x) newMinPos.x = connectable.posX;
        if (connectable.posY < newMinPos.y) newMinPos.y = connectable.posY;
        if (connectable.posX > newMaxPos.x) newMaxPos.x = connectable.posX;
        if (connectable.posY > newMaxPos.y) newMaxPos.y = connectable.posY;
      });

      // If we moved the maximum (or minimum) story block
      // And if the limits were not increased by previous loop
      // We may have to decrease them. It means to recompute the max (min) position
      let maxMoved = false;
      let minMoved = false;
      action.previousCoordinates.forEach((prevCoord) => {
        if (!maxMoved) {
          if ((prevCoord.posX === state.maxPos.x)
           || (prevCoord.posY === state.maxPos.y)) {
            maxMoved = true;
          }
        }
        if (!minMoved) {
          if ((prevCoord.posX === state.minPos.x)
           || (prevCoord.posY === state.minPos.y)) {
            minMoved = true;
          }
        }
      });

      if ((minMoved && ((newMinPos.x === state.minPos.x) || (newMinPos.y === state.minPos.y)))
       || (maxMoved && ((newMaxPos.x === state.maxPos.x) || (newMaxPos.y === state.maxPos.y)))) {
        newMinPos.x = CONSTANTS.INVALID_COORDINATE;
        newMinPos.y = CONSTANTS.INVALID_COORDINATE;
        newMaxPos.x = CONSTANTS.INVALID_COORDINATE;
        newMaxPos.y = CONSTANTS.INVALID_COORDINATE;

        for (let conni = 0; conni < connectables.length; conni += 1) {
          const elem = connectables[conni];
          // Compute min and max positions of the different elements
          if ((elem.posX !== null) && (elem.posY !== null)) {
            if (!isValidPosition(newMinPos)
             || !isValidPosition(newMaxPos)) {
              newMinPos.x = elem.posX;
              newMinPos.y = elem.posY;
              newMaxPos.x = elem.posX;
              newMaxPos.y = elem.posY;
            } else {
              if (elem.posX < newMinPos.x) newMinPos.x = elem.posX;
              if (elem.posY < newMinPos.y) newMinPos.y = elem.posY;
            }
            if (elem.posX > newMaxPos.x) newMaxPos.x = elem.posX;
            if (elem.posY > newMaxPos.y) newMaxPos.y = elem.posY;
          }
        }
      }

      return {
        ...state,
        error: null,
        status: ACTIONS.CHANGE_CONNECTABLES_COORDINATES_SUCCESS,
        connectables,
        minPos: newMinPos,
        maxPos: newMaxPos,
      };
    }
    case ACTIONS.CHANGE_CONNECTABLES_COORDINATES_FAILURE:
      return {
        ...state,
        error: action.error,
        status: ACTIONS.CHANGE_CONNECTABLES_COORDINATES_FAILURE,
      };

    case ACTIONS.CREATE_FORK_REQUEST:
    {
      return {
        ...state,
        status: ACTIONS.CREATE_FORK_REQUEST,
      };
    }
    case ACTIONS.CREATE_FORK_SUCCESS:
      return {
        ...state,
        status: ACTIONS.CREATE_FORK_SUCCESS,
      };
    case ACTIONS.CREATE_FORK_FAILURE:
      return {
        ...state,
        error: action.error,
        status: ACTIONS.CREATE_FORK_FAILURE,
      };

    case ACTIONS.CHANGE_NODE_LABEL_REQUEST:
    {
      return {
        ...state,
        status: ACTIONS.CHANGE_NODE_LABEL_REQUEST,
      };
    }
    case ACTIONS.CHANGE_NODE_LABEL_SUCCESS:
    {
      const connectables = [...state.connectables];
      const conIdx = state.connectablesIndexesMap[getConnectableIRI(action.connectable)];
      connectables[conIdx] = {
        ...connectables[conIdx],
        label: action.connectable.label,
      };

      return {
        ...state,
        error: null,
        status: ACTIONS.CHANGE_NODE_LABEL_SUCCESS,
        connectables,
      };
    }
    case ACTIONS.CHANGE_NODE_LABEL_FAILURE:
      return {
        ...state,
        error: action.error,
        status: ACTIONS.CHANGE_NODE_LABEL_FAILURE,
      };

    case ACTIONS.PERSONNALIZE_NODE_REQUEST:
    {
      return {
        ...state,
        status: ACTIONS.PERSONNALIZE_NODE_REQUEST,
      };
    }
    case ACTIONS.PERSONNALIZE_NODE_SUCCESS:
    {
      return {
        ...state,
        error: null,
        status: ACTIONS.PERSONNALIZE_NODE_SUCCESS,
      };
    }
    case ACTIONS.PERSONNALIZE_NODE_FAILURE:
      return {
        ...state,
        error: action.error,
        status: ACTIONS.PERSONNALIZE_NODE_FAILURE,
      };

    case ACTIONS.CHANGE_LINKS_TARGET_REQUEST:
    {
      return {
        ...state,
        status: ACTIONS.CHANGE_LINKS_TARGET_REQUEST,
      };
    }
    case ACTIONS.CHANGE_LINKS_TARGET_SUCCESS:
    {
      const connectables = [...state.connectables];

      // Old target connectable
      const oldToIndex = state.connectablesIndexesMap[action.oldTo];
      const oldToCon = { ...connectables[oldToIndex] };
      const oldToInputLinks = [...oldToCon.inputLinks];

      // New target connectable
      const newToIndex = state.connectablesIndexesMap[action.newTo];
      const newToCon = { ...connectables[newToIndex] };
      let newToInputLinks = [];
      if ((typeof newToCon.inputLinks !== 'undefined') && (newToCon.inputLinks !== null)) {
        newToInputLinks = [...newToCon.inputLinks];
      }

      action.links.forEach((lnk) => {
        // Remove the Link from the oldTo connectable inputLinks
        const foundTo = oldToInputLinks.findIndex(((element) => {
          return element.id === lnk.id;
        }));
        if (foundTo !== -1) {
          oldToInputLinks.splice(foundTo, 1);
        }
        oldToCon.inputLinks = oldToInputLinks;

        // Add the Link in the newTo connectable inputLinks
        newToInputLinks.push(lnk);
        newToCon.inputLinks = newToInputLinks;

        // Update the Link in the from connectable outputLinks
        const fromIndex = state.connectablesIndexesMap[lnk.from];
        const fromCon = { ...connectables[fromIndex] };
        const newFromOutputLinks = [...fromCon.outputLinks];
        const foundFrom = newFromOutputLinks.findIndex(((element) => {
          return element.id === lnk.id;
        }));
        if (foundFrom !== -1) {
          newFromOutputLinks.splice(foundFrom, 1);
        }
        newFromOutputLinks.push(lnk);
        fromCon.outputLinks = newFromOutputLinks;
        connectables[fromIndex] = fromCon;
      });

      connectables[oldToIndex] = oldToCon;
      connectables[newToIndex] = newToCon;

      return {
        ...state,
        error: null,
        status: ACTIONS.CHANGE_LINKS_TARGET_SUCCESS,
        connectables,
      };
    }
    case ACTIONS.CHANGE_LINKS_TARGET_FAILURE:
      return {
        ...state,
        error: action.error,
        status: ACTIONS.CHANGE_LINKS_TARGET_FAILURE,
      };

    /**
     * CREATE LINKS BETWEEN CONNECTABLES
     */
    case ACTIONS.LINK_CONNECTABLES_REQUEST:
    {
      return {
        ...state,
        status: ACTIONS.LINK_CONNECTABLES_REQUEST,
      };
    }
    case ACTIONS.LINK_CONNECTABLES_SUCCESS:
    {
      const connectables = [...state.connectables];

      action.links.forEach((lnk) => {
        // Add the Link in the from connectable outputLinks
        const newFromIndex = state.connectablesIndexesMap[lnk.from];
        const newFromCon = { ...connectables[newFromIndex] };
        let newFromOutputLinks = [];
        if ((typeof newFromCon.outputLinks !== 'undefined') && (newFromCon.outputLinks !== null)) {
          newFromOutputLinks = [...newFromCon.outputLinks];
        }
        newFromOutputLinks.push({ ...lnk });
        newFromCon.outputLinks = newFromOutputLinks;
        connectables[newFromIndex] = newFromCon;

        // Add the Link in the to connectable inputLinks
        const newToIndex = state.connectablesIndexesMap[lnk.to];
        const newToCon = { ...connectables[newToIndex] };
        let newToInputLinks = [];
        if ((typeof newToCon.inputLinks !== 'undefined') && (newToCon.inputLinks !== null)) {
          newToInputLinks = [...newToCon.inputLinks];
        }
        newToInputLinks.push({ ...lnk });
        newToCon.inputLinks = newToInputLinks;
        connectables[newToIndex] = newToCon;
      });

      return {
        ...state,
        error: null,
        status: ACTIONS.LINK_CONNECTABLES_SUCCESS,
        connectables,
      };
    }
    case ACTIONS.LINK_CONNECTABLES_FAILURE:
      return {
        ...state,
        error: action.error,
        status: ACTIONS.LINK_CONNECTABLES_FAILURE,
      };

    /**
     * DELETE LINKS
     */
    case ACTIONS.DELETE_LINKS_REQUEST:
    {
      return {
        ...state,
        status: ACTIONS.DELETE_LINKS_REQUEST,
      };
    }
    case ACTIONS.DELETE_LINKS_SUCCESS:
    {
      const connectables = [...state.connectables];

      const { deletedLinks } = action;

      deletedLinks.forEach((deletedLink) => {
        if (deletedLink !== null) {
          // Change inputLinks of target connectable
          const deletedToIndex = state.connectablesIndexesMap[deletedLink.to];
          const conTo = { ...connectables[deletedToIndex] };
          let newToInputLinks = [];
          if ((typeof conTo.inputLinks !== 'undefined') && (conTo.inputLinks !== null)) {
            newToInputLinks = [...conTo.inputLinks];
          }
          const found = newToInputLinks.findIndex(elem => elem.id === deletedLink.id);
          if (found !== undefined) {
            newToInputLinks.splice(found, 1);
          }
          conTo.inputLinks = newToInputLinks;
          connectables[deletedToIndex] = conTo;

          // Change outputLinks of source connectable
          const deletedFromIndex = state.connectablesIndexesMap[deletedLink.from];
          const conFrom = { ...connectables[deletedFromIndex] };
          let newFromOutputLinks = [];
          if ((typeof conFrom.outputLinks !== 'undefined') && (conFrom.outputLinks !== null)) {
            newFromOutputLinks = [...conFrom.outputLinks];
          }
          const foundFrom = newFromOutputLinks.findIndex(elem => elem.id === deletedLink.id);
          if (foundFrom !== undefined) {
            newFromOutputLinks.splice(foundFrom, 1);
          }
          conFrom.outputLinks = newFromOutputLinks;
          connectables[deletedFromIndex] = conFrom;
        }
      });

      return {
        ...state,
        connectables,
        status: ACTIONS.DELETE_LINKS_SUCCESS,
      };
    }
    case ACTIONS.DELETE_LINKS_FAILURE:
      return {
        ...state,
        error: action.error,
        status: ACTIONS.DELETE_LINKS_FAILURE,
      };

    /**
     * DELETE NODES
     */
    case ACTIONS.DELETE_CONNECTABLES_REQUEST:
    {
      return {
        ...state,
        status: action.type,
      };
    }
    case ACTIONS.DELETE_CONNECTABLES_SUCCESS:
    {
      const newRootConnectables = { ...state.rootConnectables };

      const newConnectables = [];
      for (let i = 0; i < state.connectables.length; i += 1) {
        const connectableIRI = getConnectableIRI(state.connectables[i]);
        const delIdx = action.deletedConnectables.findIndex((conIRI) => {
          return (conIRI === connectableIRI);
        });
        if (delIdx === -1) {
          newConnectables.push(state.connectables[i]);
        } else {
          if (connectableIRI === newRootConnectables.videoRootConnectable) {
            newRootConnectables.videoRootConnectable = null;
          }
          if (connectableIRI === newRootConnectables.audioRootConnectable) {
            newRootConnectables.audioRootConnectable = null;
          }
        }
      }

      // reset the min/max pos
      const newMinPos = { x: CONSTANTS.INVALID_COORDINATE, y: CONSTANTS.INVALID_COORDINATE };
      const newMaxPos = { x: CONSTANTS.INVALID_COORDINATE, y: CONSTANTS.INVALID_COORDINATE };

      // Reset the indexes map to access quickly to each object
      const newConnectablesIndexesMap = {};
      for (let conni = 0; conni < newConnectables.length; conni += 1) {
        const elem = newConnectables[conni];
        newConnectablesIndexesMap[getConnectableIRI(elem)] = conni;

        if ((elem.graphPos.x !== null) && (elem.graphPos.y !== null)) {
          if (!isValidPosition(newMinPos)
           || !isValidPosition(newMaxPos)) {
            newMinPos.x = elem.graphPos.x;
            newMinPos.y = elem.graphPos.y;
            newMaxPos.x = elem.graphPos.x;
            newMaxPos.y = elem.graphPos.y;
          } else {
            if (elem.graphPos.x < newMinPos.x) newMinPos.x = elem.graphPos.x;
            if (elem.graphPos.y < newMinPos.y) newMinPos.y = elem.graphPos.y;
          }
          if (elem.graphPos.x > newMaxPos.x) newMaxPos.x = elem.graphPos.x;
          if (elem.graphPos.y > newMaxPos.y) newMaxPos.y = elem.graphPos.y;
        }
      }

      return {
        ...state,
        connectables: newConnectables,
        connectablesIndexesMap: newConnectablesIndexesMap,
        status: action.type,
        minPos: newMinPos,
        maxPos: newMaxPos,
        rootConnectables: newRootConnectables,
      };
    }
    case ACTIONS.DELETE_CONNECTABLES_FAILURE:
      return {
        ...state,
        error: action.error,
        status: action.type,
      };

    // Modify Video Root
    case ACTIONS.MODIFY_PROJECT_VIDEO_ROOT_REQUEST:
    {
      return {
        ...state,
        status: ACTIONS.MODIFY_PROJECT_VIDEO_ROOT_REQUEST,
      };
    }
    case ACTIONS.MODIFY_PROJECT_VIDEO_ROOT_SUCCESS:
    {
      // XXX TODO: Improve MODIFY_PROJECT_VIDEO_ROOT_SUCCESS Case
      // set the new video root with the one given to the modification request
      // And update the connectables list with the modified "isRoot" properties
      const newRootConnectables = { ...state.rootConnectables };

      const newConnectables = [...state.connectables];

      // VIDEO
      if (newRootConnectables.videoRootConnectable) {
        const oldVideoRootIRI = newRootConnectables.videoRootConnectable;
        const oldVideoRootIndex = state.connectablesIndexesMap[oldVideoRootIRI];
        const oldVideoRootCon = { ...state.connectables[oldVideoRootIndex] };
        oldVideoRootCon.isRoot = false;
        newConnectables[oldVideoRootIndex] = { ...oldVideoRootCon };
      }
      const newVideoRootIRI = action.videoRoot;

      const newVideoRootIndex = state.connectablesIndexesMap[newVideoRootIRI];
      const newVideoRootCon = { ...state.connectables[newVideoRootIndex] };
      newVideoRootCon.isRoot = true;
      newConnectables[newVideoRootIndex] = { ...newVideoRootCon };

      newRootConnectables.videoRootConnectable = newVideoRootIRI;

      // AUDIO
      if (newRootConnectables.audioRootConnectable) {
        const oldAudioRootIRI = newRootConnectables.audioRootConnectable;
        const oldAudioRootIndex = state.connectablesIndexesMap[oldAudioRootIRI];
        const oldAudioRootCon = { ...state.connectables[oldAudioRootIndex] };
        oldAudioRootCon.isRoot = false;
        newConnectables[oldAudioRootIndex] = { ...oldAudioRootCon };
      }
      const newAudioRootIRI = action.audioRoot;

      const newAudioRootIndex = state.connectablesIndexesMap[newAudioRootIRI];
      const newAudioRootCon = { ...state.connectables[newAudioRootIndex] };
      newAudioRootCon.isRoot = true;
      newConnectables[newAudioRootIndex] = { ...newAudioRootCon };

      newRootConnectables.audioRootConnectable = newAudioRootIRI;

      return {
        ...state,
        error: null,
        connectables: newConnectables,
        rootConnectables: newRootConnectables,
        status: ACTIONS.MODIFY_PROJECT_VIDEO_ROOT_SUCCESS,
      };
    }

    case ACTIONS.MODIFY_PROJECT_VIDEO_ROOT_FAILURE:
      return {
        ...state,
        status: ACTIONS.MODIFY_PROJECT_VIDEO_ROOT_FAILURE,
        error: action.error,
      };


    case ACTIONS.SELECT_CONNECTABLES:
    {
      const newConnectables = [...state.connectables];
      const consToSelect = [...action.toSelect];
      const consToUnselect = [...action.toUnselect];

      let idx = -1;
      for (let i = 0; i < consToSelect.length; i += 1) {
        idx = state.connectablesIndexesMap[consToSelect[i]];
        if ((typeof newConnectables[idx] !== 'undefined')
         && ((typeof newConnectables[idx].selected === 'undefined')
          || (newConnectables[idx].selected === null)
          || (newConnectables[idx].selected !== true))) {
          // The connectable is in the array to select and not already selected-> we select it
          newConnectables[idx] = { ...newConnectables[idx] };
          newConnectables[idx].selected = true;
        }
      }// for
      for (let i = 0; i < consToUnselect.length; i += 1) {
        idx = state.connectablesIndexesMap[consToUnselect[i]];
        if ((typeof newConnectables[idx] !== 'undefined')
         && ((typeof newConnectables[idx].selected === 'undefined')
          || (newConnectables[idx].selected === null)
          || (newConnectables[idx].selected === true))) {
          // The connectable is in the array to unselect and already selected-> we unselect it
          newConnectables[idx] = { ...newConnectables[idx] };
          newConnectables[idx].selected = false;
        }
      }// for

      return {
        ...state,
        connectables: newConnectables,
      };
    }

    // Modify Video Root
    case ACTIONS.CHANGE_DK_FORKING_SOUNDTRACK_REQUEST:
      return {
        ...state,
        status: ACTIONS.CHANGE_DK_FORKING_SOUNDTRACK_REQUEST,
        forkingSoundTrackRequestRunning: true,
      };

    case ACTIONS.CHANGE_DK_FORKING_SOUNDTRACK_SUCCESS:
      return {
        ...state,
        status: ACTIONS.CHANGE_DK_FORKING_SOUNDTRACK_SUCCESS,
        forkingSoundTrackRequestRunning: false,
      };

    case ACTIONS.CHANGE_DK_FORKING_SOUNDTRACK_FAILURE:
      return {
        ...state,
        status: ACTIONS.CHANGE_DK_FORKING_SOUNDTRACK_FAILURE,
        error: action.error,
        forkingSoundTrackRequestRunning: false,
      };

    case ACTIONS.COPY_NODES_REQUEST:
    {
      const newCopiedConnectables = [...action.connectables];
      return {
        ...state,
        status: action.type,
        copiedConnectables: newCopiedConnectables,
      };
    }
    case ACTIONS.COPY_NODES_SUCCESS:
      return {
        ...state,
        status: action.type,
      };
    case ACTIONS.COPY_NODES_FAILURE:
      return {
        ...state,
        status: action.type,
        error: action.error,
      };

    case ACTIONS.CLONE_NODES_REQUEST:
      return {
        ...state,
        status: action.type,
      };
    case ACTIONS.CLONE_NODES_SUCCESS:
      return {
        ...state,
        status: action.type,
      };
    case ACTIONS.CLONE_NODES_FAILURE:
      return {
        ...state,
        status: action.type,
        error: action.error,
      };
    case DATAKEYSACTIONS.DELETE_DATAKEY_SUCCESS:
    case DATAKEYSACTIONS.DELETE_DATAKEYVALUE_SUCCESS:
    {
      let newCopiedConnectables = [...state.copiedConnectables];

      // Check if the deleted datakey is used by a copied data key instance
      let found = false;
      let connectable = null;
      for (let i = 0; (i < newCopiedConnectables.length) && !found; i += 1) {
        connectable = newCopiedConnectables[i];
        if ((isKeyInstance(connectable))
         && (connectable.dataKey === getDkIRI(action.dataKey))) {
          found = true;
        }
      }// for
      if (found) {
        newCopiedConnectables = [];
      }
      return {
        ...state,
        copiedConnectables: newCopiedConnectables,
      };
    }
    case LABELSACTIONS.DELETE_LABEL_SUCCESS:
    {
      let newCopiedConnectables = [...state.copiedConnectables];

      // Check if the deleted label is used by a copied connectable
      let found = false;
      let connectable = null;
      for (let i = 0; (i < newCopiedConnectables.length) && !found; i += 1) {
        connectable = newCopiedConnectables[i];
        if ((typeof connectable.label !== 'undefined')
         && (connectable.label !== null)
         && (connectable.label === action.labelIRI)) {
          found = true;
        }
      }// for
      if (found) {
        newCopiedConnectables = [];
      }

      return {
        ...state,
        copiedConnectables: newCopiedConnectables,
      };
    }
    default:
      return state;
  }
};
// Happy New Year !
// +1
