/**
 * File : DownloadTools.jsx
 */

/**
 * downloadFile - start the download of a file given its URL
 * @param {*} fileurl
 */
// eslint-disable-next-line import/prefer-default-export
export const downloadFile = (fileurl) => {
  const a = document.createElement('a');
  a.href = fileurl;
  a.download = fileurl.substr(fileurl.lastIndexOf('/') + 1);
  document.body.appendChild(a);
  a.click();
  document.body.removeChild(a);
};
