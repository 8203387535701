import { nameContainsString } from 'skewerui';
import {
  getObjectIRI,
  getVideoMediaTypeIRI,
  getAudioMediaTypeIRI,
  getAssetIRI,
  getAssetIRIPrefix,
} from './IRITools';

export const isAudioLabel = (label, project) => {
  let audioMediaTypeIRI = null;
  if ((typeof project !== 'undefined')
   && (project !== null)) {
    audioMediaTypeIRI = getAudioMediaTypeIRI(project);
  }

  if ((typeof label.mediaType !== 'undefined')
   && (label.mediaType !== null)) {
    let labelMediaTypeIRI = label.mediaType;
    if (typeof label.mediaType === 'object') {
      labelMediaTypeIRI = getObjectIRI(label.mediaType);
    }
    return (labelMediaTypeIRI === audioMediaTypeIRI);
  }

  // By default - act as if the asset was a video one
  return false;
};

export const isVideoLabel = (label, project) => {
  let videoMediaTypeIRI = null;
  if ((typeof project !== 'undefined')
   && (project !== null)) {
    videoMediaTypeIRI = getVideoMediaTypeIRI(project);
  }

  if ((typeof label.mediaType !== 'undefined')
   && (label.mediaType !== null)) {
    let labelMediaTypeIRI = label.mediaType;
    if (typeof label.mediaType === 'object') {
      labelMediaTypeIRI = getObjectIRI(label.mediaType);
    }
    return (labelMediaTypeIRI === videoMediaTypeIRI);
  }

  // By default - act as if the asset was a video one
  return true;
};

export const getLabelAssetRefIRI = (label) => {
  let assetIRI = null;

  if ((typeof label.assets !== 'undefined')
   && (label.assets !== null)
   && (label.assets.length > 0)) {
    [assetIRI] = label.assets;
  }
  return assetIRI;
};

/**
 * returns an array of Labels which names correspond to the given string
 * (Given an array of labels)
 * @param {*} suggestion array
 */
export const getLabelSuggestionsArray = (labels, value) => {
  const labelsArray = labels.filter(elem => nameContainsString(elem.name, value));
  return labelsArray.sort((a, b) => {
    return (a.name.localeCompare(b.name));
  });
}; // getLabelSuggestionsArray


/**
 * Types of labels - values of label type property
 */
export const LABEL_TYPES = {
  DEFAULT: 'default',
  DEDICATED: 'dedicated',
};

/**
 * Indicates if the given label object is an internal one or not
 * If an asset is given as second parameter, then it checks if the label
 * is the dedicated one of the asset
 * @param {*} label to check
 * @returns true is the label is an asset dedicated one
 */
export const isAssetDedicatedLabel = (label, asset = null) => {
  const assetIRIprefix = getAssetIRIPrefix();
  let isDedicatedOne = false;
  if ((typeof label.assets != 'undefined')
   && (label.assets !== null)
   && (label.assets.length === 1)
   && ((label.type === LABEL_TYPES.DEDICATED)
    || ((typeof label.name !== 'undefined')
     && (label.name !== null)
     && (label.name.startsWith(assetIRIprefix))))) {
    if (asset === null) {
      // If asset is null, only checks if the label is a dedicated one
      isDedicatedOne = true;
    } else if (label.assets[0] === getAssetIRI(asset)) {
      // If asset is not null, checks if the label is THE dedicated one of the asset
      isDedicatedOne = true;
    }
  }

  return isDedicatedOne;
};

export const getLabelDedicatedAssetIRI = (label) => {
  let assetIRI = null;
  if (isAssetDedicatedLabel(label)) {
    if ((typeof label.assets !== 'undefined')
     && (label.assets !== null)
     && (label.assets.length !== 0)) {
      [assetIRI] = label.assets;
    }
  }
  return assetIRI;
};
