import React, { lazy } from 'react';
import { Switch } from 'react-router-dom';
import { getSuspensedComponent, SkwrSuspense } from 'skewerui';
import Route from './containers/Route';
import Index from './pages/Index';
import Login from './pages/Login';

export const ROUTING = {
  ROOT: '/',
  SIGN_UP: '/sign-up',
  LOG_IN: '/log-in',
  FORGOT_PASSWORD: '/forgot-password',
  NEW_PASSWORD: '/new-password/:token',
  PROJECTS: '/projects',
  WORKSPACE: '/project',
  WAIT_ACCOUNT_VALIDATION: '/wait-account-validation/',
  WAIT_ACCOUNT_ACTIVATION: '/wait-account-activation/:token',
  DEFAULT_CONNECTED_HOMEPAGE: '/projects',
};


const ProjectsListingPage = (
  lazy(() => (
    import('./pages/ProjectsListing')
  ))
);

const ProjectSingle = (
  lazy(() => (
    import('./pages/Project')
  ))
);

const Error404 = (lazy(() => (import('./pages/Error404'))));
const WaitActivation = (lazy(() => (import('./pages/AccountActivationWaiting'))));
const WaitValidation = (lazy(() => (import('./pages/AccountValidationWaiting'))));
const SignUp = (lazy(() => (import('./pages/SignUp'))));
const ForgotPassword = (lazy(() => (import('./pages/ForgotPassword'))));
const NewPassword = (lazy(() => (import('./pages/NewPassword'))));


export default (() => (
  <SkwrSuspense>
    <Switch>
      <Route
        exact
        path={ROUTING.ROOT}
        component={Index}
        autoRedirectIfConnected
        needAuth={false}
      />
      <Route
        exact
        path={ROUTING.SIGN_UP}
        component={getSuspensedComponent(SignUp)}
        autoRedirectIfConnected
        needAuth={false}
      />
      <Route
        exact
        path={ROUTING.LOG_IN}
        component={Login}
        autoRedirectIfConnected
        needAuth={false}
      />
      <Route
        exact
        path={ROUTING.NEW_PASSWORD}
        component={getSuspensedComponent(NewPassword)}
        autoRedirectIfConnected
        needAuth={false}
      />

      <Route
        exact
        path={ROUTING.FORGOT_PASSWORD}
        component={getSuspensedComponent(ForgotPassword)}
        autoRedirectIfConnected
        needAuth={false}
      />

      <Route
        exact
        path={ROUTING.PROJECTS}
        needAuth
        component={getSuspensedComponent(ProjectsListingPage)}
      />

      <Route
        exact
        path={`${ROUTING.WORKSPACE}/:id`}
        needAuth
        component={getSuspensedComponent(ProjectSingle)}
      />

      <Route
        exact
        path={ROUTING.WAIT_ACCOUNT_VALIDATION}
        component={getSuspensedComponent(WaitValidation)}
        autoRedirectIfConnected
        needAuth={false}
      />

      <Route
        exact
        path={ROUTING.WAIT_ACCOUNT_ACTIVATION}
        component={getSuspensedComponent(WaitActivation)}
        autoRedirectIfConnected
        needAuth={false}
      />

      <Route component={getSuspensedComponent(Error404)} />
    </Switch>
  </SkwrSuspense>
))();
