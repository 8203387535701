// configureStore.js
import { createHashHistory } from 'history';
import { applyMiddleware, compose, createStore } from 'redux';
import { routerMiddleware } from 'connected-react-router';
import thunk from 'redux-thunk';

import createRootReducer from './reducers';
import undoRedoMiddleware from './undoRedoMiddleware';
import pendingRequestsMiddleware from './pendingRequestsMiddleware';
import { isDevelopmentMode } from '../Constants';

export const history = createHashHistory();

let invariant = null;
if (isDevelopmentMode()) {
  // Only in dev mode for development purpose
  invariant = require('redux-immutable-state-invariant').default();
}

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

export default function configureStore(preloadedState) {
  const middlewares = [];
  middlewares.push(routerMiddleware(history));
  middlewares.push(thunk);
  middlewares.push(undoRedoMiddleware);
  middlewares.push(pendingRequestsMiddleware);
  if (isDevelopmentMode()) {
    middlewares.push(invariant);
  }

  const store = createStore(
    createRootReducer(history), // root reducer with router state
    preloadedState,
    composeEnhancers(
      applyMiddleware(
        ...middlewares,
      ),
    ),
  );
  return store;
}
