export const UNAVAILIBILITYREASONS = {
  NO_REASON: 'NO_REASON',
  WORK_IN_PROGRESS: 'WORK_IN_PROGRESS',
};

const initialState = {
  available: true,
  reason: UNAVAILIBILITYREASONS.NO_REASON,
};

export const ACTIONS = {
  SET_NOT_AVAILABLE: 'SET_NOT_AVAILABLE',
  SET_AVAILABLE: 'SET_AVAILABLE',
};

// XXX TODO - In the final public release of the editor
// XXX TODO - there should be NO call to setWorkInProgress !!
export const setWorkInProgress = logmessage => ({
  type: ACTIONS.SET_NOT_AVAILABLE,
  reason: UNAVAILIBILITYREASONS.WORK_IN_PROGRESS,
  logmessage,
});

export const acknowledgeUnavailibility = () => ({
  type: ACTIONS.SET_AVAILABLE,
});

export default (state = initialState, action) => {
  switch (action.type) {
    case ACTIONS.SET_NOT_AVAILABLE:
      if ((typeof action.logmessage !== 'undefined')
        && (action.logmessage !== null)
        && (action.logmessage !== '')) {
        console.log(action.logmessage);
      }
      return {
        available: false,
        reason: action.reason,
        logmessage: action.logmessage,
      };
    case ACTIONS.SET_AVAILABLE:
      return {
        available: true,
        reason: UNAVAILIBILITYREASONS.NO_REASON,
      };
    default:
      return state;
  }
};
