import React, { forwardRef } from 'react';
import classNames from 'classnames';
import { withStyles } from '@material-ui/core/styles';
import { Color } from '../UIConstants';

const styles = theme => ({
  customTabLabel: {
    position: 'relative',
  },
  customTabLoading: {
    position: 'absolute',
    height: '10px',
    width: '10px',
    borderRadius: '50px',
    backgroundColor: Color.greyLight3,
    boxShadow: '0px 3px 6px #5d5d5d20',
    animation: `$pulseLoad infinite 3s both`,
    right: '-8%',
    top: '0%',
  },
  customTabNotLoading: {
    position: 'absolute',
    height: '10px',
    width: '10px',
    right: '-8%',
    top: '0%',
    display: 'none',
  },
  "@keyframes pulseLoad": {
    "0%": {
      opacity: '0',
      transform: 'scale(0.2)',
    },
  
    "50%": {
      opacity: '1',
      transform: 'scale(1)',
    },
  
    "100%": {
      opacity: '0',
      transform: 'scale(0.2)',
    },  
  },
});


/**
 * CustomTab must be used in <Tab component={CustomTab} /> material component
 * All CustomTab props are passed through Tab props like
 * <Tab label="My label" component={CustomTab} />
 */
const CustomTab = forwardRef((tabProps, ref) => {
  const {
    className,
    classes,
    customTabLoading,
    children,
    ...props
  } = tabProps;
  return (
    <div role="button" className={className} ref={ref} {...props}>
      <div className={classes.customTabLabel}>
        {children}
        <div
          className={customTabLoading ? classes.customTabLoading : classes.customTabNotLoading}
        />
      </div>
    </div>
  );
});

export default withStyles(styles)(CustomTab);
