import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as AuthenticationActions from '../../reducers/authenticationState';
import HeaderUserMenu from './HeaderUserMenu';
import HeaderHelpCenter from './HeaderHelpCenter';

const HeaderConnected = ({
  authenticationData,
  authenticationActions,
  appDedicatedHeader,
  appDedicatedUserMenuItems,
  onLogOut,
}) => {
  return (
    <>
      { appDedicatedHeader && (
        appDedicatedHeader
      )}
      <HeaderHelpCenter />
      <HeaderUserMenu
        username={authenticationData.user ? authenticationData.user.username : ''}
        authenticationActions={authenticationActions}
        appDedicatedUserMenuItems={appDedicatedUserMenuItems}
        onLogOut={onLogOut}
      />
    </>
  );
};

export default connect(
  state => ({
    authenticationData: state.authentication,
  }),
  dispatch => ({
    authenticationActions: bindActionCreators(AuthenticationActions, dispatch),
  }),
)(HeaderConnected);
