import React from 'react';
import Icon from '@material-ui/core/Icon';
import SkwrCheckbox from './SkwrCheckbox';


const SkwrCheckboxUpDown = (props) => (
  <SkwrCheckbox
    icon={<Icon>↓</Icon>}
    checkedIcon={<Icon>↑</Icon>}
    {...props} 
  />
);
export default SkwrCheckboxUpDown;