import axios from 'axios';
import CONSTANTS from '../Constants';
import authHeader from './auth-header';
import rejectUnfilteredError from './helperTools';
import * as User from './user';
import { getFakeConnectables } from './noConnectionHelper';

/**
 * Retrieves the connectables of a given project
 * @param {string} projectUuid uuid of the project
 */
export const getConnectables = (projectUuid, pageNumber = 1) => {
  return new Promise((resolve, reject) => {
    // No connection dev case !!
    // This is a dev fallback in case of server in down state
    // We should no rely on any data provided thru this case
    // Use with care, only for UI dev purpose
    if (User.isNoConnectionUser()) {
      resolve(getFakeConnectables());
      return;
    }

    const params = {
      'project.uuid': projectUuid,
      page: pageNumber,
    };

    axios.get(`${CONSTANTS.API.PROJECTS_URI}/connectables`, {
      headers: authHeader(),
      params,
    })
      .then((response) => {
        const hydraView = response.data['hydra:view'];

        const connectables = response.data['hydra:member'];

        // The request is paginated !
        // If we do not have everything, get next page !
        if ((typeof hydraView['hydra:next'] !== 'undefined')
         && (hydraView['hydra:next'] !== null)) {
          console.debug('Get next page of connectables : page ', pageNumber + 1);
          getConnectables(projectUuid, pageNumber + 1).then((nodesData) => {
            connectables.push(...nodesData.connectables);

            const connectablesData = {
              connectables,
            };

            resolve(connectablesData);
          });
        } else {
          const connectablesData = {
            connectables,
          };

          resolve(connectablesData);
        }
      }).catch((error) => {
        rejectUnfilteredError('getConnectables', error, reject);
      });
  });
};

/**
 * create a new Node
 * @param {object} nodeData object needed to create the Node
 */
export const createNode = (nodeData, callData) => {
  return new Promise((resolve, reject) => {
    axios.post(`${CONSTANTS.API.PROJECTS_URI}/nodes`, nodeData, {
      headers: authHeader(),
    }).then((response) => {
      resolve({
        newNode: response.data,
        callData,
      });
    }).catch((error) => {
      rejectUnfilteredError('createNode', error, reject);
    });
  });
};

/**
 * delete a Connectable
 * @param connectableID ID of the Connectable to delete
 * @param connectableType type of the connectable to delete
 */
export const deleteConnectable = (connectableIRI) => {
  return new Promise((resolve, reject) => {
    axios.delete(`${CONSTANTS.API.PROJECTS_URI}${connectableIRI}`, {
      headers: authHeader(),
    }).then((response) => {
      resolve(connectableIRI);
    }).catch((error) => {
      if (error.response) {
        if (error.response.status === 404) {
          // Delete action -> don't worry if the object to delete was not found !
          console.error('Unable to delete Connectable : ', error);
          resolve(null);
          return;
        }
      }

      rejectUnfilteredError('deleteConnectable', error, reject);
    });
  });
};

export const createKeyInstance = (keyInstanceData, callData) => {
  return new Promise((resolve, reject) => {
    axios.post(`${CONSTANTS.API.PROJECTS_URI}/key_instances`, keyInstanceData, {
      headers: authHeader(),
    })
      .then((response) => {
        resolve({
          newNode: response.data,
          callData,
        });
      }).catch((error) => {
        rejectUnfilteredError('createKeyInstance', error, reject);
      });
  });
};

export const modifyConnectable = (connectableType, connectableToModify) => {
  return new Promise((resolve, reject) => {
    let route = 'nodes';
    if (connectableType === CONSTANTS.TYPES.KEYINSTANCE) {
      route = 'key_instances';
    }
    axios.put(`${CONSTANTS.API.PROJECTS_URI}/${route}/${connectableToModify.id}`,
      connectableToModify,
      {
        headers: authHeader(),
      }).then((response) => {
      resolve(response.data);
    }).catch((error) => {
      if (error.response) {
        if (error.response.status === 400) {
          if (error.response.data['@type'] === 'ConstraintViolationList') {
            if (error.response.data.violations[0].message !== '') {
              console.error(error.response.data.violations[0].message);
            }
          }
        }
      }
      rejectUnfilteredError('modifyConnectable', error, reject);
    });
  });
};
