import React from 'react';
import Tabs from '@material-ui/core/Tabs';
import { makeStyles } from '@material-ui/core/styles';
import { ColorByUsage } from './UIConstants';

const useStyles = makeStyles({
  indicator: {
    backgroundColor: ColorByUsage.tabSelected,
  },
});

function SkwrTabs({
  value,
  children,
  onChange,
  ...props
}) {
  const classes = useStyles();

  return (
    <Tabs
      value={value}
      indicatorColor="primary"
      textColor="primary"
      onChange={onChange}
      aria-label="editor tabs"
      classes={{
        indicator: classes.indicator,
      }}
      {...props}
    >
      {children}
    </Tabs>
  );
}

export default SkwrTabs;
