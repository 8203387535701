/**
 * File: labelsStateConstants.js
 */
const ACTIONS = {
  // NODES REQUESTS
  // ----------
  CLEAR_ERROR: 'LABELS_CLEAR_ERROR',
  RESET_CURRENT_PROJECT: 'RESET_CURRENT_PROJECT',

  NEW_LABEL_REQUEST: 'NEW_LABEL_API_POST_REQUEST',
  NEW_LABEL_SUCCESS: 'NEW_LABEL_API_POST_SUCCESS',
  NEW_LABEL_FAILURE: 'NEW_LABEL_API_POST_FAILURE',

  UPDATE_LABEL_REQUEST: 'UPDATE_LABEL_API_POST_REQUEST',
  UPDATE_LABEL_SUCCESS: 'UPDATE_LABEL_API_POST_SUCCESS',
  UPDATE_LABEL_FAILURE: 'UPDATE_LABEL_API_POST_FAILURE',

  DELETE_LABEL_REQUEST: 'DELETE_LABEL_API_POST_REQUEST',
  DELETE_LABEL_SUCCESS: 'DELETE_LABEL_API_POST_SUCCESS',
  DELETE_LABEL_FAILURE: 'DELETE_LABEL_API_POST_FAILURE',
};
export default ACTIONS;
