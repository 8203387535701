import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';
import undoHistoryReducer from '../undoredo/reducer';
import localeReducer from './localeState';
import authentication from './authenticationState';
import projects from './projectsState';
import previews from './previewState';
import dataKeys from './dataKeysState';
import nodes from './nodesState';
import mediaLibrary from './mediaLibraryState';
import labels from './labelsState';
import availability from './availabilityState';
import utils from './utilsState';
import pendingRequests from './pendingRequestsState';
import exports from './exportState';
import externalPreview from './ExternalPreviewState';
import board from '../scenarioEditor/reducers/boardState';

export default history => combineReducers({
  router: connectRouter(history),
  localeReducer,
  authentication,
  projects,
  previews,
  dataKeys,
  nodes,
  mediaLibrary,
  labels,
  undoHistory: undoHistoryReducer,
  availability,
  utils,
  pendingRequests,
  exports,
  externalPreview,
  board,
});
