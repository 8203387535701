import React from 'react';
import { connect } from 'react-redux';
import {
  SkwrDialog,
} from 'skewerui';
import { getCurrentUser } from '../../../reducers/authenticationSelector';
import { getCurrentProject } from '../../../reducers/projectsSelectors';
import * as AIR_TABLE_DATA from '../../../AirTableConstants';

function ContactSupportDialog(props) {
  const {
    isOpen,
    onClose,
    dialogTitle,
    user,
    currentProject,
    prefillDate,
  } = props;

  const TABLE_DATA = AIR_TABLE_DATA.AIR_TABLE_CONTACT_DATA;

  const buildPrefill = (url, name, value) => {
    return `${url}&prefill_${name}=${value}`;
  };

  const getUrl = () => {
    let buildedUrl = TABLE_DATA.urls.en;

    if (user) {
      buildedUrl = buildPrefill(buildedUrl, TABLE_DATA.name, `${user.firstName} ${user.lastName}`);
      buildedUrl = buildPrefill(buildedUrl, TABLE_DATA.email, user.email);
    }

    if (currentProject) {
      buildedUrl = buildPrefill(buildedUrl, TABLE_DATA.projectName, currentProject.name);
      buildedUrl = buildPrefill(buildedUrl, TABLE_DATA.projectUuid, currentProject.uuid);
    }

    if (prefillDate) {
      buildedUrl = buildPrefill(buildedUrl, TABLE_DATA.date, prefillDate);
    }

    return buildedUrl;
  };

  return (
    <>
      <SkwrDialog
        open={isOpen}
        onClose={() => onClose()}
        title={dialogTitle}
      >
        <iframe
          src={getUrl()}
          frameBorder="0"
          width="100%"
          height="533"
          style={{
            background: 'transparent',
          }}
        />
      </SkwrDialog>
    </>
  );
}

export default connect((state) => ({
  user: getCurrentUser(state),
  currentProject: getCurrentProject(state),
}), (dispatch) => ({
}))(ContactSupportDialog);
