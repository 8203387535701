import React, { useEffect, useState } from 'react';
import { withStyles } from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';
import { ColorByUsage, Font, FontSizes } from './UIConstants';
import { IS_SAFARI } from './UIConstants';

const styles = theme => ({
  tooltipEllipsis: {
    backgroundColor: ColorByUsage.tooltipEllipsisBackground,
    color: ColorByUsage.tooltipEllipsisForeground,
    borderColor: ColorByUsage.tooltipEllipsisForeground,
    fontSize: FontSizes.textMedium,
    maxWidth: 'none',
    fontFamily: Font.mainFont,
  },
  tooltipStandard: {
    backgroundColor: ColorByUsage.tooltipGuideBackground,
    color: ColorByUsage.tooltipGuideForeground,
    fontSize: FontSizes.textMedium,
    fontFamily: Font.mainFont,
    maxWidth: 'none',
  },
  arrow: {
    color: ColorByUsage.tooltipGuideBackground,
  },
});

const isTextOverflow = (element) => {
  return element.clientWidth < element.scrollWidth;
}

const SkwrTooltip = ({
  classes,
  children,
  title,
  ellipsisref,
  disabled = false,
  ...props
}) => {
  const [ellipsistitle, setEllipsistitle] = useState('');

  useEffect(
    () => {
      checkOverflow();
    },
  );

  const checkOverflow = () => {
    // Don't check overflow if the tooltip is not an "overflow" one
    if (ellipsisref === undefined) {
      return;
    }

    // Safari already has an overflow tooltip -> don't use ours!
    if (IS_SAFARI) {
      return;
    }

    const newEllispsisTitle = getEllipsisTitle();
    if (newEllispsisTitle.localeCompare(ellipsistitle) !== 0) {
      setEllipsistitle(newEllispsisTitle );
    }
  };

  const getEllipsisTitle = () => {
    let ellipsisTitle = '';
    if ((ellipsisref !== undefined)
     && (ellipsisref.current !== null)) {
      const isoverflow = isTextOverflow(ellipsisref.current);

      if (isoverflow) {
        ellipsisTitle = ellipsisref.current.textContent;
      }
    }
    return ellipsisTitle;
  };

  if ((ellipsisref !== undefined) && (!disabled) && (ellipsistitle !== '')) {
    const delay = 500;
    return (
      <Tooltip
        title={ellipsistitle}
        classes={{ tooltip: classes.tooltipEllipsis }}
        enterDelay={delay}
      >
        {children}
      </Tooltip>
    );
  }
  if ((title) && (!disabled)) {
    return (
      <Tooltip
        title={title}
        arrow
        classes={{ tooltip: classes.tooltipStandard , arrow: classes.arrow }}
        {...props}
      >
        {children}
      </Tooltip>
    );
  }
  return (
    <React.Fragment>
      {children}
    </React.Fragment>
  );
};

export default withStyles(styles)(SkwrTooltip);
