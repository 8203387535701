import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Color, Orientations } from './UIConstants';

const useStyles = makeStyles(theme => ({
  divider: {
    borderLeft: `1px solid ${Color.greyAlt}`,
    height: '25px',
    width: '0px',
  },
  dividerHorizontal: {
    borderTop: `1px solid ${Color.greyAlt}`,
    height: '0px',
    width: '25px',
  },
}));

const SkwrToolBarDivider = ({
  orientation,
  toolbarorientation,
}) => {
  const classes = useStyles();

  const getClass = () => {
    if ((orientation === Orientations.HORIZONTAL)
     || (toolbarorientation === Orientations.VERTICAL)) {
      return classes.dividerHorizontal
    }
    return classes.divider
  };

  return (
    <div className={getClass()} />
  );
};

export default SkwrToolBarDivider;
