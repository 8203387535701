import React from 'react';
import classNames from 'classnames';
import { withStyles } from '@material-ui/core/styles';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';

const styles = theme => ({
  menuItem: {
    flex: 'none',
    margin: '0 0 24px', // XXX should not be set in skewerui but in the app
  },
});

const SkwrSelectLabelled = (props) => {
  const {
    id,
    label,
    value,
    onChange,
    classes,
    children,
  } = props;

  return (
    <FormControl classes={{ root: classes.menuItem }}>
      <InputLabel id={`${id}-label`}>
        {label}
      </InputLabel>
      <Select
        id={`${id}-select`}
        value={value}
        onChange={onChange}
        // Following prop is to solve infinite loop issue
        // range error - maximum call stack size exceeded
        // https://github.com/mui-org/material-ui/issues/10341
        MenuProps={{ disablePortal: true }}
      >
        {children}
      </Select>
    </FormControl>
  );
};

export default withStyles(styles)(SkwrSelectLabelled);
