export function undo() {
  return { type: 'UNDO_HISTORY@STARTUNDO' };
}

export function undoDone(success, paramsToPatch) {
  return {
    type: 'UNDO_HISTORY@UNDODONE',
    success,
    paramsToPatch,
  };
}

export function redo() {
  return { type: 'UNDO_HISTORY@STARTREDO' };
}

export function redoDone(success, undoargs, paramsToPatch) {
  return {
    type: 'UNDO_HISTORY@REDODONE',
    success,
    undoargs,
    paramsToPatch,
  };
}

export function addUndoRedoItem(action, beforeState, afterState, undoargs, redoargs) {
  return {
    type: 'UNDO_HISTORY@ADD',
    payload: {
      action,
      beforeState,
      afterState,
      undoargs,
      redoargs,
    },
  };
}

export function clear() {
  return {
    type: 'UNDO_HISTORY@CLEAR',
  };
}
