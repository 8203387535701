import React from 'react';
import classNames from 'classnames';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Hidden from '@material-ui/core/Hidden';
import { ColorByUsage, Color, FontSizes } from './UIConstants';

const styles = theme => ({
  largeButton: {
    padding: '12px 20px 10px',
  },
  commonButtonStyles: {
    display: 'flex',
    borderRadius: '5px',
    boxShadow: 'none',
    textTransform: 'uppercase',
    textAlign: 'center',
    fontSize: FontSizes.textMedium,
    justifyContent: 'space-around',
  },
  drawerClose: {
    position: 'absolute',
  },
  drawerOpen: {
    position: 'absolute',
  },
  Button: {
    borderColor: ColorByUsage.buttonPrimaryBackground,
    backgroundColor: ColorByUsage.buttonPrimaryBackground,
    color: ColorByUsage.buttonPrimaryForeground,
    '&:hover:enabled': {
      backgroundColor: ColorByUsage.buttonPrimaryBackground,
      opacity: 0.8,
    },
  },
  ButtonOutlined: {
    borderColor: ColorByUsage.buttonSecondaryForeground,
    backgroundColor: ColorByUsage.buttonSecondaryBackground,
    color: ColorByUsage.buttonSecondaryForeground,
    '&:hover:enabled': {
      backgroundColor: ColorByUsage.buttonSecondaryBackground,
      opacity: 0.8,
    },
  },
  ButtonMajor: {
    backgroundColor: ColorByUsage.buttonMajorBackground,
    border: ColorByUsage.buttonMajorBackground,
    color: ColorByUsage.buttonMajorForeground,
    '&:hover:enabled': {
      backgroundColor: ColorByUsage.buttonMajorBackground,
      opacity: 0.8,
    },
  },
  Button_icon: {
    alignItems: 'center',
    width: 'auto',
    paddingLeft: '10px',
    display: 'flex',
    height: '15px',
    '& svg': {
      fill: Color.white,
    },
  },
  Button_icon_sm: {
    padding: 0,
  },
  Button_icon_outlined: {
    width: '15px',
    '& svg': {
      stroke: ColorByUsage.buttonSecondaryForeground,
      '& path': {
        fill: ColorByUsage.buttonSecondaryForeground,
        opacity: 1,
      },
    },
  },
});

const SkwrButton = ({
  children,
  className,
  iconfile,
  svg,
  classes,
  componentStyle,
  btnSize = 'large',
  // Remove staticContext from the props to avoid a runtime error
  staticContext,
  SVGIcon,
  ...props
}) => {
  let realComponentStyle = componentStyle;
  let realVariant = 'contained';
  if (realComponentStyle === undefined) {
    realComponentStyle = 'Button';
  } else if (realComponentStyle === 'ButtonOutlined') {
    realVariant = 'outlined';
  }

  let displayIcon = false;
  if (((typeof iconfile !== 'undefined')
    && (iconfile !== null))
   || ((typeof svg !== 'undefined')
   && (svg !== null))) {
    displayIcon = true;
  }

  let btnClassName = classNames(className, classes[realComponentStyle], classes.commonButtonStyles);
  if (btnSize === 'large') {
    btnClassName = classNames(className, classes[realComponentStyle], classes.commonButtonStyles, classes.largeButton);
  }

  return (
    <Button
      variant={realVariant}
      type="button"
      raised="false"
      className={btnClassName}
      {...props}
    >
      {displayIcon
        ? (
          <React.Fragment>
            <Hidden lgUp>
              {
                componentStyle === 'ButtonOutlined'
                  ? (
                    <div
                      className={classNames(
                        classes.Button_icon,
                        classes.Button_icon_sm,
                        classes.Button_icon_outlined,
                      )}
                    >
                      <SVGIcon />
                    </div>
                  ) : (
                    <div
                      className={classNames(
                        classes.Button_icon,
                        classes.Button_icon_sm,
                      )}
                    >
                      <SVGIcon />
                    </div>
                  )
              }
            </Hidden>
            <Hidden mdDown>
              {children}
              {componentStyle === 'ButtonOutlined'
                ? (
                  <div
                    className={classNames(
                      classes.Button_icon,
                      classes.Button_icon_outlined,
                    )}
                  >
                    <SVGIcon />
                  </div>
                ) : (
                  <div
                    className={classNames(
                      classes.Button_icon,
                    )}
                  >
                    <SVGIcon />
                  </div>
                )}

            </Hidden>
          </React.Fragment>
        ) : (
          <React.Fragment>
            { children }
          </React.Fragment>
        )}
    </Button>
  );
};

export default withStyles(styles)(SkwrButton);
