import React from 'react';
import Box from '@material-ui/core/Box';

const SkwrButtonBar = ({
    children,
    className,
    ...props
  }) => {

  return (
    <div
      className={className}
      style={{
        alignItems: 'center',
        display: 'flex',
        justifyContent: 'flex-end',
      }}
      {...props}
    >
      {React.Children.map(children, (child, i) => {
        let rightMargin = 0;
        if (i < (children.length - 1)) {
          rightMargin = '10px'
        }
        return <Box mr={rightMargin}>{child}</Box>
      })}
    </div>
  );
};

export default SkwrButtonBar;
