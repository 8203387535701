/**
 * File : SkwrClickableDivider.jsx
 * @file : Divider with a clickable title
 */
import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import classNames from 'classnames';
import { Color } from './UIConstants';
import SkwrTooltip from './SkwrTooltip';

const styles = {
  dividerContainer: {
    display: 'flex',
    alignItems: 'center',
    flex: '0',
    width: '100%',
  },
  dividerTitle: {
    padding: '0 10px 0 10px',
    whiteSpace: 'nowrap',
    flex: '0',
    border: 'none',
    outline: 'none',
    backgroundColor: Color.white,
  },
  dividerTitleClickable: {
    cursor: 'pointer',
  },
  dividerIcon: {
    padding: '0px 10px 0px 10px',
  },
  dividerBorder: {
    borderBottom: `1px solid ${Color.grey}`,
    flex: '1',
  },
};

function SkwrClickableDivider({
  className,
  classes,
  onClick,
  displayOpenIcon = true,
  open = true,
  enable = true,
  title,
  tooltip,
}) {
  function handleActionOnDivider() {
    if (enable
     && (typeof onClick !== 'undefined')
     && (onClick !== null)) {
      onClick();
    }
  }

  function handleClick(e) {
    handleActionOnDivider();
  }

  function handleDoubleClick(e) {
    handleActionOnDivider();
  }

  let dividerIconText = '+';
  if (open) {
    dividerIconText = '-';
  }
  return (
    <div
      className={classNames(className, classes.dividerContainer)}
      onDoubleClick={handleDoubleClick}
    >
      <SkwrTooltip
        title={tooltip}
      >
        <button
          className={classNames(
            classes.dividerTitle,
            enable ? classes.dividerTitleClickable : null,
          )}
          onClick={handleClick}
        >
          {title}
        </button>
      </SkwrTooltip>
      {displayOpenIcon && (
        <IconButton
          className={classes.dividerIcon}
          onClick={handleClick}
          aria-label="openclose"
        >
          {dividerIconText}
        </IconButton>
      )}
      <div
        className={classNames(classes.dividerBorder)}
      />
    </div>
  );
}

export default withStyles(styles)(SkwrClickableDivider);
