import { defineMessages } from 'react-intl';

// Text content
const formValidatorsMessages = defineMessages({
  passwordContentRules: {
    id: 'formValidatorsMessages.passwordContent',
    defaultMessage: 'The password must contain at least 8 characters, lower and uppercase, a digit and a special character.',
  },
  passwordsDoNotMatch: {
    id: 'formValidatorsMessages.passwordsDoNotMatch',
    defaultMessage: 'Passwords do not match.',
  },
  wrongEmailFormat: {
    id: 'formValidatorsMessages.wrongEmailFormat',
    defaultMessage: 'Invalid email. Use the format "name@domain.com"',
  },
});

/**
 * @constant Name of the Field containing the new password
 */
export const NEWPASSWORDFIELD = 'password';
/**
 * @constant Name of the Field containing the confirmed password
 */
export const CONFIRMPASSWORDFIELD = 'passwordconfirm';

/**
 * Validate double password fields
 * @summary
 * The PasswordField fields from the validated form
 * HAVE to be named using NEWPASSWORDFIELD and CONFIRMPASSWORDFIELD constants
 * @param {*} newpassword newly entered password string
 * @param {*} confirmpassword confirmed password string
 * @returns if validated : an empty object ,
 * if not validated : an object containing :
 *   - the ID 'messageid' of the error message
 *   - the Field 'field' where the error occurs
 */
export const validateNewPassword = (newpassword, confirmpassword) => {
  const validationerror = {};
  if (newpassword) {
    if (newpassword.length < 8) {
      validationerror.field = NEWPASSWORDFIELD;
      validationerror.messageid = formValidatorsMessages.passwordContentRules;
    } else if (!/(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*\W)/.test(newpassword)) {
      validationerror.field = NEWPASSWORDFIELD;
      validationerror.messageid = formValidatorsMessages.passwordContentRules;
    } else if (!confirmpassword || (confirmpassword !== newpassword)) {
      validationerror.field = CONFIRMPASSWORDFIELD;
      validationerror.messageid = formValidatorsMessages.passwordsDoNotMatch;
    }
  } else if (confirmpassword) {
    validationerror.field = CONFIRMPASSWORDFIELD;
    validationerror.messageid = formValidatorsMessages.passwordsDoNotMatch;
  }
  return validationerror;
};


// Don't recreate the regex at each validation as it freezes the browser
// const validemailregex = new RegExp('^\\w+([.-]?\\w+)+@\\w+([.:]?\\w+)+(\\.[a-zA-Z0-9]{2,3})+$');
// use a simplier regexp, because even if created once, the previous one freezes the browser
// if checked while the user types the email
const validemailregex = new RegExp('.+\\@.+\\..+');

export const validateMailFormat = (email) => {
  let errorMessage;

  // Validate the email format
  if (email) {
    if (!validemailregex.test(email)) {
      errorMessage = formValidatorsMessages.wrongEmailFormat;
    }
  }
  return errorMessage;
};
