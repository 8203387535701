import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';

const styles = theme => ({
  expPanRoot: {
    border: '1px solid rgba(0, 0, 0, .125)',
    boxShadow: 'none',
    display: 'flex',
    flexDirection: 'column',
    '&.Mui-expanded': {
      margin: '0px',
    },
    '&:before': {
      display: 'none',
    },
  },
  expPanExpanded: {

  },
  expPanSummaryRoot: {
    backgroundColor: 'rgba(0, 0, 0, 0.03)',
    borderBottom: '1px solid rgba(0, 0, 0, .125)',
    marginBottom: -1,
    minHeight: 56,
  },
  expPanSummaryContent: {
  },
  expPanSummaryExpanded: {
  },
  expPanDetailsRoot: {
    padding: '5px',
  },
  expPanContent: {
    display: 'flex',
    flexDirection: 'column',
    paddingLeft: '10px',
    width: '100%',
  },
});

const SkwrExpansionPanel = ({
  className,
  classes,
  children,
  title,
  expanded,
  onChange,
  displayIcon = true,
}) => {
  let icon;
  if (displayIcon) {
    if (expanded) {
      icon = '-';
    } else {
      icon = '+';
    }
  }

  return (
    <Accordion
      classes={{
        root: `${classes.expPanRoot} ${className}`,
        expanded: classes.expPanExpanded,
        overrides: classes.expPanRootOverrides,
      }}
      square
      onChange={onChange}
      expanded={expanded}
    >
      <AccordionSummary
        classes={{
          root: classes.expPanSummaryRoot,
          content: classes.expPanSummaryContent,
          expanded: classes.expPanSummaryExpanded,
        }}
        expandIcon={icon}
      >
        {title}
      </AccordionSummary>
      <AccordionDetails
        classes={{ root: classes.expPanDetailsRoot }}
      >
        <div className={classes.expPanContent}>
          {children}
        </div>
      </AccordionDetails>
    </Accordion>
  );
};
export default withStyles(styles)(SkwrExpansionPanel);
