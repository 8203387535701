import React from 'react';
import classNames from 'classnames';
import { makeStyles } from '@material-ui/core/styles';
import {
  ColorByUsage,
  Shadows,
  Orientations,
  ToolBarTypes,
} from './UIConstants';

const useStyles = makeStyles(theme => ({
  toolBar: {
    backgroundColor: ColorByUsage.toolbarBackground,
    borderStyle: 'solid',
    borderColor: ColorByUsage.toolbarForeground,
    boxShadow: Shadows.shadowBlurLight,
  },
  toolBarSquared: {
    borderRadius: '8px',
  },
  toolBarRounded: {
    borderRadius: '30px',
  },
  toolBarVertical: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  toolBarHorizontal: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
  },
}));

const SkwrToolBar = ({
  children,
  className,
  orientation = Orientations.HORIZONTAL,
  toolbartype = ToolBarTypes.ROUNDED,
}) => {
  const classes = useStyles();

  const getClass = () => {
   return classNames(
      className,
      classes.toolBar,
      (toolbartype === ToolBarTypes.SQUARED) ? classes.toolBarSquared : classes.toolBarRounded,
      (orientation === Orientations.HORIZONTAL) ? classes.toolBarHorizontal : classes.toolBarVertical,
    );
  };

  const childrenWithProps = React.Children.map(children, child => {
    if (React.isValidElement(child)) {
      return React.cloneElement(
        child,
        {
          toolbarorientation: orientation,
          toolbartype,
          size: (toolbartype === ToolBarTypes.SQUARED) ? 'small' : 'medium',
        },
      );
    }
    return child;
  });

  return (
    <div className={getClass()}>
      {childrenWithProps}
    </div>
  );
};

export default SkwrToolBar;
