import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { ColorByUsage } from '../UIConstants';

const useStyles = makeStyles(theme => ({
  HeaderFormTitle: {
    color: ColorByUsage.formTitle,
    fontSize: '45px',
    marginBottom: '10px',
  },
  HeaderFormSubtitle: {
    color: ColorByUsage.formSubTitle,
    fontSize: '19px',
    marginBottom: '48px',
  },
}));


const HeaderForm = ({
  title,
  subtitle,
}) => {
  const classes = useStyles();

  return (
    <div>
      <p className={classes.HeaderFormTitle}>
        {title}
      </p>
      { subtitle
        && (
          <p className={classes.HeaderFormSubtitle}>
            {subtitle}
          </p>
        )
      }
    </div>
  );
};

export default HeaderForm;
