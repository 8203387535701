import axios from 'axios';
import CONSTANTS from '../Constants';
import authHeader from './auth-header';
import rejectUnfilteredError from './helperTools';
import { getLinkIRI } from '../tools/IRITools';

/**
 * Retrieves the links of a given project
 * @param {string} projectUuid uuid of the project
 */
export const getLinks = (projectUuid) => {
  return new Promise((resolve, reject) => {
    axios.get(`${CONSTANTS.API.PROJECTS_URI}/links?project.uuid=${projectUuid}`, {
      headers: authHeader(),
    })
      .then((response) => {
        const nodesData = {
          nodes: response.data['hydra:member'],
        };

        resolve(nodesData);
      }).catch((error) => {
        rejectUnfilteredError('getLinks', error, reject);
      });
  });
};

/**
 * create a new Link
 * @param {object} linkData object needed to create the Node
 */
export const createLink = (linkData) => {
  return new Promise((resolve, reject) => {
    axios.post(`${CONSTANTS.API.PROJECTS_URI}/links`, linkData, {
      headers: authHeader(),
    }).then((response) => {
      resolve(response.data);
    }).catch((error) => {
      rejectUnfilteredError('createLink', error, reject);
    });
  });
};

/**
 * delete a Link
 * @param linkID ID of the Link to delete
 */
export const deleteLink = (link) => {
  return new Promise((resolve, reject) => {
    axios.delete(`${CONSTANTS.API.PROJECTS_URI}${getLinkIRI(link)}`, {
      headers: authHeader(),
    }).then((response) => {
      resolve(response.data);
    }).catch((error) => {
      if (error.response) {
        if (error.response.status === 404) {
          // Delete action -> don't worry if the object to delete was not found !
          console.error('Unable to delete Link : ', error);
          resolve();
          return;
        }
      }

      rejectUnfilteredError('deleteLink', error, reject);
    });
  });
};

/**
 * modify a Link
 * @param linkID ID of the Link to delete
 */
export const modifyLink = (linkToModify) => {
  return new Promise((resolve, reject) => {
    axios.put(`${CONSTANTS.API.PROJECTS_URI}/links/${linkToModify.id}`,
      linkToModify,
      {
        headers: authHeader(),
      }).then((response) => {
      resolve(response.data);
    }).catch((error) => {
      rejectUnfilteredError('modifyLink', error, reject);
    });
  });
};
