/**
 * File: pendingRequestsMiddleware.js
 */
import ACTIONS from './pendingRequestsStateConstants';

export default function pendingRequestsMiddleware({ dispatch, getState }) {
  return next => (action) => {
    switch (action.type) {
      case ACTIONS.ADD_GET_REQUEST:
      case ACTIONS.REMOVE_GET_REQUEST:
        break;
      case ACTIONS.ADD_POST_REQUEST:
      case ACTIONS.REMOVE_POST_REQUEST:
        break;
      default: {
        if (action.type.includes('API_POST_REQUEST')) {
          dispatch({
            type: ACTIONS.ADD_POST_REQUEST,
          });
        } else if (action.type.includes('API_POST_SUCCESS')) {
          dispatch({
            type: ACTIONS.REMOVE_POST_REQUEST,
          });
        } else if (action.type.includes('API_POST_FAILURE')) {
          dispatch({
            type: ACTIONS.REMOVE_POST_REQUEST,
          });
        } else if (action.type.includes('API_GET_REQUEST')) {
          dispatch({
            type: ACTIONS.ADD_GET_REQUEST,
          });
        } else if (action.type.includes('API_GET_SUCCESS')) {
          dispatch({
            type: ACTIONS.REMOVE_GET_REQUEST,
          });
        } else if (action.type.includes('API_GET_FAILURE')) {
          dispatch({
            type: ACTIONS.REMOVE_GET_REQUEST,
          });
        }

        break;
      }
    }

    // Call the next dispatch method in the middleware chain.
    const returnValue = next(action);
    // This will likely be the action itself, unless
    // a middleware further in chain changed it.
    return returnValue;
  };
}
