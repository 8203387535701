/**
 * File : cssTools
 * 
 * CSS Tools
 * 
 */

/**
 * Retrieves next character
 * @param {string} c character to 'increment'
 */
export const getThinScrollBarCss = () => {
  return ({
    scrollbarWidth: 'thin',
    '&::-webkit-scrollbar': {
      width: '6px',
    },
    '&::-webkit-scrollbar-track': {
      background: 'transparent',
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: 'rgba(155, 155, 155, 0.5)',
      borderRadius: '20px',
      border: 'transparent',
    },
  });
};// getThinScrollBarProperties
