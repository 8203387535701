import ACTIONS from './projectsStateConstants';
import CONSTANTS from '../Constants';

/**
 * PROJECTS OBJECT SELECTORS
 */
export const getProjectTitle = currentProject => currentProject?.name;

/**
 * PROJECTS STATE SELECTORS
 */

/**
 * Get the list of projects
 * @param {*} state The State
 */
export const getProjects = state => state.projects.items;

/**
 * Get the total number of projects for current user in the database
 * @param {*} state The State
 */
export const getTotalProjectsNumber = state => state.projects.nbItemsTotal;

/**
 * Get the current project
 * @param {*} state The State
 */
export const getCurrentProject = state => state.projects.currentProject;

/**
 * Get the status of Projects state
 * @param {*} state The State
 */
export const getProjectsStatus = state => state.projects.status;

/**
 * Indicates if a loading request is currenly pending
 * @param {*} state The State
 */
export const getProjectsLoadingStatus = (state) => {
  return ((state.projects.status === ACTIONS.GET_PROJECTS_REQUEST)
       || (state.projects.status === ACTIONS.GET_PROJECT_REQUEST));
};

/**
 * Get the UUID of last project managed by Projects state
 * @param {*} state The State
 */
export const getLastProjectUUID = state => state.projects.lastProjectUuid;

/**
 * Get the error of Projects state
 * @param {*} state The State
 */
export const getProjectsError = state => state.projects.error;

/**
 * Get the GLOBAL error of requests on CURRENT project
 * @param {*} state The State
 */
export const getCurrentProjectError = (state) => {
  let errorKey = null;
  if ((state.projects.error !== null) && (typeof state.projects.error !== 'undefined')) {
    errorKey = state.projects.error;
  } else if ((state.nodes.error !== null) && (typeof state.nodes.error !== 'undefined')) {
    errorKey = state.nodes.error;
  } else if ((state.dataKeys.error !== null) && (typeof state.dataKeys.error !== 'undefined')) {
    errorKey = state.dataKeys.error;
  } else if ((state.labels.error !== null) && (typeof state.labels.error !== 'undefined')) {
    errorKey = state.labels.error;
  } else if ((state.previews.error !== null) && (typeof state.previews.error !== 'undefined')) {
    errorKey = state.previews.error;
  } else if ((state.mediaLibrary.error !== null) && (typeof state.mediaLibrary.error !== 'undefined')) {
    errorKey = state.mediaLibrary.error;
  } else if ((state.exports.error !== null) && (typeof state.exports.error !== 'undefined')) {
    errorKey = state.exports.error;
  }
  return errorKey;
};

/**
 * Get the summary of current project
 * @param {*} state The State
 */
export const getCurrentProjectSummary = state => state.projects.projectSummary;

/**
 * Get the thumbnail url pattern of current project
 * @param {*} state The State
 */
export const getCurrentProjectAssetThumbnailPattern = (state) => {
  let pattern = null;
  if ((typeof state.projects.currentProject !== 'undefined')
   && (state.projects.currentProject !== null)
   && (typeof state.projects.currentProject.projectSettings !== 'undefined')
   && (state.projects.currentProject.projectSettings !== null)) {
    pattern = state.projects.currentProject.projectSettings.assetThumbnailsUrlPattern;
  }
  return pattern;
};

/**
 * Get the thumbnail url pattern of current project
 * @param {*} state The State
 */
export const getCurrentProjectAssetPreviewPattern = (state) => {
  let pattern = null;
  if ((typeof state.projects.currentProject !== 'undefined')
   && (state.projects.currentProject !== null)
   && (typeof state.projects.currentProject.projectSettings !== 'undefined')
   && (state.projects.currentProject.projectSettings !== null)) {
    pattern = state.projects.currentProject.projectSettings.assetPreviewUrlPattern;
  }
  return pattern;
};

/**
 * Indicates if the given uuid is the one of the current project
 * @param {*} state
 * @param {*} projectUuid
 */
export const isCurrentProject = (state, projectUuid) => {
  if ((typeof state.projects.currentProject !== 'undefined')
   && (state.projects.currentProject !== null)
   && (typeof projectUuid !== 'undefined')
   && (projectUuid !== null)
   && (state.projects.currentProject.uuid === projectUuid)) {
    return true;
  }
  return false;
};

/**
 * Return the validity state of the project
 * @param {*} state
 */
export const isProjectValid = (state) => {
  if (state.projects.projectValidity.isValid === CONSTANTS.PROJECT_VALIDITY.VALID) {
    return true;
  }
  return false;
};
export const isProjectInvalid = (state) => {
  if (state.projects.projectValidity.isValid === CONSTANTS.PROJECT_VALIDITY.INVALID) {
    return true;
  }
  return false;
};
