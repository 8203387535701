import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Autocomplete, {
  createFilterOptions
} from "@material-ui/lab/Autocomplete";
import Popper from "@material-ui/core/Popper";
import { FontSizes } from './UIConstants';
import { getThinScrollBarCss } from './cssTools';

const useStyles = makeStyles({
  listBox: {
    overflowX: "hidden",
  },
  listBoxScrolls: getThinScrollBarCss(),
  font: ({ font }) => ({
    fontSize: FontSizes.textMedium,
    ...font
  }),
  input: ({input}) => ({
    ...input,
  })
});

// Used to avoid word wrap on option items
const PopperMy = function (props) {
  return <Popper {...props} style={{maxWidth: "fit-content"}} placement="bottom-start" />;
};

const filter = createFilterOptions();

const SkwrAutocomplete = (props) => {
  const {
    id,
    label,
    value,
    className,
    customJSS,
    options,
    getOptionLabel,
    onChange,
    onInputChange,
    noOptionsText,
    allowValueOutsideOptions = false,
    getNoOptionsOption,
    renderOption,
    onBlur,
    disableUnderline = false,
  } = props;

  const [inputCurrentValue, setInputCurrentValue] = useState(value);

  const classes = useStyles(customJSS);

  const filterOptionsFunction = (options, params) => {
    const filtered = filter(options, params);

    if (params.inputValue !== "") {
      if ((typeof getNoOptionsOption !== 'undefined')
      && (getNoOptionsOption !== null)) {
        const noOptionsOption = getNoOptionsOption(options, params.inputValue);
        if (noOptionsOption) {
          filtered.push(noOptionsOption);
        }
      }
    }

    return filtered;
  };

  let filterOptions = filter;
  if (allowValueOutsideOptions
   && (typeof getNoOptionsOption !== 'undefined')
   && (getNoOptionsOption !== null)) {
    filterOptions = filterOptionsFunction;
  }

  const handleOnInputChange = (evt, newValue, reason) => {
    setInputCurrentValue(newValue);

    if ((typeof onInputChange !== 'undefined')
     && (onInputChange !== null)) {
     onInputChange(evt, newValue, reason);
    }
  };

  const handleOnBlur = (evt) => {
    if ((typeof onBlur !== 'undefined')
     && (onBlur !== null)) {
      if ((inputCurrentValue !== value)
       && (inputCurrentValue !== getOptionLabel(value))) {
        if (allowValueOutsideOptions) {
          onChange(evt, inputCurrentValue, 'blur');
        }
      }
      onBlur();
    }
  };

  const renderInput = (params) => {
    const inputProps = {...params.InputProps};
    if (disableUnderline) {
      inputProps.disableUnderline = true;
    }
    return (
      <TextField
        {...params}
        autoFocus
        onFocus={event => {
          const textToSet = getOptionLabel(value);
          if (textToSet !== '') {
            event.target.value = textToSet;
            event.target.setSelectionRange(0,
              event.target.value.length);
          }
        }}
        onBlur={handleOnBlur}
        InputProps={inputProps}
        label={label}
      />
    );
  };

  return (
    <Autocomplete
      id={`${id}-autocomplete`}
      className={className}
      value={value}
      options={options}
      getOptionLabel={getOptionLabel}
      renderInput={renderInput}
      onChange={onChange}
      onInputChange={handleOnInputChange}
      disableClearable
      clearOnEscape
      PopperComponent={PopperMy}
      noOptionsText={noOptionsText}
      freeSolo={allowValueOutsideOptions}
      clearOnBlur={true}
      selectOnFocus
      filterOptions={filterOptions}
      renderOption={renderOption}
      classes={{
        listbox: `${classes.listBox} ${classes.listBoxScrolls}`,
        input: `${classes.input} ${classes.font}`,
      }}
    />
  );
};

export default SkwrAutocomplete;
