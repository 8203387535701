import React from 'react';
import Tab from '@material-ui/core/Tab';
import { makeStyles } from '@material-ui/core/styles';
import { Font, ColorByUsage } from './UIConstants';
import CustomTab from './Tab/CustomTab';

const useStyles = makeStyles({
  textColorPrimary: {
    color: 'rgba(112, 112, 112, 0.4)',
  },
  selected: {
    color: `${ColorByUsage.tabSelected} !important`,
  },
  rootTab: {
    textTransform: 'none',
    fontFamily: Font.mainFont,
    '&:focus': {
      color: ColorByUsage.tabFocused,
    },
    minWidth: '80px',
  },
});

function SkwrTab({
  displayActivity = false,
  isActive = false,
  value,
  label,
  ...props
}) {
  const classes = useStyles();

  if (displayActivity) {
    return (
      <Tab
        component={CustomTab}
        customTabLoading={isActive}
        label={label}
        classes={{
          textColorPrimary: classes.textColorPrimary,
          selected: classes.selected,
          root: classes.rootTab,
        }}
        value={value}
        focusRipple={false} // to avoid crash when keyboard focused- need more investigation
        {...props}
      />
    );
  }
  return (
    <Tab
      label={label}
      classes={{
        textColorPrimary: classes.textColorPrimary,
        selected: classes.selected,
        root: classes.rootTab,
      }}
      value={value}
      focusRipple={false} // to avoid crash when keyboard focused- need more investigation
      {...props}
    />
  );
}

export default SkwrTab;
