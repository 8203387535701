import axios from 'axios';
import CONSTANTS from '../Constants';
import authHeader from './auth-header';
import rejectUnfilteredError from './helperTools';

/**
 * get (if exist) the external preview url of the project
 */
export const getExternalPreviewUrl = (projectUuid) => {
  return new Promise((resolve, reject) => {
    axios.get(`${CONSTANTS.API.PROJECTS_URI}/projects/${projectUuid}/extern_preview`, {
      headers: authHeader(),
    }).then((response) => {
      resolve({
        url: response.data.externPreviewUrl,
        uuid: response.data.uuid,
      });
    }).catch((error) => {
      rejectUnfilteredError('getEPUrl', error, reject);
    });
  });
};

/**
 * generate the external preview url of the project
 */
export const generateExternalPreviewUrl = (projectUuid) => {
  const parsedValues = {
    project: `projects/${projectUuid}`,
  };
  return new Promise((resolve, reject) => {
    axios.post(`${CONSTANTS.API.PROJECTS_URI}/extern_previews`, JSON.stringify(parsedValues), {
      headers: authHeader(),
    }).then((response) => {
      resolve({
        url: response.data.externPreviewUrl,
        uuid: response.data.uuid,
      });
    }).catch((error) => {
      rejectUnfilteredError('generateEPUrl', error, reject);
    });
  });
};

/**
 * update the external preview url of the project
 */
export const updateExternalPreviewUrl = (externalPreviewUuid) => {
  return new Promise((resolve, reject) => {
    axios.put(`${CONSTANTS.API.PROJECTS_URI}/extern_previews/${externalPreviewUuid}`, {}, {
      headers: authHeader(),
    }).then((response) => {
      resolve({
        url: response.data.externPreviewUrl,
        uuid: response.data.uuid,
      });
    }).catch((error) => {
      rejectUnfilteredError('updateEPUrl', error, reject);
    });
  });
};

/**
 * delete the external preview url of the project
 */
export const deleteExternalPreviewUrl = (externalPreviewUuid) => {
  return new Promise((resolve, reject) => {
    axios.delete(`${CONSTANTS.API.PROJECTS_URI}/extern_previews/${externalPreviewUuid}`, {
      headers: authHeader(),
    }).then((response) => {
      resolve();
    }).catch((error) => {
      rejectUnfilteredError('deleteEPUrl', error, reject);
    });
  });
};
