import React, { useRef, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Color } from './UIConstants';

const useStyles = makeStyles(theme => ({
  d1Style: {
    textOverflow: 'clip',
    flex: 'none',
  },
  glStyle: {
    backgroundColor: Color.yellow,
  },
  d2style: {
    flex: 'none',
  },
}));

export default function HighlightableLabel(props) {
  const {
    labelText,
    glowData,
    hllClass,
    display,
  } = props;

  useEffect(
    () => {
      setStyle();
    },
  );

  const StartIndexText = labelText.toLowerCase().indexOf(glowData.text.toLowerCase());
  const labelContainer = useRef(null);
  const d1 = useRef(null);
  const gl = useRef(null);
  const d2 = useRef(null);
  const classes = useStyles();

  const [p2Width, setP2Width] = React.useState('auto');
  const [labelJustify, setLabelJustify] = React.useState('flex-start');

  const getOpacity = () => {
    if (display === false) { return '0'; }
    return '1';
  };

  const setStyle = () => {
    if (labelContainer.current && d1.current && gl.current && d2.current) {
      if (labelContainer.current.clientWidth < d1.current.clientWidth + gl.current.clientWidth) {
        const newLabelJusitfy = 'space-evenly';
        if (labelJustify !== newLabelJusitfy) { setLabelJustify(newLabelJusitfy); }
        const newP2Width = d1.current.clientWidth;
        if (p2Width !== newP2Width) { setP2Width(newP2Width); }
      } else {
        const newLabelJusitfy = 'flex-start';
        if (labelJustify !== newLabelJusitfy) { setLabelJustify(newLabelJusitfy); }
        const newP2Width = 'auto';
        if (p2Width !== newP2Width) { setP2Width(newP2Width); }
      }
    }
  };

  const getPart1 = () => {
    return labelText.substring(0, StartIndexText);
  };

  const getGlow = () => {
    return labelText.substring(StartIndexText, StartIndexText + glowData.text.length);
  };

  const getPart2 = () => {
    return labelText.substring(StartIndexText + glowData.text.length);
  };

  return (
    <>
      {glowData.glowing ? (
        <label
          className={hllClass}
          ref={labelContainer}
          style={{
            display: 'flex',
            width: '100%',
            justifyContent: labelJustify,
            opacity: 1,
            overflow: 'hidden',
          }}
        >
          <div ref={d1} className={classes.d1Style}>{getPart1()}</div>
          <div ref={gl} className={classes.glStyle}>{getGlow()}</div>
          <div
            ref={d2}
            className={classes.d2style}
            style={{ width: p2Width }}
          >
            {getPart2()}
          </div>
        </label>
      ) : (
        <label className={hllClass} style={{ opacity: getOpacity() }}>
          { labelText }
        </label>
      )}
    </>
  );
}
