import React from 'react';
import classNames from 'classnames';
import Snackbar from '@material-ui/core/Snackbar';
import SnackbarContent from '@material-ui/core/SnackbarContent';
import { withStyles } from '@material-ui/core/styles';
import { ReactComponent as CloseSVG } from './assets/svg/close.svg';
import { ReactComponent as ErrorSVG } from './assets/svg/error.svg';
import { ColorByUsage, FontSizes } from './UIConstants';

const styles = theme => ({
  error: {
    backgroundColor: ColorByUsage.errorColor,
  },
  icon: {
    fontSize: FontSizes.textLarge,
    height: '24px',
    width: '24px',
    marginRight: '15px',
    '& svg': {
      fill: ColorByUsage.errorForeground,
    },
  },
  closeButton: {
    backgroundColor: 'transparent',
    border: 0,
    outline: 'none',
    cursor: 'pointer',
    '&:hover': {
      opacity: '0.75',
    },
  },
  closeIcon: {
    fontSize: FontSizes.textMicro,
    height: '12px',
    width: '12px',
    '& svg': {
      fill: ColorByUsage.errorForeground,
    },
  },
  message: {
    marginRight: '15px',
  },
  errorContent: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  margin: {
    margin: theme.spacing(1),
  },
});

const SkwrErrorSnackBar = ({
  onClose,
  classes,
  message,
  toggleError,
}) => {
  const handleOnClickClose = () => {
    if (onClose) {
      onClose();
    }
  };

  return (
    <Snackbar
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
      }}
      open={toggleError}
    >
      <SnackbarContent
        className={classNames(classes.error, classes.margin)}
        aria-describedby="client-snackbar"
        message={(
          <div className={classes.errorContent}>
            <span id="client-snackbar" className={classNames(classes.message, classes.errorContent)}>
              <div className={classes.icon}>
                <ErrorSVG />
              </div>
              {message}
            </span>
            <button
              className={classes.closeButton}
              onClick={handleOnClickClose}
            >
              <div className={classes.closeIcon}>
                <CloseSVG />
              </div>
            </button>
          </div>
        )}
      />
    </Snackbar>
  );
};

export default withStyles(styles)(SkwrErrorSnackBar);
