/**
 * File : ProjectsState.js
 */
import CONSTANTS from '../Constants';
import ACTIONS from './projectsStateConstants';

import { fakeUserProjectFeatureAccessRights } from '../tools/fakeFeatureAccessRights';

import {
  getProjectIRI,
} from '../tools/IRITools';

const initialState = {
  hasError: false,
  items: [
  ],
  nbItemsTotal: 0,
  nbProjectsPerPage: 0,
  currentProject: null,
  lastProjectUuid: null,
  projectSummary: {
    nbPersonna: 0,
    nbVersions: 0,
    needCompute: false,
  },
  projectValidity: {
    isValid: CONSTANTS.PROJECT_VALIDITY.UNKNOWN,
  },
};

export default (state = initialState, action) => {
  switch (action.type) {
    case ACTIONS.CLEAR_ERROR:
      return {
        ...state,
        error: null,
        status: null,
      };

    case ACTIONS.RESET_PROJECT_STATE:
      return initialState;

    case ACTIONS.RESET_CURRENT_PROJECT:
    {
      let newUuid = null;
      if ((typeof action.newCurrentProject !== 'undefined')
        && (action.newCurrentProject !== null)) {
        newUuid = action.newCurrentProject.uuid;
      }
      return {
        ...state,
        status: ACTIONS.RESET_CURRENT_PROJECT,
        currentProject: { ...action.newCurrentProject },
        lastProjectUuid: newUuid,
        projectSummary: { ...initialState.projectSummary },
        projectValidity: { ...initialState.projectValidity },
      };
    }
    case ACTIONS.GET_PROJECT_REQUEST:
      return {
        ...state,
        status: ACTIONS.GET_PROJECT_REQUEST,
        error: null,
      };

    case ACTIONS.GET_PROJECT_SUCCESS:
    {
      const gettedProject = action.project;
      // XXX FAKE - set the user right access on the project
      // XXX FAKE - should be done by BACK
      gettedProject.featuresRights = fakeUserProjectFeatureAccessRights;

      return {
        ...state,
        error: null,
        status: ACTIONS.GET_PROJECT_SUCCESS,
        currentProject: { ...gettedProject },
        lastProjectUuid: action.project.uuid,
      };
    }

    case ACTIONS.GET_PROJECT_FAILURE:
      return {
        ...state,
        status: ACTIONS.GET_PROJECT_FAILURE,
        error: action.error,
        currentProject: null,
        lastProjectUuid: null,
      };

    case ACTIONS.GET_PROJECTS_REQUEST:
      return {
        ...state,
        status: ACTIONS.GET_PROJECTS_REQUEST,
      };

    case ACTIONS.GET_PROJECTS_SUCCESS:
    {
      let newItems = state.items;
      let newNbProjectsPerPage = state.nbProjectsPerPage;

      if (action.page !== 1) {
        newItems = newItems.concat(action.items.projects);
      } else {
        newItems = action.items.projects;
      }

      // Calcul du nombre de projets par page
      if (newItems.length < action.items.totalProjects) {
        if (newNbProjectsPerPage === 0) {
          newNbProjectsPerPage = newItems.length;
        }
      }

      // XXX FAKE - set the user right access on each project
      // XXX FAKE - should be done by BACK
      for (let i = 0; (i < newItems.length) ; i += 1) {
        newItems[i].featuresRights = fakeUserProjectFeatureAccessRights;
      }

      return {
        ...state,
        error: null,
        status: ACTIONS.GET_PROJECTS_SUCCESS,
        items: newItems,
        nbItemsTotal: action.items.totalProjects,
        nbProjectsPerPage: newNbProjectsPerPage,
      };
    }

    case ACTIONS.GET_PROJECTS_FAILURE:
      return {
        ...state,
        status: ACTIONS.GET_PROJECTS_FAILURE,
        error: action.error,
        lastProjectUuid: null,
      };

    case ACTIONS.NEW_PROJECT_REQUEST:
      return {
        ...state,
        status: ACTIONS.NEW_PROJECT_REQUEST,
      };

    case ACTIONS.NEW_PROJECT_SUCCESS:
    {
      const newlyCreatedProject = action.payload;

      // XXX FAKE - set the user right access on the project
      // XXX FAKE - should be done by BACK
      newlyCreatedProject.featuresRights = fakeUserProjectFeatureAccessRights;

      // Prepend the newly created project to the current list of projects
      // Increment the number of total items
      const newItems = [...state.items];
      newItems.unshift(newlyCreatedProject);
      return {
        ...state,
        error: null,
        status: ACTIONS.NEW_PROJECT_SUCCESS,
        items: newItems,
        nbItemsTotal: state.nbItemsTotal + 1,
        lastProjectUuid: action.payload.uuid,
      };
    }
    case ACTIONS.NEW_PROJECT_FAILURE:
      return {
        ...state,
        error: action.error,
        status: ACTIONS.NEW_PROJECT_FAILURE,
        lastProjectUuid: null,
      };

    case ACTIONS.DELETE_PROJECT_REQUEST:
      return {
        ...state,
        status: ACTIONS.DELETE_PROJECT_REQUEST,
      };

    case ACTIONS.DELETE_PROJECT_SUCCESS:
    {
      // Remove the deleted project from the current list of projects
      // Decrement the number of total items
      const newItems = [...state.items];
      const found = newItems.findIndex(((element) => {
        return element.uuid === action.projectUUid;
      }));
      if (found !== -1) {
        newItems.splice(found, 1);
      }
      return {
        ...state,
        error: null,
        status: ACTIONS.DELETE_PROJECT_SUCCESS,
        items: newItems,
        nbItemsTotal: state.nbItemsTotal - 1,
      };
    }
    case ACTIONS.DELETE_PROJECT_FAILURE:
      return {
        ...state,
        error: action.error,
        status: ACTIONS.DELETE_PROJECT_FAILURE,
        lastProjectUuid: null,
      };

    case ACTIONS.MODIFY_PROJECT_NAME_REQUEST:
      return {
        ...state,
        status: ACTIONS.MODIFY_PROJECT_NAME_REQUEST,
      };

    case ACTIONS.MODIFY_PROJECT_NAME_SUCCESS:
    {
      // Clone current list and update the modified project
      const newItems = [...state.items];
      const found = newItems.findIndex(((element) => {
        return element.uuid === action.project.uuid;
      }));
      if (found !== -1) {
        const modifiedProject = {
          ...newItems[found],
          ...action.project,
        };
        newItems[found] = { ...modifiedProject };
      }

      return {
        ...state,
        items: newItems,
        error: null,
        status: ACTIONS.MODIFY_PROJECT_NAME_SUCCESS,
      };
    }

    case ACTIONS.MODIFY_PROJECT_NAME_FAILURE:
      return {
        ...state,
        status: ACTIONS.MODIFY_PROJECT_NAME_FAILURE,
        error: action.error,
        lastProjectUuid: null,
      };

    case ACTIONS.MODIFY_PROJECT_DESCRIPTION_REQUEST:
      return {
        ...state,
        status: ACTIONS.MODIFY_PROJECT_DESCRIPTION_REQUEST,
      };

    case ACTIONS.MODIFY_PROJECT_DESCRIPTION_SUCCESS:
    {
      // Clone current list and update the modified project
      const newItems = [...state.items];
      const found = newItems.findIndex(((element) => {
        return element.uuid === action.project.uuid;
      }));
      if (found !== -1) {
        const modifiedProject = {
          ...newItems[found],
          ...action.project,
        };
        newItems[found] = { ...modifiedProject };
      }

      return {
        ...state,
        items: newItems,
        error: null,
        status: ACTIONS.MODIFY_PROJECT_DESCRIPTION_SUCCESS,
      };
    }

    case ACTIONS.MODIFY_PROJECT_DESCRIPTION_FAILURE:
      return {
        ...state,
        status: ACTIONS.MODIFY_PROJECT_DESCRIPTION_FAILURE,
        error: action.error,
        lastProjectUuid: null,
      };

    case ACTIONS.MODIFY_PROJECT_THUMBNAIL_REQUEST:
    {
      return {
        ...state,
        status: ACTIONS.MODIFY_PROJECT_THUMBNAIL_REQUEST,
      };
    }
    case ACTIONS.MODIFY_PROJECT_THUMBNAIL_SUCCESS:
    {
      const newItems = [...state.items];

      if ((typeof action.projectThumbnail !== 'undefined')
       && (action.projectThumbnail !== null)) {
        const found = newItems.findIndex(((element) => {
          return getProjectIRI(element) === action.projectThumbnail.project;
        }));
        if (found !== -1) {
          const modifiedProject = {
            ...newItems[found],
            projectThumbnail: { ...action.projectThumbnail },
          };
          newItems[found] = { ...modifiedProject };
        }
      }
      return {
        ...state,
        items: newItems,
        error: null,
        status: ACTIONS.MODIFY_PROJECT_THUMBNAIL_SUCCESS,
      };
    }
    case ACTIONS.MODIFY_PROJECT_THUMBNAIL_FAILURE:
    {
      return {
        ...state,
        status: ACTIONS.MODIFY_PROJECT_THUMBNAIL_FAILURE,
        error: action.error,
        lastProjectUuid: null,
      };
    }

    case ACTIONS.MODIFY_PROJECT_SETTINGS_REQUEST:
      return {
        ...state,
        status: ACTIONS.MODIFY_PROJECT_SETTINGS_REQUEST,
      };

    case ACTIONS.MODIFY_PROJECT_SETTINGS_SUCCESS:
    {
      // set the new settings with the one givent to the modification request
      const project = { ...state.currentProject };
      if (project !== null) {
        project.projectSettings = action.projectSettings;
      }
      return {
        ...state,
        error: null,
        status: ACTIONS.MODIFY_PROJECT_SETTINGS_SUCCESS,
        currentProject: { ...project },
        lastProjectUuid: project.uuid,
      };
    }

    case ACTIONS.MODIFY_PROJECT_SETTINGS_FAILURE:
      return {
        ...state,
        status: ACTIONS.MODIFY_PROJECT_SETTINGS_FAILURE,
        error: action.error,
        lastProjectUuid: null,
      };

    case ACTIONS.GET_PROJECT_SUMMARY: {
      return {
        ...state,
        projectSummary: {
          ...action.summary,
          needCompute: false,
        },
      };
    }

    case ACTIONS.RESET_PROJECT_SUMMARY: {
      const projectSummary = { ...state.projectSummary };
      return {
        ...state,
        projectSummary: {
          ...projectSummary,
          needCompute: action.needCompute,
        },
      };
    }

    case ACTIONS.IS_PROJECT_VALID_REQUEST:
      return {
        ...state,
        status: ACTIONS.IS_PROJECT_VALID_REQUEST,
        projectValidity: {
          isValid: CONSTANTS.PROJECT_VALIDITY.UNKNOWN,
        },
      };
    case ACTIONS.IS_PROJECT_VALID_SUCCESS:
    {
      let isValid = CONSTANTS.PROJECT_VALIDITY.INVALID;
      if (action.valid) {
        isValid = CONSTANTS.PROJECT_VALIDITY.VALID;
      }
      return {
        ...state,
        status: ACTIONS.IS_PROJECT_VALID_SUCCESS,
        projectValidity: {
          isValid,
        },
      };
    }
    case ACTIONS.IS_PROJECT_VALID_FAILURE:
      return {
        ...state,
        status: ACTIONS.IS_PROJECT_VALID_FAILURE,
      };

    default:
      return state;
  }
};
