import React, { Component } from 'react';
import * as Router from 'react-router-dom';
import classNames from 'classnames';
import '../assets/scss/components/Link.scss';

class Link extends Component {
  render() {
    const { className, ...props } = this.props;
    return (
      <Router.Link className={classNames('Link', className)} {...props} />
    );
  }
}

export default Link;
