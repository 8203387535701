import React, { useEffect, useState } from 'react';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { withStyles } from '@material-ui/core';
import Switch from '@material-ui/core/Switch';
import { ColorByUsage } from './UIConstants';

const styles = theme => ({
  colorSwitchBase: {
    color: ColorByUsage.tabIndicator,
    '&$colorChecked': {
      color: ColorByUsage.tabIndicator,
      '& + $colorBar': {
        backgroundColor: ColorByUsage.tabIndicator,
      },
    },
  },
  colorBar: {},
  colorChecked: {},
});

const SkwrSwitchLabel = ({
  classes,
  label,
  defaultStatus,
  id,
  onChange,
}) => {
  const [isChecked, setIsChecked] = useState(false);

  useEffect(() => {
      setIsChecked(defaultStatus);
    },
    [defaultStatus]
  );

  const handleChange = (event, isChckd) => {
    setIsChecked( isChckd );

    if (onChange && id) {
      onChange(isChckd, id);
    }
  }

  return (
    <FormControlLabel
      className={classes.formControl}
      control={(
        <Switch
          checked={isChecked}
          onChange={handleChange}
          value="checkedG"
          classes={{
            switchBase: classes.colorSwitchBase,
            checked: classes.colorChecked,
            track: classes.colorBar,
          }}
        />
      )}
      label={label}
    />
  );
};

export default withStyles(styles)(SkwrSwitchLabel);
