import React, { useEffect, useState } from 'react';
import { injectIntl, defineMessages } from 'react-intl';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withStyles } from '@material-ui/core/styles';
import {
  SkwrButton,
  SkwrButtonWithProgress,
  SkwrDialog,
  SkwrFormError,
  SkwrInput,
  ColorByUsage,
} from 'skewerui';
import { useForm, Controller } from 'react-hook-form';
import * as AuthenticationActions from '../../reducers/authenticationState';
import { ReactComponent as ShapeSVG } from '../../assets/svg/shape.svg';
import { commonMessages } from '../../Constants';
import {
  getCurrentUser,
  getAuthenticationStatus,
  getAuthenticationError,
} from '../../reducers/authenticationSelector';
import AUTHENTICATIONACTIONS from '../../reducers/authenticationStateConstants';

// Text content
const messages = defineMessages({
  title: {
    id: 'userSettingsDialog.title',
    defaultMessage: 'User Settings',
  },

  name: {
    id: 'userSettingsDialog.name',
    defaultMessage: 'Name',
  },
  mail: {
    id: 'userSettingsDialog.mail',
    defaultMessage: 'Mail',
  },
  firstName: {
    id: 'userSettingsDialog.firstName',
    defaultMessage: 'First name',
  },
  lastName: {
    id: 'userSettingsDialog.lastName',
    defaultMessage: 'Last name',
  },
  'updateUserSettings.error.EmptyUserSettings': {
    id: 'updateUserSettings.error.EmptyUserSettings',
    defaultMessage: 'User mail and user name cannot be empty',
  },
  'updateUserSettings.error.AlreadyUsedEmail': {
    id: 'updateUserSettings.error.AlreadyUsedEmail',
    defaultMessage: 'This email is already used by another account',
  },
  'updateUserSettings.error.AlreadyUsedUser': {
    id: 'updateUserSettings.error.AlreadyUsedUser',
    defaultMessage: 'This user name is already used by another account',
  },
});

const styles = {
  userHeader: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
  },
  userIcon: {
    height: '48px',
    width: '48px',
    '& path': {
      fill: ColorByUsage.toolIconsForeground,
    },
  },
};

const UserSettingsDialog = ({
  intl: { formatMessage, messages: intlMessages },
  isOpenProps,
  status,
  authenticationActions,
  user,
  hide,
  apiError,
  classes,
}) => {
  // -------------
  // - state
  // -------------
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({ mode: 'onChange' });
  const [apiErrorMessage, setApiErrorMessage] = useState('');
  const waitingRequestAnswer =
    status === AUTHENTICATIONACTIONS.UPDATE_USERSETTINGS_REQUEST;
  const [prevStatus, setPrevStatus] = useState(null);

  // -------------
  // - useEffect
  // -------------
  // hide Dialog if form sucess
  useEffect(() => {
    if (
      prevStatus === AUTHENTICATIONACTIONS.UPDATE_USERSETTINGS_REQUEST &&
      status === AUTHENTICATIONACTIONS.UPDATE_USERSETTINGS_SUCCESS
    ) {
      setPrevStatus(null);
      hide();
    }
  }, [status, isOpenProps]);

  useEffect(() => {
    // reset errors when opening
    authenticationActions.resetError();
  }, [isOpenProps]);

  useEffect(() => {
    if (!apiError) {
      setApiErrorMessage('');
      return;
    }

    if (typeof messages[apiError] !== 'undefined') {
      setApiErrorMessage(formatMessage(messages[apiError]));
    } else {
      setApiErrorMessage(
        formatMessage(commonMessages.internalError, {
          internalErrorCode: apiError,
        })
      );
    }
  }, [apiError]);

  // -------------
  // - function
  // -------------
  function onSubmit(data) {
    // remove extra spaces
    const newUserSettings = {
      username: data.username.trim(),
      email: data.email.trim(),
      firstName: data.firstName.trim(),
      lastName: data.lastName.trim(),
    };

    // If nothing changed, do nothing
    if (
      newUserSettings.username === user.username &&
      newUserSettings.email === user.email &&
      newUserSettings.firstName === user.firstName &&
      newUserSettings.lastName === user.lastName
    ) {
      hide();
    } else {
      authenticationActions.updateUserSettings(newUserSettings);
      setPrevStatus(AUTHENTICATIONACTIONS.UPDATE_USERSETTINGS_REQUEST);
    }
  }

  return (
    <SkwrDialog
      open={isOpenProps}
      onClose={hide}
      title={formatMessage(messages.title)}
      actions={[
        <SkwrButton
          componentStyle="ButtonOutlined"
          onClick={hide}
          disabled={waitingRequestAnswer}
        >
          {formatMessage(commonMessages.cancelButton)}
        </SkwrButton>,
        <SkwrButtonWithProgress
          type="submit"
          form="UserSettings"
          inProgress={waitingRequestAnswer}
        >
          {formatMessage(commonMessages.saveButton)}
        </SkwrButtonWithProgress>,
      ]}
    >
      <div className={classes.userHeader}>
        <div className={classes.userIcon}>
          <ShapeSVG />
        </div>
      </div>
      <SkwrFormError errorMessage={apiErrorMessage} />

      <form id="UserSettings" onSubmit={handleSubmit(onSubmit)}>
        <Controller
          control={control}
          name="email"
          rules={{ required: intlMessages['signup.incompleteForm'] }}
          defaultValue={user?.email}
          render={({ field }) => (
            <SkwrInput
              type="email"
              name="email"
              placeholder={formatMessage(commonMessages.emailPlaceholder)}
              label={formatMessage(messages.mail)}
              errorMessage={errors.email?.message}
              inputRef={field.ref}
              onChange={field.onChange}
              onBlur={field.onBlur}
              value={field.value || ''}
            />
          )}
        />

        <Controller
          control={control}
          name="username"
          rules={{ required: intlMessages['signup.incompleteForm'] }}
          defaultValue={user?.username}
          render={({ field }) => (
            <SkwrInput
              type="text"
              name="username"
              placeholder={formatMessage(messages.name)}
              label={formatMessage(messages.name)}
              errorMessage={errors.username?.message}
              inputRef={field.ref}
              onChange={field.onChange}
              onBlur={field.onBlur}
              value={field.value || ''}
            />
          )}
        />

        <Controller
          control={control}
          name="firstName"
          defaultValue={user?.firstName}
          render={({ field }) => (
            <SkwrInput
              type="text"
              name="firstName"
              placeholder={formatMessage(messages.firstName)}
              label={formatMessage(messages.firstName)}
              errorMessage={errors.firstName?.message}
              inputRef={field.ref}
              onChange={field.onChange}
              onBlur={field.onBlur}
              value={field.value || ''}
            />
          )}
        />

        <Controller
          control={control}
          name="lastName"
          defaultValue={user?.lastName}
          render={({ field }) => (
            <SkwrInput
              type="text"
              name="lastName"
              placeholder={formatMessage(messages.lastName)}
              label={formatMessage(messages.lastName)}
              errorMessage={errors.lastName?.message}
              inputRef={field.ref}
              onChange={field.onChange}
              onBlur={field.onBlur}
              value={field.value || ''}
            />
          )}
        />
      </form>
    </SkwrDialog>
  );
};

export default connect(
  (state) => ({
    status: getAuthenticationStatus(state),
    user: getCurrentUser(state),
    apiError: getAuthenticationError(state),
  }),
  (dispatch) => ({
    authenticationActions: bindActionCreators(AuthenticationActions, dispatch),
  })
)(withStyles(styles)(injectIntl(UserSettingsDialog)));
