import CONSTANTS from '../Constants';

/**
 * Factorized code that reject errors we do not filter
 * @param {string} action action basename
 * @param {Error} error error to reject
 * @param {function} reject reject function
 */
export default function rejectUnfilteredError(action, error, reject) {
  if (error.response) {
    // Handle error 401 case -> JWT token expired
    if (error.response.status === 401) {
      console.error(error);
      reject(new Error(CONSTANTS.COMMONERRORS.NOT_LOGGED_IN));
    } else if (typeof error.response.data['@type'] == 'undefined') {
      reject(error);
    } else if (error.response.data['@type'] === 'AwsPerformOperationError') {
      if (typeof error.response.data['hydra:description'] !== 'undefined') {
        console.error(error.response.data['hydra:description']);
      }
      reject(error);
    } else if (error.response.data['@type'] === 'AwsInvalidAccessKeyIdError') {
      if (typeof error.response.data['hydra:description'] !== 'undefined') {
        console.error(error.response.data['hydra:description']);
      }
      reject(new Error(CONSTANTS.COMMONERRORS.AWS_INVALID_ACCESS_KEY));
    } else if (error.response.data['@type'] === 'hydra:Error') {
      if (typeof error.response.data['hydra:description'] === 'undefined') {
        reject(error);
      } else {
        console.error(error.response.data['hydra:description']);
        reject(new Error(error.response.data['hydra:description']));
      }
    } else {
      reject(new Error(`${action}.error.${error.response.data['@type']}`));
    }
    return;
  }
  reject(error);
}
