import * as Labels from '../helpers/labels';
import CONSTANTS from '../Constants';
import PROJECTSACTIONS from './projectsStateConstants';
import MEDIALIBRARYACTIONS from './mediaLibraryStateConstants';
import ACTIONS from './labelsStateConstants';
import { changeAssetsLabels } from './mediaLibraryActions';
import { getLabelByName } from './labelsSelectors';
import { isVideoAssets } from '../tools/AssetTools';
import {
  getLabelIRI,
  getAssetIRI,
  getProjectIRI,
  getVideoMediaTypeIRI,
  getAudioMediaTypeIRI,
  setAssetIRI,
  setLabelIRI,
} from '../tools/IRITools';
import { LABEL_TYPES } from '../tools/LabelTools';

const initialState = {
  error: null,
  status: null,
  labels: [],
  labelsIndexesMap: {},
};

export const LABELS_REVERT_ACTIONS = {
  [ACTIONS.UPDATE_LABEL_SUCCESS]: {
    undo: {
      command: (action, { label }) => updateLabel(label),
      createArgs: undoArgsForUpdateLabel,
      succeededWhen: [ACTIONS.UPDATE_LABEL_SUCCESS],
      failedWhen: [ACTIONS.UPDATE_LABEL_FAILURE],
    },
    redo: {
      command: (action, { label }) => updateLabel(label),
      createArgs: redoArgsForUpdateLabel,
      succeededWhen: [ACTIONS.UPDATE_LABEL_SUCCESS],
      failedWhen: [ACTIONS.UPDATE_LABEL_FAILURE],
    },
  },
};

export const resetError = () => {
  return {
    type: ACTIONS.CLEAR_ERROR,
  };
};

export const createNewLabel = labelValueText => (dispatch, getState) => {
  dispatch({ type: ACTIONS.CLEAR_ERROR });

  dispatch({
    type: ACTIONS.NEW_LABEL_REQUEST,
    payload: 'waiting response from API...',
  });

  const {
    currentProject,
  } = getState().projects;

  if ((currentProject === null) || (typeof currentProject === 'undefined')) {
    dispatch({
      type: ACTIONS.NEW_LABEL_FAILURE,
      error: CONSTANTS.COMMONERRORS.NO_CURRENT_PROJECT,
    });
    return;
  }

  const projectIRI = getProjectIRI(currentProject);
  Labels.createLabel({
    name: labelValueText,
    project: projectIRI,
    mediaType: getVideoMediaTypeIRI(currentProject),
    type: LABEL_TYPES.DEFAULT,
  }).then((label) => {
    dispatch({
      type: ACTIONS.NEW_LABEL_SUCCESS,
      newLabel: label,
    });
  }).catch((error) => {
    dispatch({
      type: ACTIONS.NEW_LABEL_FAILURE,
      error: error.message,
    });
  });
};

export const createNewLabelAndBind = (labelValueText, assetsToModify) => (dispatch, getState) => {
  dispatch({ type: ACTIONS.CLEAR_ERROR });

  const {
    currentProject,
  } = getState().projects;
  if ((currentProject === null) || (typeof currentProject === 'undefined')) {
    return;
  }

  // Media type of the new label
  const labelMediaType = isVideoAssets(assetsToModify, currentProject)
    ? getVideoMediaTypeIRI(currentProject)
    : getAudioMediaTypeIRI(currentProject);

  // Just a quick check - Does the label already exist ?
  const existingLabel = getLabelByName(getState(), labelValueText);

  function bindLabelToAssets(label) {
    const changesArray = [];
    for (let i = 0; i < assetsToModify.length; i += 1) {
      const assetToModify = assetsToModify[i];
      const newChange = {};
      newChange.previousLabels = [...assetToModify.labels];
      const asset = {};
      setAssetIRI(asset, getAssetIRI(assetToModify));
      asset.labels = [...assetToModify.labels];
      asset.labels.push(getLabelIRI(label));
      newChange.asset = asset;
      changesArray.push(newChange);
    }
    dispatch(changeAssetsLabels(changesArray));
  }

  if ((typeof existingLabel !== 'undefined')
   && (existingLabel !== null)) {
    // Associate the existing label to the given assets
    bindLabelToAssets(existingLabel);
    return;
  }
  dispatch({
    type: ACTIONS.NEW_LABEL_REQUEST,
    payload: 'waiting response from API...',
  });

  const projectIRI = getProjectIRI(currentProject);
  Labels.createLabel({
    name: labelValueText,
    project: projectIRI,
    mediaType: labelMediaType,
    type: LABEL_TYPES.DEFAULT,
  }).then((label) => {
    // Associate the created label to the given assets
    bindLabelToAssets(label);
    dispatch({
      type: ACTIONS.NEW_LABEL_SUCCESS,
      newLabel: label,
    });
    return label;
  }).catch((error) => {
    dispatch({
      type: ACTIONS.NEW_LABEL_FAILURE,
      error: error.message,
    });
    return error;
  });
};

/**
 * Update Label
 * @param {Object} label
 */
export const updateLabel = label => (dispatch, getState) => {
  dispatch({ type: ACTIONS.CLEAR_ERROR });

  dispatch({
    type: ACTIONS.UPDATE_LABEL_REQUEST,
    payload: 'waiting response from API...',
  });

  const {
    currentProject,
  } = getState().projects;

  if ((currentProject === null) || (typeof currentProject === 'undefined')) {
    dispatch({
      type: ACTIONS.UPDATE_LABEL_FAILURE,
      error: CONSTANTS.COMMONERRORS.NO_CURRENT_PROJECT,
    });
    return;
  }

  const labelClone = { ...label };
  labelClone.project = getProjectIRI(currentProject);

  Labels.updateLabel(labelClone).then((response) => {
    dispatch({
      type: ACTIONS.UPDATE_LABEL_SUCCESS,
      payload: response,
    });
  }).catch((error) => {
    dispatch({
      type: ACTIONS.UPDATE_LABEL_FAILURE,
      error: error.message,
    });
  });
};

/**
 * Creates the arguments for the undo command of a datakey modification
 * @param {*} state state when the action to undo was called
 * @param {*} action action to undo
 */
function undoArgsForUpdateLabel(state, action) {
  const {
    currentProject,
  } = state.projects;
  const {
    labels,
    labelsIndexesMap,
  } = state.labels;

  if ((currentProject === null) || (typeof currentProject === 'undefined')) {
    return null;
  }

  const modifiedLabel = action.payload;
  const foundIdx = labelsIndexesMap[getLabelIRI(modifiedLabel)];

  // Create the smaller as possible label for the undo request
  const previousLabel = labels[foundIdx];
  const keys = Object.keys(previousLabel);
  const labelForUndo = {
    id: previousLabel.id,
  };
  setLabelIRI(labelForUndo, getLabelIRI(previousLabel));
  // Parse the object and keep only the different attributes
  keys.forEach((elem) => {
    if ((typeof modifiedLabel[elem] !== 'undefined')
     && (modifiedLabel[elem] !== null)
     && (modifiedLabel[elem] !== previousLabel[elem])) {
      labelForUndo[elem] = previousLabel[elem];
    }
  });

  return {
    label: labelForUndo,
  };
}// undoArgsForUpdateLabel

/**
 * Creates the arguments for the redo command of a label modification
 * @param {*} state state when the action to redo was called
 * @param {*} action action to redo
 */
function redoArgsForUpdateLabel(state, action) {
  return {
    label: action.payload,
  };
}

/**
 * Quick and dirty : just a loop to create one label after the other
 * There should be a single request DELETE_LABELS_REQUEST to delete all labels
 * at same time.
 * And it should take a 'undoable' parameter as it may be called in delete of assets user action
 * @param {*} labels
 */
export const deleteLabels = labels => (dispatch, getState) => {
  if ((typeof labels === 'undefined')
   || (labels === null)
   || (labels.length === 0)) {
    return;
  }

  labels.forEach((lbl) => {
    dispatch(deleteLabel(lbl));
  });
};


export const deleteLabel = label => (dispatch, getState) => {
  dispatch({ type: ACTIONS.CLEAR_ERROR });

  dispatch({
    type: ACTIONS.DELETE_LABEL_REQUEST,
    payload: 'waiting response from API...',
  });

  const {
    currentProject,
  } = getState().projects;

  if ((currentProject === null) || (typeof currentProject === 'undefined')) {
    dispatch({
      type: ACTIONS.DELETE_LABEL_FAILURE,
      error: CONSTANTS.COMMONERRORS.NO_CURRENT_PROJECT,
    });
    return;
  }

  const labelClone = { ...label };
  labelClone.project = getProjectIRI(currentProject);

  Labels.deleteLabel(labelClone).then(() => {
    dispatch({
      type: ACTIONS.DELETE_LABEL_SUCCESS,
      labelIRI: getLabelIRI(labelClone),
      assets: labelClone.assets,
    });
  }).catch((error) => {
    dispatch({
      type: ACTIONS.DELETE_LABEL_FAILURE,
      error: error.message,
    });
  });
};

export default (state = initialState, action) => {
  switch (action.type) {
    case PROJECTSACTIONS.CLEAR_ERROR:
    case ACTIONS.CLEAR_ERROR:
      return {
        ...state,
        error: null,
        status: null,
      };

    case PROJECTSACTIONS.GET_PROJECT_SUCCESS:
    {
      const labels = [];
      const newLabelsIndexesMap = {};
      action.labels.forEach((lbl, idx) => {
        const newLbl = { ...lbl };
        labels.push(newLbl);
        newLabelsIndexesMap[getLabelIRI(lbl)] = idx;
      });

      return {
        ...state,
        status: ACTIONS.GET_DATAKEYS_SUCCESS,
        labels,
        labelsIndexesMap: newLabelsIndexesMap,
      };
    }
    case ACTIONS.NEW_LABEL_REQUEST:
      return {
        ...state,
        status: ACTIONS.NEW_LABEL_REQUEST,
      };

    case ACTIONS.NEW_LABEL_SUCCESS:
    {
      const clonedIndexesMap = { ...state.labelsIndexesMap };
      clonedIndexesMap[getLabelIRI(action.newLabel)] = state.labels.length;

      const newlyCreatedLabel = { ...action.newLabel };

      return {
        ...state,
        error: null,
        status: ACTIONS.NEW_LABEL_SUCCESS,
        labels: [...state.labels, newlyCreatedLabel],
        labelsIndexesMap: clonedIndexesMap,
      };
    }

    case ACTIONS.NEW_LABEL_FAILURE:
      return {
        ...state,
        error: action.error,
        status: ACTIONS.NEW_LABEL_FAILURE,
      };

    case ACTIONS.UPDATE_LABEL_REQUEST:
      return {
        ...state,
        status: ACTIONS.UPDATE_LABEL_REQUEST,
      };

    case ACTIONS.UPDATE_LABEL_SUCCESS: {
      const cloneLabels = [...state.labels];
      const labelIndex = state.labelsIndexesMap[getLabelIRI(action.payload)];
      cloneLabels[labelIndex] = action.payload;

      return {
        ...state,
        labels: [...cloneLabels],
        status: ACTIONS.UPDATE_LABEL_SUCCESS,
      };
    }

    case ACTIONS.UPDATE_LABEL_FAILURE:
      return {
        ...state,
        error: action.error,
        status: ACTIONS.UPDATE_LABEL_FAILURE,
      };

    case ACTIONS.DELETE_LABEL_REQUEST:
      return {
        ...state,
        status: ACTIONS.DELETE_LABEL_REQUEST,
      };

    case ACTIONS.DELETE_LABEL_SUCCESS:
    {
      // Remove the deleted label from the current list of labels
      // Decrement the number of total labels

      const newLabels = [...state.labels];
      newLabels.splice(state.labelsIndexesMap[action.labelIRI], 1);

      // Reset the quick access array of indexes
      const newLabelsIndexesMap = {};
      newLabels.forEach((elem, index) => {
        newLabelsIndexesMap[getLabelIRI(elem)] = index;
      });

      return {
        ...state,
        error: null,
        status: ACTIONS.DELETE_LABEL_SUCCESS,
        labels: newLabels,
        labelsIndexesMap: newLabelsIndexesMap,
      };
    }
    case ACTIONS.DELETE_LABEL_FAILURE:
      return {
        ...state,
        error: action.error,
        status: ACTIONS.DELETE_LABEL_FAILURE,
      };

    case MEDIALIBRARYACTIONS.NEW_ASSET_SUCCESS:
    {
      const newLabels = [...state.labels];
      const newlyCreatedAsset = action.payload;

      if ((typeof newlyCreatedAsset !== 'undefined')
       && (newlyCreatedAsset !== null)
       && (typeof newlyCreatedAsset.labels !== 'undefined')
       && (newlyCreatedAsset.labels !== null)
       && (newlyCreatedAsset.labels.length !== 0)) {
        newlyCreatedAsset.labels.forEach((lblIRI) => {
          const labelIndex = state.labelsIndexesMap[lblIRI];
          const currentLabel = newLabels[labelIndex];

          newLabels.splice(labelIndex, 1, {
            ...currentLabel,
            assets: [...currentLabel.assets, getAssetIRI(newlyCreatedAsset)],
          });
        });
      }

      return {
        ...state,
        labels: newLabels,
      };
    }

    case MEDIALIBRARYACTIONS.UPDATE_ASSETS_LABELS_SUCCESS:
    {
      const newLabels = [...state.labels];
      const modifiedAssets = action.changedData;

      modifiedAssets.forEach((changedAssetData) => {
        const modifiedAsset = changedAssetData.asset;

        const oldLabels = [...changedAssetData.previousLabels];

        modifiedAsset.labels.forEach((lblIRI) => {
          const labelIndex = state.labelsIndexesMap[lblIRI];
          const currentLabel = newLabels[labelIndex];

          // Is this IRI in the old array ?
          const lblIndex = oldLabels.findIndex(elem => getLabelIRI(elem) === lblIRI);
          if (lblIndex !== -1) {
            oldLabels.splice(lblIndex, 1);
          }

          newLabels.splice(labelIndex, 1, {
            ...currentLabel,
            assets: [...currentLabel.assets, getAssetIRI(modifiedAsset)],
          });
        });

        // Remove on previous attached labels
        if (oldLabels.length > 0) {
          oldLabels.forEach((previousLabelIRI) => {
            const lblIdx = state.labelsIndexesMap[previousLabelIRI];
            const oldLabel = newLabels[lblIdx];
            const newLabelAssets = [...oldLabel.assets];

            const assetIndex = newLabelAssets.indexOf(getAssetIRI(modifiedAsset));
            newLabelAssets.splice(assetIndex, 1);

            newLabels.splice(lblIdx, 1, {
              ...oldLabel,
              assets: [...newLabelAssets],
            });
          });
        }
      });

      return {
        ...state,
        labels: newLabels,
      };
    }

    case MEDIALIBRARYACTIONS.UPDATE_ASSET_LABEL_SUCCESS:
    {
      const modifiedAsset = action.asset;
      const newLabels = [...state.labels];

      modifiedAsset.labels.forEach((lblIRI) => {
        const labelIndex = state.labelsIndexesMap[lblIRI];
        const currentLabel = newLabels[labelIndex];

        newLabels.splice(labelIndex, 1, {
          ...currentLabel,
          assets: [...currentLabel.assets, getAssetIRI(modifiedAsset)],
        });
      });

      // Remove on previous attached label
      if (action.removedLabels.length > 0) {
        action.removedLabels.forEach((previousLabelIRI) => {
          const lblIdx = state.labelsIndexesMap[previousLabelIRI];
          const oldLabel = newLabels[lblIdx];
          const newLabelAssets = [...oldLabel.assets];

          const assetIndex = newLabelAssets.indexOf(getAssetIRI(modifiedAsset));
          newLabelAssets.splice(assetIndex, 1);

          newLabels.splice(lblIdx, 1, {
            ...oldLabel,
            assets: [...newLabelAssets],
          });
        });
      }

      // Add on new attached label
      if (action.addedLabels.length > 0) {
        action.addedLabels.forEach((addedLblIRI) => {
          const lblIdx = state.labelsIndexesMap[addedLblIRI];
          const oldLabel = newLabels[lblIdx];

          newLabels.splice(lblIdx, 1, {
            ...oldLabel,
          });
        });
      }

      return {
        ...state,
        labels: newLabels,
      };
    }

    case MEDIALIBRARYACTIONS.DELETE_ASSETS_SUCCESS:
    {
      const newLabels = [...state.labels];

      action.deletedAssets.forEach((deletedAsset) => {
        deletedAsset.labels.forEach((labelToUpdate) => {
          const lblIdx = state.labelsIndexesMap[labelToUpdate];
          const oldLabel = newLabels[lblIdx];
          if (oldLabel !== undefined) {
            const newLabelAssets = [...oldLabel.assets];

            const assetIndex = newLabelAssets.indexOf(getAssetIRI(deletedAsset));
            newLabelAssets.splice(assetIndex, 1);

            newLabels.splice(lblIdx, 1, {
              ...oldLabel,
              assets: [...newLabelAssets],
            });
          }
        });
      });

      return {
        ...state,
        labels: newLabels,
      };
    }

    default:
      return state;
  }
};
