import React from 'react';
import classNames from 'classnames';
import { withStyles } from '@material-ui/core/styles';
import { ReactComponent as SpinnerSVG } from './assets/svg/spinner.svg';

const styles = theme => ({
  spinning: {
    animation: `$spinIconAnimation`,
    animationDuration: '1s',
    animationIterationCount: 'infinite',
    animationTimingFunction: 'linear',
  },
  "@keyframes spinIconAnimation": {
    from: {
      transform: 'rotate(0deg)',
    },
    to: {
      transform: 'rotate(360deg)',
    },  
  },
});

const SkwrSpinningIcon = React.forwardRef(({
  className,
  classes,
  ...props
}, ref) => {
  return (
    <div
      className={classNames(className, classes.spinning)}
      ref={ref}
      {...props}
    >
      <SpinnerSVG />
    </div>
  );
});

export default withStyles(styles)(SkwrSpinningIcon);
