import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Color } from './UIConstants';

const useStyles = makeStyles(theme => ({
  drag: {
    flexShrink: '0',
    display: 'flex',
    width: '20px',
    alignItems: 'stretch',
    justifyContent: 'center',
    cursor: 'ew-resize',
  },
  bar: {
    border: `1px solid ${Color.greyAlt}`,
  },
}));

export default function (props) {
  // const [value, setValue] = React.useState(0);
  const classes = useStyles();

  const mouseDown = (e) => {
    let isMousedown = true;
    const start = e.clientX;
    let addWidth = 0;
    window.addEventListener('mousemove', (evt) => {
      if (isMousedown === true) {
        addWidth = start - evt.clientX;
        props.resizePannel(addWidth);
      }
    });

    window.addEventListener('mouseup', (evt) => {
      isMousedown = false;
    });
  };

  return (
    <div
      className={classes.drag}
      onMouseDown={mouseDown}
      role="button"
    >
      <div className={classes.bar} />
    </div>
  );
}
