/**
 * File: authenticationStateConstants.js
 */
const ACTIONS = {
  CLEAR_ERROR: 'AUTH_CLEAR_ERROR',

  SIGNUP_REQUEST: 'SIGNUP_REQUEST',
  SIGNUP_SUCCESS: 'SIGNUP_SUCCESS',
  SIGNUP_FAILURE: 'SIGNUP_FAILURE',

  LOGIN_REQUEST: 'USERS_LOGIN_REQUEST',
  LOGIN_SUCCESS: 'USERS_LOGIN_SUCCESS',
  LOGIN_FAILURE: 'USERS_LOGIN_FAILURE',

  LOGOUT: 'USERS_LOGOUT',

  FORGOT_PASSWORD_REQUEST: 'USER_FORGOT_PASSWORD_REQUEST',
  FORGOT_PASSWORD_SUCCESS: 'USER_FORGOT_PASSWORD_SUCCESS',
  FORGOT_PASSWORD_FAILURE: 'USER_FORGOT_PASSWORD_FAILURE',

  RESET_PASSWORD_REQUEST: 'USER_RESET_PASSWORD_REQUEST',
  RESET_PASSWORD_SUCCESS: 'USER_RESET_PASSWORD_SUCCESS',
  RESET_PASSWORD_FAILURE: 'USER_RESET_PASSWORD_FAILURE',

  CHECKTOKEN_REQUEST: 'CHECKTOKEN_REQUEST',
  CHECKTOKEN_SUCCESS: 'CHECKTOKEN_SUCCESS',
  CHECKTOKEN_FAILURE: 'CHECKTOKEN_FAILURE',

  UPDATE_USERSETTINGS_REQUEST: 'UPDATE_USERSETTINGS_REQUEST',
  UPDATE_USERSETTINGS_SUCCESS: 'UPDATE_USERSETTINGS_SUCCESS',
  UPDATE_USERSETTINGS_FAILURE: 'UPDATE_USERSETTINGS_FAILURE',

  VALIDATE_USER_REQUEST: 'VALIDATE_USER_REQUEST',
  VALIDATE_USER_SUCCESS: 'VALIDATE_USER_SUCCESS',
  VALIDATE_USER_FAILURE: 'VALIDATE_USER_FAILURE',
};
export default ACTIONS;
