import React from 'react';
import Divider from '@material-ui/core/Divider';

const SkwrMenuDivider = () => {
  return (
    <Divider />
  );
};

export default SkwrMenuDivider;
