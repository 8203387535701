import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { injectIntl, defineMessages, FormattedMessage } from 'react-intl';
import {
  SkwrButton,
  SkwrDialog,
} from 'skewerui';
import { commonMessages } from '../../Constants';
import * as AvailabilityActions from '../../reducers/availabilityState';

// Text content
const messages = defineMessages({
  title: {
    id: 'NotAvailableDialog.title',
    defaultMessage: 'Not Available Feature',
  },
});

class NotAvailableDialog extends Component {
  handleClose = () => {
    const { availabilityActions } = this.props;

    availabilityActions.acknowledgeUnavailibility();
  };

  render() {
    const {
      intl: { formatMessage },
      available,
    } = this.props;

    return (
      <SkwrDialog
        open={!available}
        onClose={this.handleClose}
        title={formatMessage(messages.title)}
        actions={[
          <SkwrButton
            componentStyle="ButtonOutlined"
            onClick={this.handleClose}
          >
            {formatMessage(commonMessages.cancelButton)}
          </SkwrButton>,
        ]}
      >
        <FormattedMessage
          id="NotAvailableDialog.workInProgress"
          defaultMessage="This feature is not available yet.{br}Our team is working to make it available ASAP."
          values={{
            br: <br />,
          }}
        />
      </SkwrDialog>
    );
  }
}

const intlComponent = injectIntl(NotAvailableDialog);

export default connect(state => ({
  available: state.availability.available,
  reason: state.availability.reason,
}), dispatch => ({
  availabilityActions: bindActionCreators(AvailabilityActions, dispatch),
}))(intlComponent);
